import { Component, Host, h, Prop, Event, EventEmitter, Element, State, Method } from '@stencil/core';
import { getNamespacedTagFor } from '../../utils/namespace';

/**
 * @slot - The text for the toast
 * @slot action - for `<a href>` or `<button>` (not a `<market-button>`)
 */
@Component({
  tag: 'market-toast',
  styleUrl: 'market-toast.css',
  shadow: true,
})
export class MarketToast {
  @Element() el: HTMLMarketToastElement;

  /**
   * toast variant that corresponds to the type of info it is conveying
   */
  @Prop({ reflect: true }) readonly variant: 'info' | 'success' | 'warning' | 'critical' | 'insight' = 'info';

  /**
   * Whether or not the toast persists, ie does not autodismiss after 5s
   */
  @Prop() readonly persistent: boolean = false;

  /**
   * Optional property to pass a string to the dismiss "x"
   * that will function as its aria-label. Defaults to "Dismiss".
   */
  @Prop({ reflect: true }) readonly dismissButtonAriaLabel: string = 'Dismiss';

  /**
   * The progress of the action, progress bar will be rendered for values between 0-100 inclusive
   */
  @Prop({ reflect: true }) readonly progress: number = -1;

  /**
   * The amount of time (ms) until the toast autodismisses
   * TODO(UI-1153): should be a design token
   */
  @State() durationTilAutoDismiss: number = 5000;

  @State() showActionsNav: boolean = false;

  /**
   * Emitted when the toast automatically dismisses.
   */
  @Event() marketToastAutoDismissed: EventEmitter;

  /**
   * Emitted when the toast's dismissed button is clicked.
   */
  @Event() marketToastManuallyDismissed: EventEmitter;

  /**
   * Set toast to disappear after the autodismiss timeout has passed
   */
  @Method()
  startAutoDismissTimer() {
    if (!this.persistent) {
      setTimeout(() => {
        this.marketToastAutoDismissed.emit();
      }, this.durationTilAutoDismiss);
    }
    return Promise.resolve();
  }

  handleManualDismiss() {
    this.marketToastManuallyDismissed.emit();
  }

  componentWillLoad() {
    this.checkIfActionsArePresent();
  }

  checkIfActionsArePresent() {
    const actions = this.el.querySelector('[slot="action"]');
    this.showActionsNav = actions ? true : false;
  }

  // TODO: replace with design token references after they are added (UI-6241)
  getVariantIcon() {
    switch (this.variant) {
      case 'success':
        return 'success';
      case 'info':
        return 'info';
      case 'warning':
        return 'attention';
      case 'insight':
        return 'recommendation';
      default:
        return 'warning';
    }
  }

  render() {
    const MarketIconTagName = getNamespacedTagFor('market-icon');
    const progress = Math.min(this.progress, 100);
    const progressStyle = {
      width: `${progress}%`,
    };

    return (
      <Host class="market-toast" role="alert">
        <div class="content">
          <span class="icon-container">
            {/* this span is the flexbox container to vertically align the icon */}
            <MarketIconTagName name={this.getVariantIcon()}></MarketIconTagName>
          </span>
          <div class="main">
            <section>
              <slot></slot>
            </section>
            <nav aria-label="toast-actions" class={this.showActionsNav ? '' : 'hidden'}>
              <slot name="action" onSlotchange={() => this.checkIfActionsArePresent()}></slot>
            </nav>
          </div>
          <nav aria-label="dismiss-container" class="dismiss-container">
            <button type="button" aria-label={this.dismissButtonAriaLabel} onClick={() => this.handleManualDismiss()}>
              <MarketIconTagName name="close"></MarketIconTagName>
            </button>
          </nav>
        </div>
        {this.progress >= 0 && (
          <progress-bar
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
            style={progressStyle}
          ></progress-bar>
        )}
      </Host>
    );
  }
}
