import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import type { JSX } from '@market/react';
import { MarketBanner } from 'src/components/Market';

/**
 * Primitive wrapper of the MarketBanner component for the Messenger.
 * The purpose of this component is to prevent the default dismiss behaviour from Market
 * that can break the page when the banner is dismissed when used alongside conditional rendering.
 */
const MessengerBanner = observer(
  ({
    onMarketBannerDismissed,
    ...rest
  }: JSX.MarketBanner & {
    className?: string;
    children?: ReactNode;
  }): ReactElement => (
    <MarketBanner
      {...rest}
      onMarketBannerDismissed={(e) => {
        e.preventDefault();
        onMarketBannerDismissed?.(e);
      }}
    />
  ),
);

export default MessengerBanner;
