import { makeAutoObservable, runInAction } from 'mobx';
import Api from 'src/api/Api';
import { IMoney } from 'src/gen/squareup/common/currency';
import { UnitDedicatedNumber } from 'src/gen/squareup/messenger/v3/messenger_service';
import Logger from 'src/Logger';
import type MessengerController from 'src/MessengerController';
import { LoadingStatus, Unit } from 'src/MessengerTypes';
import { SubscriptionStatus } from 'src/gen/squareup/sub2/common';
import { SEVENTY_TWO_HOURS_IN_MILLISECONDS } from 'src/utils/timeUtils';
import { unitsComparator } from './UserStore/utils';
import {
  MESSAGES_PLUS_SOURCE_TO_APP_SCHEME,
  MOBILE_APP_NAME,
  MobileAppName,
} from 'src/utils/appSchemes';
import { getMessagesPlusSourceFromQueryString } from 'src/utils/url';
import { KEY_MESSAGES_PLUS } from './FeatureFlagStore';
import { refreshFeatureStatuses } from '@squareup/saas-shared-ui/dist/api';

// Prefix key for local storage to store the source of M+ onboarding.
const MESSAGES_PLUS_SOURCE_PREFIX = 'MESSAGES_PLUS_SOURCE_';

// Prefix key for local storage to store unit tokens that the user selected
// to onboard to M+.
const MESSAGES_PLUS_ONBOARDING_UNITS_PREFIX = 'MESSAGES_PLUS_ONBOARDING_UNITS_';

/**
 * Store to contain states and functions related to Messages Plus subscription.
 */
export default class SubscriptionStore {
  private _stores: MessengerController;
  private _api: Api;

  status: LoadingStatus = 'LOADING';

  subscriptionStatus = SubscriptionStatus.INVALID;

  pricing?: IMoney;

  isProhibited = false;

  // Epoch time in microseconds of when the free trial ends. Zero if not in free trial.
  freeTrialEndAt = 0;

  // Epoch time in microseconds when the subscription started. Zero if not loaded.
  startAt = 0;

  // Epoch time in microseconds of when the subscription will end or did end at. Zero if not loaded.
  endAt = 0;

  // Epoch time in microseconds of when the subscription will renew at. Zero if unsubscribed or not present.
  renewalAt = 0;

  // Epoch time in microseconds of when the subscription will be delinquent. Zero if not in in grace state.
  delinquentAt = 0;

  // The number of units subscribed to M+.
  unitUsage = 0;

  // The saved payment method display string for subscriptions.
  savedPaymentMethod: string | undefined;

  savedPaymentMethodStatus: LoadingStatus = 'NOT_STARTED';

  // Whether the user is eligible for SquareOne (SQ1).
  // This is fetched from Featurama, which checks that the user meets certain criteria in order to
  // be eligible for SquareOne. This does NOT mean that the user should automatically see upsells
  // for SquareOne.
  // If the user is ineligible for SQ1, we fallback to legacy behavior (M+ or M Free).
  isEligibleForSquareOne = false;

  // A truthy value means that the user has access to the MESSAGES_PLUS feature, irrespective of
  // type of subscription (i.e. legacy vs SQ1) or plan type (i.e. free trial vs paid sub).
  // This should correspond directly to `isFeatureEntitled` returned from saas-shared-ui.
  private _isMessagesFeatureEnabled = false;

  // Whether the user should see a SQ1 upsell for the MESSAGES_PLUS feature.
  // This should correspond directly to `showFeatureUpsell` returned from saas-shared-ui,
  // which checks `isEligibleForSquareOne && !isFeatureEntitled` under the hood.
  // When `isEligibleForSquareOne` is true, this should always be the inverse of `_isMessagesFeatureEnabled`.
  private _showSqOneFeatureUpsell = false;

  // Whether the user is in a free trial for SquareOne.
  // This should correspond directly to `isFeatureInTrial` returned from saas-shared-ui.
  private _isSqOneFeatureInTrial = false;

  /**
   * Status of feature statuses from saas-shared-ui.
   * 'NOT_STARTED' is not a valid status returned from saas-shared-ui and is only meant to
   * indicate that nothing has been returned yet from the useSubscriptionFeatureStatus hook.
   */
  private _saasFeatureStatus: LoadingStatus = 'NOT_STARTED';

  constructor(stores: MessengerController) {
    makeAutoObservable(this);

    this._stores = stores;
    this._api = stores.api;

    this.init();
  }

  init = async (): Promise<void> => {
    this.status = 'LOADING';
    try {
      await this.getSubscriptionDetails();

      runInAction(() => {
        this.status = 'SUCCESS';
      });
    } catch {
      Logger.logWithSentry('Unable to load subscription details', 'error');
      runInAction(() => {
        this.status = 'ERROR';
      });
    }
  };

  // Retry refreshing feature statuses when there is an error preventing the app
  // from loading.
  refreshFeatureStatusesOnError = async (): Promise<void> => {
    if (!this.isEligibleForSquareOne) return;

    try {
      // This will kick off a call to Featurama (BulkRetrieveFeatureStatuses)
      // which will update the status returned from `useSubscriptionFeatureStatus` hook.
      // A status change will be picked up by the `useSaasFeatureStatus` hook and
      // updated via `setSaasFeatureStatus`.
      await refreshFeatureStatuses();
    } catch (error) {
      // Only set if `refreshFeatureStatuses` somehow fails.
      this.setSaasFeatureStatus('ERROR');
      Logger.logWithDatadog(
        new Error(`Error refreshing feature statuses when errored`, {
          cause: error,
        }),
      );
    }
  };

  /**
   * Helper used to silently refresh the subscription details in the background.
   * Shows application level error state if refresh fails.
   */
  refresh = async (): Promise<void> => {
    try {
      await Promise.all([
        this.getSubscriptionDetails(),
        this.isEligibleForSquareOne
          ? refreshFeatureStatuses()
          : Promise.resolve(),
      ]);

      runInAction(() => {
        this.status = 'SUCCESS';
      });
    } catch {
      Logger.logWithSentry('Error refreshing subscription details', 'error');
      runInAction(() => {
        this.status = 'ERROR';
      });
    }
  };

  getSubscriptionDetails = async (): Promise<void> => {
    const {
      subscription,
      plan,
      isProhibited,
      unitDetails,
      isEligibleForSquareOne,
    } = await this._api.subscription.getSubscriptionDetails();

    // Extract information from subscription
    if (subscription) {
      this.subscriptionStatus = subscription.status;
      this.freeTrialEndAt = subscription.freeTrialEndAt?.instantUsec || 0;
      this.startAt = subscription.startAt?.instantUsec || 0;
      this.endAt = subscription.endAt?.instantUsec || 0;
      this.renewalAt = subscription.renewalAt?.instantUsec || 0;
      this.delinquentAt = subscription.delinquentAt?.instantUsec || 0;
      this.unitUsage = subscription.currentUsage?.unitUsages?.[0]?.count || 0;
    }

    // Extract information from plan
    this.pricing =
      subscription?.pricing?.bucketPricing?.cost ||
      plan?.pricing?.bucketPricing?.cost;

    // Extract information from unitDetails
    unitDetails.forEach((unitDetail) => {
      const {
        unitToken,
        isSubscribed,
        dedicatedNumber,
        isPendingCancellation,
      } = unitDetail;
      this._stores.user.setUnit(unitToken, {
        subscription: {
          isSubscribed,
          dedicatedNumber,
          isPendingCancellation,
        },
      });
    });

    this.isEligibleForSquareOne = isEligibleForSquareOne ?? false;

    // Extract other information
    this.isProhibited = isProhibited;
  };

  get isInitialized(): boolean {
    return this.status !== 'LOADING';
  }

  /**
   * Indicates if the user can manage the subscription.
   */
  get canManage(): boolean {
    return (
      !this.isProhibited &&
      this._stores.user.currentEmployee.canManageSubscriptions
    );
  }

  /**
   * Indicates whether the user has pending TFV.
   */
  get hasPendingDedicatedNumber(): boolean {
    return this._stores.user.unitsWithPendingDedicatedNumbers.length > 0;
  }

  /**
   * Indicates if the subscription is IN_GRACE, which means that the merchant's payment has failed
   * but that they are within the grace period to continue using M+.
   *
   * The billing status may still show 'FAILED' until a successful billing cycle changes it to 'PAID'.
   * Therefore we rely on subscription status rather than billing status.
   */
  get isSubscriptionInGrace(): boolean {
    return this.subscriptionStatus === SubscriptionStatus.IN_GRACE;
  }

  /**
   * Indicates whether the subscription is IN_GRACE and the merchant intends to keep the subscription.
   * In this case, the merchant needs to update their payment method in order for the subscription
   * to stay active.
   *
   * If the merchant has unsubscribed while their subscription is IN_GRACE, we will not show the CTA
   * to update their payment method. In this scenario, the subscription status stays IN_GRACE but the
   * subscription will have an `endAt` timestamp.
   */
  get inGraceWithActiveSubscription(): boolean {
    return this.isSubscriptionInGrace && !this.endAt;
  }

  /**
   * Indicates if the subscription is DELINQUENT, which means that the merchant's payment has failed
   * and they are outside the grace period to continue using M+.
   *
   * The billing status may still show 'LAPSED' until a successful billing cycle changes it to 'PAID'.
   * Therefore we rely on subscription status rather than billing status.
   */
  get isSubscriptionDelinquent(): boolean {
    return this.subscriptionStatus === SubscriptionStatus.DELINQUENT;
  }

  /**
   * Checks if the overall M+ is subscribed.
   */
  get isSubscribed(): boolean {
    return (
      this.subscriptionStatus === SubscriptionStatus.ACTIVE ||
      this.subscriptionStatus ===
        SubscriptionStatus.ACTIVE_PENDING_CANCELLATION ||
      this.subscriptionStatus === SubscriptionStatus.FREE_TRIAL ||
      this.subscriptionStatus ===
        SubscriptionStatus.FREE_TRIAL_PENDING_CANCELLATION ||
      this.subscriptionStatus === SubscriptionStatus.IN_GRACE
    );
  }

  /**
   * Checks if a unit is subscribed to M+.
   *
   * @param {string} unitToken
   * The unit to check if it is subscribed to M+.
   */
  isUnitSubscribed = (unitToken: string): boolean => {
    return (
      Boolean(
        this._stores.user.units.get(unitToken)?.subscription?.isSubscribed,
      ) && !this.isProhibited
    );
  };

  /**
   * Indicates if the subscription is active but set to be cancelled at a date tracked in the endAt property.
   */
  get isSubscriptionExpiring(): boolean {
    return (
      this.subscriptionStatus === SubscriptionStatus.ACTIVE_PENDING_CANCELLATION
    );
  }

  /**
   * Indicates if the user is on a free trial, but there subscription will eventually expire and not renew.
   */
  get isFreeTrialExpiring(): boolean {
    return (
      this.subscriptionStatus ===
        SubscriptionStatus.FREE_TRIAL_PENDING_CANCELLATION ||
      this.subscriptionStatus === SubscriptionStatus.FREE_TRIAL_NO_OBLIGATION
    );
  }

  /**
   * Shorthand to return if either the subscription or free trial is set to expire.
   */
  get isExpiring(): boolean {
    return this.isSubscriptionExpiring || this.isFreeTrialExpiring;
  }

  /**
   * Indicates whether the user is using M+ with a free trial.
   */
  get isFreeTrial(): boolean {
    if (this.isEligibleForSquareOne) {
      return this._isSqOneFeatureInTrial;
    }

    return (
      this.subscriptionStatus === SubscriptionStatus.FREE_TRIAL ||
      this.isFreeTrialExpiring
    );
  }

  /**
   * Returns true if the user has already subscribed to M+ at some point.
   */
  get hasPreviouslySubscribed(): boolean {
    return this.subscriptionStatus !== SubscriptionStatus.INVALID;
  }

  /**
   * Returns true if a unit dedicated number status is updated within 72h to the statuses specified.
   *
   * @param {string} unitToken
   * The token of the unit we are checking a recent subscription for.
   * @param {UnitDedicatedNumber.Status[]} statuses
   * A list of statuses used to filter the subscribed number status by.
   */
  isUnitDedicatedStatusRecentlyUpdatedTo(
    unitToken: string,
    statuses: UnitDedicatedNumber.Status[],
  ): boolean {
    const unit = this._stores.user.units.get(unitToken);
    const dedicatedNumberStatus = unit?.subscription?.dedicatedNumber?.status;
    const isNumberPresent =
      dedicatedNumberStatus && statuses.includes(dedicatedNumberStatus);
    return Boolean(
      isNumberPresent &&
        unit.subscription?.dedicatedNumber?.statusSinceMillis &&
        unit.subscription.dedicatedNumber.statusSinceMillis +
          SEVENTY_TWO_HOURS_IN_MILLISECONDS >
          Date.now(),
    );
  }

  /**
   * Returns true if a unit has NO_NUMBER dedicated number status.
   *
   * @param {string} unitToken
   * The token of the unit to check.
   */
  isUnitNoNumber(unitToken: string): boolean {
    return (
      this._stores.user.units.get(unitToken)?.subscription?.dedicatedNumber
        ?.status === UnitDedicatedNumber.Status.NO_NUMBER
    );
  }

  /**
   * Returns true if a unit has FAILED_RETRYABLE dedicated number status.
   *
   * @param {string} unitToken
   * The token of the unit to check.
   */
  isUnitRetryableFailure(unitToken: string): boolean {
    return (
      this._stores.user.units.get(unitToken)?.subscription?.dedicatedNumber
        ?.status === UnitDedicatedNumber.Status.FAILED_RETRYABLE
    );
  }

  /**
   * Returns whether the unit is pending verification.
   *
   * @param {string} unitToken
   * The token of the unit to check.
   */
  isUnitPendingVerification(unitToken: string): boolean {
    const unitDedicatedNumberStatus =
      this._stores.user.units.get(unitToken)?.subscription?.dedicatedNumber
        ?.status;

    return (
      unitDedicatedNumberStatus === UnitDedicatedNumber.Status.PENDING ||
      unitDedicatedNumberStatus === UnitDedicatedNumber.Status.UNVERIFIED ||
      unitDedicatedNumberStatus === UnitDedicatedNumber.Status.CANCELED_PENDING
    );
  }

  /**
   * Returns whether the unit is pending verification or failed retryable.
   * Failed nonretryable is included here because there are scenarios where the unit
   * was incorrectly marked as failed nonretryable and are not prohibited.
   *
   * @param {string} unitToken
   * The token of the unit to check.
   */
  isUnitPendingOrFailedRetryable(unitToken: string): boolean {
    const unitDedicatedNumberStatus =
      this._stores.user.units.get(unitToken)?.subscription?.dedicatedNumber
        ?.status;

    const failedButNotProhibited =
      unitDedicatedNumberStatus ===
        UnitDedicatedNumber.Status.FAILED_NONRETRYABLE && !this.isProhibited;

    return (
      unitDedicatedNumberStatus === UnitDedicatedNumber.Status.PENDING ||
      unitDedicatedNumberStatus === UnitDedicatedNumber.Status.UNVERIFIED ||
      unitDedicatedNumberStatus ===
        UnitDedicatedNumber.Status.CANCELED_PENDING ||
      unitDedicatedNumberStatus ===
        UnitDedicatedNumber.Status.FAILED_RETRYABLE ||
      failedButNotProhibited
    );
  }

  /**
   * Returns whether the unit is failed nonretryable and NOT prohibited.
   * Treatment is similar to pending verification, because
   * the account may be converted to failed retryable after manual review.
   *
   * @param {string} unitToken
   * The token of the unit to check.
   */
  isUnitFailedNonretryableAndNotProhibited(unitToken: string): boolean {
    const unitDedicatedNumberStatus =
      this._stores.user.units.get(unitToken)?.subscription?.dedicatedNumber
        ?.status;

    return (
      unitDedicatedNumberStatus ===
        UnitDedicatedNumber.Status.FAILED_NONRETRYABLE && !this.isProhibited
    );
  }

  /**
   * Check if a unit is pending cancellation.
   *
   * @param {string} unitToken
   * The token of the unit to check.
   */
  isUnitPendingCancellation(unitToken: string): boolean {
    return Boolean(
      this._stores.user.units.get(unitToken)?.subscription
        ?.isPendingCancellation,
    );
  }

  /**
   * Returns whether the merchant has an active unit that is pending verification
   * or failed nonretryable but not prohibited.
   */
  get hasUnitsPendingOrFailedNonretryableNotProhibited(): boolean {
    return this._stores.user.activeUnits.some(({ token }) => {
      return (
        this.isUnitPendingVerification(token) ||
        this.isUnitFailedNonretryableAndNotProhibited(token)
      );
    });
  }

  /**
   * Returns whether the merchant has an active unit that is subscribed.
   */
  get hasSubscribedUnit(): boolean {
    return this._stores.user.activeUnits.some(({ token }) => {
      return this.isUnitSubscribed(token);
    });
  }

  /**
   * Returns dedicated numbers for active units that are subscribed and recently verified
   * (in the last 3 days).
   */
  get recentlyVerifiedAndSubscribedNumbers(): UnitDedicatedNumber[] {
    return this._stores.user.activeUnits
      .filter(
        ({ token, subscription }) =>
          (this.isEligibleForSquareOne
            ? this.isMessagesFeatureEnabledWithSquareOne
            : this.isUnitSubscribed(token)) &&
          this.isUnitDedicatedStatusRecentlyUpdatedTo(token, [
            UnitDedicatedNumber.Status.VERIFIED,
          ]) &&
          subscription?.dedicatedNumber,
      )
      .map(
        ({ subscription }) =>
          subscription?.dedicatedNumber as UnitDedicatedNumber,
      );
  }

  /**
   * Units that failed TFN verification for a retryable reason.
   */
  get unitTokensFailedRetryable(): string[] {
    return this._stores.user.activeUnits
      .filter(({ token }) => this.isUnitRetryableFailure(token))
      .map(({ token }) => token);
  }

  get onboardingSourceLocalStorageKey(): string {
    return `${MESSAGES_PLUS_SOURCE_PREFIX}${this._stores.user.merchantToken}`;
  }

  get saveOnboardingUnitsStorageKey(): string {
    return `${MESSAGES_PLUS_ONBOARDING_UNITS_PREFIX}${this._stores.user.merchantToken}`;
  }

  /**
   * Returns all available units that can start the TFN flow.
   */
  get unitsAvailableForTFNFlow(): Unit[] {
    const units: Unit[] = [];

    if (this.isEligibleForSquareOne) {
      this._stores.user.activeUnits.forEach((unit) => {
        if (
          unit.subscription?.dedicatedNumber?.status ===
            UnitDedicatedNumber.Status.NO_NUMBER ||
          unit.subscription?.dedicatedNumber?.status ===
            UnitDedicatedNumber.Status.MISSING_INFO ||
          unit.subscription?.dedicatedNumber?.status ===
            UnitDedicatedNumber.Status.FAILED_RETRYABLE
        ) {
          units.push(unit);
        }
      });
    } else {
      this._stores.user.activeUnits.forEach((unit) => {
        if (
          !this.isUnitSubscribed(unit.token) &&
          (unit.subscription?.dedicatedNumber?.status ===
            UnitDedicatedNumber.Status.NO_NUMBER ||
            unit.subscription?.dedicatedNumber?.status ===
              UnitDedicatedNumber.Status.MISSING_INFO ||
            unit.subscription?.dedicatedNumber?.status ===
              UnitDedicatedNumber.Status.FAILED_RETRYABLE ||
            unit.subscription?.dedicatedNumber?.status ===
              UnitDedicatedNumber.Status.VERIFIED)
        ) {
          units.push(unit);
        }
      });
    }

    return units.sort(unitsComparator);
  }

  /**
   * Check if we have a source for Messages Plus onboarding through query param.
   * If we do, save that information in local storage.
   */
  checkOnboardingSource(): void {
    const source = getMessagesPlusSourceFromQueryString();
    const sqOneTrust = this._stores.services.sqOneTrust.getSqOneTrust();

    if (
      source &&
      window.localStorage &&
      !sqOneTrust.functionalityCookiesBlocked
    ) {
      window.localStorage.setItem(this.onboardingSourceLocalStorageKey, source);
    }
  }

  /**
   * Check if we have an onboarding source saved previously. If yes, we redirect
   * to the mobile app.
   */
  onOnboardingComplete(): void {
    const source = window.localStorage.getItem(
      this.onboardingSourceLocalStorageKey,
    );
    window.localStorage.removeItem(this.onboardingSourceLocalStorageKey);

    if (MOBILE_APP_NAME.includes(source as MobileAppName)) {
      window.location.replace(
        MESSAGES_PLUS_SOURCE_TO_APP_SCHEME[source as MobileAppName],
      );
    }
  }

  /**
   * Get the saved payment method for Square subscriptions.
   */
  async getSavedPaymentMethod(): Promise<void> {
    try {
      this.savedPaymentMethodStatus = 'LOADING';
      this.savedPaymentMethod =
        await this._api.subscription.getSavedPaymentMethod();
      this.savedPaymentMethodStatus = 'SUCCESS';
    } catch {
      this.savedPaymentMethodStatus = 'ERROR';
    }
  }

  /**
   * Save the unit tokens that the merchant has selected to subscribe to
   * M+ in local storage for use later.
   *
   * @param {string[]} unitTokens
   */
  saveOnboardingUnits(unitTokens: string[]): void {
    const sqOneTrust = this._stores.services.sqOneTrust.getSqOneTrust();

    if (window.localStorage && !sqOneTrust.functionalityCookiesBlocked) {
      window.localStorage.setItem(
        this.saveOnboardingUnitsStorageKey,
        JSON.stringify(unitTokens),
      );
    }
  }

  /**
   * Check if we previously saved the unit tokens intended for M+ subscription
   * so that we can resume the onboarding flow for the user.
   */
  checkOnboardingUnits(): string[] | undefined {
    const unitTokensString = window.localStorage.getItem(
      this.saveOnboardingUnitsStorageKey,
    );

    if (!unitTokensString) {
      return undefined;
    }

    window.localStorage.removeItem(this.saveOnboardingUnitsStorageKey);
    try {
      const unitTokens = JSON.parse(unitTokensString);
      return Array.isArray(unitTokens) ? unitTokens : undefined;
    } catch {
      return undefined;
    }
  }

  setIsFeatureEntitled = (isFeatureEntitled: boolean): void => {
    this._isMessagesFeatureEnabled = isFeatureEntitled;
  };

  setShowFeatureUpsell = (showFeatureUpsell: boolean): void => {
    this._showSqOneFeatureUpsell = showFeatureUpsell;
  };

  setIsFeatureInTrial = (isFeatureInTrial: boolean): void => {
    this._isSqOneFeatureInTrial = isFeatureInTrial;
  };

  /**
   * Update status of feature statuses from saas-shared-ui.
   *
   * @param {Exclude<LoadingStatus, 'NOT_STARTED'>} status
   */
  setSaasFeatureStatus = (
    status: Exclude<LoadingStatus, 'NOT_STARTED'>,
  ): void => {
    this._saasFeatureStatus = status;
  };

  // Wrapper to gate saas loading state with SQ1 eligibility
  get isSaasLoading(): boolean {
    return this.isEligibleForSquareOne
      ? this._saasFeatureStatus === 'NOT_STARTED' ||
          this._saasFeatureStatus === 'LOADING'
      : false;
  }

  // Wrapper to gate saas error state with SQ1 eligibility
  get isSaasError(): boolean {
    return this.isEligibleForSquareOne
      ? this._saasFeatureStatus === 'ERROR'
      : false;
  }

  // If seller is SQ1 eligible and does not have access to the MESSAGES_PLUS feature
  // Combined with checking the MESSAGES_PLUS feature flag, to ensure that upsells are only shown if Messages Plus
  // is available in the user's location.
  // When `isEligibleForSquareOne` and MESSAGES_PLUS feature flag are both true, this should
  // be the inverse of `isMessagesFeatureEnabledWithSquareOne`.
  // TODO (teresalin): Remove `isEligibleForSquareOne` check once successfully launched,
  // because showFeatureUpsell from @squareup/saas-shared-ui includes the check for isEligibleForSquareOne internally.
  // It is only included now to ensure that all SquareOne booleans are gated consistently via isEligibleForSquareOne from Messages BE.
  get showSquareOneMessagesFeatureUpsell(): boolean {
    return (
      this.isEligibleForSquareOne &&
      this._showSqOneFeatureUpsell &&
      Boolean(this._stores.featureFlag.get(KEY_MESSAGES_PLUS))
    );
  }

  // Whether or not the user is entitled to the MESSAGES_PLUS features with SquareOne.
  // When `isEligibleForSquareOne` and MESSAGES_PLUS feature flag are both true, this should
  // be the inverse of `showSquareOneMessagesFeatureUpsell`.
  get isMessagesFeatureEnabledWithSquareOne(): boolean {
    return (
      this.isEligibleForSquareOne &&
      this._isMessagesFeatureEnabled &&
      Boolean(this._stores.featureFlag.get(KEY_MESSAGES_PLUS))
    );
  }

  // Whether the user is eligible for SquareOne and the MESSAGES_PLUS feature is enabled (M+ FF is for country gating)
  get isEligibleForSquareOneFeature(): boolean {
    return (
      this.isEligibleForSquareOne &&
      Boolean(this._stores.featureFlag.get(KEY_MESSAGES_PLUS))
    );
  }

  // Determines if a feature is enabled. If the user is eligible for SquareOne, feature entitlement is checked.
  // If not, the M+ FF and subscription status is checked.
  get isEnabledForSquareOneOrMPlusAndSubscribed(): boolean {
    return this.isEligibleForSquareOne
      ? this.isMessagesFeatureEnabledWithSquareOne
      : Boolean(this._stores.featureFlag.get(KEY_MESSAGES_PLUS)) &&
          this.isSubscribed;
  }

  // Determines if a feature is enabled. If the user is eligible for SquareOne, feature entitlement is checked.
  // If not, the M+ FF and unit subscription status is checked.
  isEnabledForSquareOneOrUnitIsSubscribedToMPlus(unitToken: string): boolean {
    return this.isEligibleForSquareOne
      ? this.isMessagesFeatureEnabledWithSquareOne
      : Boolean(this._stores.featureFlag.get(KEY_MESSAGES_PLUS)) &&
          this.isUnitSubscribed(unitToken);
  }

  // Indicates if the user is on SQ1 and has the MESSAGES_PLUS feature enabled, but does not yet have a dedicated number for all units.
  get hasSqOneUnitsWithNoNumber(): boolean {
    return (
      this.isMessagesFeatureEnabledWithSquareOne &&
      this._stores.user.activeUnits.some(
        ({ subscription }) =>
          subscription?.dedicatedNumber?.status ===
          UnitDedicatedNumber.Status.NO_NUMBER,
      )
    );
  }

  // Indicates if the user is on SQ1 and has the MESSAGES_PLUS feature enabled, and at least 1 unit has a verified dedicated number.
  get hasSqOneUnitsWithVerifiedNumber(): boolean {
    return (
      this.isMessagesFeatureEnabledWithSquareOne &&
      this._stores.user.activeUnits.some(
        ({ subscription }) =>
          subscription?.dedicatedNumber?.status ===
          UnitDedicatedNumber.Status.VERIFIED,
      )
    );
  }
  // Indicates if the user is on SQ1 and has the MESSAGES_PLUS feature enabled, but does not yet have a dedicated number for all units.
  get hasSqOneUnitsWithoutDedicatedNumber(): boolean {
    return (
      this.isMessagesFeatureEnabledWithSquareOne &&
      this._stores.user.activeUnits.some(
        ({ subscription }) =>
          subscription?.dedicatedNumber?.status ===
            UnitDedicatedNumber.Status.NO_NUMBER ||
          subscription?.dedicatedNumber?.status ===
            UnitDedicatedNumber.Status.MISSING_INFO ||
          subscription?.dedicatedNumber?.status ===
            UnitDedicatedNumber.Status.FAILED_RETRYABLE ||
          (subscription?.dedicatedNumber?.status ===
            UnitDedicatedNumber.Status.FAILED_NONRETRYABLE &&
            !this.isProhibited),
      )
    );
  }
}
