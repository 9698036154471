export function getDefaultIconFidelity(icon): number {
  const defaultFidelity = Object.keys(icon)
    .filter((key) => key.includes('fidelity'))
    .find((fidelity) => {
      return icon[fidelity].default ? icon[fidelity].size : null;
    });

  return icon[defaultFidelity].size;
}

export function getFidelityToken(fidelity: number, icon) {
  return icon[`fidelity:${fidelity}`];
}

export function isValidTokenFidelity(fidelity: number, icon): boolean {
  return Boolean(getFidelityToken(fidelity, icon));
}

export function getMarketIconSVGSymbol(asset: string, fidelity?: number): HTMLElement {
  // Return the SVG asset matching the asset name and the fidelity provided. Of no fidelity provided, return the first match.
  return fidelity
    ? document.querySelector(`[data-name="${asset}"][data-fidelity="${fidelity}"]`)
    : document.querySelector(`[data-name="${asset}"]`);
}

export function isValidSpriteFidelity(asset: string, fidelity: number): boolean {
  return Boolean(getMarketIconSVGSymbol(asset, fidelity));
}
