import { Component, Event, EventEmitter, Host, h, Prop, Element, Method } from '@stencil/core';
import { getControlInputAriaLabel } from '../../utils/aria';

enum ARIA_VALUE {
  TRUE = 'true',
  FALSE = 'false',
  MIXED = 'mixed',
}

@Component({
  tag: 'market-checkbox',
  styleUrl: 'market-checkbox.css',
  shadow: true,
})
export class MarketCheckbox {
  @Element() el: HTMLMarketCheckboxElement;

  /**
   * Whether the checkbox is checked or unchecked. Operates independently of the indeterminate property.
   * If used as a slotted control inside of `market-row`, this will be overridden by the row's `selected` property.
   */
  @Prop({ mutable: true, reflect: true }) checked: boolean = false;

  /**
   * Whether the checkbox is disabled.
   */
  @Prop({ mutable: true, reflect: true }) disabled: boolean = false;

  /**
   * Whether the checkbox is indeterminate. If true, indeterminate visual state takes precedence over checked/unchecked.
   */
  @Prop({ mutable: true, reflect: true }) indeterminate: boolean = false;

  /**
   * Whether the checkbox is invalid.
   */
  @Prop({ reflect: true }) readonly invalid: boolean = false;

  /**
   * Whether the checkbox is focused or not.
   */
  @Prop({ mutable: true, reflect: true }) focused: boolean = false;

  /**
   * Whether the checkbox is hovered or not.
   */
  @Prop({ mutable: true, reflect: true }) hovered: boolean = false;

  /**
   * Whether the checkbox is active or not.
   */
  @Prop({ mutable: true, reflect: true }) active: boolean = false;

  /**
   * Fired whenever "checked" prop value changes.
   */
  @Event() marketCheckboxValueChange: EventEmitter<{ current: boolean; previous: boolean }>;

  innerInput: HTMLInputElement;

  /**
   * Toggles `checked` prop, and emits a change event accordingly.
   * Used by `market-row` to sync its selected state w/ slotted checkboxes.
   */
  @Method()
  setSelection(newValue: boolean, { silent = false } = {}) {
    // this method's implementation could be cleaned up and simplified
    // (see analogous setSelection methods in toggle & radio),
    // but the extra indeterminate state complicates things a bit.
    // so just implementing this in a roundabout way for now in order to
    // keep the tests the same, until we decide to handle it differently.
    // ideally the indeterminate state wouldn't change if the event is prevented,
    // but this could be a breaking change which would need to be addressed.
    const { marketCheckboxValueChange, checked: prevValue, innerInput } = this;

    if (typeof newValue !== 'boolean') return Promise.resolve();

    this.indeterminate = false;

    if (prevValue === newValue) return Promise.resolve();

    if (!silent) {
      const { defaultPrevented } = marketCheckboxValueChange.emit({
        current: newValue,
        previous: prevValue,
      });
      if (defaultPrevented) {
        return Promise.resolve();
      }
    }

    this.checked = newValue;
    // When using the non-lazy output target, this method sometimes gets called
    // from market-row's watcher after innerInput is removed, hence this check.
    if (innerInput) {
      innerInput.checked = newValue;
    }
    return Promise.resolve();
  }

  /**
   * Toggles `indeterminate` prop. Operates independently of the `checked` property but if `true`,
   * indeterminate visual appearance takes precedence over checked/unchecked.
   */
  @Method()
  setIndeterminate(newValue: boolean) {
    this.indeterminate = newValue;
    return Promise.resolve();
  }

  /**
   * DEPRECATED (3.x): Toggles `selected` state (unrelated to the HTML attribute `value`).
   */
  @Method()
  setValue(newValue: boolean) {
    /* eslint-disable-next-line no-console */
    console.warn("market-checkbox's setValue() method has been deprecated. Use setSelection() instead.", this.el);
    this.setSelection(newValue);
    return Promise.resolve();
  }

  /**
   * Sets `active` state. Allows external elements to programmatically
   * trigger active styling, ex. when slotted as a control into `market-row`.
   */
  @Method()
  setActive(value: boolean) {
    this.active = value;
    return Promise.resolve();
  }

  /**
   * Sets `hovered` state. Allows external elements to programmatically
   * trigger hover styling, ex. when slotted as a control into `market-row`.
   */
  @Method()
  setHover(value: boolean) {
    this.hovered = value;
    return Promise.resolve();
  }

  /**
   * Sets `disabled` state. Allows external elements to programmatically
   * trigger disabled styling, ex. when slotted as a control into `market-row`.
   */
  @Method()
  setDisabled(value: boolean) {
    this.disabled = value;
    return Promise.resolve();
  }

  /**
   * Sets `focused` state, except when disabled.
   * Allows external consumers to programmatically
   * trigger focused styling.
   */
  @Method()
  setFocus(value: boolean = true) {
    if (this.disabled) {
      return Promise.resolve();
    }
    this.focused = value;
    return Promise.resolve();
  }

  handleClick(event: MouseEvent) {
    // Always prevent default so we can manually control the selection
    event.preventDefault();

    if (this.disabled) {
      return;
    }

    this.setFocus();
    this.setSelection(!this.checked);
  }

  getCheckedState(): boolean | 'indeterminate' {
    return this.indeterminate ? 'indeterminate' : this.checked;
  }

  getAriaCheckedValue(): ARIA_VALUE {
    switch (this.getCheckedState()) {
      case 'indeterminate':
        return ARIA_VALUE.MIXED;
      case true:
        return ARIA_VALUE.TRUE;
      default:
        return ARIA_VALUE.FALSE;
    }
  }

  render() {
    return (
      <Host
        class="market-checkbox"
        role="checkbox"
        aria-checked={this.getAriaCheckedValue()}
        onBlur={() => {
          this.setFocus(false);
        }}
        onClick={this.handleClick}
        onFocus={() => {
          this.setFocus();
        }}
      >
        <input
          ref={(el) => (this.innerInput = el)}
          type="checkbox"
          aria-checked={this.getAriaCheckedValue()}
          aria-label={getControlInputAriaLabel(this.el)}
          checked={this.checked}
          indeterminate={this.indeterminate}
          disabled={this.disabled}
        />
        {this.checked && !this.indeterminate && (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="check"
          >
            <path
              d="M6 10L8.85714 13L14 7"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
        {/* indeterminate visual state overrides checked state */}
        {this.indeterminate && (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="indeterminate"
          >
            <path d="M6 10H14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        )}
      </Host>
    );
  }
}
