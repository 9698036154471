import { getNamespacedTagFor } from '../../utils/namespace';
import { TMarketListValidControlRowInputElement } from './types';

/**
 * Check if the value is considered empty
 * @param {unknown} value
 */
export function isValueEmpty(value: unknown): boolean {
  // Note: we don't check for 0 here because it's a valid value, so _.isEmpty() is not used
  return value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0);
}

/**
 * Check if the slotted control in <market-row slot="control-row> is a valid control row input
 * @param {unknown} control
 */
export function isValidControl(control: unknown): control is TMarketListValidControlRowInputElement {
  if (!control) {
    return false;
  }
  const tagName = (control as HTMLElement).tagName?.toLocaleLowerCase();
  const validControlTags = [
    getNamespacedTagFor('market-checkbox').toLocaleLowerCase(),
    getNamespacedTagFor('market-radio').toLocaleLowerCase(),
    getNamespacedTagFor('market-toggle').toLocaleLowerCase(),
  ] as string[];
  return validControlTags.includes(tagName);
}
