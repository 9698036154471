import {
  MODAL_DIALOG_ANIMATION_ENTER_TRANSITION_DURATION,
  MODAL_DIALOG_ANIMATION_EXIT_TRANSITION_DURATION,
} from '@market/market-theme/js/cjs/index.js';
import { Component, Host, Prop, Element, Method, Event, EventEmitter, h, Watch } from '@stencil/core';

import { DialogDismissedEvent, DialogLoadedEvent, DialogType } from '../../utils/dialog';
import {
  createAndActivateFocusTrap,
  FocusTrap,
  FocusTrapActivateOptions,
  FocusTrapDeactivateOptions,
  FocusTrapOptions,
} from '../../utils/focus-trap';
import { getNamespacedTagFor } from '../../utils/namespace';

/**
 * @slot - The main content of the dialog. Use `<section class="main">` tag.
 */
@Component({
  tag: 'market-dialog',
  styleUrl: 'market-dialog.css',
  shadow: true,
})
export class MarketDialog {
  @Element() el: HTMLMarketDialogElement;
  connectedCallbackTimeout: NodeJS.Timeout;
  type: DialogType = 'dialog';
  focusTrap: FocusTrap;
  header: HTMLMarketHeaderElement;

  /**
   * INTERNAL ONLY: Used in CSS to trigger start and stop animations
   */
  @Prop({ mutable: true, reflect: true }) hidden: boolean = false;

  /**
   * INTERNAL ONLY: Used by the context manager to identify a specific dialog/modal
   */
  @Prop({ reflect: true, attribute: 'data-dialog-id' }) readonly dialogID: string;

  /**
   * INTERNAL ONLY: Used by the context manager to identify a specific dialog/modal's place
   * in the stack
   */
  @Prop({ reflect: true, attribute: 'data-dialog-index' }) readonly index: number;

  /**
   * Whether the activity indicator is rendered or not
   */
  @Prop() readonly isLoading: boolean = false;

  /**
   * Whether the dialog is persistent or dismissable
   */
  @Prop({ reflect: true }) readonly persistent: boolean = false;

  /**
   * Enforces focus trapping on the dialog
   */
  @Prop({ mutable: true }) trapFocus: boolean = false;

  /**
   * The duration for the modal enter animation, set from design tokens
   */
  @Prop()
  readonly animationEnterDuration: number = MODAL_DIALOG_ANIMATION_ENTER_TRANSITION_DURATION;

  /**
   * The duration for the modal exit animation, set from design tokens
   */
  @Prop()
  readonly animationExitDuration: number = MODAL_DIALOG_ANIMATION_EXIT_TRANSITION_DURATION;

  /**
   * Triggered when the dialog finishes loading
   */
  @Event() marketDialogLoaded: EventEmitter<DialogLoadedEvent>;

  /**
   * Triggered when the dialog is dismissed, handled by context manager
   */
  @Event() marketDialogDismissed: EventEmitter<DialogDismissedEvent>;

  /**
   * Triggered when the dialog is fully dismissed
   */
  @Event() marketDialogDidDismiss: EventEmitter<DialogDismissedEvent>;

  /**
   * Emits the dismiss event
   * The parent context will handle actually removing elements from the DOM,
   * All the dialog needs to do it emit an event so actually closing it can be
   * some other elements problem
   */
  @Method()
  dismiss(dismissOptions?: Partial<DialogDismissedEvent>) {
    if (!this.persistent) {
      const { defaultPrevented } = this.marketDialogDismissed.emit({
        dialog: this.el,
        type: this.type,
        origin: dismissOptions?.origin || this.el,
      });

      if (!defaultPrevented) {
        this.hidden = true;

        /**
         * Emit a marketDialogDidDismiss event when modal gets fully dismissed (after animation).
         */
        setTimeout(() => {
          this.marketDialogDidDismiss.emit({
            dialog: this.el,
            type: this.type,
            origin: this.el,
          });
        }, this.animationExitDuration);
      }
    }
    return Promise.resolve();
  }

  @Watch('trapFocus')
  onTrapFocusChanged(newValue: boolean, oldValue: boolean) {
    // only activate/deactivate when the `trapFocus` prop value changes
    if (newValue !== oldValue) {
      if (newValue) {
        this.activateFocusTrap();
      } else {
        this.deactivateFocusTrap();
      }
    }
  }

  /**
   * Activates the focus trap
   *
   * See [`focus-trap.ts`](../../utils/focus-trap.ts) for default options
   *
   * @param {Object} [options] [focus-trap create options](https://github.com/focus-trap/focus-trap#createoptions)
   * @param {Object} [activateOptions] set options for [onActivate, onPostActivate, and checkCanFocusTrap](https://github.com/focus-trap/focus-trap#trapactivate)
   */
  @Method()
  activateFocusTrap(options?: FocusTrapOptions, activateOptions?: FocusTrapActivateOptions) {
    if (this.focusTrap) {
      this.focusTrap.activate(activateOptions ?? {});
      if (!this.trapFocus) {
        this.trapFocus = true;
      }
    } else {
      this.focusTrap = createAndActivateFocusTrap({
        activateOptions,
        el: this.el,
        options,
      });
    }
    return Promise.resolve();
  }

  /**
   * Deactivates the focus trap
   *
   * @param {FocusTrapDeactivateOptions} [deactivateOptions] set options for [onDeactivate, onPostDeactivate, and checkCanReturnFocus](https://github.com/focus-trap/focus-trap#trapdeactivate)
   */
  @Method()
  deactivateFocusTrap(deactivateOptions?: FocusTrapDeactivateOptions) {
    if (this.focusTrap) {
      this.focusTrap.deactivate({
        returnFocus: true,
        checkCanReturnFocus: (trigger) =>
          new Promise((resolve) => {
            if (typeof (trigger as any)?.setFocus === 'function') {
              (trigger as any).setFocus();
            } else {
              resolve(); // node.focus(); will be called by focus-trap
            }
          }),
        ...deactivateOptions,
      });
      this.focusTrap = undefined;
    }
    return Promise.resolve();
  }

  connectedCallback() {
    this.connectedCallbackTimeout = setTimeout(() => {
      /**
       * Emit a marketDialogLoaded event when the component connects. Need this so
       * the context manager isn't rummaging around it's DOM to try and find the
       * dialog that was just appended
       */
      this.marketDialogLoaded.emit({
        dialog: this.el,
        type: this.type,
      });

      if (this.trapFocus) {
        this.activateFocusTrap();
      }
    }, this.animationEnterDuration);
  }

  disconnectedCallback() {
    this.deactivateFocusTrap();

    /**
     * Prevents error caused by race conditions during rapid mounting and
     * unmounting of component by clearing the setTimeout from connectedCallback
     * if it gets called after disconnectedCallback.
     */
    clearTimeout(this.connectedCallbackTimeout);
  }

  render() {
    const MarketActivityIndicatorTagName = getNamespacedTagFor('market-activity-indicator');

    return (
      <Host class="market-dialog" role="dialog">
        {this.isLoading && <MarketActivityIndicatorTagName></MarketActivityIndicatorTagName>}
        <slot></slot>
      </Host>
    );
  }
}
