import React, { ReactElement, RefObject } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  CheckoutLink,
  Coupon,
  Estimate,
  Feedback,
  FeedbackFacet,
  Invoice,
  LocalUtterance,
  Marketing,
  MediumTimestamp as Timestamp,
  Order,
  Receipt,
  TranscriptViewItem as TranscriptViewItemType,
} from 'src/MessengerTypes';
import CustomerEventCard from 'src/pages/TranscriptViewPage/components/CustomerEventCard/CustomerEventCard';
import MerchantEventCard from 'src/pages/TranscriptViewPage/components/MerchantEventCard/MerchantEventCard';
import MarketingIcon from 'src/svgs/MarketingIcon';
import ReceiptIcon from 'src/svgs/ReceiptIcon';
import CouponPercentageIcon from 'src/svgs/CouponPercentageIcon';
import HappyIcon from 'src/svgs/HappyIcon';
import SadIcon from 'src/svgs/SadIcon';
import {
  moneyToCurrencyString,
  paymentToCurrencyString,
} from 'src/utils/moneyUtils';
import { useDate } from 'src/utils/timeUtils';
import Utterance from 'src/pages/TranscriptViewPage/components/Utterance/Utterance';
import {
  Medium,
  Utterance as UtteranceProto,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import MediumTimestamp from 'src/pages/TranscriptViewPage/components/MediumTimestamp/MediumTimestamp';
import Logger from 'src/Logger';
import OrderIcon from 'src/svgs/OrderIcon';
import InvoiceIcon from 'src/svgs/InvoiceIcon';
import EstimateIcon from 'src/svgs/EstimateIcon';
import CheckoutLinkCardIcon from 'src/svgs/CheckoutLinkCardIcon';
import {
  getCouponUrl,
  getCustomerMarketingUrl,
  getTransactionLink,
} from 'src/utils/url';
import FormSubmissionEventCard from 'src/pages/TranscriptViewPage/components/FormSubmissionEventCard/FormSubmissionEventCard';
import MessagesPluginEntryEventCard from 'src/pages/TranscriptViewPage/components/MessagesPluginEntryEventCard/MessagesPluginEntryEventCard';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import VoicemailEventCard from 'src/pages/TranscriptViewPage/components/VoicemailEventCard/VoicemailEventCard';
import SquareGoReviewEventCard from 'src/pages/TranscriptViewPage/components/SquareGoReviewCard/SquareGoReviewEventCard';
import MissedCallEventCard from 'src/pages/TranscriptViewPage/components/MissedCallEventCard/MissedCallEventCard';

export type TranscriptViewItemProps = {
  item: TranscriptViewItemType;
  isLastItem?: boolean;
  itemRef?: RefObject<HTMLDivElement>;
};

/**
 * This component extracts the information of a TranscriptViewItem object and decides
 * what kind of component to use to display it. A transcript view item is an element
 * that is rendered in the list of the TranscriptViewPage. It represents a message
 * or event that happen between the buyer and seller, such as utterances or appointment
 * events.
 *
 * Rendering is done by checking the 2 types of a TranscriptViewItem:
 * 1) dataType: this marks the type of information stored in an item. For example,
 * 'UTTERANCE' will contain a text and its metadata such as speaker type. 'APPOINTMENT' will
 * contain the appointment date, service, and staff information.
 * 2) componentType: this refers to how the item should be rendered. For example,
 * 'CUSTOMER_EVENT_CARD' looks like an utterance on the customer side with extra information above
 * the text. 'GENERAL_EVENT_CARD' is a card that is centered in the middle of a conversation.
 *
 * For component types, there are 2 high level visual categories:
 * - Card: A rounded-rectangle that contains content based on the dataType. It is inline with
 * other cards in a conversation.
 * - Banner: A rectangle that spans the full width of Messenger. It sticks to the top and is
 * independent of the list of cards.
 *
 * @example
 * Basic usage:
 * <TranscriptViewItem item={positiveFeedbackEvent} />
 *
 * With optional:
 * <TranscriptViewItem
 *   item={positiveFeedbackEvent}
 *   isLastItem={index === arr.length}
 * />
 * @param {TranscriptViewItemType} item
 * The event that we want to display.
 * @param {boolean} [isLastItem]
 * (Optional) If true, this is the last item in the list. Used to show
 * send status on utterance.
 * @param {RefObject<HTMLDivElement>} [itemRef]
 * (Optional) Ref for the TranscriptViewItem element.
 * @author klim
 */
const TranscriptViewItem = observer(
  ({
    item,
    isLastItem,
    itemRef,
  }: TranscriptViewItemProps): ReactElement | null => {
    const { user, event } = useMessengerControllerContext();
    const { t } = useTranslation();

    const {
      dataType,
      componentType,
      data,
      timestampMillis,
      attachedUtterance,
      showCustomerImage,
    } = item;

    // Utterance
    if (dataType === 'UTTERANCE' && componentType === 'UTTERANCE_CARD') {
      const localUtterance = data as LocalUtterance;
      const utterance = localUtterance.utterance;

      // Determine if we need to show send status
      let showSendStatus = false;
      if (
        utterance.sendStatus === UtteranceProto.SendStatus.PENDING ||
        utterance.sendStatus === UtteranceProto.SendStatus.FAILED ||
        isLastItem
      ) {
        showSendStatus = true;
      }

      return (
        <Utterance
          localUtterance={localUtterance}
          showSentStatus={showSendStatus}
          key={utterance.id ?? utterance.metadata?.clientId}
          showCustomerImage={showCustomerImage}
          utteranceRef={itemRef}
        />
      );
    }

    // Medium timestamp
    if (dataType === 'TIMESTAMP' && componentType === 'TIMESTAMP') {
      const timestamp = data as Timestamp;
      return (
        <MediumTimestamp
          timestampMillis={timestampMillis}
          medium={timestamp.medium ?? Medium.MEDIUM_UNRECOGNIZED}
        />
      );
    }

    // Feedback customer event card
    if (dataType === 'FEEDBACK' && componentType === 'CUSTOMER_EVENT_CARD') {
      const feedback = data as Feedback;

      let icon: ReactElement = <div />;
      let title = '';
      let subtitle = '';
      let description: string | undefined = undefined;
      let utterance: string | undefined = undefined;
      let link = '';

      // icon, title
      if (feedback.sentiment === 'POSITIVE') {
        icon = <HappyIcon />;
        title = t('ContextualEvent.feedback.sentiment.positive');
      }
      if (feedback.sentiment === 'NEGATIVE') {
        title = t('ContextualEvent.feedback.sentiment.negative');
        icon = <SadIcon />;
      }

      // subtitle
      const date = useDate(timestampMillis);
      if (feedback.payment && feedback.payment.amount) {
        link = getTransactionLink(feedback.paymentToken ?? '');
        const currencyAmount = paymentToCurrencyString(
          feedback.payment,
          user.locale,
        );
        subtitle = t('ContextualEvent.feedback.payment', {
          currencyAmount,
          date,
        });
      } else {
        subtitle = t('common.time.on_date', {
          date,
        });
      }

      // description
      if (feedback.facets) {
        const facetsText = feedback.facets.map((facet: FeedbackFacet) => {
          switch (facet) {
            case 'INVALID':
              return t('ContextualEvent.feedback.facets.invalid');
            case 'OTHER':
              return t('ContextualEvent.feedback.facets.other');
            case 'PRODUCT_QUALITY':
              return t('ContextualEvent.feedback.facets.product_quality');
            case 'WAIT_TIME':
              return t('ContextualEvent.feedback.facets.wait_time');
            case 'CUSTOMER_SERVICE':
              return t('ContextualEvent.feedback.facets.customer_service');
            case 'PRODUCT_SELECTION':
              return t('ContextualEvent.feedback.facets.product_selection');
            case 'QUALITY':
              return t('ContextualEvent.feedback.facets.quality');
            case 'THOROUGHNESS':
              return t('ContextualEvent.feedback.facets.thoroughness');
            case 'ENVIRONMENT':
              return t('ContextualEvent.feedback.facets.environment');
            case 'TIMELINESS':
              return t('ContextualEvent.feedback.facets.timeliness');
            case 'ROUTE_TAKEN':
              return t('ContextualEvent.feedback.facets.route_taken');
            case 'VEHICLE':
              return t('ContextualEvent.feedback.facets.vehicle');
            case 'TRAVEL_TIME':
              return t('ContextualEvent.feedback.facets.travel_time');
            case 'DRIVER':
              return t('ContextualEvent.feedback.facets.driver');
            case 'OFFERINGS':
              return t('ContextualEvent.feedback.facets.offerings');
            case 'PROFESSIONALISM':
              return t('ContextualEvent.feedback.facets.professionalism');
            case 'SELECTION':
              return t('ContextualEvent.feedback.facets.selection');
            case 'AMBIANCE':
              return t('ContextualEvent.feedback.facets.ambiance');
            case 'ORGANIZATION':
              return t('ContextualEvent.feedback.facets.organization');
            case 'LOCATION':
              return t('ContextualEvent.feedback.facets.location');
            case 'SERVICE':
              return t('ContextualEvent.feedback.facets.service');
            case 'UNSPECIFIED':
              return t('ContextualEvent.feedback.facets.unspecified');
            case 'PERFORMANCE_LENGTH':
              return t('ContextualEvent.feedback.facets.performance_length');
            case 'EVENT_LENGTH':
              return t('ContextualEvent.feedback.facets.event_length');
            case 'MOVIE_LENGTH':
              return t('ContextualEvent.feedback.facets.movie_length');
            case 'UNKNOWN_CHARGE':
              return t('ContextualEvent.feedback.facets.unknown_charge');
            case 'UNDELIVERED':
              return t('ContextualEvent.feedback.facets.undelivered');
            default:
              return t('ContextualEvent.feedback.facets.other');
          }
        });
        description = facetsText.join(', ');
      }

      // utterance
      if (feedback.comment) {
        utterance = feedback.comment;
      } else if (
        attachedUtterance?.utterance &&
        attachedUtterance.utterance.plainText
      ) {
        utterance = attachedUtterance.utterance.plainText;
      }

      return (
        <CustomerEventCard
          timestampMillis={timestampMillis}
          icon={icon}
          title={title}
          subtitle={subtitle}
          description={description}
          utterance={utterance}
          showCustomerImage={showCustomerImage}
          link={link}
          linkText={t('ContextualEvent.view.transaction')}
          track={() => event.track('Click View Transaction Feedback')}
          cardRef={itemRef}
          id={attachedUtterance?.utterance.id}
        />
      );
    }

    // Receipt customer event card
    if (dataType === 'RECEIPT' && componentType === 'CUSTOMER_EVENT_CARD') {
      const receipt = data as Receipt;

      let title = '';
      let subtitle = '';
      const description: string | undefined = undefined;
      let utterance: string | undefined = undefined;
      let link = '';

      // title
      title = t('ContextualEvent.receipt.title');

      // subtitle
      const date = useDate(timestampMillis);
      if (receipt.payment && receipt.payment.amount) {
        link = getTransactionLink(receipt.paymentToken ?? '');
        const currencyAmount = paymentToCurrencyString(
          receipt.payment,
          user.locale,
        );
        subtitle = t('ContextualEvent.feedback.payment', {
          currencyAmount,
          date,
        });
      } else {
        subtitle = t('common.time.on_date', {
          date,
        });
      }

      // utterance
      if (receipt.comment) {
        utterance = receipt.comment;
      } else if (
        attachedUtterance?.utterance &&
        attachedUtterance.utterance.plainText
      ) {
        utterance = attachedUtterance.utterance.plainText;
      }

      return (
        <CustomerEventCard
          timestampMillis={timestampMillis}
          icon={<ReceiptIcon />}
          title={title}
          subtitle={subtitle}
          description={description}
          utterance={utterance}
          showCustomerImage={showCustomerImage}
          link={link}
          linkText={t('ContextualEvent.view.transaction')}
          track={() => event.track('Click View Transaction Receipt')}
          cardRef={itemRef}
          id={attachedUtterance?.utterance.id}
        />
      );
    }

    // Marketing customer event card
    if (dataType === 'MARKETING' && componentType === 'CUSTOMER_EVENT_CARD') {
      const marketing = data as Marketing;

      let title = '';
      let subtitle = '';
      const description: string | undefined = undefined;
      let utterance: string | undefined = undefined;
      let link = '';

      // title
      title = t('ContextualEvent.marketing.title');

      // subtitle
      const date = useDate(timestampMillis);
      if (marketing.name) {
        link = marketing.url ?? getCustomerMarketingUrl;
        subtitle = `${marketing.name} ${t('common.time.on_date', {
          date,
        })}`;
      } else {
        subtitle = t('common.time.on_date', {
          date,
        });
      }

      // utterance
      if (marketing.comment) {
        utterance = marketing.comment;
      } else if (
        attachedUtterance?.utterance &&
        attachedUtterance.utterance.plainText
      ) {
        utterance = attachedUtterance.utterance.plainText;
      }

      return (
        <CustomerEventCard
          timestampMillis={timestampMillis}
          icon={<MarketingIcon />}
          title={title}
          subtitle={subtitle}
          description={description}
          utterance={utterance}
          showCustomerImage={showCustomerImage}
          link={link}
          linkText={t('ContextualEvent.view.campaign')}
          track={() => event.track('Click View Campaign')}
          cardRef={itemRef}
          id={attachedUtterance?.utterance.id}
        />
      );
    }

    // Form submission customer event card
    if (
      dataType === 'FORM_SUBMISSION' &&
      componentType === 'CUSTOMER_EVENT_CARD'
    ) {
      return <FormSubmissionEventCard item={item} cardRef={itemRef} />;
    }

    // Messages plugin entry event card
    if (
      dataType === 'MESSAGES_PLUGIN_SUBMISSION' &&
      componentType === 'CUSTOMER_EVENT_CARD'
    ) {
      return <MessagesPluginEntryEventCard item={item} cardRef={itemRef} />;
    }

    if (dataType === 'VOICEMAIL' && componentType === 'CUSTOMER_EVENT_CARD') {
      return <VoicemailEventCard item={item} cardRef={itemRef} />;
    }

    // Coupon merchant event card
    if (dataType === 'COUPON' && componentType === 'MERCHANT_EVENT_CARD') {
      const coupon = data as Coupon;

      let title = '';
      let subtitle = '';
      let link = undefined;

      // title
      if (coupon.name) {
        title = coupon.name;
      }

      // subtitle
      if (coupon.expirationMillis) {
        subtitle = t('ContextualEvent.coupon.expires', {
          date: useDate(coupon.expirationMillis),
        });
      }

      // link
      if (coupon.id) {
        link = getCouponUrl(coupon.id);
      }

      return (
        <MerchantEventCard
          timestampMillis={timestampMillis}
          icon={<CouponPercentageIcon />}
          title={title}
          subtitle={subtitle}
          link={link}
          linkText={t('ContextualEvent.view.coupon')}
          utterance={attachedUtterance}
          showSentStatus={isLastItem}
          track={() => event.track('Click View Coupon')}
          cardRef={itemRef}
          id={attachedUtterance?.utterance.id}
        />
      );
    }

    // Order merchant event card
    if (dataType === 'ORDER' && componentType === 'MERCHANT_EVENT_CARD') {
      const order = data as Order;

      let title = t('ContextualEvent.type.order');
      let subtitle = '';
      const link = order.url;

      // title
      if (order.event) {
        switch (order.event) {
          case UtteranceProto.Metadata.Order.OrderEvent.ORDER_CONFIRMED:
            title = t('ContextualEvent.order.confirmed');
            break;
          case UtteranceProto.Metadata.Order.OrderEvent.ORDER_READY_FOR_PICKUP:
            title = t('ContextualEvent.order.pickup');
            break;
          case UtteranceProto.Metadata.Order.OrderEvent.ORDER_SHIPPED:
            title = t('ContextualEvent.order.shipped');
            break;
          case UtteranceProto.Metadata.Order.OrderEvent.ORDER_CANCELLED:
            title = t('ContextualEvent.order.cancelled');
            break;
          default:
        }
      }

      // subtitle
      if (order.amount && order.numItems) {
        const adjustedAmount = {
          ...order.amount,
        };

        if (
          order.event ===
            UtteranceProto.Metadata.Order.OrderEvent.ORDER_CANCELLED &&
          adjustedAmount.amount
        ) {
          // Order amounts are always positive for canceled orders,
          // but need to be displayed as negative.
          adjustedAmount.amount *= -1;
        }

        const amount = moneyToCurrencyString(adjustedAmount, user.locale);
        const itemCount = t('ContextualEvent.order.item', {
          count: order.numItems,
        });
        subtitle = `${amount}・${itemCount}`;
      }

      return (
        <MerchantEventCard
          timestampMillis={timestampMillis}
          icon={<OrderIcon />}
          title={title}
          subtitle={subtitle}
          link={link}
          linkText={t('ContextualEvent.view.order')}
          utterance={attachedUtterance}
          showSentStatus={isLastItem}
          track={() => event.track('Click View Order')}
          cardRef={itemRef}
          id={attachedUtterance?.utterance.id}
        />
      );
    }

    // Invoice merchant event card
    if (dataType === 'INVOICE' && componentType === 'MERCHANT_EVENT_CARD') {
      const invoice = data as Invoice;

      let title = t('ContextualEvent.type.invoice');
      let subtitle = '';
      const link = invoice.url;

      // title
      if (invoice.event) {
        switch (invoice.event) {
          case UtteranceProto.Metadata.Invoice.InvoiceEvent.INVOICE_CREATED:
            title = t('ContextualEvent.invoice.created');
            break;
          case UtteranceProto.Metadata.Invoice.InvoiceEvent.INVOICE_UPDATED:
            title = t('ContextualEvent.invoice.updated');
            break;
          case UtteranceProto.Metadata.Invoice.InvoiceEvent.INVOICE_PAID:
            title = t('ContextualEvent.invoice.paid');
            break;
          case UtteranceProto.Metadata.Invoice.InvoiceEvent.INVOICE_CANCELLED:
            title = t('ContextualEvent.invoice.canceled');
            break;
          case UtteranceProto.Metadata.Invoice.InvoiceEvent.INVOICE_DECLINED:
            title = t('ContextualEvent.invoice.declined');
            break;
          case UtteranceProto.Metadata.Invoice.InvoiceEvent.INVOICE_REFUNDED:
            title = t('ContextualEvent.invoice.refunded');
            break;
          case UtteranceProto.Metadata.Invoice.InvoiceEvent
            .INVOICE_BANK_TRANSFER_PAYMENT_INITIATED:
            title = t('ContextualEvent.invoice.bank_transfer_initiated');
            break;
          case UtteranceProto.Metadata.Invoice.InvoiceEvent
            .INVOICE_BANK_TRANSFER_PAYMENT_COMPLETED:
            title = t('ContextualEvent.invoice.bank_transfer_completed');
            break;
          case UtteranceProto.Metadata.Invoice.InvoiceEvent
            .INVOICE_BANK_CHARGE_CONFIRMATION_REQUEST:
            title = t('ContextualEvent.invoice.bank_confirmation_request');
            break;
          default:
        }
      }

      // subtitle
      if (invoice.amount && invoice.name) {
        const amount = moneyToCurrencyString(invoice.amount, user.locale);
        subtitle = `${amount}・${invoice.name}`;
      }

      return (
        <MerchantEventCard
          timestampMillis={timestampMillis}
          icon={<InvoiceIcon />}
          title={title}
          subtitle={subtitle}
          link={link}
          linkText={t('ContextualEvent.view.invoice')}
          utterance={attachedUtterance}
          showSentStatus={isLastItem}
          track={() => event.track('Click View Invoice')}
          cardRef={itemRef}
          id={attachedUtterance?.utterance.id}
        />
      );
    }

    // Estimate merchant event card
    if (dataType === 'ESTIMATE' && componentType === 'MERCHANT_EVENT_CARD') {
      const estimate = data as Estimate;

      let title = t('ContextualEvent.type.estimate');
      let subtitle = '';
      const link = estimate.url;

      // title
      if (estimate.event) {
        switch (estimate.event) {
          case UtteranceProto.Metadata.Estimate.EstimateEvent.ESTIMATE_CREATED:
            title = t('ContextualEvent.estimate.created');
            break;
          case UtteranceProto.Metadata.Estimate.EstimateEvent.ESTIMATE_UPDATED:
            title = t('ContextualEvent.estimate.updated');
            break;
          case UtteranceProto.Metadata.Estimate.EstimateEvent.ESTIMATE_ACCEPTED:
            title = t('ContextualEvent.estimate.accepted');
            break;
          case UtteranceProto.Metadata.Estimate.EstimateEvent
            .ESTIMATE_CANCELLED:
            title = t('ContextualEvent.estimate.canceled');
            break;
          default:
        }
      }

      // subtitle
      if (estimate.amount && estimate.name) {
        const amount = moneyToCurrencyString(estimate.amount, user.locale);
        subtitle = `${amount}・${estimate.name}`;
      }

      return (
        <MerchantEventCard
          timestampMillis={timestampMillis}
          icon={<EstimateIcon />}
          title={title}
          subtitle={subtitle}
          link={link}
          linkText={t('ContextualEvent.view.estimate')}
          utterance={attachedUtterance}
          showSentStatus={isLastItem}
          track={() => event.track('Click View Estimate')}
          cardRef={itemRef}
          id={attachedUtterance?.utterance.id}
        />
      );
    }

    // Checkout link merchant event card
    if (
      dataType === 'CHECKOUT_LINK' &&
      componentType === 'MERCHANT_EVENT_CARD'
    ) {
      const checkoutLink = data as CheckoutLink;

      let title = '';
      let subtitle = '';
      let link = undefined;
      let icon = <CheckoutLinkCardIcon />;

      // title
      if (checkoutLink.title) {
        title = checkoutLink.title;
      } else {
        title = t('ContextualEvent.CheckoutLink.title');
      }

      // subtitle
      if (checkoutLink.amount && checkoutLink.amount.amount) {
        subtitle = moneyToCurrencyString(checkoutLink.amount, user.locale);
      } else {
        subtitle = t('ContextualEvent.CheckoutLink.customer_enters');
      }

      // link
      if (checkoutLink.url) {
        link = checkoutLink.url;
      }

      // icon
      if (checkoutLink.imageUrl) {
        icon = (
          <img
            data-testid="TranscriptViewItem__image"
            src={checkoutLink.imageUrl}
            alt={title}
          />
        );
      }

      return (
        <MerchantEventCard
          timestampMillis={timestampMillis}
          icon={icon}
          title={title}
          subtitle={subtitle}
          link={link}
          linkText={t('ContextualEvent.CheckoutLink.view_link')}
          utterance={attachedUtterance}
          showSentStatus={isLastItem}
          track={() => event.track('Click View Payment Link')}
          cardRef={itemRef}
          id={attachedUtterance?.utterance.id}
        />
      );
    }

    // Square Go Review
    if (
      dataType === 'SQUARE_GO_REVIEW' &&
      componentType === 'CUSTOMER_EVENT_CARD'
    ) {
      return <SquareGoReviewEventCard item={item} cardRef={itemRef} />;
    }

    if (
      dataType === 'INBOUND_CALL' &&
      componentType === 'CUSTOMER_EVENT_CARD'
    ) {
      return <MissedCallEventCard item={item} cardRef={itemRef} />;
    }

    Logger.warn('Unable to render TranscriptViewItem');
    return null;
  },
);

export default TranscriptViewItem;
