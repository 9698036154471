@import "../../styles/mixins/component-base.css";
@import "../../styles/mixins/field.css";
@import "../../styles/mixins/field-states.css";
@import "../../styles/mixins/field-accessories.css";
@import "../../styles/mixins/floating-input.css";
@import "../../styles/mixins/number-input.css";
@import "../../styles/mixins/time-input.css";
@import "../../styles/mixins/date-input.css";

:host {
  @extend %field;
  @extend %field-states;
  @extend %field-accessories;
  @extend %floating-input;
  @extend %number-input;
}

/* Visually displays a placeholder when no value is set but a placeholder is set elsewhere,
   such as with Safari using their own placeholders for date and time inputs */
:host([value=""]) input,
:host([value=""]) ::slotted(input) {
  color: var(--field-placeholder-text-color);
}

/*
  These animations are part of a hack that allows us to detect browser autofill:
  https://stackoverflow.com/a/41530164
*/

:host input:-webkit-autofill,
:host ::slotted(input:-webkit-autofill) {
  animation-name: market-input-autofill-start;
  animation-duration: 0.1ms;
}

:host input:not(:-webkit-autofill),
:host ::slotted(input:not(:-webkit-autofill)) {
  animation-name: market-input-autofill-cancel;
  animation-duration: 0.1ms;
}

:host input::-webkit-date-and-time-value {
  text-align: left;
}

:host ::slotted(input::-webkit-date-and-time-value) {
  text-align: left;
}
