import { Component, Listen, Element, Prop, EventEmitter, Event, Host, State, h, Watch, Method } from '@stencil/core';
import { uniqBy } from 'lodash-es';

import { getNamespacedTagFor } from '../../utils/namespace';
import { asyncRequestAnimationFrame } from '../../utils/raf';
import { TMarketInputSearchValueChangeEventDetail } from '../market-input-search/events';

import { Reorderable, TMarketReorderableOptions, TMarketReorderEventDetail } from '../../utils/reorderable';
import { isDraggable, TMarketDragEventDetail } from '../../utils/draggable';

import { TMarketListItemsFilteredEventDetail, TMarketListSelectionsDidChangeEventDetail } from './events';
import { TMarketListFilterStrategyPropTypes, TMarketListItem, TMarketListValidControlRowInputElement } from './types';
import { isValidControl, isValueEmpty } from './utils';

/* If Stencil supported extending built-in elements, I would much prefer to extend the <li>
element instead of creating a completely different one here, but unlike buttons and anchors,
the default <li> tag doesn't provide a whole lot over a custom element aside from semantics */

/**
 * @slot control-row - Intended for use with interactive multiselect lists. When used with a
 * `<market-row>` containing a slotted control (such as `<market-checkbox>`), toggling this row
 * will select/deselect all list options.
 * @slot search - Intended for use with `<market-input-search>`
 * @slot empty-state - Intended for use with `<market-empty-state>`; shown when filtering items
 * via `<market-input-search>` and there are no search results.
 * @slot - Intended for use with `<market-row>` or `<market-action-card>`.
 */
@Component({
  tag: 'market-list',
  shadow: true,
  styleUrl: 'market-list.css',
})
export class MarketList {
  @Element() el: HTMLMarketListElement;
  inputSearchEl: HTMLMarketInputSearchElement;
  controlRow: HTMLMarketRowElement;
  observers: {
    itemDisabledAttribute?: MutationObserver;
  } = {};

  /**
   * A string specifying a value for the list. To select multiple values,
   * separate **unique** values with a comma (e.g. `'orange,pear'`).
   * Setting to empty string (`''`) will clear all current selections.
   */
  @Prop({ mutable: true, reflect: true }) value: string | Array<any> = '';

  /**
   * Whether or not the list is interactive. Results in list items receiving hover
   * and active styling when hovered/clicked.<br>
   *
   * _NOTE:_ Lists slotted into `market-popover`, or any of the components that use it
   * internally such as `market-select`, `market-dropdown`, and `market-button-dropdown`,
   * will automatically have their `interactive` property set to `true`.
   */
  @Prop({ mutable: true }) interactive: boolean = false;

  /**
   * When set to `true`, rows/cards will not persist selected state on click. Only takes effect when `interactive` is true.
   */
  @Prop() readonly transient: boolean = false;

  /**
   * Whether or not the list can allow for multiple selections (currently not
   * reflected in the `value` prop)
   */
  @Prop({ reflect: true }) readonly multiselect: boolean = false;

  /**
   * String value used for the `aria-labelledby` attribute.
   */
  @Prop() readonly name: string;

  /**
   * Filter strategy
   *
   * - `"textcontent"` (default, case-insensitive): This strategy searches through each of the row’s `.textContent`. This means it would also search through a row’s subtext, accessories, and other slots.
   * - `"label"` (case-insensitive): This strategy searches through the slotted `<label>` elements of rows. Note that if a `<label>` is not slotted in a row, this default filter strategy will not work.
   * - `"value"` (case-sensitive): This strategy searches through the rows’ `value` attribute. Values are usually case-sensitive so they are treated the same way when searching for them.
   * - `Function`: This strategy works similarly to `Array.prototype.filter()` where the function’s `boolean` output determines if the item will be kept or filtered out. For your convenience, you are provided with 5 parameters:
   *   - `item`: `TMarketListItem`
   *   - `label`: the `<label>`’s `.textContent`
   *   - `query`: `value` of `<market-input-search>`
   *   - `textContent`: the item’s `.textContent`
   *   - `value`: `value` of the item
   */
  @Prop() readonly filterStrategy: TMarketListFilterStrategyPropTypes = 'textcontent';

  /**
   * Whether the list is reorderable or not.
   * Setting to `internal` enables reordering rows internally
   * while `external` also allows dragging to & from other lists.
   */
  @Prop({ reflect: true }) readonly reorderable: TMarketReorderableOptions = 'off';

  /**
   * When set to `framework`, the list will move the reordered row back to its original position
   * before the `marketListItemsReordered` event is fired. This is useful when the list
   * is rendered within a framework like Ember or React.
   */
  @Prop() readonly reorderMode: 'default' | 'framework' = 'default';

  /**
   * Whether a count of selectable items rendered within the control row will be hidden
   */
  @Prop({ reflect: true }) readonly hideSelectableCount: boolean = false;

  /**
   * Used to indicate if the list has a search input
   */
  @State() hasSearch: boolean = false;

  /**
   * All items
   */
  @State() items: Array<TMarketListItem>;

  /**
   * Current itemselections
   */
  @State() selections: Set<TMarketListItem> = new Set();

  /**
   * Filtered items by `market-input-search`
   */
  @State() filteredItems: {
    visible: Array<TMarketListItem>;
    hidden: Array<TMarketListItem>;
    visibleSelected: Array<TMarketListItem>;
    selected: Array<TMarketListItem>;
  };

  @Watch('reorderable')
  reorderableWatcher() {
    this.setReorderable();
  }

  /**
   * Fired whenever an item is selected or deselected.
   *
   * @property {TMarketListItem} newSelection
   * - the row or card that has been selected
   * @property {string} newSelectionValue - the value of the new selection
   * @property {TMarketListItem} newDeselection
   * - the row or card that has been deselected
   * @property {string} newDeselectionValue - the value of the new deselection
   * @property {Array<TMarketListItem>} currentSelections
   * - an array of the currently selected rows or cards (excludes slotted control row, if any)
   * @property {string[]} currentSelectionValues - an array of the currently selected values
   * (excludes slotted control row, if any)
   * @property {string[]} prevSelectionValues - an array of the previously selected values
   * (excludes slotted control row, if any)
   */
  @Event({ bubbles: true, composed: true })
  marketListSelectionsDidChange: EventEmitter<TMarketListSelectionsDidChangeEventDetail>;

  /**
   * Fired when the slotchange event happens on the list. Allows parent components (like `market-select`)
   * to update when slotted list items change.
   */
  @Event({ bubbles: true, composed: true }) marketListSlotChange: EventEmitter;

  /**
   * Fired when the list items are reordered.
   * If an item was dropped into this list from an external list, `oldIndex` is `-1`.
   * If an item was removed from this list and dropped into an external list, `newIndex` is `-1`.
   */
  @Event({ bubbles: false, composed: true }) marketListItemsReordered: EventEmitter<TMarketReorderEventDetail>;

  /**
   * Fired when items are filtered using `market-input-search`
   */
  @Event({ bubbles: true, composed: true }) marketListItemsFiltered: EventEmitter<TMarketListItemsFilteredEventDetail>;

  @Watch('value')
  valueWatcher() {
    this.setSelectionsFromValue();
  }

  @Watch('hideSelectableCount')
  hideSelectableCountWatcher() {
    this.injectCountOnControlRow();
  }

  /* Listen for the marketRowSelected event which is emitted by slotted market-row elements
  when they are clicked */
  @Listen('marketRowSelected')
  rowSelectedEventHandler(e: CustomEvent) {
    this.handleItemSelectedEvent(e.target as HTMLMarketRowElement);
  }

  /* Listen for the marketRowDeselected event which is emitted by slotted market-row elements
  when they are clicked */
  @Listen('marketRowDeselected')
  rowDeselectedEventHandler(e: CustomEvent) {
    this.handleItemDeselectedEvent(e.target as HTMLMarketRowElement);
  }

  /* Listen for the marketCardSelected event which is emitted by slotted market-action-card elements
  when they are clicked */
  @Listen('marketCardSelected')
  cardSelectedEventHandler(e: CustomEvent) {
    this.handleItemSelectedEvent(e.target as HTMLMarketActionCardElement);
  }

  /* Listen for the marketCardDeselected event which is emitted by slotted market-action-card elements
  when they are clicked */
  @Listen('marketCardDeselected')
  cardDeselectedEventHandler(e: CustomEvent) {
    this.handleItemDeselectedEvent(e.target as HTMLMarketActionCardElement);
  }

  /**
   * Listen for `marketInputSearchValueChange` which is emitted by the slotted `market-input-search`
   */
  @Listen('marketInputSearchValueChange')
  marketInputSearchValueChangeEventHander({ detail }: CustomEvent<TMarketInputSearchValueChangeEventDetail>) {
    this.filterItems(detail.value);
  }

  getEventSelectionDetails() {
    const prevSelectionValues = (() => {
      if (typeof this.value === 'string') {
        return this.value ? this.value.split(',').filter((value) => !isValueEmpty(value)) : [];
      } else if (Array.isArray(this.value)) {
        return this.value.filter((value) => !isValueEmpty(value));
      }
      return this.value;
    })();
    const currentSelections = uniqBy(
      [...this.selections].filter((item) => item !== this.controlRow),
      (item) => item.value, // ensure uniqueness by value
    );
    const currentSelectionValues = currentSelections.reduce((items, item) => {
      if (!isValueEmpty(item.value)) {
        items.push(item.value);
      }
      return items;
    }, [] as TMarketListItem['value'][]);
    return { currentSelections, currentSelectionValues, prevSelectionValues };
  }

  handleItemSelectedEvent(selectedItem: TMarketListItem) {
    if (selectedItem === this.controlRow) {
      this.selectAllItems();
    } else {
      this.selectItem(selectedItem);
    }

    const { currentSelections, currentSelectionValues, prevSelectionValues } = this.getEventSelectionDetails();
    this.value = currentSelectionValues.join(','); // reflect to DOM

    this.marketListSelectionsDidChange.emit({
      newSelection: selectedItem,
      newSelectionValue: selectedItem.value,
      newDeselection: null,
      newDeselectionValue: null,
      currentSelections,
      currentSelectionValues,
      prevSelectionValues,
    });
  }

  handleItemDeselectedEvent(deselectedItem: TMarketListItem) {
    // We check to see if the element is in our selections, since we may have
    // already manually deselected it due to another element being clicked.
    // We only want the code in the block to fire when `marketRowDeselected` is being
    // emitted due to a merchant actually clicking to deselect an element.
    if (!this.selections.has(deselectedItem)) {
      return;
    }

    if (deselectedItem === this.controlRow) {
      /**
       * special case: when the only remaining selected items are disabled,
       * the control row's checkbox will be indeterminate (expected UI behavior).
       * when normally, clicking a row with an indeterminate checkbox selects all,
       * in this case, we want it to select all (non-disabled items) instead.
       */
      const shouldSelectAll = (() => {
        const items = (this.filteredItems?.visible ?? this.items).filter((item) => item !== this.controlRow);
        const nonDisabledItems = items.filter((item) => !item.disabled);
        return nonDisabledItems.every((item) => !item.selected);
      })();
      if (shouldSelectAll) {
        this.selectAllItems();
      } else {
        this.deselectAllItems();
      }
    } else {
      this.deselectItem(deselectedItem);
    }

    const { currentSelections, currentSelectionValues, prevSelectionValues } = this.getEventSelectionDetails();
    this.value = currentSelectionValues.join(','); // reflect to DOM

    this.marketListSelectionsDidChange.emit({
      newSelection: null,
      newSelectionValue: null,
      newDeselection: deselectedItem,
      newDeselectionValue: deselectedItem.value,
      currentSelections,
      currentSelectionValues,
      prevSelectionValues,
    });
  }

  /**
   * Selects a given option from the list. Also handles deselecting
   * all other elements when not in multiselect mode.
   */
  selectItem(selectedItem: TMarketListItem) {
    /* Only if this list is interactive and *doesn't* allow multiple selections,
    deselect all the options except the one that was just selected */
    if (this.interactive) {
      if (!this.multiselect) {
        this.deselectItems([selectedItem]);
        this.selections = new Set([selectedItem]);
      } else {
        this.selections.add(selectedItem);
      }
    }
  }

  /**
   * Selects all multiselect list options.
   */
  selectAllItems() {
    if (!this.items || !this.interactive || !this.multiselect) {
      return;
    }

    // if items are being filtered, "Select all" only applies to visible items
    // and then filter all non-disabled items to already selected items (might include disabled items)
    const selectableItems = (this.filteredItems?.visible || this.items).filter((item) => !item.disabled);
    this.selections = new Set([...this.selections.values(), ...selectableItems]);
  }

  /**
   * Deselects a given option from the list.
   */
  deselectItem(deselectedItem: TMarketListItem) {
    this.selections.delete(deselectedItem);
  }

  /**
   * Deselects all other items other than the ones that were just selected.
   */
  deselectItems(selectedItems: Array<TMarketListItem>) {
    if (!this.items) {
      return;
    }

    this.items.forEach((item) => {
      /* Check to make sure the item isn't in the list of
        selected items (likely only the one that was just
        selected and triggered the callback) */
      if (!selectedItems.includes(item) && item.selected) {
        item.deselect();
      }
    });
  }

  /**
   * Deselects all list options.
   */
  deselectAllItems() {
    if (!this.selections || !this.interactive) {
      return;
    }

    /**
     * If items are being filtered, only deselect visible items.
     * Disabled items will not be deselected.
     */
    const visibleItemsSet = new Set(this.filteredItems?.visible ?? this.items);
    const selectedItemsToKeep = [...this.selections].filter((selectedItem) => {
      return selectedItem.disabled || !visibleItemsSet.has(selectedItem);
    });
    this.selections = new Set(selectedItemsToKeep);
  }

  /**
   * If passed or updates interactive, ensure list itself is set to interactive mode.
   */
  syncListInteractiveWithItems() {
    if (
      this.items.length > 0 &&
      (this.items[0].tagName === getNamespacedTagFor('market-action-card').toUpperCase() ||
        (this.items[0].tagName === getNamespacedTagFor('market-row').toUpperCase() &&
          (this.items[0] as HTMLMarketRowElement).interactive === true)) &&
      !this.interactive
    ) {
      // force list to be interactive if items are interactive rows
      // (i.e. when they contain slotted controls), or
      // items are action cards (which are always interactive)
      this.interactive = true;
    }
  }

  /**
   * Processes interactive, transient, and multiselect props and propagates these props
   * to children components whenever these props are updated.
   */
  processItems() {
    this.items.forEach((item) => {
      if (item.tagName === getNamespacedTagFor('market-action-card').toUpperCase()) {
        item.transient = this.transient;

        const cardRow = item.querySelector<HTMLMarketRowElement>(getNamespacedTagFor('market-row'));
        if (cardRow) {
          this.setRowProperties(cardRow);
        }
      } else {
        this.setRowProperties(item as HTMLMarketRowElement);
      }
    });
  }

  getCurrentSelectionValues(): Set<string> {
    if (Array.isArray(this.value)) {
      return new Set(this.value);
    }
    return new Set(this.multiselect ? this.value.split(',') : [this.value]);
  }

  setRowProperties(row: HTMLMarketRowElement) {
    row.interactive = this.interactive;
    row.transient = this.transient;

    // We don't want subsequent clicks to deselect rows for single select lists
    row.togglable = this.multiselect;
  }

  /**
   * Select item that corresponds to passed value, or clear all values if value is empty string.
   */
  setSelectionsFromValue() {
    if (this.value || this.value === '') {
      const values = this.getCurrentSelectionValues();
      this.items?.forEach((item) => {
        if (item === this.controlRow) {
          // control row selection happens in syncControlRowWithSelections
          return;
        }
        if (!isValueEmpty(this.value) && values.has(item.value)) {
          item.silentlySelect();
          this.selectItem(item);
        } else {
          // value is '', so deselect all items
          item.silentlyDeselect();
          this.deselectItem(item);
        }
      });
    }
  }

  /**
   * Find any list items with the `selected` property and add to `selections`.
   */
  setSelectionsFromRowAttributes() {
    const initialSelections: Set<TMarketListItem> = new Set();
    this.items.forEach((item) => {
      if (item.selected) {
        /* TODO: Maybe figure out how to handle the case where the
         * list is not multiselect, but more than one market-row has the
         * [selected] attribute */
        initialSelections.add(item);
      }
    });

    this.selections = initialSelections;
  }

  /**
   * Sets the initial state of the list by updating and propagating props and setting
   * default value.
   */
  setInternalState() {
    this.items = [
      ...this.el.querySelectorAll<TMarketListItem>(
        `:scope > ${getNamespacedTagFor('market-row')}, :scope > ${getNamespacedTagFor('market-action-card')}`,
      ),
    ];
    this.syncListInteractiveWithItems();
    this.processItems();

    if (this.items.length > 0) {
      if (this.value) {
        this.setSelectionsFromValue();
      } else {
        this.setSelectionsFromRowAttributes();
      }
    }
  }

  /**
   * Syncs the state of the slotted control row with list selections (e.g. all selected, none
   * selected, some selected).
   */
  async syncControlRowWithSelections() {
    if (!this.controlRow) {
      return;
    }

    const slottedControl = this.controlRow.querySelector('[slot="control"]') as TMarketListValidControlRowInputElement;
    const isCheckbox = slottedControl.tagName.toLowerCase() === getNamespacedTagFor('market-checkbox');

    const items = (this.filteredItems?.visible ?? this.items).filter((item) => item !== this.controlRow);
    const controlRowStatus = (() => {
      if (items.every((item) => item.selected)) {
        // all non-disabled items are selected
        return 'checked';
      } else if (items.some((item) => item.selected)) {
        // at least one is selected (regardless if it's disabled or not)
        return 'indeterminate';
      } else {
        return 'unchecked';
      }
    })();

    switch (controlRowStatus) {
      case 'unchecked':
        // no options selected, deselect control row
        this.deselectItem(this.controlRow);
        await this.controlRow.silentlyDeselect();
        await asyncRequestAnimationFrame(); // prevents flash where it goes from indeterminate -> checked -> unchecked
        slottedControl.removeAttribute('indeterminate');
        break;
      case 'checked':
        // all options selected, select control row
        await this.controlRow.silentlySelect();
        this.selectItem(this.controlRow);
        slottedControl.removeAttribute('indeterminate');
        break;
      case 'indeterminate':
        // some options selected
        if (isCheckbox) {
          // control row gets selected, checkbox set to indeterminate
          await this.controlRow.silentlySelect();
          this.selectItem(this.controlRow);
          await asyncRequestAnimationFrame(); // prevents bug where checkbox becomes checked but not indeterminate
          slottedControl.setAttribute('indeterminate', '');
        } else {
          // control row gets deselected
          await this.controlRow.silentlyDeselect();
          this.deselectItem(this.controlRow);
        }
        break;
      default:
        break;
    }

    this.injectCountOnControlRow();
  }

  /**
   * Injects an accessory to the control row that displays the number of items;
   * or edit that accessory's text content if the element already exists.
   *
   * Disabled items are not included in the count.
   *
   * Count is only rendered when `hideSelectableCount` is `false`, which it is by default.
   */
  injectCountOnControlRow() {
    if (!this.controlRow) {
      return;
    }
    const countAccessoryEl = this.controlRow.querySelector('.count[slot="trailing-accessory"]');
    if (this.hideSelectableCount) {
      if (countAccessoryEl) {
        this.controlRow.removeChild(countAccessoryEl);
      }
      return;
    }

    const count = this.filteredItems.visible.filter((item) => !item.disabled).length;
    if (countAccessoryEl) {
      countAccessoryEl.textContent = `${count}`;
    } else {
      const newEl = document.createElement('span');
      newEl.classList.add('count');
      newEl.setAttribute('slot', 'trailing-accessory');
      newEl.textContent = `${count}`;
      this.controlRow.appendChild(newEl);
    }
  }

  /**
   * Filters items based on search query inputted in slotted `market-input-search`
   */
  filterItems(query: string) {
    const filteredItems: typeof this.filteredItems = this.items.reduce(
      (filteredItems, item) => {
        if (item.getAttribute('slot') === 'control-row') {
          // filteredItems will not contain the control row as it isn't needed for logic purposes
          return filteredItems;
        } else if (!query) {
          // if there's no search query, all items are visible
          filteredItems.visible.push(item);
          return filteredItems;
        } else if (typeof this.filterStrategy === 'function') {
          // attempts to call the provided function
          const callbackResult = this.filterStrategy({
            item,
            label: item.querySelector('[slot="label"]')?.textContent,
            query,
            textContent: item.textContent,
            value: item.value,
          });
          if (callbackResult) {
            filteredItems.visible.push(item);
            return filteredItems;
          }
        } else if (this.filterStrategy?.toLocaleLowerCase?.() === 'textcontent') {
          // search through the entire item's textContent
          const isFound = item?.textContent?.search(new RegExp(query, 'i')) >= 0;
          if (isFound) {
            filteredItems.visible.push(item);
            return filteredItems;
          }
        } else if (this.filterStrategy?.toLocaleLowerCase?.() === 'label') {
          // only works if there's a slotted label
          const labelEl = item.querySelector('[slot="label"]');
          const isFound = labelEl?.textContent?.search(new RegExp(query, 'i')) >= 0;
          if (isFound) {
            filteredItems.visible.push(item);
            return filteredItems;
          }
        } else if (this.filterStrategy?.toLocaleLowerCase?.() === 'value') {
          // if the item's value isn't a string, it may not work well UX-wise
          const value = String(item.value);
          const isFound = value.search(new RegExp(query)) >= 0;
          if (isFound) {
            filteredItems.visible.push(item);
            return filteredItems;
          }
        }
        // item didn't pass any of the conditions / filter strategies above
        filteredItems.hidden.push(item);
        return filteredItems;
      },
      {
        visible: [],
        hidden: [],
        visibleSelected: [],
        selected: [],
      },
    );

    // DOM manipulation
    requestAnimationFrame(() => {
      // make sure that visible items are visible, hidden items are hidden
      filteredItems.visible.forEach((item) => item.classList.remove('hidden'));
      filteredItems.hidden.forEach((item) => item.classList.add('hidden'));

      // hide last visible item's bottom border
      this.items.forEach((item) => item.classList.remove('hide-bottom-border'));
      if (filteredItems.visible.length > 0) {
        const lastVisibleItem = filteredItems.visible[filteredItems.visible.length - 1];
        lastVisibleItem.classList.add('hide-bottom-border');
      }

      // hide control row if there are no search results
      this.controlRow?.classList.toggle('hidden', filteredItems.visible.length === 0);
    });

    // will not emit when the list is initially rendered without a search query
    if (this.filteredItems || (!this.filteredItems && query)) {
      this.marketListItemsFiltered.emit({
        items: filteredItems.visible,
        prevItems: this.filteredItems?.visible,
      });
    }

    // this triggers a re-render since `this.filteredItems` is a `@State`
    this.filteredItems = filteredItems;
    this.updateSelectedItemsInFilteredItems();
  }

  updateSelectedItemsInFilteredItems() {
    if (!this.filteredItems) {
      return;
    }
    this.filteredItems.selected = this.items.filter(
      (item) => item.getAttribute('slot') !== 'control-row' && item.selected,
    );
    this.filteredItems.visibleSelected = this.filteredItems.visible.filter(
      (item) => item.getAttribute('slot') !== 'control-row' && item.selected,
    );
  }

  /* SLOTCHANGE HANDLERS */

  handleSearchSlotchange() {
    this.inputSearchEl = this.el.querySelector('[slot="search"]');
    this.hasSearch = Boolean(this.inputSearchEl);
    this.filterItems(this.inputSearchEl?.value);
  }

  defaultSlotchangeHandler() {
    this.setInternalState();
    this.filterItems(this.inputSearchEl?.value);
    this.setReorderable();
    this.marketListSlotChange.emit();
  }

  /**
   * Rows slotted into the "control-row" slot only function as such if the list is interactive and
   * multiselect and the row contains a valid slotted control (checkbox or toggle).
   */
  controlRowSlotchangeHandler() {
    if (!this.interactive || !this.multiselect) {
      return;
    }

    const slottedRow = this.el.querySelector('[slot="control-row"]') as HTMLMarketRowElement;
    const slottedControl = slottedRow?.querySelector('[slot="control"]');
    this.controlRow = isValidControl(slottedControl) ? slottedRow : undefined;
  }

  /**
   * Show empty state if:
   * - list isn't empty, but
   * - there is a search query, and
   * - there are no search results
   */
  setEmptyStateVisibility() {
    const emptyStateEl =
      this.el.querySelector('[slot="empty-state"]') ||
      this.el.shadowRoot.querySelector(getNamespacedTagFor('market-empty-state'));
    const willShowEmptyState = this.inputSearchEl?.value && !this.filteredItems?.visible.length;
    emptyStateEl?.classList.toggle('hidden', !willShowEmptyState);
  }

  /**
   * Updates the count that is injected to the control row
   * when there’s a change on an item’s `disabled` attribute.
   */
  initItemDisabledAttributeObserver() {
    if (this.observers.itemDisabledAttribute) {
      return;
    }
    this.observers.itemDisabledAttribute = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (
          mutation.target.parentElement === this.el &&
          mutation.type === 'attributes' &&
          mutation.attributeName === 'disabled'
        ) {
          this.injectCountOnControlRow();
        }
      }
    });
    this.observers.itemDisabledAttribute.observe(this.el, {
      attributes: true,
      attributeFilter: ['disabled'],
      childList: true,
      subtree: true,
    });
  }

  /* LIFECYCLE EVENTS */
  connectedCallback() {
    this.syncControlRowWithSelections();
  }

  componentWillLoad() {
    this.setInternalState();
    this.filterItems(this.inputSearchEl?.value);
  }

  componentWillRender() {
    this.syncListInteractiveWithItems();
    this.processItems();
    this.updateSelectedItemsInFilteredItems();
    this.controlRowSlotchangeHandler();
    this.syncControlRowWithSelections();
    this.setEmptyStateVisibility();
  }

  componentDidLoad() {
    this.initItemDisabledAttributeObserver();
  }

  disconnectedCallback() {
    this.observers.itemDisabledAttribute?.disconnect();
  }

  /*
    KEYBOARD ACCESSIBILITY

    tabbing goes through rows (and slotted controls, if any) once before moving
    on to rest of page content

    once list has focus, up/down arrows can move focus up/down, stopping at end
    of list rather than cycling through (similar to native html <select>)
    - https://developer.mozilla.org/en-US/docs/Web/HTML/Element/select

    if slotted row contents have focus, up/down arrows do nothing
  */
  handleKeydown(e: KeyboardEvent) {
    switch (e.key) {
      case 'ArrowDown':
        this.handleArrowDown(e);
        break;
      case 'ArrowUp':
        this.handleArrowUp(e);
        break;
      default:
        break;
    }
  }

  handleArrowDown(e) {
    const focused = this.el.querySelector(':focus');
    const prevFocusedRowIndex = [...this.items].indexOf(focused as TMarketListItem);

    // return if no rows are focused
    if (prevFocusedRowIndex === -1) {
      return;
    }

    // focus next enabled row
    for (let i = prevFocusedRowIndex + 1; i < this.items.length; i++) {
      if (!this.items[i].disabled) {
        this.items[i].focus();
        break;
      }
    }
    e.preventDefault(); // down arrow should not scroll page
  }

  handleArrowUp(e) {
    const focused = this.el.querySelector(':focus');
    const prevFocusedRowIndex = [...this.items].indexOf(focused as TMarketListItem);

    // return if no rows are focused
    if (prevFocusedRowIndex === -1) {
      return;
    }

    // focus last enabled row
    for (let i = prevFocusedRowIndex - 1; i >= 0; i--) {
      if (!this.items[i].disabled) {
        this.items[i].focus();
        break;
      }
    }
    e.preventDefault(); // up arrow should not scroll page
  }

  /**
   * Focuses the row at the given index.
   * @param index - The index of the row to focus.
   * @returns A promise that resolves when the row is focused.
   */
  @Method()
  async focusRowAtIndex(index: number) {
    const row = this.items[index];
    if (row) {
      row.focus();
      return Promise.resolve();
    } else {
      return Promise.reject(new Error(`Row at index ${index} not found`));
    }
  }

  // market reorder utils
  reorder: Reorderable;

  setReorderable() {
    const { el, items, controlRow, reorderable, reorderMode, reorder, marketListItemsReordered } = this;

    if (reorderable === 'off') {
      reorder?.destroy();
      this.reorder = null;
    } else if (!reorder) {
      this.reorder = new Reorderable({
        el,
        accepts: [`${getNamespacedTagFor('market-row')}:not([slot="control"])`],
        event: marketListItemsReordered,
        mode: reorderMode,
      });
    }

    items?.forEach((item) => {
      if (!isDraggable(item)) return;
      if (item === controlRow) return; // control row is not reorderable
      item.dragEnabled = reorderable !== 'off';
    });
  }

  onDragMove(e: CustomEvent<TMarketDragEventDetail>) {
    this.reorder?.dragMove(e);
  }
  onDragLeave() {
    this.reorder?.dragLeave();
  }
  onDragEnd(e: CustomEvent<TMarketDragEventDetail>) {
    this.reorder?.dragEnd(e);
  }
  onDragDrop(e: CustomEvent<TMarketDragEventDetail>) {
    this.reorder?.dragDrop(e);
  }

  componentDidRender() {
    this.setReorderable();
  }

  render() {
    const MarketEmptyState = getNamespacedTagFor('market-empty-state');

    return (
      <Host
        class="market-list"
        role={this.interactive ? 'listbox' : 'list'}
        aria-labelledby={this.name}
        aria-multiselectable={this.multiselect}
        has-search={this.hasSearch}
        onKeydown={(e: KeyboardEvent) => this.handleKeydown(e)}
        onMarketDragMove={(e: CustomEvent<TMarketDragEventDetail>) => this.onDragMove(e)}
        onMarketDragLeave={() => this.onDragLeave()}
        onMarketDragEnd={(e: CustomEvent<TMarketDragEventDetail>) => this.onDragEnd(e)}
        onMarketDragDrop={(e: CustomEvent<TMarketDragEventDetail>) => this.onDragDrop(e)}
      >
        <slot name="search" onSlotchange={() => this.handleSearchSlotchange()}></slot>
        <slot name="control-row" onSlotchange={() => this.controlRowSlotchangeHandler()}></slot>
        <slot onSlotchange={() => this.defaultSlotchangeHandler()}></slot>
        <slot name="empty-state">
          <MarketEmptyState class="hidden">
            <svg height="40" slot="media" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M34.4667 17.2L28.1 10.8333H26.6667C26.6667 9.45 25.55 8.33333 24.1667 8.33333C22.7834 8.33333 21.6667 9.45 21.6667 10.8333H18.3334C18.3334 9.45 17.2167 8.33333 15.8334 8.33333C14.45 8.33333 13.3334 9.45 13.3334 10.8333H11.9L5.53337 17.2C4.11671 18.6167 3.33337 20.5 3.33337 22.5C3.33337 26.6333 6.70004 30 10.8334 30C14.8 30 18.0167 26.9 18.2834 23.0167C18.8167 23.2167 19.4 23.3333 20 23.3333C20.6 23.3333 21.1834 23.2167 21.7167 23.0167C21.9834 26.9 25.2 30 29.1667 30C33.3 30 36.6667 26.6333 36.6667 22.5C36.6667 20.5 35.8834 18.6167 34.4667 17.2ZM10.8334 26.6667C8.53337 26.6667 6.66671 24.8 6.66671 22.5C6.66671 21.3833 7.10004 20.3333 7.88337 19.55C8.66671 18.7667 9.71671 18.3333 10.8334 18.3333C13.1334 18.3333 15 20.2 15 22.5C15 24.8 13.1334 26.6667 10.8334 26.6667ZM15.35 16.55C14.4667 15.8833 13.4334 15.3833 12.3 15.15L13.2667 14.1667H17.2334C16.3834 14.7333 15.7167 15.5667 15.35 16.55ZM20 20C19.0834 20 18.3334 19.25 18.3334 18.3333C18.3334 17.4167 19.0834 16.6667 20 16.6667C20.9167 16.6667 21.6667 17.4167 21.6667 18.3333C21.6667 19.25 20.9167 20 20 20ZM22.75 14.1667H26.7167L27.7 15.15C26.5667 15.3833 25.5334 15.8833 24.65 16.55C24.2834 15.5667 23.6167 14.7333 22.75 14.1667ZM29.1667 26.6667C26.8667 26.6667 25 24.8 25 22.5C25 20.2 26.8667 18.3333 29.1667 18.3333C30.2834 18.3333 31.3334 18.7667 32.1167 19.55C32.9 20.3333 33.3334 21.3833 33.3334 22.5C33.3334 24.8 31.4667 26.6667 29.1667 26.6667Z"
                fill="var(--core-text-10-color)"
                fill-opacity="0.9"
              />
            </svg>
            <h3 slot="primary-text">
              <slot name="empty-state-primary-text">No search results for “{this.inputSearchEl?.value}”</slot>
            </h3>
            <p slot="secondary-text">
              <slot name="empty-state-secondary-text">Try a different search.</slot>
            </p>
          </MarketEmptyState>
        </slot>
      </Host>
    );
  }
}
