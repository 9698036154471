import { Component, Host, h, State, Element, Prop } from '@stencil/core';
import { getNamespacedTagFor } from '../../utils/namespace';
import { BUTTON_GROUP_OVERFLOW_BUTTON_ICON_ASSET, BUTTON_GROUP_SPACING } from '@market/market-theme/js/cjs/index.js';
import type { PositioningStrategy } from '@popperjs/core';
import { throttle } from 'lodash-es';
import { asyncRequestAnimationFrame } from '../../utils/raf';

const MAX_VISIBLE_BUTTONS = 2;
const DROPDOWN_MENU_BUTTON_WIDTH = 48;
const RESIZE_DEBOUNCE_DURATION = 16; // 60fps

/**
 * @slot - Intended to slot any number of `<market-button>` components here.
 * @slot overflow-buttons - Not intended for external consumers. Used by
 * `<market-button-group>` when programmatically arranging visible vs overflow
 * buttons based on available space.
 */
@Component({
  tag: 'market-button-group',
  styleUrl: 'market-button-group.css',
  shadow: true,
})
export class MarketButtonGroup {
  @Element() el: HTMLMarketButtonGroupElement;

  /**
   * A string specifying the alignment for the button group.
   * This will change button size and distribution across the group.
   */
  @Prop({ reflect: true }) readonly alignment: 'left' | 'right' | 'split' | 'fill' | 'stack' = 'right';

  /**
   * Configuration option for Popper.js (used to position `<market-popover>`).
   * Describes the positioning strategy to use. By default, it is absolute. If
   * your reference element is in a fixed container, use the fixed strategy.
   * https://popper.js.org/docs/v2/constructors//#strategy
   */
  @Prop() readonly popoverStrategy: PositioningStrategy = 'absolute';

  /**
   * Sorted overflow and visible buttons
   */
  @State() private _sortedButtonEls: {
    overflow: Array<HTMLMarketButtonElement>;
    visible: Array<HTMLMarketButtonElement>;
  } = {
    overflow: [],
    visible: [],
  };

  /**
   * References to the button elements
   */
  private _buttonEls: Array<HTMLMarketButtonElement> = [];

  /**
   * Used to set the index cutoff for overflowing buttons
   */
  private _buttonCutoffIndex: number;

  /**
   * Observers
   */
  private _observers: {
    content?: ResizeObserver;
    host?: ResizeObserver;
  } = {};

  private getComputedWidth(el: HTMLElement) {
    return Number.parseFloat(window.getComputedStyle(el).width);
  }

  /**
   * Find out where the cutoff will happen.
   * Main chunk of the overflow logic happens here
   */
  private async findButtonCutoffIndex(): Promise<number> {
    if (this.alignment === 'stack') {
      // buttons are full width so no overflow necessary
      return this._buttonEls.length;
    }

    const buttonGroupWidth = this.getComputedWidth(this.el);

    /**
     * Temporary container where we can measure button widths
     * https://dev.to/sstraatemans/calculate-html-element-width-before-render-4ii7
     */
    const tempEl = document.createElement('div');
    tempEl.style.width = 'auto';
    tempEl.style.position = 'absolute';
    tempEl.style.visibility = 'hidden';
    this.el.shadowRoot.appendChild(tempEl);

    let index = 0;
    let buttonWidths = 0;
    for (const buttonEl of this._buttonEls) {
      if (index === MAX_VISIBLE_BUTTONS) {
        break;
      }

      /**
       * Presuming that all the remaining buttons (**excluding** the current one, i.e. `buttonEl`)
       * will be overflowed, calculate the potential dropdown menu button width.
       * If this is the last button, it will not be followed by a `market-button-dropdown-menu`.
       */
      const dropdownMenuButtonWidth =
        index + 1 === this._buttonEls.length // is this the last one?
          ? 0
          : BUTTON_GROUP_SPACING + DROPDOWN_MENU_BUTTON_WIDTH;

      // measure the button's width in the temporary container
      const clonedButtonEl = buttonEl.cloneNode(true) as HTMLMarketButtonElement;
      clonedButtonEl.style.display = 'block';
      tempEl.appendChild(clonedButtonEl);

      // let the shadow DOM render within the temp container first before measuring its width
      await asyncRequestAnimationFrame();
      const buttonElWidth = this.getComputedWidth(tempEl);
      tempEl.removeChild(clonedButtonEl);

      // width of all the buttons so far; gap is only added for buttons after the first
      buttonWidths += (index > 0 ? BUTTON_GROUP_SPACING : 0) + buttonElWidth;

      // check if button can fit
      const potentialWidth = buttonWidths + dropdownMenuButtonWidth;
      if (potentialWidth >= buttonGroupWidth) {
        // it won't fit; breaking the loop sets the cutoff
        break;
      }
      ++index;
    }

    // cleanup
    this.el.shadowRoot.removeChild(tempEl);
    tempEl.remove();

    return index;
  }

  /**
   * Sort buttons:
   * - split by `this._buttonCutoffIndex`
   * - visible buttons: remove attr `[slot="overflow-buttons"]`; remove `display: none;`
   * - overflow buttons: set attr `[slot="overflow-buttons"]`; add `display: none;`
   */
  private sortVisibleAndOverflowButtons() {
    this._sortedButtonEls = {
      visible: this._buttonEls.slice(0, this._buttonCutoffIndex),
      overflow: this._buttonEls.slice(this._buttonCutoffIndex),
    };
    this._sortedButtonEls.visible.forEach((buttonEl) => {
      // if (buttonEl.style.display) {
      //   buttonEl.style.removeProperty('display');
      // }
      if (buttonEl.getAttribute('slot') === 'overflow-buttons') {
        buttonEl.removeAttribute('slot');
      }
    });
    this._sortedButtonEls.overflow.forEach((buttonEl) => {
      // if (buttonEl.style.display !== 'none') {
      //   buttonEl.style.display = 'none';
      // }
      if (buttonEl.getAttribute('slot') !== 'overflow-buttons') {
        buttonEl.setAttribute('slot', 'overflow-buttons');
      }
    });
  }

  /**
   * Handle screen / component resize
   */
  private async handleResize() {
    if (!this.getComputedWidth(this.el)) {
      // element isn't fully rendered yet
      return;
    }

    const index = await this.findButtonCutoffIndex();
    const isButtonCutoffUpdated = index !== this._buttonCutoffIndex;
    if (isButtonCutoffUpdated) {
      this._buttonCutoffIndex = index;
      await asyncRequestAnimationFrame();
      this.sortVisibleAndOverflowButtons();
    }

    this.el.style.visibility = '';
  }

  private registerSlottedButtons() {
    const MarketButtonTagName = getNamespacedTagFor('market-button');
    this._buttonEls = [...this.el.querySelectorAll(MarketButtonTagName)];
  }

  private throttledHandleResize = throttle(this.handleResize.bind(this), RESIZE_DEBOUNCE_DURATION);

  private observeContent(el: HTMLDivElement) {
    if (!this._observers.content) {
      this._observers.content = new ResizeObserver(this.throttledHandleResize);
      this._observers.content.observe(el);
    }
  }

  connectedCallback() {
    if (!this._observers.host) {
      this._observers.host = new ResizeObserver(this.throttledHandleResize);
      this._observers.host.observe(this.el);
    }
  }

  componentWillLoad() {
    // hide component until handleResize()
    this.el.style.visibility = 'hidden';
    this.registerSlottedButtons();
    this.handleResize();
  }

  disconnectedCallback() {
    Object.entries(this._observers).forEach(([key, observer]) => {
      if (observer) {
        observer.disconnect();
        this._observers[key] = undefined;
      }
    });
  }

  render() {
    const MarketButtonDropdownTagName = getNamespacedTagFor('market-button-dropdown');
    const MarketButtonTagName = getNamespacedTagFor('market-button');
    const MarketIconTagName = getNamespacedTagFor('market-icon');
    return (
      <Host class="market-button-group">
        <div class="content" ref={(el) => this.observeContent(el)}>
          <slot onSlotchange={() => this.registerSlottedButtons()}></slot>
          {this._sortedButtonEls.overflow.length > 0 && (
            <MarketButtonDropdownTagName no-caret popover-strategy={this.popoverStrategy}>
              <MarketButtonTagName slot="trigger">
                <MarketIconTagName slot="icon" name={BUTTON_GROUP_OVERFLOW_BUTTON_ICON_ASSET}></MarketIconTagName>
              </MarketButtonTagName>
              <div slot="content">
                <slot name="overflow-buttons" slot="overflow-buttons"></slot>
              </div>
            </MarketButtonDropdownTagName>
          )}
        </div>
      </Host>
    );
  }
}
