@import "../../styles/mixins/component-base.css";

/* TODO: design tokens (this component corresponds to the "Spinner" component in Figma) */

:host([size="large"]) {
  .market-icon {
    width: 48px;
    height: 48px;
  }
}

:host([size="small"]) {
  .market-icon {
    width: 24px;
    height: 24px;
  }
}

.market-icon {
  position: static;
  flex: none;
  fill: currentcolor;
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
