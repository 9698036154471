import { Component, Element, Host, h, Listen, Prop, State, Watch } from '@stencil/core';
import {
  CORE_ANIMATION_EXIT_TRANSITION_FAST_SPEED_DURATION,
  CORE_BREAKPOINT_NARROW_MAX_WIDTH,
  CORE_TYPE_PARAGRAPH_20_FONT_FAMILY,
  CORE_TYPE_PARAGRAPH_20_SIZE,
  CORE_TYPE_PARAGRAPH_30_FONT_FAMILY,
  CORE_TYPE_PARAGRAPH_30_SIZE,
} from '@market/market-theme/js/cjs/index.js';
import { throttle } from 'lodash-es';

import { getNamespacedTagFor, isElementWithTagName } from '../../utils/namespace';
import { asyncRequestAnimationFrame } from '../../utils/raf';
import {
  TMarketInputSearchFocusEventDetail,
  TMarketInternalInputSearchCompactAnimationEventDetail,
} from '../market-input-search/events';

const MAX_VISIBLE_FILTERS = 3;
const FILTER_GROUP_GAP = 8;
const FILTER_BUTTON_WIDTH = 50;
const FILTER_BUTTON_FEEDBACK_GAP = 8;
const RESIZE_DEBOUNCE_DURATION = 16; // 60fps

/**
 * @slot search - Search input, using `<market-input-search>`
 * @slot filters - Filters, using `<market-filter>`
 */
@Component({
  tag: 'market-filter-group',
  styleUrl: 'market-filter-group.css',
  shadow: true,
})
export class MarketFilterGroup {
  @Element() el: HTMLMarketFilterGroupElement;

  /**
   * Maximum number of visible filters before they are truncated and moved into the overflow menu.
   * However, filters may be truncated anyway if there is not enough space.
   *
   * @default 3
   */
  @Prop() readonly maxVisibleFilters: number = 3; // not using MAX_VISIBLE_FILTERS because storybook shows the variable instead of the number literal

  /**
   * Sorted overflow and visible filters
   */
  @State() private _sortedFilterEls: {
    overflow: Array<HTMLMarketFilterElement>;
    visible: Array<HTMLMarketFilterElement>;
  } = {
    overflow: [],
    visible: [],
  };

  /**
   * References to the filter elements
   */
  private _filterEls: Array<HTMLMarketFilterElement>;

  /**
   * Reference to the `market-filter-dropdown-menu`
   */
  private _dropdownMenuEl: HTMLMarketFilterDropdownMenuElement;

  /**
   * Slotted input search element
   */
  private _slottedInputSearchEl: HTMLMarketInputSearchElement;

  /**
   * Used to hide filters when on compact mode
   */
  private _isSearchActive: boolean = false;

  /**
   * Used to set the index cutoff for overflowing filters
   */
  private _filterCutoffIndex: number;

  /**
   * Used to focus on the first filter when 'Tab' is pressed on the input search
   */
  private _willFocusOnFirstFilter: boolean = false;

  /**
   * Used to delay the overflow dropdown menu from opening
   */
  private _willDelayDropdownOpen: boolean = false;

  /**
   * Observers
   */
  private _observers: {
    content?: ResizeObserver;
    host?: ResizeObserver;
  } = {};

  @Watch('maxVisibleFilters')
  maxVisibleFiltersWatcher() {
    this.handleResize();
  }

  /**
   * Search is active when it's focused
   */
  @Listen('marketInputSearchFocus')
  marketInputSearchFocusHandler({ detail }: CustomEvent<TMarketInputSearchFocusEventDetail>) {
    this._isSearchActive = detail;
  }

  /**
   * For every search animation event, we either show or hide filters
   */
  @Listen('marketInternalInputSearchCompactAnimation')
  async marketInternalInputSearchCompactAnimationHandler(
    e: CustomEvent<TMarketInternalInputSearchCompactAnimationEventDetail>,
  ) {
    e.stopPropagation();
    await this.handleResize();
    await asyncRequestAnimationFrame();

    /**
     * 'animationstart' means that search is expanded from its compact state.
     * So when the dropdown menu is clicked, we defer the popover from opening
     * right away to prevent jittery animations while elements are getting settled.
     * (See `marketDropdownOpenedHandler` for the delay logic)
     */
    this._willDelayDropdownOpen = e.detail === 'animationstart';
  }

  /**
   * Handle dropdown menu's `marketDropdownOpened` event
   * TODO: There is no `market-dropdown` in this template below.
   * This event is bubbling up from `market-filter-dropdown-menu`.
   * We should refactor this to use a custom event from that instead,
   * but that component does not yet emit custom open/close events.
   */
  @Listen('marketDropdownOpened')
  marketDropdownOpenedHandler(e: CustomEvent<void>) {
    if (!this._willDelayDropdownOpen) {
      return;
    }
    this._willDelayDropdownOpen = false;
    e.preventDefault();

    /**
     * Delay `marketDropdownOpened` and manually open the dropdown later.
     * A little extra time (1.25x) has to be added in case the browser rendering
     * is a bit slow. FAST x 1.25 looks like the sweet spot for now.
     */
    setTimeout(async () => {
      await asyncRequestAnimationFrame();
      await this._dropdownMenuEl?.shadowRoot
        ?.querySelector(getNamespacedTagFor('market-dropdown'))
        ?.updateDropdownPosition();
      this._dropdownMenuEl?.shadowRoot?.querySelector(getNamespacedTagFor('market-dropdown'))?.openDropdown();
    }, CORE_ANIMATION_EXIT_TRANSITION_FAST_SPEED_DURATION * 1.25);
  }

  /**
   * The overflow feedback's text length is based on filters that have value.
   * Basically, the button's structure is: `[ <icon> <gap> <feedback> ]`
   */
  private calculateOverflowButtonWidth(filterEls: HTMLMarketFilterElement[]): number {
    // if there are no filters, the overflow button will be hidden
    if (!filterEls?.length) {
      return 0;
    }

    const hasValueCount = filterEls.reduce((result, filterEl) => {
      const listEl = filterEl.querySelector(getNamespacedTagFor('market-list'));
      if (listEl?.value) {
        return result + 1;
      }
      const datePickerEl = filterEl.querySelector(getNamespacedTagFor('market-date-picker'));
      if (datePickerEl?.selectedStartDate) {
        return result + 1;
      }
      return result;
    }, 0);

    // feedback omitted when there's no selection
    if (hasValueCount === 0) {
      return FILTER_BUTTON_WIDTH;
    }

    // render the text in some canvas and calculate its width
    const canvasEl = document.createElement('canvas');
    const context = canvasEl.getContext('2d');
    // assume that the first filter's size is the same size as the rest
    if (filterEls[0].size === 'small') {
      context.font = `${CORE_TYPE_PARAGRAPH_20_SIZE}px ${CORE_TYPE_PARAGRAPH_20_FONT_FAMILY}`;
    } else {
      context.font = `${CORE_TYPE_PARAGRAPH_30_SIZE}px ${CORE_TYPE_PARAGRAPH_30_FONT_FAMILY}`;
    }
    const feedbackTextWidth = context.measureText(`${hasValueCount}`).width;
    canvasEl.remove(); // cleanup
    return FILTER_BUTTON_WIDTH + FILTER_BUTTON_FEEDBACK_GAP + feedbackTextWidth;
  }

  private getComputedWidth(el: HTMLElement) {
    return Number.parseFloat(window.getComputedStyle(el).width);
  }

  /**
   * Find out where the cutoff will happen.
   * Main chunk of the overflow logic happens here
   */
  private async findFilterCutoffIndex(): Promise<number> {
    if ((this.maxVisibleFilters ?? MAX_VISIBLE_FILTERS) <= 0) {
      return 0;
    }

    const isNarrowBreakpoint = window?.innerWidth <= CORE_BREAKPOINT_NARROW_MAX_WIDTH;
    if (isNarrowBreakpoint && !this._isSearchActive) {
      return 1; // show 1 filter max on narrow breakpoints
    } else if (this._isSearchActive) {
      return 0; // search is active so no filters should be shown
    }

    /**
     * Get the widths of all the other components (group, search)
     * where `FILTER_GROUP_GAP` is the gap between elements
     */
    const filterGroupWidth = this.getComputedWidth(this.el);
    const searchWidth = this._slottedInputSearchEl
      ? this.getComputedWidth(this._slottedInputSearchEl) + FILTER_GROUP_GAP
      : 0;

    /**
     * Temporary container where we can measure filter widths
     * https://dev.to/sstraatemans/calculate-html-element-width-before-render-4ii7
     */
    const tempEl = document.createElement('div');
    tempEl.style.width = 'auto';
    tempEl.style.position = 'absolute';
    tempEl.style.visibility = 'hidden';
    this.el.shadowRoot.appendChild(tempEl);

    let index = 0;
    let filterWidths = 0;
    for (const filterEl of this._filterEls) {
      if (index === (this.maxVisibleFilters ?? MAX_VISIBLE_FILTERS)) {
        break;
      }

      /**
       * Presuming that all the remaining filters (**excluding** the current one, i.e. `filterEl`)
       * will be overflowed, calculate the potential dropdown menu button width.
       * If this is the last filter, it will not be followed by a `market-filter-dropdown-menu`.
       */
      const dropdownMenuButtonWidth =
        index + 1 === this._filterEls.length // is this the last one?
          ? 0
          : FILTER_GROUP_GAP + this.calculateOverflowButtonWidth(this._filterEls.slice(index));

      // measure the filter's width in the temporary container
      const clonedFilterEl = filterEl.cloneNode(true) as HTMLMarketFilterElement;
      clonedFilterEl.style.display = 'block';
      tempEl.appendChild(clonedFilterEl);

      // let the shadow DOM render within the temp container first before measuring its width
      await asyncRequestAnimationFrame();
      const filterElWidth = this.getComputedWidth(tempEl);
      tempEl.removeChild(clonedFilterEl);

      // width of all the filters so far; gap is only added for filters after the first
      filterWidths += (index > 0 ? FILTER_GROUP_GAP : 0) + filterElWidth;

      // check if filter can fit
      const potentialWidth = searchWidth + filterWidths + dropdownMenuButtonWidth;
      if (potentialWidth >= filterGroupWidth) {
        // it won't fit; breaking the loop sets the cutoff
        break;
      }
      ++index;
    }

    // cleanup
    this.el.shadowRoot.removeChild(tempEl);
    tempEl.remove();

    return index;
  }

  /**
   * Sort filters:
   * - split by `this._filterCutoffIndex`
   * - visible filters: set attr `[slot="filters"]`; remove `display: none;`
   * - overflow filters: set attr `[slot="overflow-filters"]`; add `display: none;`
   */
  private sortVisibleAndOverflowFilters() {
    this._sortedFilterEls = {
      visible: this._filterEls.slice(0, this._filterCutoffIndex),
      overflow: this._filterEls.slice(this._filterCutoffIndex),
    };
    this._sortedFilterEls.visible.forEach((filterEl) => {
      if (filterEl.style.display) {
        filterEl.style.removeProperty('display');
      }
      if (filterEl.getAttribute('slot') !== 'filters') {
        filterEl.setAttribute('slot', 'filters');
      }
    });
    this._sortedFilterEls.overflow.forEach((filterEl) => {
      if (filterEl.style.display !== 'none') {
        filterEl.style.display = 'none';
      }
      if (filterEl.getAttribute('slot') !== 'overflow-filters') {
        filterEl.setAttribute('slot', 'overflow-filters');
      }
    });
  }

  /**
   * Handle screen / component resize
   */
  private async handleResize() {
    if (!this.getComputedWidth(this.el)) {
      // element isn't fully rendered yet
      return;
    }
    const index = await this.findFilterCutoffIndex();
    const isFilterCutoffUpdated = index !== this._filterCutoffIndex;
    if (isFilterCutoffUpdated) {
      this._filterCutoffIndex = index;
      await asyncRequestAnimationFrame();
      this.sortVisibleAndOverflowFilters();
      // collapse dropdown if it's expanded
      if (this._dropdownMenuEl) {
        const dropdownEl = this._dropdownMenuEl.querySelector(getNamespacedTagFor('market-dropdown'));
        if (dropdownEl?.expanded) {
          dropdownEl?.closeDropdown();
        }
      }
    }
    this.checkIfSearchShouldBeCompact();
  }

  /**
   * Toggle search's compact mode, if present and not focused
   */
  private async checkIfSearchShouldBeCompact() {
    if (!this._slottedInputSearchEl || this._slottedInputSearchEl.hasAttribute('focused')) {
      return;
    }
    const isNarrowBreakpoint = window?.innerWidth <= CORE_BREAKPOINT_NARROW_MAX_WIDTH;
    const hasFilters = Boolean(this._filterEls?.length);
    const hasMoreThanMaxFilters = this._filterEls.length > (this.maxVisibleFilters ?? MAX_VISIBLE_FILTERS);
    const searchShouldBeCompact = hasFilters && (isNarrowBreakpoint || hasMoreThanMaxFilters);
    // only toggle `compact` when value is new
    if (searchShouldBeCompact !== this._slottedInputSearchEl.hasAttribute('compact')) {
      if (this._slottedInputSearchEl.getAttribute('focused')) {
        await this._slottedInputSearchEl.setFocus(false);
      }
      if (searchShouldBeCompact) {
        this._slottedInputSearchEl.setAttribute('compact', '');
      } else {
        this._slottedInputSearchEl.removeAttribute('compact');
      }
    }
  }

  /**
   * When tabbing from the search input and into the first filter,
   */
  private handleInputSearchTabKeydown(e: KeyboardEvent) {
    if (e.key === 'Tab' && !e.shiftKey && this._filterEls?.length) {
      e.preventDefault();
      this._slottedInputSearchEl.blur();
      this._willFocusOnFirstFilter = true;
    }
  }

  /**
   * When input search is focused, make sure that dropdown menu is closed
   */
  private handleInputSearchFocus() {
    const dropdownEl = this._dropdownMenuEl?.shadowRoot?.querySelector(getNamespacedTagFor('market-dropdown'));
    if (dropdownEl?.hasAttribute('expanded')) {
      dropdownEl.closeDropdown();
    }
  }

  private registerSlottedInputSearch() {
    this._slottedInputSearchEl?.removeEventListener?.('keydown', this.handleInputSearchTabKeydown);
    this._slottedInputSearchEl?.removeEventListener?.('focus', this.handleInputSearchFocus);
    this._slottedInputSearchEl = this.el.querySelector('[slot="search"]');
    this._slottedInputSearchEl?.addEventListener?.('keydown', this.handleInputSearchTabKeydown.bind(this));
    this._slottedInputSearchEl?.addEventListener?.('focus', this.handleInputSearchFocus.bind(this));

    this.checkIfSearchShouldBeCompact();
  }

  private registerSlottedFilters() {
    this._filterEls = [...this.el.children].filter((el): el is HTMLMarketFilterElement =>
      isElementWithTagName(el, 'market-filter'),
    );
  }

  private throttledHandleResize = throttle(this.handleResize.bind(this), RESIZE_DEBOUNCE_DURATION);

  private observeContent(el: HTMLDivElement) {
    if (!this._observers.content) {
      this._observers.content = new ResizeObserver(this.throttledHandleResize);
      this._observers.content.observe(el);
    }
  }

  private filtersOnSlotChangeHandler() {
    this.registerSlottedFilters();
    this.handleResize();
  }

  connectedCallback() {
    if (!this._observers.host) {
      this._observers.host = new ResizeObserver(this.throttledHandleResize);
      this._observers.host.observe(this.el);
    }
  }

  componentWillLoad() {
    this.registerSlottedFilters();
    this.registerSlottedInputSearch();
    this.handleResize();
  }

  componentWillRender() {
    // if 'Tab' was pressed (see `handleInputSearchTabKeydown`), attempt to focus on the first filter
    if (this._willFocusOnFirstFilter) {
      this._filterEls?.[0]?.setFocus?.();
      this._willFocusOnFirstFilter = false;
    }
  }

  disconnectedCallback() {
    Object.entries(this._observers).forEach(([key, observer]) => {
      if (observer) {
        observer.disconnect();
        this._observers[key] = undefined;
      }
    });
  }

  render() {
    /**
     * The dropdown menu button will follow the size of the first filter;
     * basically assuming that the rest of the filters follow the same size.
     */
    const dropdownMenuButtonSize = this._filterEls?.[0]?.size;

    const MarketFilterDropdownMenuTagName = getNamespacedTagFor('market-filter-dropdown-menu');

    return (
      <Host class="market-filter-group">
        {/**
         * .content is an inline-flex container that is observed by a ResizeObserver.
         * In events such as filter selection where the `market-filter`'s feedback changes
         * and in turn its overall width, `handleResize` will be triggered via ResizeObserver.
         * The .content's width is fluid while the host's width stays at 100% of its parent.
         */}
        <div class="content" ref={(el) => this.observeContent(el)}>
          <slot name="search" onSlotchange={() => this.registerSlottedInputSearch()}></slot>
          <slot name="filters" onSlotchange={() => this.filtersOnSlotChangeHandler()}></slot>
          {this._sortedFilterEls.overflow.length > 0 && (
            <MarketFilterDropdownMenuTagName
              class="dropdown-menu"
              size={dropdownMenuButtonSize}
              ref={(el) => (this._dropdownMenuEl = el)}
            >
              <slot name="overflow-filters" slot="overflow-filters"></slot>
            </MarketFilterDropdownMenuTagName>
          )}
        </div>
      </Host>
    );
  }
}
