import { useSubscriptionFeatureStatus } from '@squareup/saas-shared-ui/dist/feature-gating';
import { Feature } from '@squareup/saas-shared-ui';
import { useEffect } from 'react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { LoadingStatus } from 'src/MessengerTypes';

/**
 * Maps status from useSubscriptionFeatureStatus to LoadingStatus for use in SubscriptionStore
 *
 * @param {'pending' | 'error' | 'success'} status
 * 'pending' means that the statuses are initially loading/fetching. Future refreshes do not cause this to become 'loading' again.
 * 'error' means that an error has occurred.
 * 'success' means that the statuses have been successfully fetched.
 * See @tanstack/query-core/src/queryObserver.ts for more information on these and other states.
 * @returns {LoadingStatus} - The mapped loading status.
 */
const mapSaasStatusToLoadingStatus = (
  status: 'pending' | 'error' | 'success',
): Exclude<LoadingStatus, 'NOT_STARTED'> => {
  if (status === 'pending') return 'LOADING';
  return status.toUpperCase() as Exclude<LoadingStatus, 'NOT_STARTED'>;
};

/**
 * Custom hook to read and save the MESSAGES_PLUS feature statuses
 * to the SubscriptionStore.
 *
 * @returns {object} isFeatureEntitled and showFeatureUpsell
 * isFeatureEntitled {boolean} - Whether the user is entitled to access this feature.
 * showFeatureUpsell {boolean} - Whether the user is eligible for SQ1 and should be shown
 * an upsell for this feature.
 * isFeatureInTrial {boolean} - Whether the user is in a free trial for Square one.
 */
const useSaasFeatureStatus = (): {
  isFeatureEntitled: boolean;
  showFeatureUpsell: boolean;
  isFeatureInTrial: boolean;
} => {
  const { subscription } = useMessengerControllerContext();
  const { isFeatureEntitled, showFeatureUpsell, isFeatureInTrial, response } =
    useSubscriptionFeatureStatus(Feature.MESSAGES_PLUS);

  const { status } = response;

  useEffect(() => {
    subscription.setSaasFeatureStatus(mapSaasStatusToLoadingStatus(status));
    subscription.setIsFeatureEntitled(isFeatureEntitled);
    subscription.setShowFeatureUpsell(showFeatureUpsell);
    subscription.setIsFeatureInTrial(isFeatureInTrial);
  }, [
    status,
    isFeatureEntitled,
    showFeatureUpsell,
    isFeatureInTrial,
    subscription,
  ]);

  return {
    isFeatureEntitled,
    showFeatureUpsell,
    isFeatureInTrial,
  };
};

export default useSaasFeatureStatus;
