/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../connect/v2/common/money';
import '../../connect/v2/catalog/resources/datatypes';
import '../../connect/v2/resources/address';
import '../../agenda/calendar';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "connect": {
        "nested": {
          "v2": {
            "nested": {
              "bookings": {
                "nested": {
                  "service": {
                    "nested": {
                      "BookingStatus": {
                        "values": {
                          "PENDING": 1,
                          "CANCELLED_BY_CUSTOMER": 2,
                          "CANCELLED_BY_SELLER": 3,
                          "DECLINED": 4,
                          "ACCEPTED": 5,
                          "NO_SHOW": 6
                        }
                      },
                      "AppointmentSegment": {
                        "fields": {
                          "durationMinutes": {
                            "type": "int32",
                            "id": 1,
                            "rule": "optional"
                          },
                          "serviceVariationId": {
                            "type": "string",
                            "id": 2,
                            "rule": "optional"
                          },
                          "serviceVariationClientId": {
                            "type": "string",
                            "id": 17,
                            "rule": "optional"
                          },
                          "teamMemberId": {
                            "type": "string",
                            "id": 3,
                            "rule": "optional"
                          },
                          "serviceVariationVersion": {
                            "type": "int64",
                            "id": 4,
                            "rule": "optional"
                          },
                          "intermissionMinutes": {
                            "type": "int32",
                            "id": 5,
                            "rule": "optional"
                          },
                          "anyTeamMember": {
                            "type": "bool",
                            "id": 6,
                            "rule": "optional"
                          },
                          "resourceIds": {
                            "type": "string",
                            "id": 7,
                            "rule": "repeated"
                          },
                          "uid_DO_NOT_USE": {
                            "type": "string",
                            "id": 8,
                            "rule": "optional"
                          },
                          "ordinal": {
                            "type": "int32",
                            "id": 9,
                            "rule": "optional"
                          },
                          "intermissionStartOffsetMinutes": {
                            "type": "int32",
                            "id": 10,
                            "rule": "optional"
                          },
                          "intermissionEndOffsetMinutes": {
                            "type": "int32",
                            "id": 11,
                            "rule": "optional"
                          },
                          "serviceId": {
                            "type": "string",
                            "id": 12,
                            "rule": "optional"
                          },
                          "serviceClientId": {
                            "type": "string",
                            "id": 18,
                            "rule": "optional"
                          },
                          "lineItemUid": {
                            "type": "string",
                            "id": 13,
                            "rule": "optional"
                          },
                          "serviceCostOverride": {
                            "type": "int32",
                            "id": 14,
                            "rule": "optional"
                          },
                          "serviceCostOverrideMoney": {
                            "type": "squareup.connect.v2.common.Money",
                            "id": 15,
                            "rule": "optional"
                          },
                          "defaultServiceCost": {
                            "type": "squareup.connect.v2.merchant_catalog.resources.ServiceCost",
                            "id": 16,
                            "rule": "optional"
                          }
                        }
                      },
                      "InternalSource": {
                        "values": {
                          "UNKNOWN": 0,
                          "FACEBOOK": 1,
                          "INSTAGRAM": 2,
                          "OBS": 3,
                          "RESERVE_WITH_GOOGLE": 4,
                          "YELP": 5,
                          "API": 6,
                          "IMPORT": 7,
                          "ROBOT": 8,
                          "MARKETPLACE": 9,
                          "RESERVE_WITH_GOOGLE_V2": 10
                        }
                      },
                      "Booking": {
                        "fields": {
                          "id": {
                            "type": "string",
                            "id": 1,
                            "rule": "optional"
                          },
                          "version": {
                            "type": "int32",
                            "id": 2,
                            "rule": "optional"
                          },
                          "status": {
                            "type": "squareup.connect.v2.bookings.service.BookingStatus",
                            "id": 3,
                            "rule": "optional"
                          },
                          "createdAt": {
                            "type": "string",
                            "id": 4,
                            "rule": "optional"
                          },
                          "updatedAt": {
                            "type": "string",
                            "id": 5,
                            "rule": "optional"
                          },
                          "startAt": {
                            "type": "string",
                            "id": 6,
                            "rule": "optional"
                          },
                          "locationId": {
                            "type": "string",
                            "id": 7,
                            "rule": "optional"
                          },
                          "customerId": {
                            "type": "string",
                            "id": 8,
                            "rule": "optional"
                          },
                          "customerNote": {
                            "type": "string",
                            "id": 9,
                            "rule": "optional"
                          },
                          "sellerNote": {
                            "type": "string",
                            "id": 10,
                            "rule": "optional"
                          },
                          "appointmentSegments": {
                            "type": "squareup.connect.v2.bookings.service.AppointmentSegment",
                            "id": 11,
                            "rule": "repeated"
                          },
                          "transitionTimeMinutes": {
                            "type": "int32",
                            "id": 12,
                            "rule": "optional"
                          },
                          "allDay": {
                            "type": "bool",
                            "id": 13,
                            "rule": "optional"
                          },
                          "locationType": {
                            "type": "squareup.connect.v2.bookings.service.BusinessAppointmentSettings.BookingLocationType",
                            "id": 14,
                            "rule": "optional"
                          },
                          "creatorDetails": {
                            "type": "squareup.connect.v2.bookings.service.Booking.CreatorDetails",
                            "id": 15,
                            "rule": "optional"
                          },
                          "source": {
                            "type": "squareup.connect.v2.bookings.service.Booking.BookingSource",
                            "id": 16,
                            "rule": "optional"
                          },
                          "tableDetails": {
                            "type": "squareup.connect.v2.bookings.service.TableDetails",
                            "id": 17,
                            "rule": "optional"
                          },
                          "rrule": {
                            "type": "string",
                            "id": 18,
                            "rule": "optional"
                          },
                          "repeatUntil": {
                            "type": "string",
                            "id": 19,
                            "rule": "optional"
                          },
                          "exdates": {
                            "type": "string",
                            "id": 20,
                            "rule": "optional"
                          },
                          "deleted": {
                            "type": "bool",
                            "id": 21,
                            "rule": "optional"
                          },
                          "merchantId": {
                            "type": "string",
                            "id": 22,
                            "rule": "optional"
                          },
                          "dateEnd": {
                            "type": "string",
                            "id": 23,
                            "rule": "optional"
                          },
                          "timeZone": {
                            "type": "string",
                            "id": 24,
                            "rule": "optional"
                          },
                          "parentId": {
                            "type": "string",
                            "id": 25,
                            "rule": "optional"
                          },
                          "address": {
                            "type": "squareup.connect.v2.resources.Address",
                            "id": 26,
                            "rule": "optional"
                          },
                          "contractTokens": {
                            "type": "string",
                            "id": 27,
                            "rule": "repeated"
                          },
                          "internalSource": {
                            "type": "squareup.connect.v2.bookings.service.InternalSource",
                            "id": 28,
                            "rule": "optional",
                            "options": {
                              "default": "UNKNOWN"
                            }
                          },
                          "topmostParentId": {
                            "type": "string",
                            "id": 29,
                            "rule": "optional"
                          },
                          "recurrenceRule": {
                            "type": "squareup.agenda.RecurrenceRule",
                            "id": 30,
                            "rule": "optional"
                          },
                          "exceptionDates": {
                            "type": "string",
                            "id": 31,
                            "rule": "repeated"
                          },
                          "originalDateStart": {
                            "type": "string",
                            "id": 32,
                            "rule": "optional"
                          }
                        },
                        "nested": {
                          "CreatorDetails": {
                            "fields": {
                              "creatorType": {
                                "type": "squareup.connect.v2.bookings.service.Booking.CreatorDetails.CreatorType",
                                "id": 1,
                                "rule": "optional"
                              },
                              "teamMemberId": {
                                "type": "string",
                                "id": 2,
                                "rule": "optional"
                              },
                              "customerId": {
                                "type": "string",
                                "id": 3,
                                "rule": "optional"
                              },
                              "applicationId": {
                                "type": "string",
                                "id": 4,
                                "rule": "optional"
                              },
                              "widgetId": {
                                "type": "string",
                                "id": 5,
                                "rule": "optional"
                              }
                            },
                            "nested": {
                              "CreatorType": {
                                "values": {
                                  "TEAM_MEMBER": 1,
                                  "CUSTOMER": 2
                                }
                              }
                            }
                          },
                          "BookingSource": {
                            "values": {
                              "FIRST_PARTY_MERCHANT": 1,
                              "FIRST_PARTY_BUYER": 2,
                              "THIRD_PARTY_BUYER": 3,
                              "API": 4
                            }
                          }
                        }
                      },
                      "TableDetails": {
                        "fields": {
                          "partySize": {
                            "type": "int32",
                            "id": 1,
                            "rule": "optional"
                          },
                          "tableName": {
                            "type": "string",
                            "id": 2,
                            "rule": "optional"
                          },
                          "tableNames": {
                            "type": "string",
                            "id": 3,
                            "rule": "repeated"
                          }
                        }
                      },
                      "BusinessAppointmentSettings": {
                        "nested": {
                          "BookingLocationType": {
                            "values": {
                              "BUSINESS_LOCATION": 1,
                              "CUSTOMER_LOCATION": 2,
                              "PHONE": 3
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.bookings?.nested?.service?.nested?.BookingStatus && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.bookings?.nested?.service?.nested?.AppointmentSegment && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.bookings?.nested?.service?.nested?.InternalSource && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.bookings?.nested?.service?.nested?.Booking && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.bookings?.nested?.service?.nested?.Booking?.nested?.CreatorDetails && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.bookings?.nested?.service?.nested?.Booking?.nested?.CreatorDetails?.nested?.CreatorType && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.bookings?.nested?.service?.nested?.Booking?.nested?.BookingSource && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.bookings?.nested?.service?.nested?.TableDetails && !$root.nested?.squareup?.nested?.connect?.nested?.v2?.nested?.bookings?.nested?.service?.nested?.BusinessAppointmentSettings?.nested?.BookingLocationType) {
	$root.addJSON(jsonData);
}

/**
 * @desc Supported booking statuses.
 *
 * SOURCE BookingStatus @ squareup/appointments/api/public.proto at 30:1
 */
export const BookingStatus = $root.lookupEnum('squareup.connect.v2.bookings.service.BookingStatus').values;
/**
 * @desc Defines an appointment segment of a booking.
 *
 * SOURCE AppointmentSegment @ squareup/appointments/api/public.proto at 77:1
 */
export const AppointmentSegment = $root.lookupType('squareup.connect.v2.bookings.service.AppointmentSegment');
fixType(AppointmentSegment, {
  teamMemberId: { notEmpty: true },
});
Builder.createAndAttachToType(AppointmentSegment);
/**
 * The internal creation source of the appointment
 *
 * SOURCE InternalSource @ squareup/appointments/api/public.proto at 167:1
 */
export const InternalSource = $root.lookupEnum('squareup.connect.v2.bookings.service.InternalSource').values;
/**
 * @desc Represents a booking as a time-bound service contract for a seller's staff member to provide a specified service
 * at a given location to a requesting customer in one or more appointment segments.
 *
 * SOURCE Booking @ squareup/appointments/api/public.proto at 202:1
 */
export const Booking = $root.lookupType('squareup.connect.v2.bookings.service.Booking');
fixType(Booking);
Builder.createAndAttachToType(Booking);
/**
 * @desc Information about a booking creator.
 *
 * SOURCE CreatorDetails @ squareup/appointments/api/public.proto at 250:3
 */
Booking.CreatorDetails = $root.lookupType('squareup.connect.v2.bookings.service.Booking.CreatorDetails');
fixType(Booking.CreatorDetails);
Builder.createAndAttachToType(Booking.CreatorDetails);
/**
 * @desc Supported types of a booking creator.
 *
 * SOURCE CreatorType @ squareup/appointments/api/public.proto at 254:5
 */
Booking.CreatorDetails.CreatorType = $root.lookupEnum('squareup.connect.v2.bookings.service.Booking.CreatorDetails.CreatorType').values;
/**
 * @desc Supported sources a booking was created from.
 *
 * SOURCE BookingSource @ squareup/appointments/api/public.proto at 298:3
 */
Booking.BookingSource = $root.lookupEnum('squareup.connect.v2.bookings.service.Booking.BookingSource').values;
/**
 * @desc Fields specific to a table_booking
 *
 * SOURCE TableDetails @ squareup/appointments/api/public.proto at 348:1
 */
export const TableDetails = $root.lookupType('squareup.connect.v2.bookings.service.TableDetails');
fixType(TableDetails);
Builder.createAndAttachToType(TableDetails);
/**
 * @desc The service appointment settings, including where and how the service is provided.
 *
 * SOURCE BusinessAppointmentSettings @ squareup/appointments/api/public.proto at 775:1
 */
export const BusinessAppointmentSettings = {};
/**
 * @desc Supported types of location where service is provided.
 *
 * SOURCE BookingLocationType @ squareup/appointments/api/public.proto at 779:3
 */
BusinessAppointmentSettings.BookingLocationType = $root.lookupEnum('squareup.connect.v2.bookings.service.BusinessAppointmentSettings.BookingLocationType').values;
