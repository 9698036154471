import { Component, Prop, h, Event, EventEmitter, Host, State, Watch } from '@stencil/core';
import { getNamespacedTagFor } from '../../../../utils/namespace';
import { format, isAfter, isBefore, isSameDay, isValid, parseISO } from 'date-fns';

@Component({
  tag: 'market-date-picker-input-date',
  styleUrl: 'market-date-picker-input-date.css',
  shadow: true,
})
export class MarketDateInputDate {
  /**
   * Whether to use date-time input instead of date-only
   * @type {boolean}
   * @memberof MarketDateInputDate
   * @default false
   */
  @Prop() readonly withTime: boolean = false;

  /**
   * The selected start date
   * @type {string}
   * @memberof MarketDateInputDate
   * @default ''
   */
  @Prop() readonly selectedStartDate: string;

  /**
   * The selected end date
   * @type {string}
   * @memberof MarketDateInputDate
   * @default ''
   */
  @Prop() readonly selectedEndDate: string;

  /**
   * A function that returns true if the date should be disabled
   * @type {(day: Date) => boolean}
   * @memberof MarketDateInputDate
   * @default undefined
   */
  @Prop() readonly isDateDisabled: (day: Date) => boolean;

  /**
   * The timeframe to restrict the date picker to
   * @type {'past' | 'future' | 'present'}
   * @memberof MarketDateInputDate
   * @default 'present'
   */
  @Prop() readonly timeframe: 'past' | 'future' | 'present' = 'present';

  /**
   * Whether the date picker is a range
   * @type {boolean}
   * @memberof MarketDateInputDate
   * @default false
   */
  @Prop() readonly range: boolean = false;

  /**
   * Emitted when the date picker date is selected
   */
  @Event() marketDatePickerInputDateSet: EventEmitter<{ date: string; input: string }>;

  /**
   * Emitted when the date selection changes validity
   */
  @Event() marketDatePickerInputDateInvalidStateChanged: EventEmitter<{ invalid: boolean }>;

  /*
   * Whether or not the entire date range is invalid
   */
  @State() isRangeInvalid: boolean = false;

  @Watch('isRangeInvalid')
  emitInvalidStateEvent() {
    this.marketDatePickerInputDateInvalidStateChanged.emit({
      invalid: this.isRangeInvalid,
    });
  }

  private formatDate(date: string, isTime: boolean = false): string {
    if (!date) return '';

    const dateObj = new Date(date);
    if (!isValid(dateObj)) {
      return '';
    }
    const formatStr = isTime ? 'HH:mm' : 'yyyy-MM-dd';
    return format(dateObj, formatStr);
  }

  private isInvalidDateForTimeframe(dateObj: Date, today: Date = new Date()): boolean {
    const isFutureDate = this.timeframe === 'future' && isBefore(dateObj, today) && !isSameDay(dateObj, today);
    const isPastDate = this.timeframe === 'past' && isAfter(dateObj, today) && !isSameDay(dateObj, today);

    return isFutureDate || isPastDate;
  }

  private updateInvalidState(start: string = this.selectedStartDate, end: string = this.selectedEndDate): boolean {
    if (this.isDateInvalid(start) || this.isDateInvalid(end)) {
      this.isRangeInvalid = true;
      return;
    }

    // If end date is set, check if start date is after end date
    if (end) {
      this.isRangeInvalid = isAfter(new Date(start), new Date(end));
      return;
    }

    this.isRangeInvalid = false;
  }

  private isDateInvalid(date: string): boolean {
    if (!date) return false;
    const dateObj = parseISO(date);
    if (dateObj.toString() === 'Invalid Date') return true;

    if (this.isDateDisabled && this.isDateDisabled(dateObj)) {
      return true;
    }

    if (this.timeframe && this.isInvalidDateForTimeframe(dateObj)) {
      return true;
    }

    return false;
  }

  emitDateSetStart(ev: Event) {
    this.emitDateSet(ev, 'start');
  }

  emitDateSetEnd(ev: Event) {
    this.emitDateSet(ev, 'end');
  }

  emitDateSetStartTime(ev: Event) {
    this.emitDateSet(ev, 'start', true);
  }

  emitDateSetEndTime(ev: Event) {
    this.emitDateSet(ev, 'end', true);
  }

  emitDateSet(ev: Event, input: string, timeInput: boolean = false) {
    ev.preventDefault();
    ev.stopPropagation();
    const { value } = ev.target as HTMLInputElement;
    let valueToSubmit = value;

    // If time is set, add the time to the date
    if (this.withTime) {
      if (timeInput) {
        const date = this.formatDate(input === 'start' ? this.selectedStartDate : this.selectedEndDate);
        const time = value.slice(0, 5);
        valueToSubmit = `${date}T${time}`;
      } else {
        const time = this.formatDate(input === 'start' ? this.selectedStartDate : this.selectedEndDate, true);
        valueToSubmit = `${value}T${time}`;
      }
    }
    // Remove leading zeros
    const realLength = valueToSubmit.replace(/^0+/, '').length;
    // Only emit if length is (yyyy-mm-dd) or (yyyy-mm-ddThh:mm) and is valid
    if (realLength === 10 || realLength === 16) {
      this.marketDatePickerInputDateSet.emit({ date: valueToSubmit, input });
    }
  }

  componentWillRender() {
    this.updateInvalidState();
  }

  render() {
    const MarketInputTextTagName = getNamespacedTagFor('market-input-text');
    const MarketBannerTagName = getNamespacedTagFor('market-banner');

    const inputWrapper = (type) => (
      <div class={`input-wrapper ${type === 'start' ? 'start-input' : ''}`}>
        {type === 'start' && dateInput('start')}
        {type === 'start' && this.withTime && timeInput('start')}
        {type === 'end' && dateInput('end')}
        {type === 'end' && this.withTime && timeInput('end')}
      </div>
    );

    const dateInput = (type: string) => (
      <MarketInputTextTagName
        type="date"
        class={`date-input`}
        name={`date-picker-date-${type}`}
        invalid={this.isRangeInvalid}
        value={this.formatDate(type === 'start' ? this.selectedStartDate : this.selectedEndDate)}
        onInput={type === 'start' ? this.emitDateSetStart.bind(this) : this.emitDateSetEnd.bind(this)}
      >
        <label htmlFor={`date-picker-${type}`}>
          <slot name={`${type}-date`}>{this.range && (type === 'start' ? 'Start' : 'End')} Date</slot>
        </label>
      </MarketInputTextTagName>
    );

    const timeInput = (type: string) => (
      <MarketInputTextTagName
        type="time"
        class={`time-input`}
        name={`date-picker-time-${type}`}
        invalid={this.isRangeInvalid}
        value={this.formatDate(type === 'start' ? this.selectedStartDate : this.selectedEndDate, true)}
        onInput={type === 'start' ? this.emitDateSetStartTime.bind(this) : this.emitDateSetEndTime.bind(this)}
      >
        <label htmlFor={`date-picker-time-${type}`}>
          <slot name={`${type}-time`}>{this.range && (type === 'start' ? 'Start' : 'End')} Time</slot>
        </label>
      </MarketInputTextTagName>
    );

    return (
      <Host class="market-date-picker-input-date">
        {this.range ? (
          <div class="input-row">
            {inputWrapper('start')}
            {inputWrapper('end')}
          </div>
        ) : (
          <div>{inputWrapper('start')}</div>
        )}
        {this.isRangeInvalid && (
          <MarketBannerTagName variant="critical">
            <slot name="range-error">Enter a valid date range</slot>
          </MarketBannerTagName>
        )}
      </Host>
    );
  }
}
