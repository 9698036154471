@import "../../styles/mixins/component-base.css";
@import "../../styles/mixins/modals.css";

:host {
  /* TODO: Make this a design token. Based on this Slack convo: https://square.slack.com/archives/G865UJS3B/p1632361735181400 */
  --modal-full-wide-layout-width: 1200px;
  --regular-modal-main-horizontal-padding: calc((100vw - var(--modal-full-fixed-layout-width)) / 2);
  --wide-modal-main-horizontal-padding: calc((100vw - var(--modal-full-wide-layout-width)) / 2);

  @extend %modal-full;
}

/* sadly you can't use variables in media queries, so here is the math for this breakpoint:
   --modal-full-fixed-layout-width (600px) + 2 * --modal-full-medium-viewport-horizontal-padding-size (24px) = 648px */
@media (min-width: 648px) {
  :host([layout="regular"]) {
    > :not(.market-header, .market-footer),
    ::slotted(main),
    ::slotted(.main) {
      padding: 0 var(--regular-modal-main-horizontal-padding);
    }
  }
}

/* sadly you can't use variables in media queries, so here is the math for this breakpoint:
   --modal-full-wide-layout-width (1200px) + 2 * --modal-full-wide-viewport-horizontal-padding-size (40px) = 1280px */
@media (min-width: 1280px) {
  :host([layout="wide"]) {
    > :not(.market-header, .market-footer),
    ::slotted(main),
    ::slotted(.main) {
      padding: 0 var(--wide-modal-main-horizontal-padding);
    }
  }
}
