import { makeAutoObservable } from 'mobx';
import type MessengerController from 'src/MessengerController';
import type Api from 'src/api/Api';
import type { Contact } from 'src/gen/squareup/messenger/v3/messenger_auxiliary_service';
import TranscriptsList from 'src/stores/objects/TranscriptsList';
import PastAppointmentsList from 'src/stores/objects/PastAppointmentsList';

/**
 * Store containing information on a given customer.
 */
class Customer {
  private _stores: MessengerController;
  private _api: Api;

  /**
   * The token of the customer that this store pertains to.
   */
  customerToken: string;

  /**
   * The customer details pertaining to this customer.
   */
  private _contact?: Contact;

  /**
   * The past conversation history for this customer.
   */
  history: TranscriptsList;

  /**
   * The past appointments for this customer.
   */
  pastAppointments: PastAppointmentsList;

  constructor(stores: MessengerController, customerToken: string) {
    makeAutoObservable<Customer, '_contact'>(this);

    this._stores = stores;
    this._api = stores.api;

    this.customerToken = customerToken;
    this.history = new TranscriptsList(stores, {
      customerToken,
    });
    this.pastAppointments = new PastAppointmentsList(stores, customerToken);
  }

  setContact = (contact: Contact): void => {
    this._contact = contact;
  };

  get contact(): Contact | undefined {
    return this._contact;
  }

  loadTranscriptsHistory = (): Promise<void> => {
    if (this.history.status === 'NOT_STARTED') {
      return this.history.init();
    }
    return Promise.resolve();
  };

  loadPastAppointments = (): Promise<void> => {
    if (this.pastAppointments.status === 'NOT_STARTED') {
      return this.pastAppointments.init();
    }
    return Promise.resolve();
  };
}

export default Customer;
