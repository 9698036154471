import { Component, Element, Event, EventEmitter, Host, h, Listen, Method, Prop, State } from '@stencil/core';
import {
  ACCORDION_HEADING_10_VARIANT_EXPANDED_PHASE_ICON_ASSET,
  ACCORDION_HEADING_20_VARIANT_EXPANDED_PHASE_ICON_ASSET,
  ACCORDION_HEADING_30_VARIANT_EXPANDED_PHASE_ICON_ASSET,
} from '@market/market-theme/js/cjs/index.js';

import { getNamespacedTagFor } from '../../utils/namespace';

const sizeToHeadingType: Record<'small' | 'medium' | 'large', string> = {
  large: '2',
  medium: '3',
  small: '4',
};

@Component({
  tag: 'market-accordion-item',
  styleUrl: 'market-accordion-item.css',
  shadow: true,
})
export class MarketAccordionItem {
  @Element() el: HTMLMarketAccordionItemElement;

  /**
   * The unique name of the accordion.
   */
  @Prop({ reflect: true }) readonly name!: string;

  /**
   * Determines whether the accordion is shown as expanded or collapsed.
   */
  @Prop({ mutable: true, reflect: true }) expanded: boolean = false;

  /**
   * Whether the accordion should appear in a disabled state.
   */
  @Prop({ mutable: true, reflect: true }) disabled: boolean = false;

  /**
   * The size of the heading text of the accordion.
   */
  @Prop({ reflect: true }) readonly size: 'small' | 'medium' | 'large' = 'medium';

  /**
   * Use a custom trigger to expand/collapse content
   */
  @State() customTrigger: HTMLMarketTableRowElement;

  /**
   * Fired whenever the "expanded" prop value changes.
   */
  @Event() marketAccordionItemExpandedChange: EventEmitter<{ expanded: boolean }>;

  @Listen('marketAccordionToggled')
  marketAccordionToggleHandler(e: CustomEvent) {
    e.stopPropagation();
    this.setExpanded(!this.expanded);
  }

  /**
   * Used to set the "open" state of the accordion.
   */
  @Method()
  setExpanded(newExpanded: boolean) {
    const oldExpanded = this.expanded;

    if (newExpanded !== oldExpanded) {
      const { defaultPrevented } = this.marketAccordionItemExpandedChange.emit({
        expanded: newExpanded,
      });

      if (!defaultPrevented) {
        this.expanded = newExpanded;
        if (this.customTrigger) {
          this.customTrigger.expanded = newExpanded;
        }
      }
    }

    return Promise.resolve();
  }

  /**
   * Sets `disabled` state. Allows external elements to programmatically trigger disabled styling.
   */
  @Method()
  setDisabled(value: boolean) {
    this.disabled = value;
    return Promise.resolve();
  }

  getAccordionIcon() {
    switch (this.size) {
      case 'small':
        return ACCORDION_HEADING_10_VARIANT_EXPANDED_PHASE_ICON_ASSET;
      case 'large':
        return ACCORDION_HEADING_30_VARIANT_EXPANDED_PHASE_ICON_ASSET;
      default: // medium
        return ACCORDION_HEADING_20_VARIANT_EXPANDED_PHASE_ICON_ASSET;
    }
  }

  componentWillLoad() {
    this.customTrigger = this.el.querySelector('[slot="custom-trigger"]') as HTMLMarketTableRowElement;
    if (this.customTrigger) {
      this.customTrigger.expanded = this.expanded;
    }
  }

  render() {
    const Heading = `h${sizeToHeadingType[this.size]}`;
    const MarketIconTagName = getNamespacedTagFor('market-icon');

    return (
      <Host class="market-accordion-item">
        {this.customTrigger ? (
          <slot name="custom-trigger"></slot>
        ) : (
          <Heading>
            <button
              id={`${this.name}__button`}
              type="button"
              aria-expanded={this.expanded}
              aria-controls={`${this.name}__content`}
              aria-disabled={this.disabled}
              disabled={this.disabled}
              onClick={() => this.setExpanded(!this.expanded)}
            >
              <slot name="label" />
              <MarketIconTagName name={this.getAccordionIcon()}></MarketIconTagName>
            </button>
          </Heading>
        )}
        {this.expanded && (
          <div
            id={`${this.name}__content`}
            class="accordion-content"
            role="region"
            aria-labelledby={`${this.name}__button`}
          >
            <slot></slot>
          </div>
        )}
      </Host>
    );
  }
}
