import React, { ReactElement, ReactNode, RefObject } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import EventCard, {
  SharedEventCardProps,
} from 'src/pages/TranscriptViewPage/components/EventCard/EventCard';
import CustomerImage from 'src/components/CustomerImage/CustomerImage';
import './CustomerEventCard.scss';

export type CustomerEventCardProps = SharedEventCardProps & {
  description?: string;
  utterance?: string;
  showCustomerImage?: boolean;
  body?: ReactElement;
  className?: string;
  cardRef?: RefObject<HTMLDivElement>;
  children?: ReactNode;
};

/**
 * Represents a customer-initiated event, that appears like an utterance on the customer
 * side with extra information above the utterance text. Contains the event information,
 * and the utterance/comment relating to it, if any. Appears in a conversation view page.
 *
 * @example
 * Basic usage:
 * <CustomerEventCard
 *   icon={<Happy Icon />}
 *   title="Positive Feedback"
 *   subtitle="Sale $10"
 * />
 *
 * With optional:
 * <CustomerEventCard
 *   timestampMillis={143129341000}
 *   icon={<Happy Icon />}
 *   title="Positive Feedback"
 *   subtitle="Sale $10"
 *   description="Customer Service"
 *   utterance="Ken's haircut is always the best!"
 * />
 * @param {number} timestampMillis
 * Time when this event happened, in millisecond. Shown when hovered.
 * @param {ReactElement} icon
 * Icon representing this event. It should generally be a square of size 32x32.
 * @param {string} title
 * Title of event.
 * @param {string | ReactElement} [subtitle]
 * (Optional) Usually additional information of the event.
 * @param {string} [link]
 * (Optional) If present, show a button that will open a new tab to the link.
 * @param {string} [linkText]
 * (Optional) Should be present if link is provided. Text to show in the link button.
 * @param {string} [description]
 * (Optional) Additional description of the event.
 * @param {string} [utterance]
 * (Optional) Utterance attached to this event.
 * @param {boolean} [showCustomerImage]
 * (Optional) If present, show the image on the left side of the card.
 * @param {Function} [track]
 * (Optional) If present, call this function when link is clicked. Used mainly to
 * trigger a track on an event.
 * @param {ReactElement} [body]
 * (Optional) Rich content to render in place of a text utterance.
 * @param {string} [className]
 * (Optional) class name to be applied to the root div of the event card.
 * @param {RefObject<HTMLDivElement>} [cardRef]
 * (Optional) Ref that should be set on the root div element of the card.
 * @param {number} [id]
 * (Optional) Utterance id for this event card. Used to highlight the card when the user
 * clicks on a search result.
 * @param {ReactNode} [children]
 * (Optional) Children that will be rendered within the card's container element.
 * @author klim
 */
const CustomerEventCard = observer(
  (props: CustomerEventCardProps): ReactElement => {
    const {
      timestampMillis,
      icon,
      title,
      subtitle,
      description,
      utterance,
      showCustomerImage,
      link,
      linkText,
      track,
      body,
      className,
      cardRef,
      id,
      children,
    } = props;
    const { transcriptView } = useMessengerControllerContext();
    const { t } = useTranslation();
    const { displayName } = transcriptView.transcript;

    return (
      <div
        className={`Utterance${className ? ` ${className}` : ''}`}
        data-testid="CustomerEventCard"
        ref={cardRef}
      >
        <div className="Utterance__customer">
          {showCustomerImage ? (
            <CustomerImage
              customerInitials={displayName?.initials}
              imageUrl={displayName?.profileImageUrl}
              customerName={
                displayName?.name || t('TranscriptViewPage.unknown_user')
              }
            />
          ) : (
            <div />
          )}
          <div className="CustomerEventCard__content Utterance__timestamp-container">
            <EventCard
              timestampMillis={timestampMillis}
              icon={icon}
              title={title}
              subtitle={subtitle}
              description={description}
              body={body ?? utterance}
              link={link}
              linkText={linkText}
              track={track}
              id={id}
            />
            {children}
          </div>
        </div>
      </div>
    );
  },
);

export default CustomerEventCard;
