import { Component, Element, Event, Host, h, State, Prop, EventEmitter, Watch } from '@stencil/core';
import { getReadableFilesize } from '../../utils/filesize';
import { getNamespacedTagFor } from '../../utils/namespace';
import { TMarketFileUploadFileConfig } from './types';

/**
 * @slot - Used for drop zone content. Intended for an icon (optional), main text, and secondary text (optional).
 * @slot bottom-accessory - DEPRECATED: Used for the bottom accessory text. We recommend using the default slot
 * to add secondary text to the drop zone content instead.
 * @slot error - DEPRECATED: Used for input-level error text. We recommend using the fileMetadata prop to set error
 * status and messages on the file level instead.
 */

@Component({
  tag: 'market-file-upload',
  styleUrl: 'market-file-upload.css',
  shadow: true,
})
export class MarketFileUpload {
  @Element() el: HTMLMarketFileUploadElement;

  /**
   * String that is a list of file types the uploader should accept. This is
   * passed to the internal `<input type="file"/>` tag. For more info, see the
   * [MDN docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept).
   */
  @Prop() readonly accept: string = '';

  /**
   * An array of File objects that can be passed in. (If using vanilla JS, this must be set using JS and not in the HTML markup.)
   */
  @Prop() readonly value: Array<File> = [];

  /**
   * Functionally and visually disables the file picker.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * DEPRECATED: Represents whether the input is invalid or not. This represents input-level error states.
   * We recommend using the fileMetadata prop to set error status and messages on the file level instead.
   */
  @Prop({ reflect: true }) readonly invalid: boolean = false;

  /**
   * Represents whether the selector allows multiple files
   * to be selected.
   */
  @Prop({ reflect: true }) readonly multiple: boolean = false;

  /**
   * What type of secondary information, if any, to display on all file rows
   */
  @Prop() readonly fileSubtext?: 'size' | 'type';

  /**
   * What type of custom information, if any, to display on specified file rows.
   * Expects an array of TMarketFileUploadFileConfig objects (must be set using JS and not in the HTML markup), which
   * must include `filename` and can include an optional `status`, `message`, or `leadingIconName`.
   * Note that `message` is only shown when `status` is `'error'`, and `leadingIconName` expects the semantic or
   * descriptive name of an existing `market-icon`.
   */
  @Prop() readonly fileMetadata?: Array<TMarketFileUploadFileConfig>;

  /**
   * Optional property passed to the delete icons on selected file
   * rows that will function as its aria-label. Defaults to "Delete".
   */
  @Prop({ reflect: true }) readonly deleteButtonAriaLabel: string = 'Delete';

  /**
   * Tracks whether a file is being dragged over the component.
   */
  @State() isDraggingOver: boolean = false;

  /**
   * Files that have been selected by the user either via drag & drop or
   * the file selector.
   */
  @State() files: Array<File>;

  @Watch('value')
  watchValueHandler(newValue: Array<File>) {
    if (Array.isArray(newValue)) {
      this.files = this.multiple ? [...newValue] : [newValue[0]];
    }
  }

  /**
   * Triggered when the selected files array changes.
   */
  @Event() marketFileUploadValueChange: EventEmitter<{ value: Array<File> }>;

  /**
   * We want to disable certain functions if multiple is false and a file has been selected
   */
  get hasSingleFileSelected(): boolean {
    return !this.multiple && this.files.length === 1;
  }

  private fileInputElement: HTMLInputElement;

  draggingOver(e) {
    if (this.disabled || this.hasSingleFileSelected) {
      return;
    }

    this.isDraggingOver = true;
    e.preventDefault();
  }

  endDrag(e) {
    e.preventDefault();
    if (this.disabled || this.hasSingleFileSelected) {
      return;
    }

    this.isDraggingOver = false;
    if (e.type === 'drop' && e.dataTransfer) {
      this.addFiles(e.dataTransfer.files);
    }
  }

  handleButtonClick() {
    this.fileInputElement.click();
  }

  handleDeleteKeydown(e, index) {
    if (e.key === 'Enter' && !this.disabled) {
      this.removeFile(index);
    }
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
    this.files = [...this.files];
    this.emitFileChange();
  }

  labelContainerClassNames() {
    const classNames = [];
    if (this.isDraggingOver) {
      classNames.push('is-dragging-over');
    }
    if (this.hasSingleFileSelected) {
      classNames.push('has-file-selected');
    }

    return classNames;
  }

  onInputChange(e) {
    this.addFiles(e.target.files);
    // unset the file input value so that we can re-add files
    // if they are removed by the user
    this.fileInputElement.value = '';
  }

  addFiles(files: Array<File>) {
    if (files) {
      if (!this.multiple) {
        this.files = [files[0]];
      } else {
        this.files = [...files, ...this.files];
      }
    }
    this.emitFileChange();
  }

  emitFileChange() {
    this.marketFileUploadValueChange.emit({ value: this.files });
  }

  componentWillLoad() {
    if (this.value) {
      this.files = [...this.value];
    } else {
      this.files = [];
    }
  }

  renderFilesAsMarketRows() {
    const MarketRowTagName = getNamespacedTagFor('market-row');
    const MarketAccessoryTagName = getNamespacedTagFor('market-accessory');
    const MarketIconTagName = getNamespacedTagFor('market-icon');

    return this.files.map((file, index) => {
      const rowConfig = this.fileMetadata?.find((row) => row.filename === file.name);

      // set default icons
      let leadingIcon = file.type.startsWith('image/') ? 'picture' : 'file';
      let trailingIcon = 'delete';

      // set default secondary text
      let secondaryText;
      switch (this.fileSubtext) {
        case 'size':
          secondaryText = getReadableFilesize(file[this.fileSubtext]);
          break;
        case 'type':
          secondaryText = file[this.fileSubtext];
          break;
        default:
          break;
      }

      // row config status overrides default row appearance
      switch (rowConfig?.status) {
        case 'error':
          leadingIcon = 'warning';
          trailingIcon = 'clear';
          // message only displayed if status = error
          secondaryText = rowConfig?.message;
          break;
        case 'loading':
          leadingIcon = 'loading';
          trailingIcon = 'clear';
          break;
        case 'success':
          leadingIcon = 'success';
          break;
        default:
          break;
      }

      // row config custom icon overrides previously set leading icon
      leadingIcon = rowConfig?.leadingIconName || leadingIcon;

      return (
        <MarketRowTagName
          disabled={this.disabled}
          interactive
          transient
          data-index={index}
          data-status={rowConfig?.status}
        >
          <label slot="label">{file.name}</label>
          {secondaryText && <p slot="subtext">{secondaryText}</p>}
          <MarketAccessoryTagName slot="leading-accessory" size="image">
            <MarketIconTagName name={leadingIcon} fidelity={24}></MarketIconTagName>
          </MarketAccessoryTagName>
          <MarketAccessoryTagName
            slot="trailing-accessory"
            size="icon"
            tabindex={this.disabled ? -1 : 0}
            role="button"
            aria-label={this.deleteButtonAriaLabel}
            onKeyDown={(e) => this.handleDeleteKeydown(e, index)}
            onClick={() => this.removeFile(index)}
          >
            <MarketIconTagName name={trailingIcon} fidelity={24}></MarketIconTagName>
          </MarketAccessoryTagName>
        </MarketRowTagName>
      );
    });
  }

  render() {
    const MarketListTagName = getNamespacedTagFor('market-list');
    const MarketAccessoryTagName = getNamespacedTagFor('market-accessory');
    const MarketIconTagName = getNamespacedTagFor('market-icon');
    const MarketFieldTagName = getNamespacedTagFor('market-field');

    const fileMarketRows = this.renderFilesAsMarketRows();
    const showUploadLabel = this.multiple || fileMarketRows.length === 0;
    const showList = this.multiple && fileMarketRows.length > 0;

    return (
      <Host
        class="market-file-upload"
        ondragenter={(e) => this.draggingOver(e)}
        ondragover={(e) => this.draggingOver(e)}
        ondragleave={(e) => this.endDrag(e)}
        ondrop={(e) => this.endDrag(e)}
      >
        <MarketFieldTagName disabled={this.disabled} invalid={this.invalid}>
          <div class={`label-container ${this.labelContainerClassNames()}`}>
            {!showUploadLabel && fileMarketRows[0]}
            {showUploadLabel && (
              // the input is correctly wrapped inside its label, but eslint gets confused by label > span > slot > text
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label>
                <slot>
                  <MarketAccessoryTagName size="image">
                    <MarketIconTagName name="file" fidelity={24}></MarketIconTagName>
                  </MarketAccessoryTagName>
                  <span>
                    <button onClick={() => this.handleButtonClick()}>Choose a file</button> or drag and drop it here
                  </span>
                </slot>
                <input
                  ref={(el) => (this.fileInputElement = el)}
                  onChange={(e) => this.onInputChange(e)}
                  type="file"
                  name="files[]"
                  multiple={this.multiple}
                  accept={this.accept}
                  hidden
                />
              </label>
            )}
          </div>
          {/* DEPRECATED: bottom-accessory slot, to be removed in future major version */}
          <slot name="bottom-accessory">
            <small slot="bottom-accessory"></small>
          </slot>
          {/* DEPRECATED: error slot, to be removed in future major version */}
          <slot name="error">
            <small slot="error"></small>
          </slot>
        </MarketFieldTagName>
        {showList && (
          <MarketListTagName interactive transient>
            {fileMarketRows}
          </MarketListTagName>
        )}
      </Host>
    );
  }
}
