import { Component, Host, h, Prop } from '@stencil/core';
import { getNamespacedTagFor } from '../../utils/namespace';

@Component({
  tag: 'market-activity-indicator',
  styleUrl: 'market-activity-indicator.css',
  shadow: true,
})
export class MarketActivityIndicator {
  /**
   * String for setting activity indicator size
   */
  @Prop({ reflect: true }) readonly size: 'large' | 'small' = 'large';

  render() {
    const MarketIconTagName = getNamespacedTagFor('market-icon');

    return (
      <Host class="market-activity-indicator">
        <MarketIconTagName name="radial-spinner"></MarketIconTagName>
      </Host>
    );
  }
}
