import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import type { JSX } from '@market/react';
import { MarketRow } from 'src/components/Market';

export interface ControlledMarketRowWithToggleProps extends JSX.MarketRow {
  children: ReactNode;
  className?: string;
}

/**
 * Specifically for use when rendering a MarketRow component with a MarketToggle child component, and when
 * the selected state is controlled by the MarketRow component.
 * In these cases, the MarketToggle should be slotted as "control", and the MarketRow component should
 * explicitly pass the "selected" prop.
 *
 * This is a wrapper component because if the onMarketRowSelected and onMarketRowDeselected callbacks call
 * asynchronous functions to set the selected state, the MarketRow component under the hood may update the
 * selected state before the asynchronous function completes, causing the selected state to flicker.
 * This is fixed by calling `event.preventDefault()` in the callbacks.
 *
 * @example
 * <ControlledMarketRowWithToggle
 *   selected={isSelected}
 *   onMarketRowSelected={() => setIsSelected(true)}
 *   onMarketRowDeselected={() => setIsSelected(false)}
 * >
 *   <MarketToggle slot="control" />
 * </ControlledMarketRowWithToggle>
 *
 * @param {ReactNode} children
 * Children of the MarketRow component. Should contain a MarketToggle component.
 * @param {string} [className]
 * (Optional) Classname to apply to the MarketRow component.
 */
const ControlledMarketRowWithToggle = observer(
  ({
    children,
    onMarketRowSelected,
    onMarketRowDeselected,
    ...props
  }: ControlledMarketRowWithToggleProps): ReactElement => {
    return (
      <MarketRow
        {...props}
        onMarketRowSelected={(event) => {
          event.preventDefault();
          onMarketRowSelected?.(event);
        }}
        onMarketRowDeselected={(event) => {
          event.preventDefault();
          onMarketRowDeselected?.(event);
        }}
      >
        {children}
      </MarketRow>
    );
  },
);

export default ControlledMarketRowWithToggle;
