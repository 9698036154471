import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

/**
 * Returns the referral page for various CDP events.
 * The list of returned page names is non-exhaustive and should be updated as needed.
 *
 * @returns {string} - The referral page name.
 */
const useReferralPageName = (): string => {
  const { navigation, transcriptView } = useMessengerControllerContext();

  if (navigation.sheet.currentPageName === 'SETTINGS') {
    return 'messages_settings';
  }

  if (navigation.navStoreForUrl.currentPageName === 'TRANSCRIPT_VIEW') {
    if (transcriptView.transcript.viewItems.length === 0) {
      return 'conversation_compose';
    } else if (transcriptView.transcript.isActive) {
      return 'conversation_active';
    } else {
      return 'conversation_assistant';
    }
  }

  return 'new_messages_user';
};

export default useReferralPageName;
