import { computePosition, autoUpdate, offset, autoPlacement, size, shift } from '@floating-ui/dom';
import type { Strategy } from '@floating-ui/dom';
import { Component, Prop, Element, Event, EventEmitter, Host, Listen, State, Method, h, Watch } from '@stencil/core';
import { v4 as uuid } from 'uuid';

import { getTextInputAriaLabel } from '../../utils/aria';
import { debounce } from '../../utils/callbacks';
import { submitFormImplicitly } from '../../utils/forms';
import { getNamespacedTagFor } from '../../utils/namespace';
import { classNames } from '../../utils/classnames';
import { getMaxZIndex } from '../../utils/max-z-index';

/**
 * @slot - Intended for use as the input's text label.
 * @slot list - Intended for use with a slotted `<market-list>` containing `<market-row>`s.
 * @slot leading-accessory - An accessory set on the left side of the input.
 * @slot trailing-accessory - An accessory set on the right side of the input.
 * @slot displayed-selection - Used internally to display the selected `market-row` while retaining any custom styling. Not intended for use by Market consumers.
 */
@Component({
  tag: 'market-select',
  shadow: true,
  styleUrl: 'market-select.css',
})
export class MarketSelect {
  @Element() el: HTMLMarketSelectElement;

  /**
   * String for the "name" attribute. Used when slotted into `market-field`.
   */
  @Prop() readonly name: string;

  /**
   * A string specifying a value for the select
   * Must correspond to a `value` attribute on a slotted `market-row`
   * For multiselect, separate values with a comma (e.g. 'orange,pear').
   */
  @Prop({ mutable: true, reflect: true }) value: string | Array<any> = '';

  /**
   * String for setting select size.
   * Sizes `small` and `medium` visually hide the label,
   * but you should still provide one for accessibility purposes.
   */
  @Prop({ reflect: true }) readonly size: 'small' | 'medium' | 'large' = 'large';

  /**
   * A string specifying the placeholder for the select.
   * This is shown when the select is open with no selection.
   */
  @Prop() readonly placeholder: string;

  /**
   * Whether or not the select is readonly.
   */
  @Prop({ reflect: true }) readonly readonly: boolean = false;

  /**
   * Functionally and visually disables the select.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * Whether or not the select is focused.
   */
  @Prop({ mutable: true, reflect: true }) focused: boolean = false;

  /**
   * Whether or not the select is invalid (w/ corresponding visual state)
   */
  @Prop({ reflect: true }) readonly invalid: boolean = false;

  /**
   * Whether or not multiselect is enabled
   */
  @Prop({ reflect: true }) readonly multiselect: boolean = false;

  /**
   * Ancestor selector to contain the popover menu.
   * Use this if you need the popover to be appended to
   * an ancestor element other than the `body` element.
   */
  @Prop() readonly popoverContainer: string;

  /**
   * Configuration option for Floating UI (used to position `<market-popover>`).
   * Describes the positioning strategy to use. By default, it is absolute. If
   * your reference element is in a fixed container, use the fixed strategy.
   * https://floating-ui.com/docs/computePosition#strategy
   */
  @Prop() readonly popoverStrategy: Strategy = 'absolute';

  @State() listIsActive: boolean = false;

  /*
    The index of which row item has focus when navigating market-select with keys.
  */
  @State() focusedRowIndex: number = -1;

  @State() hasLeadingAccessory: boolean = false;

  @State() hasTrailingAccessory: boolean = false;

  /**
   * Fired by the `marketListSelectionsDidChange` listener.
   *
   * @property {string} value - value attribute of the selected element
   * @property {HTMLMarketRowElement} newSelectedOption - the row that has just been selected
   * @property {HTMLMarketRowElement} newDeselectedOption - the row that has just been deselected
   * @property {HTMLMarketRowElement} currentSelectedOptions - the rows that are currently selected
   */
  @Event() marketSelectValueDidChange: EventEmitter<{
    value: string | Array<any>;
    newSelectedOption: HTMLMarketRowElement;
    newDeselectedOption: HTMLMarketRowElement;
    currentSelectedOptions: Array<HTMLMarketRowElement>;
  }>;

  /**
   * Fired whenever the select is opened.
   */
  @Event({ bubbles: true, composed: true }) marketSelectOpened: EventEmitter;

  /**
   * Fired whenever the select is closed.
   */
  @Event({ bubbles: true, composed: true }) marketSelectClosed: EventEmitter;

  @Watch('value')
  valueWatcher() {
    this.propagateValue();
  }

  @Watch('multiselect')
  multiselectWatcher() {
    this.list.multiselect = this.multiselect;
  }

  // Listener for the change event emitted by `market-list`
  handleListSelection({ detail: { currentSelectionValues, currentSelections, newDeselection, newSelection } }) {
    const value = currentSelectionValues.join(',');
    this.value = value;

    if (this.multiselect) {
      this.setFocusedRow(newDeselection || newSelection);
    } else {
      this.setFocusedRow(newSelection);
    }

    this.marketSelectValueDidChange.emit({
      value,
      newSelectedOption: newSelection,
      newDeselectedOption: newDeselection,
      currentSelectedOptions: currentSelections,
    });
  }

  handleListItemsFiltered() {
    window.requestAnimationFrame(() => {
      if (this.listIsActive) {
        this.updatePopper();
      }
    });
  }

  @Listen('click', { target: 'window' })
  windowClick(e) {
    if (this.el.contains(e.target) || this.popoverElement?.contains(e.target)) {
      return;
    }

    this.focused = false;
    if (this.listIsActive) {
      this.closeList();
    }
  }

  getValues(): Set<string> {
    if (Array.isArray(this.value)) {
      return new Set(this.value);
    }
    return new Set(this.multiselect ? this.value.split(',') : [this.value]);
  }

  getValuesCount(): number {
    return this.getValues().size;
  }

  /**
   * We search based on the `value` property rather than using querySelector and the `value`
   * attribute (i.e. `market-row[value=${this.value}]`) due to rendering issues we've seen
   * with Ember/Handlebars, where the property may be set before attribute is present.
   * See here for details: https://github.com/squareup/market/issues/2635
   */
  getMatchingRows(): Array<HTMLMarketRowElement> {
    if (this.rows === null) {
      return [];
    } else {
      const values = this.getValues();
      return [...this.rows].filter((row) => values.has(row.value));
    }
  }

  get hasMultipleSelections(): boolean {
    return this.multiselect && this.getValues().size > 1;
  }

  getMultiselectDisplayValue(): string {
    const values = this.getValues();
    return values.size > 1 ? `${values.size} selected` : '';
  }

  getDisplayedSelectionRow() {
    return this.el.querySelector(
      `${getNamespacedTagFor('market-row')}[slot="displayed-selection"]`,
    ) as HTMLMarketRowElement;
  }

  /**
   * Ensure the passed `value` is reflected in the selected list item and the displayed selection row.
   */
  propagateValue() {
    if (!this.list || !this.rows) return;

    const matchingRows = this.getMatchingRows();

    if (matchingRows.length === 0) {
      // there were no matching rows, so sanitize & reset the value
      this.list.value = this.value = '';
      this.focusedRowIndex = -1;
    } else {
      // set the list value so the correct list row will be selected
      this.list.value = this.value;
      this.setFocusedRow(matchingRows[0]);
    }

    this.displaySelection(matchingRows);
  }

  /**
   * Renders the passed row into the displayed selection slot, so it's visible
   * as the current selection in the main "input" area of the select.
   */
  displaySelection(selectedRows: Array<HTMLMarketRowElement> = []) {
    // Remove existing selected row from the DOM
    this.getDisplayedSelectionRow()?.remove?.();

    if (selectedRows.length === 1) {
      /**
       * For single select, we need to clone the row from the list so we can place a
       * duplicate into the field area. If we don't clone it, the row
       * will be removed from the list when we append it to the selection area
       */
      const clonedRow: HTMLMarketRowElement = selectedRows[0].cloneNode(true) as HTMLMarketRowElement;

      // Row was cloned from the list, so we need to remove its interactive attribute + reset its role
      clonedRow.interactive = false;
      clonedRow.setAttribute('role', 'listitem'); // needed after Stencil 4 upgrade

      // don't duplicate cloned element IDs!
      clonedRow.removeAttribute('id');
      clonedRow.querySelectorAll('[id]').forEach((el) => el.removeAttribute('id'));

      /**
       * Set slot='displayed-selection' so this shows up within the slotted area.
       * We use a slot instead of appending this directly into the shadowDOM
       * because we want to keep whatever styling cascaded from the Light DOM
       * on the row because market - row can accept any markup.
       */
      clonedRow.setAttribute('slot', 'displayed-selection');

      // if the select size is small, the selected row needs to be small too
      clonedRow.size = this.size === 'small' ? 'small' : 'medium';

      // Append the cloned node after the first slot element (label).
      this.el.append(clonedRow);
    } else if (selectedRows.length > 1) {
      /**
       * For multiselect, we need to create a `market-row` element to display "n selected"
       * and possibly clone the "selected-translation" slot, if provided
       */
      const multiselectDisplayRow = document.createElement(
        `${getNamespacedTagFor('market-row')}`,
      ) as HTMLMarketRowElement;
      multiselectDisplayRow.setAttribute('slot', 'displayed-selection');
      multiselectDisplayRow.innerText = `${this.getValuesCount().toLocaleString()} `;

      // if the select size is small, the selected row needs to be small too
      multiselectDisplayRow.size = this.size === 'small' ? 'small' : 'medium';

      // Add slot for "selected"
      const selectedTranslationSlot = document.createElement('slot');
      selectedTranslationSlot.setAttribute('name', 'selected-translation');

      // Clone the contents of the "selected-translation"
      const selectedTranslation = this.el.querySelector('[slot="selected-translation"]');
      selectedTranslationSlot.innerHTML = selectedTranslation?.innerHTML ?? 'selected';
      multiselectDisplayRow.appendChild(selectedTranslationSlot);

      // Observe for changes on the "selected-translation" slot
      if (selectedTranslation) {
        const observer = new MutationObserver(() => {
          selectedTranslationSlot.innerHTML = selectedTranslation?.innerHTML;
        });
        observer.observe(selectedTranslation, { characterData: true, subtree: true });
      }

      this.el.append(multiselectDisplayRow);
    }
  }

  /**
   * Listens to changes in list content to ensure that if the content is dynamically updated,
   * those changes will be copied to the popover and the displayed-selection row.
   */
  initListObserver() {
    const observer = new MutationObserver(this.onListChange.bind(this));
    observer.observe(this.list, { childList: true, characterData: true, subtree: true });
  }

  onListChange() {
    this.initRows();
    this.propagateValue();
    this.updatePopper();
  }

  /**
   * Record the index of the new selected row for keyboard navigation
   */
  setFocusedRow(row: HTMLMarketRowElement) {
    this.focusedRowIndex = Array.prototype.indexOf.call(this.rows, row);
  }

  /**
   * Opens the select.
   */
  @Method()
  async openList() {
    if (this.listIsActive || !this.popoverElement) {
      return Promise.resolve();
    }

    const container: HTMLElement = this.el.closest(this.popoverContainer) || document.body;

    Object.assign(this.popoverElement.style, {
      zIndex: `${getMaxZIndex(container) + 1}`,
      visibility: 'hidden',
    });

    this.moveListToPopover();
    container.append(this.popoverElement);
    this.initPopperListeners();

    return new Promise<void>((resolve) => {
      // hack: ensure initial position is correctly set before visible
      setTimeout(() => {
        this.updatePopper();
        this.popoverElement.style.visibility = 'visible';
        this.listIsActive = true;
        this.marketSelectOpened.emit();
        resolve();
      }, 50);
    });
  }

  /**
   * Closes the select.
   */
  @Method()
  closeList() {
    if (!this.listIsActive) {
      return Promise.resolve();
    }

    this.popoverElement.remove();
    this.cleanupPopperListeners();
    this.moveListToSelect();
    this.listIsActive = false;
    this.marketSelectClosed.emit();
    return Promise.resolve();
  }

  /**
   * Toggles the select open and closed.
   */
  @Method()
  async toggleList() {
    this.listIsActive ? await this.closeList() : await this.openList();
  }

  /* KEYBOARD ACCESSIBILITY */

  // keyboard handler on the trigger (separate from the list)
  handleTriggerKeyDown(ev: KeyboardEvent) {
    switch (ev.key) {
      case 'ArrowDown':
        this.handleTriggerArrowDown(ev);
        break;
      case 'ArrowUp':
        this.handleTriggerArrowUp(ev);
        break;
      case ' ':
        this.handleTriggerSpacebar(ev);
        break;
      case 'Enter':
        this.handleTriggerEnter();
        break;
      case 'Escape':
        this.handleEscape();
        break;
      case 'Tab':
        this.handleTab(ev);
        break;
      default:
        this.typeaheadHandler(ev);
        break;
    }
    ev.stopPropagation();
  }

  /**
   * NOTE: market-list has it's own internal keyboard functionality,
   * so we only need to handle the trigger keypress here
   *
   * - if the list is open:
   *   - focus the next enabled row
   * - if the list is closed:
   *   - open the list
   *   - if there is a current selection, focus it
   */
  async handleTriggerArrowDown(ev) {
    ev.preventDefault(); // do not scroll page while select has focus

    if (this.listIsActive) {
      for (let i = this.focusedRowIndex + 1; i < this.rows.length; i++) {
        if (!this.rows[i].disabled) {
          this.focusedRowIndex = i;
          this.rows[i].focus();
          break;
        }
      }
    } else {
      await this.openList();
      if (this.focusedRowIndex > -1) {
        this.rows[this.focusedRowIndex].focus();
      }
    }
  }

  /**
   * NOTE: market-list has it's own internal keyboard functionality,
   * so we only need to handle the trigger keypress here
   *
   * - if the list is open:
   *   - focus the last enabled row
   * - if the list is closed:
   *   - open the list
   *   - if there is a current selection, focus it
   */
  async handleTriggerArrowUp(ev) {
    ev.preventDefault(); // do not scroll page while select has focus

    if (this.listIsActive) {
      for (let i = this.focusedRowIndex - 1; i > -1; i--) {
        if (!this.rows[i].disabled) {
          this.focusedRowIndex = i;
          this.rows[i].focus();
          break;
        }
      }
    } else {
      await this.openList();
      if (this.focusedRowIndex > -1) {
        this.rows[this.focusedRowIndex].focus();
      }
    }
  }

  /**
   * - if the list is open:
   *   - close it
   * - if the list is closed:
   *   - open the list
   *   - if there is a current selection, focus it
   */
  async handleTriggerSpacebar(ev) {
    ev.preventDefault(); // do not scroll page while select has focus

    if (this.listIsActive) {
      this.closeList();
    } else {
      await this.openList();
      if (this.focusedRowIndex > -1) {
        this.rows[this.focusedRowIndex].focus();
      }
    }
  }

  /**
   * when the list is closed, invoke implicit submission
   */
  handleTriggerEnter() {
    if (this.listIsActive) {
      this.closeList();
      this.el.focus();
    } else {
      submitFormImplicitly(this.el);
    }
  }

  /*
  - when the list is open, close it and focus the trigger
  */
  handleEscape() {
    if (this.listIsActive) {
      this.closeList();
      this.el.focus();
    }
  }

  /*
  - when the list is open, suppress tab navigation
  - when the list is closed, allow tab navigation and remove focus
  */
  handleTab(ev) {
    if (this.listIsActive) {
      ev.preventDefault();
    } else {
      this.focused = false;
    }
  }

  // keyboard handler on the list (separate from the trigger)
  handleListKeyDown(ev: KeyboardEvent) {
    switch (ev.key) {
      case 'Enter':
        this.handleListEnter();
        break;
      case 'Escape':
        this.handleEscape();
        break;
      case 'Tab':
        this.handleTab(ev);
        break;
      case ' ':
        this.handleListSpacebar(ev);
        break;
      default:
        this.typeaheadHandler(ev);
        break;
    }
    ev.stopPropagation();
  }

  /**
   * market-list handles selection functionality internally
   * just need to close the list if single select
   */
  handleListSpacebar(ev) {
    // do nothing if list is multiselect or keydown event is emitted by typing into a slotted search input
    if (this.multiselect || ev.target.hasAttribute('slot', 'search')) {
      return;
    }

    ev.preventDefault(); // do not scroll page while select has focus

    this.closeList();
    this.el.focus();
  }

  /**
   * market-list handles selection functionality internally
   * just need to close the list if single select
   */
  handleListEnter() {
    if (!this.multiselect) {
      this.closeList();
      this.el.focus();
    }
  }

  /* TYPEAHEAD FUNCTIONALITY
  - https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
  - type a character: focus moves to the next item with a name that starts with
    the typed character
  - type multiple characters in rapid succession: focus moves to the next item
    with a name that starts with the string of characters typed
  - if no match is found, focus remains where it was
  - search wraps around end of list
  */

  rowsInnerText: Array<string> = [];
  keypresses: Array<string> = [];
  debounceDelay: number = 250;

  typeaheadHandler(ev: KeyboardEvent) {
    // if list has a search, that will be used instead of a typeahead
    if (this.list?.hasAttribute('has-search')) {
      return;
    }

    // because this is the default keydown event handler, we're ignoring
    // "special" keys, numbers, and punctuation for typeahead functionality
    // note that this check will also ignore characters from languages that have
    // no concept of upper/lowercase (ex. japanese)
    // TODO: revisit if localization requires it
    if (ev.key.length > 1 || ev.key.toUpperCase() === ev.key.toLowerCase()) {
      return;
    }

    this.storeKeypresses(ev);
    this.setFocusOnMatch();
  }

  storeKeypresses(ev) {
    this.keypresses = [...this.keypresses, ev.key];
  }

  setFocusOnMatch = debounce(() => {
    const searchString = this.keypresses.join('');

    // array slicing is so we start the search from the currently focused row
    const nextMatch = [
      ...this.rowsInnerText.slice(this.focusedRowIndex + 1),
      ...this.rowsInnerText.slice(0, this.focusedRowIndex + 1),
    ].find((innerText: string) => innerText.startsWith(searchString));

    const matchIndex = this.rowsInnerText.findIndex((innerText) => {
      return innerText.startsWith(nextMatch);
    });

    const matchingRow: HTMLMarketRowElement = this.rows[matchIndex];

    // if matching row exists and is not disabled, focus it
    if (matchingRow && !matchingRow.disabled) {
      this.focusedRowIndex = matchIndex;
      matchingRow.focus();

      // if list is closed and single select, set the value
      if (!this.listIsActive && !this.multiselect) {
        // row is not in the DOM, so matchingRow.click() doesn't bubble
        // instead we manually set the value to the row's value
        this.value = matchingRow.value;

        // since we're bypassing marketListSelectionsDidChange event here,
        // we also need to manually emit marketSelectValueDidChange event
        this.marketSelectValueDidChange.emit({
          value: this.value,
          newSelectedOption: matchingRow,
          newDeselectedOption: null,
          currentSelectedOptions: [matchingRow],
        });
      }
    }

    this.keypresses = []; // reset keypress array
  }, this.debounceDelay);

  /* EVENT HANDLING */

  handleFocus() {
    if (this.readonly || this.disabled) {
      return;
    }

    this.focused = true;
  }

  handleTriggerClick(ev) {
    if (ev.target.tagName.toLowerCase() === getNamespacedTagFor('market-tooltip')) {
      return;
    }

    if (this.readonly || this.disabled) {
      return;
    }

    this.toggleList();
  }

  handleListClick() {
    if (!this.multiselect) {
      this.closeList();
      this.el.focus();
    }
  }

  /* POPPER FUNCTIONALITY */

  cleanupPopperListeners: Function;
  initPopperListeners() {
    const { el, popoverElement, updatePopper } = this;
    this.cleanupPopperListeners = autoUpdate(el, popoverElement, updatePopper.bind(this), {
      elementResize: false,
    });
  }

  updatePopper() {
    const { el, popoverElement, popoverStrategy } = this;

    // TODO: use design tokens
    const MARGIN_OFFSET = 8;
    const MAX_HEIGHT = 464;

    computePosition(el, popoverElement, {
      strategy: popoverStrategy,
      middleware: [
        offset(MARGIN_OFFSET),
        autoPlacement({
          allowedPlacements: ['top', 'bottom'],
        }),
        size({
          apply({ rects, availableHeight }) {
            Object.assign(popoverElement.style, {
              width: `${rects.reference.width}px`,
              maxHeight: `${Math.min(availableHeight - MARGIN_OFFSET, MAX_HEIGHT)}px`,
            });
          },
        }),
        shift({ padding: MARGIN_OFFSET }),
      ],
    }).then(({ x, y }) => {
      Object.assign(popoverElement.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  list: HTMLMarketListElement = null;
  rows: NodeListOf<HTMLMarketRowElement> = null;
  popoverElement: HTMLMarketPopoverElement = null;
  popoverId: string;

  initPopover() {
    this.popoverElement =
      this.popoverElement ||
      (document.createElement(getNamespacedTagFor('market-popover')) as HTMLMarketPopoverElement);
    this.popoverElement.id = this.popoverId;
    this.popoverElement.style.position = this.popoverStrategy;
  }

  initList() {
    this.list = this.el.querySelector('[slot="list"]');
    this.list.interactive = true;
    this.list.multiselect = this.multiselect;
    this.list.addEventListener('marketListSelectionsDidChange', this.handleListSelection.bind(this));
    this.list.addEventListener('marketListItemsFiltered', this.handleListItemsFiltered.bind(this));
    this.list.addEventListener('keydown', this.handleListKeyDown.bind(this));
    this.list.addEventListener('click', this.handleListClick.bind(this));
  }

  initRows() {
    this.rows = this.list.querySelectorAll(`${getNamespacedTagFor('market-row')}`) as NodeListOf<HTMLMarketRowElement>;
    // used for typeahead functionality
    this.rowsInnerText = [...this.rows]
      .filter((row) => {
        /**
         * Addresses an issue in test environments where the innerText of
         * market-rows isn't set before componentWillLoad of the parent market-select (this file)
         * is executed.
         */
        return row && row.innerText;
      })
      .map((row) => row.innerText.trim().toLowerCase());
  }

  moveListToPopover() {
    this.list.removeAttribute('slot');
    this.popoverElement.append(this.list);
  }

  moveListToSelect() {
    this.list.setAttribute('slot', 'list');
    this.el.append(this.list);
  }

  /* COMPONENT LIFECYCLE EVENTS AND HELPERS */

  registerSlottedAccessories() {
    const leadingAccessory = [...this.el.children].filter(function (child) {
      return child.matches('[slot="leading-accessory"]');
    });
    const trailingAccessory = [...this.el.children].filter(function (child) {
      return child.matches('[slot="trailing-accessory"]');
    });

    this.hasLeadingAccessory = leadingAccessory.length > 0;
    this.hasTrailingAccessory = trailingAccessory.length > 0;
  }

  componentWillLoad() {
    this.registerSlottedAccessories();
    this.popoverId = this.popoverId || `popover-${uuid()}`;
  }

  componentDidLoad() {
    this.initList();
    this.initRows();
    this.initPopover();
    this.initListObserver();
    this.propagateValue();
  }

  componentWillRender() {
    const { listIsActive, list } = this;
    if (list && listIsActive) {
      list.removeAttribute('slot');
    }
  }

  disconnectedCallback() {
    this.closeList();
  }

  render() {
    const tabindex = this.disabled ? null : '0';

    return (
      <Host
        class="market-select"
        role="combobox"
        aria-label={getTextInputAriaLabel(this.el)}
        aria-expanded={this.listIsActive.toString()}
        aria-controls={this.popoverId}
        aria-haspopup="listbox"
        tabindex={tabindex}
        onClick={(e) => {
          this.handleTriggerClick(e);
        }}
        onFocus={() => {
          this.handleFocus();
        }}
        onKeyDown={(e) => {
          this.handleTriggerKeyDown(e);
        }}
      >
        <slot name="leading-accessory" onSlotchange={() => this.registerSlottedAccessories()}></slot>

        <div
          class={classNames('label-input-container', {
            'has-leading-accessory': this.hasLeadingAccessory,
            'has-trailing-accessory': this.hasTrailingAccessory,
          })}
        >
          {/* Default slot for field label */}
          <slot></slot>
          <slot name="displayed-selection">
            <div class="placeholder">{this.placeholder}</div>
          </slot>
        </div>

        <slot name="list"></slot>

        <slot name="trailing-accessory" onSlotchange={() => this.registerSlottedAccessories()}></slot>

        <svg class="caret" width="14" height="8" viewBox="0 0 14 8" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.70715 7.70711C7.31663 8.09763 6.68346 8.09763 6.29294 7.70711L0.29294 1.70711L1.70715 0.292892L7.00005 5.58579L12.2929 0.292893L13.7072 1.70711L7.70715 7.70711Z"
            fill="black"
          />
        </svg>
      </Host>
    );
  }
}
