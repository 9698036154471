import { Component, Prop, Element, Host, h, Listen, State, Method, Watch } from '@stencil/core';

import { observeAriaAttributes, AriaAttributes } from '../../utils/aria';
import { getNamespacedTagFor } from '../../utils/namespace';

/**
 * @slot - The text used for the button label
 * @slot icon - an icon that is to the left of button text, or centered if there is no text
 */
@Component({
  tag: 'market-button',
  shadow: true,
  styleUrl: 'styles/market-button.css',
})
export class MarketButton {
  @Element() el: HTMLMarketButtonElement;

  /**
   * String for setting (optional) button caret direction
   */
  @Prop() readonly caret: 'up' | 'down' | 'none' = 'none';

  /**
   * Functionally and visually disables the button
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * Causes the browser to treat the linked URL as a download. Only works for same-origin URLs.
   * Only applies when an `href` is provided.
   * See [here](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-download) for details on accepted values.
   */
  @Prop() readonly download: string | undefined;

  /**
   * Whether or not the button is in a focused state
   */
  @Prop({ mutable: true, reflect: true }) focused: boolean = false;

  /**
   * Contains a URL or a URL fragment that the hyperlink points to.
   * If this property is set, an anchor tag will be rendered.
   */
  @Prop() readonly href: string | undefined;

  /**
   * Whether the button only contains an icon.
   */
  @Prop({ mutable: true, reflect: true }) iconOnly: boolean = false;

  /**
   * Optionally set a custom tabindex on the inner HTML `<button>`.
   */
  @Prop() readonly innerTabindex: number;

  /**
   * Whether or not the button is in a loading state
   */
  @Prop({ reflect: true }) readonly isLoading: boolean = false;

  /**
   * String for setting button rank
   */
  @Prop({ reflect: true }) readonly rank: 'primary' | 'secondary' | 'tertiary' = 'secondary';

  /**
   * Defines the relationship between a linked resource and the current document.
   * Only applies when an `href` is provided.
   * See [here](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/rel) for details on accepted values.
   */
  @Prop() readonly rel: string | undefined;

  /**
   * String for setting button size
   */
  @Prop({ reflect: true }) readonly size: 'small' | 'medium' | 'large' = 'medium';

  /**
   * Specifies where to display the linked URL.
   * Only applies when an `href` is provided.
   * See [here](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target) for details on accepted values.
   */
  @Prop() readonly target: '_blank' | '_self' | '_parent' | '_top' | undefined;

  /**
   * String for setting button type
   */
  @Prop({ reflect: true }) readonly type: 'button' | 'reset' | 'submit' = 'button';

  /**
   * String for setting button variant
   */
  @Prop({ reflect: true }) readonly variant: 'regular' | 'destructive' = 'regular';

  @State() ariaAttributes: AriaAttributes;

  innerTag: HTMLElement;

  mutationObserver: MutationObserver;

  @Watch('focused')
  focusedChangeHandler(newValue: boolean) {
    if (!this.innerTag) {
      return;
    }
    if (newValue) {
      this.innerTag.focus();
    } else {
      this.innerTag.blur();
    }
  }

  /* Listening for click events here allows us to stop bubbling when
  disabled. Attaching listeners to Host (as the Stencil linter prefers),
  seems to also work in Stencil tests, but fails in Ember for some reason.
  We listen in the "capture" phase to ensure we're hit before any external
  click handlers. See here for details:
  https://www.sitepoint.com/event-bubbling-javascript/ */
  @Listen('click', { capture: true })
  handleClick(event: UIEvent) {
    if (this.disabled || this.isLoading) {
      // Calling `stopImmediatePropagation` instead of `stopPropagation`
      // allows us to block current and future "sibling" event listeners
      // that have also been attached to this element, as opposed to just
      // those higher in the DOM tree.
      event.stopImmediatePropagation();
    }
  }

  /**
   * Sets `focused` state, except when disabled. Allows external consumers to programmatically
   * trigger focused styling.
   */
  @Method()
  setFocus(value: boolean = true) {
    if (this.disabled) {
      return Promise.resolve();
    }
    this.focused = value;
    return Promise.resolve();
  }

  // Implicit submission is when an interaction within a form fires a click event on the form's first submit button in tree order.
  // Because the inner <button> of <market-button> is in the shadow dom and not in tree order, we lose this functionality.
  // To ensure we have the exact behavior that a plain button would have had in this situation,
  // we add a hidden button to the form and click it for the implicit submission.
  // https://www.hjorthhansen.dev/shadow-dom-and-forms/

  // note: this is attached in the host element's onclick instead of with the @Listen decorator
  // to ensure the following fire in the correct order:
  // 1) handleClick
  // 2) the event passed to market-button
  // 3) implicit submission
  // In Ember, the @Listen would fire before the event passed to the button, which could result in the event not happening.
  handleImplicitSubmission = () => {
    if (this.type === 'submit') {
      const form = this.el.closest('form');
      if (form) {
        const fakeButton = document.createElement('button');
        fakeButton.type = this.type;
        fakeButton.style.display = 'none';
        form.appendChild(fakeButton);
        fakeButton.click();
        fakeButton.remove();
      }
    }
  };

  onMutationObserved = (ariaAttributes: AriaAttributes) => {
    this.ariaAttributes = ariaAttributes;
  };

  handleSlotChange() {
    const iconSlot = this.el.querySelector('[slot="icon"]');
    const hasIcon = Boolean(iconSlot);
    const buttonText = this.el.textContent.trim();

    let hasLabelText: boolean;
    if (hasIcon) {
      // Check for text in the icon itself, such as icon badge text. This text is considered part of the icon and not a label.
      // Since buttonText contains all text in the element including the svg, only set hasLabelText to true if text exists outside of the icon.
      const iconText = iconSlot.textContent.trim();
      hasLabelText = buttonText.length > iconText.length;
    } else {
      hasLabelText = buttonText.length > 0;
    }

    this.iconOnly = hasIcon && !hasLabelText;
  }

  componentWillLoad() {
    this.mutationObserver = observeAriaAttributes(this.el, this.onMutationObserved);
    this.handleSlotChange();
  }

  render() {
    const {
      // props
      caret,
      disabled,
      href,
      innerTabindex,
      isLoading,
      target,
      type,
      rel,
      download,

      // state
      ariaAttributes,

      // methods
      handleImplicitSubmission,
    } = this;
    const TagType: string = href === undefined ? 'button' : 'a';
    const ariaRole = TagType === 'button' ? 'button' : 'link';
    const TagTypeAttrs = TagType === 'button' ? { type, disabled } : { href, target, rel, download };
    const MarketActivityIndicatorTagName = getNamespacedTagFor('market-activity-indicator');
    const MarketIconTagName = getNamespacedTagFor('market-icon');

    return (
      <Host class="market-button" role={ariaRole} aria-disabled={disabled} onClick={handleImplicitSubmission}>
        <TagType
          class="inner-tag"
          {...TagTypeAttrs}
          {...ariaAttributes}
          tabindex={disabled ? -1 : innerTabindex}
          onFocus={() => {
            this.focused = true;
          }}
          onBlur={() => {
            this.focused = false;
          }}
          ref={(el) => (this.innerTag = el)}
        >
          <slot name="icon" onSlotchange={() => this.handleSlotChange()}></slot>
          <slot onSlotchange={() => this.handleSlotChange()}></slot>
          {caret === 'down' && <MarketIconTagName name="expand"></MarketIconTagName>}
          {caret === 'up' && <MarketIconTagName name="collapse"></MarketIconTagName>}
        </TagType>
        {isLoading && <MarketActivityIndicatorTagName size="small"></MarketActivityIndicatorTagName>}
      </Host>
    );
  }

  disconnectedCallback() {
    this.mutationObserver?.disconnect();
  }
}
