import React, { ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { KEY_MESSAGES_PLUS } from 'src/stores/FeatureFlagStore';

export type SquareOneEligibleProps = {
  eligibleUI: ReactElement;
  children: ReactNode;
};

/**
 * Wrapper component to reduce branching logic in SquareOne and Legacy components.
 * If the user is eligible for SquareOne and Messages Plus features are enabled,
 * the `eligible` prop will be rendered. Otherwise, the children will be rendered
 * as the fallback to the legacy (ineligible) state.
 *
 * @example
 * Usage:
 * <SquareOneEligible eligibleUI={<SquareOneEmptyState />}>
 *  <LegacyEmptyState />
 * </SquareOneEligible>
 * @param {ReactElement} eligibleUI
 * The React element to render if the user is eligible for SquareOne.
 * @param {React.ReactNode} children
 * The React children to render if the user is not eligible for SquareOne.
 */
const SquareOneEligible = observer(
  ({
    eligibleUI,
    children,
  }: React.PropsWithChildren<SquareOneEligibleProps>): ReactElement => {
    const { subscription, featureFlag } = useMessengerControllerContext();
    return subscription.isEligibleForSquareOne &&
      featureFlag.get(KEY_MESSAGES_PLUS) ? (
      eligibleUI
    ) : (
      <>{children}</>
    );
  },
);

export default SquareOneEligible;
