import { Component, Element, Event, EventEmitter, h, Host, Listen, Prop } from '@stencil/core';

import { getNamespacedTagFor } from '../../../../utils/namespace';
import { TMarketInternalPaginationNavigationEventDetail } from '../../events';

@Component({
  tag: 'market-pagination-nav',
})
export class MarketPaginationNav {
  @Element() el: HTMLMarketPaginationNavElement;

  /**
   * The current page being viewed.
   */
  @Prop() readonly currentPage!: number;

  /**
   * Functionally and visually disables the navigation buttons
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * The total number of pages.
   */
  @Prop() readonly totalPages!: number;

  /**
   * Allow the user to navigate to the next page
   */
  @Prop() readonly hasNextPage!: boolean;

  /**
   * Allow the user to navigate to the previous page
   */
  @Prop() readonly hasPreviousPage!: boolean;

  /**
   * The number of results displayed per page.
   * Required to emit this value as part of the navigation event.
   */
  @Prop() readonly pageSize: string;

  /**
   * Fired when a new page is selected
   *
   * @property {string} page - page number to navigate to
   * @property {string} prevPage - the current page _before_ navigation
   * @property {string} pageSize - the number of items per page
   */
  @Event() marketInternalPaginationNavigation: EventEmitter<TMarketInternalPaginationNavigationEventDetail>;

  @Listen('marketListSelectionsDidChange')
  listSelectionEventHandler(e: CustomEvent) {
    const { newSelectionValue } = e.detail;
    this.marketInternalPaginationNavigation.emit({
      page: newSelectionValue,
      prevPage: `${this.currentPage}`,
      pageSize: this.pageSize,
    });
  }

  handlePrev() {
    if (this.hasPreviousPage === false || this.currentPage === 1) {
      return;
    }

    const prevPage = this.currentPage - 1;
    this.marketInternalPaginationNavigation.emit({
      page: `${prevPage}`,
      prevPage: `${this.currentPage}`,
      pageSize: this.pageSize,
    });
  }

  handleNext() {
    if (this.hasNextPage === false || (Boolean(this.totalPages) && this.currentPage === this.totalPages)) {
      return;
    }

    const nextPage = this.currentPage + 1;
    this.marketInternalPaginationNavigation.emit({
      page: `${nextPage}`,
      prevPage: `${this.currentPage}`,
      pageSize: this.pageSize,
    });
  }

  render() {
    // Don't render page info when totalPages is missing or set to 0
    const renderPageInfo = Boolean(this.totalPages);

    const hasPreviousPage = this.currentPage > 1 || this.hasPreviousPage;
    const hasNextPage = this.currentPage < this.totalPages || this.hasNextPage;

    // Dropdown navigates by every 10 pages if there are more than 20 pages
    const isLargeDataset = this.totalPages > 20;
    const largeDatasetSteps = 10;

    // pageList for a large dataset = [10, 20, 30, 40, 50...]
    // pageList for a small dataset = [1, 2, 3, 5, 6, 7...]
    const pageList = isLargeDataset
      ? Array.from({ length: Math.floor(this.totalPages / largeDatasetSteps) }).map(
          (_, i) => (i + 1) * largeDatasetSteps,
        )
      : Array.from({ length: this.totalPages }).map((_, i) => i + 1);

    const MarketButtonTagName = getNamespacedTagFor('market-button');
    const MarketButtonDropdownTagName = getNamespacedTagFor('market-button-dropdown');
    const MarketFilterButtonTagName = getNamespacedTagFor('market-filter-button');
    const MarketListTagName = getNamespacedTagFor('market-list');
    const MarketRowTagName = getNamespacedTagFor('market-row');
    const MarketAccessoryTagName = getNamespacedTagFor('market-accessory');

    return (
      <Host class="market-pagination-nav">
        <nav>
          <MarketButtonTagName
            size="small"
            disabled={this.disabled || !hasPreviousPage}
            onClick={() => this.handlePrev()}
          >
            <MarketAccessoryTagName slot="icon">
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.29289 11.293C3.90237 11.6835 3.90237 12.3167 4.29289 12.7072L11.2929 19.7072L12.7071 18.293L7.41421 13.0001L19 13.0001V11.0001L7.41421 11.0001L12.7071 5.70718L11.2929 4.29297L4.29289 11.293Z"
                />
              </svg>
            </MarketAccessoryTagName>
          </MarketButtonTagName>

          {renderPageInfo && (
            <MarketButtonDropdownTagName disabled={this.disabled} no-caret popover-placement="bottom-end" persist-list>
              <MarketFilterButtonTagName size="small" slot="trigger">
                <span>
                  <slot name="nav-label">Page</slot>
                </span>
                <span slot="feedback">
                  <slot name="nav-feedback">
                    {this.currentPage} of {this.totalPages}
                  </slot>
                </span>
              </MarketFilterButtonTagName>
              <MarketListTagName value={`${this.currentPage}`} slot="content">
                {pageList.map((pageNum) => (
                  <MarketRowTagName key={`pageNav_${pageNum}`} value={`${pageNum}`}>
                    {pageNum}
                  </MarketRowTagName>
                ))}
              </MarketListTagName>
            </MarketButtonDropdownTagName>
          )}

          <MarketButtonTagName size="small" disabled={this.disabled || !hasNextPage} onClick={() => this.handleNext()}>
            <MarketAccessoryTagName slot="icon">
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.7071 11.293C20.0976 11.6835 20.0976 12.3167 19.7071 12.7072L12.7071 19.7072L11.2929 18.293L16.5858 13.0001L5 13.0001L5 11.0001L16.5858 11.0001L11.2929 5.70718L12.7071 4.29297L19.7071 11.293Z"
                />
              </svg>
            </MarketAccessoryTagName>
          </MarketButtonTagName>
        </nav>
      </Host>
    );
  }
}
