@import "../../styles/mixins/component-base.css";
@import "../../styles/mixins/field.css";
@import "../../styles/mixins/field-states.css";
@import "../../styles/mixins/floating-input.css";

:host {
  --textarea-height: 120px;
  --textarea-minimum-height: 120px;
  --textarea-label-bottom-padding: 16px;

  @extend %field;
  @extend %field-states;
  @extend %floating-input;

  flex-direction: column;
  overflow: hidden;
  min-height: var(--textarea-minimum-height);
  max-height: var(--textarea-maximum-height);
  resize: vertical;

  .label-input-container,
  &([value='']:not([focused]):not([autofilled])) .label-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: var(--textarea-label-bottom-padding);
    font-weight: var(--textarea-font-weight);
    font-size: var(--textarea-font-size);
    line-height: var(--textarea-font-leading);
    letter-spacing: var(--textarea-font-tracking);
  }

  /* Override `:host([disabled]) *` style in component-base mixin */
  &([disabled]) textarea,
  &([disabled]) ::slotted(textarea) {
    pointer-events: auto !important; /* stylelint-disable-line declaration-no-important */
  }
}

textarea,
::slotted(textarea) {
  flex-grow: 1;
  resize: none;
}
