import { Component, Host, h } from '@stencil/core';

/**
 * @slot - Heading tag with text, ex. h2, h3, h4, h5, h6.
 * @slot trailing-accessory - Accessory to display at the end of the header line.
 */
@Component({
  tag: 'market-inline-section-header',
  styleUrl: 'market-inline-section-header.css',
  shadow: true,
})
export class MarketInlineSectionHeader {
  render() {
    return (
      <Host>
        <slot></slot>
        <slot name="trailing-accessory"></slot>
      </Host>
    );
  }
}
