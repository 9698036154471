import { Component, Element, Host, h, Prop, Listen, Watch } from '@stencil/core';

import { TMarketProgressTrackerStepClickEventDetail } from './subcomponents/market-progress-tracker-step/events';
import { isElementWithTagName } from '../../utils/namespace';
import { pick } from 'lodash-es';

/**
 * @slot - Default slot, intended to be slotted with `<market-progress-tracker-step>`s
 */
@Component({
  tag: 'market-progress-tracker',
  shadow: true,
  styleUrl: 'market-progress-tracker.css',
})
export class MarketProgressTracker {
  @Element() el: HTMLMarketProgressTrackerElement;

  /**
   * Whether the progress tracker is in compact mode
   *
   * Only functional when `orientation` is set to `"horizontal"`
   *
   * @default false
   */
  @Prop() readonly compact: boolean = false;

  /**
   * Whether there are connectors displayed between steps
   *
   * @default false
   */
  @Prop() readonly connectorless: boolean = false;

  /**
   * Index of the current step of the tracker
   *
   * When defined, the child `market-progress-tracker-step` components’
   * `active` and `completed` attributes will be automatically set.
   *
   * Otherwise, it is expected that the steps’
   * `active` and `completed` properties are manually set.
   *
   * @default undefined
   */
  @Prop({ mutable: true, reflect: true }) currentStepIndex: number;

  /**
   * Type of icon used to indicate the step’s progress
   *
   * @default undefined
   */
  @Prop() readonly indicator: 'circle' | 'check';

  /**
   * Whether this step tracker is interactive
   *
   * Only functional when `orientation` is set to `"horizontal"`
   *
   * @default false
   */
  @Prop() readonly interactive: boolean = false;

  /**
   * Progress tracker orientation
   *
   * @default 'vertical'
   */
  @Prop() readonly orientation: 'horizontal' | 'vertical' = 'vertical';

  /**
   * Whether the direction of the progress indicators is reversed
   *
   * @default false
   */
  @Prop() readonly reversed: boolean = false;

  /**
   * Steps’ size
   *
   * @default 'medium'
   */
  @Prop({ reflect: true }) readonly size: 'large' | 'medium' | 'small' = 'medium';

  @Listen('marketProgressTrackerStepClick')
  marketProgressTrackerStepClickEventHandler(event: CustomEvent<TMarketProgressTrackerStepClickEventDetail>) {
    const {
      defaultPrevented,
      detail: { index },
    } = event;

    // state will be updated only if `currentStepIndex` is defined
    if (!defaultPrevented && this.currentStepIndex !== undefined) {
      this.currentStepIndex = index;
    }
  }

  @Watch('currentStepIndex')
  currentStepIndexWatcher() {
    this.updateStepState();
  }

  @Watch('reversed')
  reversedWatcher() {
    this.updateStepState();
  }

  @Watch('compact')
  @Watch('indicator')
  @Watch('interactive')
  @Watch('orientation')
  @Watch('size')
  otherPropsWatcher(newValue: unknown, propKey: 'compact' | 'indicator' | 'interactive' | 'orientation' | 'size') {
    this.propagatePropsToSteps({ [propKey]: newValue });
  }

  get stepEls() {
    return [...this.el.children].filter((childEl): childEl is HTMLMarketProgressTrackerStepElement =>
      isElementWithTagName(childEl, 'market-progress-tracker-step'),
    );
  }

  updateStepState() {
    const { connectorless, currentStepIndex, reversed, stepEls } = this;
    const orderedSteps = reversed ? [...stepEls].reverse() : stepEls; // logical order

    // set `data-step-index` based on logical order
    orderedSteps.forEach((stepEl, index) => {
      stepEl.dataset.stepIndex = `${index}`;
    });

    // automatically updates steps’ `completed` and `active` states if `currentStepIndex` is defined
    if (currentStepIndex !== undefined) {
      orderedSteps.forEach((stepEl, index) => {
        stepEl.completed = index < currentStepIndex;
        stepEl.active = index === currentStepIndex;
      });
    }

    const activeIndex = currentStepIndex ?? orderedSteps.findIndex((stepEl) => stepEl.active) ?? -1;
    stepEls.forEach((stepEl, index) => {
      if (index < stepEls.length - 1) {
        if (connectorless) {
          stepEl.connector = null;
        } else if ((reversed && index >= stepEls.length - activeIndex - 1) || (!reversed && index < activeIndex)) {
          stepEl.connector = 'active';
        } else {
          stepEl.connector = 'inactive';
        }
      } else {
        /**
         * visually hides the last item’s connector:
         *  - null:   progress tracker is connectorless
         *  - hidden: progress tracker has connectors, but only the last step’s connector is hidden
         * note that this has styling implications
         */
        stepEl.connector = connectorless ? null : 'hidden';
      }
    });
  }

  /**
   * Updates the steps’ props based on the provided prop-value pair(s)
   */
  propagatePropsToSteps(
    propValues: Partial<
      Pick<HTMLMarketProgressTrackerElement, 'compact' | 'indicator' | 'interactive' | 'orientation' | 'size'>
    >,
  ) {
    this.stepEls.forEach((stepEl) => {
      stepEl.compact = propValues?.compact ?? stepEl.compact;
      stepEl.indicator = propValues?.indicator ?? stepEl.indicator;
      stepEl.interactive = propValues?.interactive ?? stepEl.interactive;
      stepEl.orientation = propValues?.orientation ?? stepEl.orientation;
      stepEl.size = propValues?.size ?? stepEl.size;
    });
  }

  handleDefaultSlotChange() {
    this.propagatePropsToSteps(pick(this, 'compact', 'indicator', 'interactive', 'orientation', 'size'));
    this.updateStepState();
  }

  connectedCallback() {
    this.propagatePropsToSteps(pick(this, 'compact', 'indicator', 'interactive', 'orientation', 'size'));
    this.updateStepState();
  }

  render() {
    const { handleDefaultSlotChange, interactive } = this;

    return (
      <Host class="market-progress-tracker" role={interactive ? 'tablist' : 'list'}>
        <slot onSlotchange={handleDefaultSlotChange.bind(this)}></slot>
      </Host>
    );
  }
}
