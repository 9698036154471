import {
  CORE_ANIMATION_ENTER_TRANSITION_MODERATE_SPEED_DURATION,
  CORE_ANIMATION_EXIT_TRANSITION_MODERATE_SPEED_DURATION,
} from '@market/market-theme/js/cjs/index.js';
import { Component, Prop, Host, Element, Method, State, Listen, Event, Watch, EventEmitter, h } from '@stencil/core';

import { Dialog, DialogLoadedEvent, DialogElement, ALL_DIALOG_TYPES, DIALOGS_META } from '../../utils/dialog';
import { getNamespacedTagFor } from '../../utils/namespace';

@Component({
  tag: 'market-context',
  styleUrl: 'market-context.css',
  shadow: true,
})
export class MarketContext {
  @Element() el: HTMLMarketContextElement;

  /**
   * **INTERNAL [do not use directly]**
   * Exposes the context's currentDialog for use by market-context-manager
   */
  @Prop({ mutable: true }) currentDialog: Dialog;

  /**
   * **INTERNAL [do not use directly]**
   * Disabling the context's default veil (including scroll blocking behavior)
   * when visible. By default, this is set by market-context according to what
   * dialog type is being opened. In the future, we want to expose this as an
   * optional config option for market-context-manager's open() method.
   */
  @Prop({ mutable: true }) noVeil: Boolean = false;

  /**
   * Whether the context is hidden or visible.
   */
  @Prop({ mutable: true, reflect: true }) hidden: boolean = false;

  /* TODO: make these match whatever the first/last dialog's animation is */
  /**
   * The duration for the modal enter animation, set from design tokens
   */
  @Prop()
  readonly animationEnterDuration: number = CORE_ANIMATION_ENTER_TRANSITION_MODERATE_SPEED_DURATION;

  /**
   * The duration for the modal exit animation, set from design tokens
   */
  @Prop()
  readonly animationExitDuration: number = CORE_ANIMATION_EXIT_TRANSITION_MODERATE_SPEED_DURATION;

  // TODO: refactor context to only take one dialog (breaking change)
  @State() stack: Array<Dialog> = [];
  @State() totalCount: number = 0;
  @State() dialogMeta: object = {};

  /**
   * Emitted whenever the contents of the context have changed:
   * - Dialog added to the stack
   * - Dialog removed from the stack
   */
  @Event() marketContextContentsChanged: EventEmitter<{
    action: 'marketNewDialogOpened' | 'marketDialogClosed';
    currentDialog: Dialog;
    stack: Array<Dialog>;
  }>;

  /**
   * Emitted whenever the context's stack is empty (no more open dialogs)
   */
  @Event() marketContextEmptied: EventEmitter;

  @Watch('currentDialog')
  currentDialogWatcher(newDialog: Dialog) {
    this.stack.push(newDialog);
    this.stack = [...this.stack]; // Spread syntax ensures triggering the watcher
  }

  @Watch('stack')
  stackWatcher(newValue: Array<Dialog>) {
    newValue.forEach((dialog) => {
      // increase the count of the type of dialog in the meta
      this.dialogMeta[dialog.type].count += 1;
      // Increase the total count of dialogs
      this.totalCount += 1;
    });

    // If there are no more dialogs opened, then emit an event indicating so
    if (newValue.length === 0) {
      this.hidden = true;

      setTimeout(() => {
        this.marketContextEmptied.emit();
      }, this.animationExitDuration);
    }

    this.setContextVeil();
  }

  doesStackContainDialogThatRequiresVeil(dialog: DialogElement) {
    const veiledDialogTagnames = Object.entries(DIALOGS_META)
      .filter(([, config]) => config.veil)
      .map(([type]) => getNamespacedTagFor(`market-${type}` as keyof HTMLElementTagNameMap));
    return veiledDialogTagnames.includes(dialog.tagName.toLowerCase() as keyof HTMLElementTagNameMap);
  }

  setContextVeil(): void {
    // consumer use of noVeil prop overrides default behavior
    // veil shouldn't reset when stack is emptied
    if (this.el.hasAttribute('no-veil') || this.stack.length === 0) {
      return;
    }

    // context will turn off veil if no context in its stack requires one
    this.noVeil = !this.stack.some((dialog) => this.doesStackContainDialogThatRequiresVeil(dialog.el));
  }

  stackHasDialog(dialogEl: DialogElement): boolean {
    return this.stack.some((dialog) => dialog.el === dialogEl);
  }

  @Listen('marketDialogLoaded')
  modalLoadedEventHandler(e: CustomEvent<DialogLoadedEvent>) {
    const dialog = e.detail.dialog;
    const type = e.detail.type;

    if (dialog.parentElement !== this.el) {
      // Ignore marketDialogLoaded events from dialogs which are not children of this
      // context.
      return;
    } else if (this.stackHasDialog(dialog)) {
      // Ignore marketDialogLoaded events from dialogs already contained in this
      // context's stack.
      return;
    }

    // Generate a new dialogID (ex. "modal-partial-2")
    const generatedDialogID = this.generateDialogID(type);

    // Set the dialogID for the dialog element (note: this maps to data-dialog-id
    // and not the native id attribute)
    dialog.dialogID = generatedDialogID;

    // Set the id prop if one does not exist
    // (we don't use this prop anymore, but since we were setting it to
    // generatedDialogID before, removing it would be a breaking change)
    dialog.id = dialog.id || generatedDialogID;

    // Build a new Dialog object and set the currentDialog
    this.currentDialog = {
      el: dialog,
      type,
      dialogID: dialog.dialogID,
      id: dialog.id,
      index: this.stack.length,
      indexOfType: this.dialogMeta[type].count + 1,
    };

    // Emit a nice marketContextContentsChanged event
    this.marketContextContentsChanged.emit({
      action: 'marketNewDialogOpened',
      currentDialog: this.currentDialog,
      stack: this.stack,
    });
  }

  // This event is emitted from market dialog components (Modal, Sheet, Blade, etc.)
  @Listen('marketDialogDismissed')
  dialogDismissedEventHandler(event) {
    if (event.defaultPrevented) {
      return;
    }

    // only close direct children of this context
    if (event.target.parentElement === this.el) {
      this.close(event.detail.dialog.dialogID);
    }
  }

  generateDialogID(type: string) {
    // ex. "sheet-2"
    return `${type}-${this.dialogMeta[type].count + 1}`;
  }

  getDialogByID(dialogID: string) {
    return this.stack.find((dialog) => dialog.dialogID === dialogID);
  }

  /**
   * Adds the passed dialogTemplate to the stack and inserts it into the DOM
   */
  @Method()
  open(dialogTemplate) {
    if (this.stack.length === 0) {
      this.noVeil = !this.doesStackContainDialogThatRequiresVeil(dialogTemplate);
    }

    this.el.appendChild(dialogTemplate);
    return Promise.resolve();
  }

  /**
   * **Recommended for internal use only**
   * Removes the topmost dialog from the stack or the dialog matching the passed `dialogID`
   * Note that using this will not trigger the dialog to emit a marketDialogDismissed event.
   *
   * The recommended path for closing a dialog is to call its dismiss() method.
   */
  // TODO (breaking): consider renaming this method to `removeDialogElement`
  @Method()
  close(dialogID?: string) {
    let dialog;
    const d = this.stack.indexOf(dialog);

    // If we want to close a specific dialog, then find that dialog in the stack
    if (dialogID) {
      dialog = this.stack.find((dialog) => dialog.dialogID === dialogID);

      // If there is no dialog with the passed id, log a helpful warning
      /* eslint-disable-next-line no-console */
      !dialog && console.warn(`Tried to close dialog with data-dialog-id "${dialogID}" but none were found`);
      // Otherwise we will close the current/most recently opened dialog
    } else {
      dialog = this.currentDialog;
    }

    if (dialog) {
      // currently, "persistent" is only implemented for market-dialog, bc it's
      // the only dialog type that doesn't programmatically insert a close button
      // when used w/ market-header
      if (dialog.type === 'dialog' && dialog.el.persistent) {
        return Promise.resolve();
      }

      // Remove the dialog node from the DOM
      setTimeout(() => {
        dialog.el.remove();

        // Remove the dialog from the stack. Reassign to trigger the watcher
        this.stack.splice(d, 1);
        this.stack = [...this.stack];

        // Emit a nice event
        this.marketContextContentsChanged.emit({
          action: 'marketDialogClosed',
          currentDialog: dialog,
          stack: this.stack,
        });
      }, dialog.el.animationExitDuration);
    }

    return Promise.resolve();
  }

  /**
   * **Recommended for internal use only**
   * Removes the topmost dialog from the stack (just an alias for default .close() behavior)
   * Note that using this will not trigger the dialog to emit a marketDialogDismissed event.
   *
   * The recommended path for closing a dialog is to call its dismiss() method.
   */
  // TODO (breaking): consider removing this method in favor of encouraging consumers to close dialogs via dialog.dismiss();
  @Method()
  closeCurrent() {
    this.close();
    return Promise.resolve();
  }

  connectedCallback() {
    ALL_DIALOG_TYPES.forEach((dialogType) => {
      this.dialogMeta[dialogType] = { count: 0 };
    });
  }

  render() {
    return (
      <Host class={`market-context ${this.noVeil ? 'no-veil' : ''}`}>
        <slot></slot>
      </Host>
    );
  }
}
