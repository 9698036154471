import { Component, Host, h, Element, Prop, State, Method, Watch, EventEmitter, Event } from '@stencil/core';

/**
 * @slot trailing-accessory - An icon set on the right side of the input.
 */
@Component({
  tag: 'market-code-input',
  styleUrl: 'market-code-input.css',
  shadow: true,
})
export class MarketCodeInput {
  @Element() el: HTMLMarketCodeInputElement;

  /**
   * A string specifying the type of input to render (text or numeric)
   */
  @Prop({ reflect: true }) readonly type: 'text' | 'number' | 'password' = 'number';

  /**
   * A string specifying a name for the code input.
   */
  @Prop() readonly name: string;

  /**
   * A number specifying the length of the code
   */
  @Prop() readonly length: number = 4;

  /**
   * A boolean representing whether the code input is focused or not.
   */
  @Prop({ mutable: true, reflect: true }) focused: boolean = false;

  /**
   * A string representing a default value (code) that can be passed in to be rendered
   */
  @Prop({ mutable: true, reflect: true }) value: string = '';

  /**
   * A boolean representing whether the input is readonly or not.
   */
  @Prop({ reflect: true }) readonly readonly: boolean = false;

  /**
   * A boolean representing whether the input is invalid or not.
   * This represents error states.
   */
  @Prop({ reflect: true }) readonly invalid: boolean = false;

  /**
   * A boolean representing whether the input is disabled or not.
   * This visually and functionally will disable the input.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  // Internal array representation of the code
  @State() _code: Array<string>;

  /**
   * Emitted whenever any of the input values change.
   */
  @Event() marketCodeInputValueChange: EventEmitter<{ code: string }>;

  @Watch('value')
  valueChangeHandler(value: string) {
    const sanitized = this.sanitizeValue(value);
    if (this.value !== sanitized) {
      this.value = sanitized;
    }
    this.setInputsFromValue(this.value);
  }

  /**
   * Trigger focus styling on `<market-input-text>`
   * and focus the cursor on the first empty `<input />`.
   */
  @Method()
  setFocus(value: boolean = true) {
    this.focused = value;
    if (value) {
      this.focusFirstEmptyInput();
    } else {
      (this.el.shadowRoot.activeElement as HTMLInputElement)?.blur();
    }
    return Promise.resolve();
  }

  isNumber(value: string): boolean {
    return /^\d+$/.test(value);
  }

  isValidChar(char) {
    // no whitespace
    if (/\s/.test(char)) return false;
    // number check
    return ['text', 'password'].includes(this.type) || this.isNumber(char);
  }

  focusFirstEmptyInput() {
    const inputs = this.el.shadowRoot.querySelectorAll('input');
    let input;
    inputs.forEach((i) => {
      if (!i.value && !input) input = i;
    });
    if (!input) input = inputs[this.length - 1];
    input.focus();
  }

  /**
   * Inits this._code to the passed-in `value` prop or to an
   * empty array representation of the code input i.e ['', '', '', '']
   * Called only once on componentWillLoad() as to not cause re-renders
   */
  initCode(value) {
    if (value) {
      this._code = value.split('');

      // ensure that this._code is always of size this.length
      if (this._code.length < this.length) {
        const padding = Array.from<string>({ length: this.length - this._code.length }).fill('');
        this._code = [...this._code, ...padding];
      } else if (this._code.length > this.length) {
        this._code = this._code.slice(0, this.length);
      }
    } else {
      this._code = Array.from<string>({ length: this.length }).fill('');
    }
  }

  sanitizeValue(value: string): string {
    return value
      .split('')
      .filter((char) => this.isValidChar(char))
      .join('')
      .slice(0, this.length);
  }

  getValueFromInputs() {
    let value = '';
    this.el.shadowRoot.querySelectorAll('input').forEach((input) => {
      value += input.value;
    });
    return value;
  }

  setInputsFromValue(value: string) {
    const inputs = this.el.shadowRoot.querySelectorAll('input');

    inputs.forEach((input, i) => {
      const char = value[i];
      const prevChar = value[i - 1];

      // set the input value
      input.value = char || '';

      // set the input tabindex
      if (i === 0 || prevChar) {
        input.removeAttribute('tabindex');
      } else {
        input.tabIndex = -1;
      }
    });
  }

  spreadChars(e) {
    const { target, data } = e;
    const chars = data || target.value;
    if (!chars) return;

    const sanitized = this.sanitizeValue(chars);
    if (sanitized) {
      this.insertChars(target, sanitized.split(''));
    } else {
      target.value = '';
    }
  }

  insertChars(input, chars: Array<string>) {
    const { nextElementSibling } = input;
    const [first, ...rest] = chars;

    input.value = first;
    nextElementSibling?.focus();

    if (nextElementSibling && rest.length > 0) {
      this.insertChars(nextElementSibling, rest);
    }
  }

  updateValue() {
    const previousValue = this.value;
    const newValue = this.sanitizeValue(this.getValueFromInputs());
    if (previousValue !== newValue) {
      const { defaultPrevented } = this.marketCodeInputValueChange.emit({ code: newValue });
      if (defaultPrevented) {
        this.setInputsFromValue(previousValue);
      } else {
        this.value = newValue;
      }
    }
  }

  onInput(e) {
    // Handle paste or autocomplete of multiple chars
    this.spreadChars(e);
    this.updateValue();
  }

  onFocus(e) {
    const { target } = e;
    this.focused = true;

    if (target.value) {
      target.select();
    } else {
      this.focusFirstEmptyInput();
    }
  }

  onBlur() {
    this.focused = false;
  }

  onKeyDown(e) {
    const { target, key } = e;
    const { value, previousElementSibling, nextElementSibling } = target;

    switch (key) {
      case 'ArrowLeft':
        e.preventDefault();
        previousElementSibling?.select();
        break;
      case 'ArrowRight':
        e.preventDefault();
        nextElementSibling?.select();
        break;
      case 'Backspace':
        if (!value) previousElementSibling?.select();
        break;
      default:
        break;
    }
  }

  onKeyUp(e) {
    const { target } = e;
    const { value } = target;

    if (value.length > 1) {
      this.spreadChars(e);
    } else if (!this.isValidChar(value)) {
      target.value = '';
      target.focus();
    }
  }

  onHostClick() {
    // if a child input does not already have focus
    if (!this.el.shadowRoot.activeElement) {
      this.setFocus();
    }
  }

  componentWillLoad() {
    this.valueChangeHandler(this.value);
    this.initCode(this.value);
  }

  render() {
    const inputs = [];
    this._code.forEach((char: string, index: number) => {
      const tabindex = this._code[index - 1] || index === 0 ? null : -1;
      inputs.push(
        <input
          required
          type={this.type === 'password' ? 'password' : 'text'}
          inputmode={this.type === 'number' ? 'numeric' : null}
          autocomplete={index === 0 ? 'one-time-code' : null}
          value={char}
          maxlength={index === this.length - 1 ? '1' : null}
          readOnly={this.readonly}
          disabled={this.disabled}
          placeholder="●"
          tabindex={tabindex}
          onFocus={(e) => this.onFocus(e)}
          onBlur={() => this.onBlur()}
          onInput={(e) => this.onInput(e)}
          onKeyDown={(e) => this.onKeyDown(e)}
          onKeyUp={(e) => this.onKeyUp(e)}
        />,
      );
    });

    return (
      <Host class="market-code-input" name={this.name} onClick={() => this.onHostClick()}>
        <span class="code-input-container">{inputs}</span>
        <slot name="trailing-accessory"></slot>
      </Host>
    );
  }
}
