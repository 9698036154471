import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import {
  MarketButton,
  MarketEmptyState,
  MarketLink,
  MarketTooltip,
} from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { M_PLUS_SUBSCRIPTION_FAQ_URL, SUPPORT_URL } from 'src/utils/url';
import ChatBubblesIcon from 'src/svgs/ChatBubblesIcon';
import 'src/components/UpgradeEmptyState/UpgradeEmptyState.scss';
import './ProhibitedState.scss';
import useReferralPageName from 'src/hooks/useReferralPageName';

/**
 * Component that renders a message in place of the input bar describing that the user is unable to
 * subscribe to M+ due to the nature of their business.
 */
const ProhibitedState = observer((): ReactElement => {
  const { event, subscription } = useMessengerControllerContext();
  const { t } = useTranslation();

  useEffect(() => {
    event.track('View CBD Null State', {
      referral_page_name: 'conversation',
    });
  }, [event]);

  const referralPageName = useReferralPageName();

  const handleClickSupport = (): void => {
    event.track('Click CBD Contact Support', {
      referral_page_name: referralPageName,
    });
  };

  const handleClickLearnMore = (): void => {
    event.track('Click CBD Learn More', {
      referral_page_name: referralPageName,
    });
  };

  if (subscription.isEligibleForSquareOne) {
    return (
      <MarketEmptyState
        className="ProhibitedState"
        data-testid="ProhibitedState"
      >
        <h3 className="heading-20" slot="primary-text">
          <div className="UpgradeEmptyState__icon">
            <ChatBubblesIcon />
          </div>
          {t('ProhibitedState.title')}
        </h3>
        <p className="paragraph-30" slot="secondary-text">
          {t('ProhibitedState.description')}
        </p>
        <div slot="actions" className="UpgradeEmptyState__actions-container">
          <MarketButton
            rank="primary"
            href={SUPPORT_URL}
            onClick={handleClickSupport}
            target="_blank"
          >
            {t('ProhibitedState.support_button')}
          </MarketButton>

          <MarketTooltip interaction="persistent" popoverPlacement="bottom">
            <span slot="trigger">{t('ProhibitedState.tooltip')}</span>
            <div slot="content" className="UpgradeEmptyState__tooltip-content">
              <p className="UpgradeEmptyState__tooltip-text">
                {t('ProhibitedState.tooltip_popover')}
              </p>
              <MarketLink
                href={M_PLUS_SUBSCRIPTION_FAQ_URL}
                target="_blank"
                className="UpgradeEmptyState__tooltip__link"
                onClick={handleClickLearnMore}
              >
                <span className="UpgradeEmptyState__tooltip-cta">
                  {t('common.learn_more')}
                </span>
              </MarketLink>
            </div>
          </MarketTooltip>
        </div>
      </MarketEmptyState>
    );
  }

  return (
    <MarketEmptyState className="ProhibitedState" data-testid="ProhibitedState">
      <h3 className="heading-30" slot="primary-text">
        {t('MessagesPlus.empty_state.prohibited.title')}
      </h3>
      <p className="paragraph-30" slot="secondary-text">
        <Trans
          i18nKey="MessagesPlus.empty_state.prohibited.description"
          components={{
            1: (
              <MarketLink href={M_PLUS_SUBSCRIPTION_FAQ_URL} target="_blank" />
            ),
          }}
        />
      </p>
      <MarketButton
        rank="primary"
        slot="actions"
        href={M_PLUS_SUBSCRIPTION_FAQ_URL}
        target="_blank"
        onClick={handleClickLearnMore}
      >
        {t('common.learn_more')}
      </MarketButton>
    </MarketEmptyState>
  );
});

export default ProhibitedState;
