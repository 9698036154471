import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  MarketButton,
  MarketHeader,
  MarketLink,
  MarketToggle,
} from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { MessengerModalFullContent } from 'src/components/MessengerModalFull';
import MarketBackIcon from 'src/svgs/MarketBackIcon';
import { EditVoicemailPage as EditVoicemailPageType } from 'src/MessengerTypes';
import LoadingIndicator from 'src/components/LoadingIndicator/LoadingIndicator';
import Link from 'src/components/Link/Link';
import EditRequestedTextMessageModal from 'src/pages/EditVoicemailPage/components/EditRequestedTextMessageModal/EditRequestedTextMessageModal';
import RecordNewVoicemailGreetingModal from 'src/pages/EditVoicemailPage/components/RecordNewVoicemailGreetingModal/RecordNewVoicemailGreetingModal';
import VoicemailPlayer from 'src/pages/EditVoicemailPage/components/VoicemailPlayer/VoicemailPlayer';
import DeleteVoicemailConfirmationDialog from 'src/pages/EditVoicemailPage/components/DeleteVoicemailConfirmationDialog/DeleteVoicemailConfirmationDialog';
import './EditVoicemailPage.scss';
import ControlledMarketRowWithToggle from 'src/components/ControlledMarketRowWithToggle/ControlledMarketRowWithToggle';

/**
 * Top level component that renders the Edit Voicemail page. View contains the UI controls
 * to allow a merchant to configure their voicemail settings for their dedicated number.
 */
const EditVoicemailPage = observer((): ReactElement => {
  const { navigation, status, user, settings, modal, event } =
    useMessengerControllerContext();
  const { t } = useTranslation();

  const page = navigation.sheet.currentPage as EditVoicemailPageType;
  const unitToken = page.unitToken;
  const unit = user.units.get(unitToken);
  const unitSetting = settings.allUnitSettings.get(unitToken);
  const isLoading =
    settings.status === 'LOADING' || settings.status === 'NOT_STARTED';

  useEffect(() => {
    if (settings.status === 'NOT_STARTED') {
      settings.init();
    }
  }, [settings, settings.status]);

  useEffect(() => {
    // If the user does not have access to the voicemail settings, navigate them back to the settings page
    if (unitSetting?.voicemail?.appliesToCurrentEmployee === false) {
      navigation.openSheet('SETTINGS');
    }
  }, [unitSetting?.voicemail?.appliesToCurrentEmployee, navigation]);

  useEffect(() => {
    return () => {
      settings.clearUserVoicemailUnitSettings(unitToken);
      settings.resetVoicemailStatuses();
    };
  }, [settings, unitToken]);

  useEffect(() => {
    if (!isLoading && (!unit || !unitSetting)) {
      status.setError();
      navigation.closeSheet();
    }
  }, [isLoading, unit, unitSetting, status, navigation]);

  if (!unit || !unitSetting) {
    // Missing unit error case handled in useEffect
    return <LoadingIndicator />;
  }

  const { isEnabled, isTextEnabled, textMessage } = unitSetting.voicemail;

  const setVoicemailEnabled = async (
    isVoicemailEnabled: boolean,
  ): Promise<void> => {
    event.track('Click Enable Voicemail Toggle', {
      unit_token: unitToken,
      is_enabled: isVoicemailEnabled,
    });

    try {
      await settings.saveVoicemailUnitSettings(unitToken, {
        ...unitSetting.voicemail,
        isEnabled: isVoicemailEnabled,
      });
    } catch {
      status.setError({
        label: t('EditVoicemailPage.edit_toggle_error_text'),
      });
      return;
    }
    status.setSuccess({
      label: t('EditVoicemailPage.edit_toggle_success_text'),
    });
    await settings.refreshDefaultVoicemail();
  };
  const setTextEnabled = async (isTextEnabled: boolean): Promise<void> => {
    try {
      await settings.saveVoicemailUnitSettings(unitToken, {
        ...unitSetting.voicemail,
        isTextEnabled,
      });
    } catch {
      status.setError({
        label: t('EditVoicemailPage.edit_toggle_error_text'),
      });
      return;
    }
    status.setSuccess({
      label: t('EditVoicemailPage.edit_toggle_success_text'),
    });
    await settings.refreshDefaultVoicemail();
  };
  const onConfirmExitWithoutSaving = (): void => {
    if (modal.currentModal === 'UNSAVED') {
      modal.closeModal();
    }
    if (navigation.sheet.canNavigateBack) {
      navigation.sheet.navigateBack();
      return;
    }
    navigation.sheet.clearNavigation();
    navigation.sheet.navigateTo('SETTINGS');
  };

  const isToggleDisabled = settings.voicemailSaveStatus === 'LOADING';

  return (
    <>
      <MarketHeader showNavigation className="EditVoicemailPage__header">
        <MarketButton
          rank="secondary"
          slot="navigation"
          onClick={() => {
            if (settings.userMadeAVoicemailChange(unitToken)) {
              modal.openModal('UNSAVED');
              return;
            }
            onConfirmExitWithoutSaving();
          }}
        >
          <MarketBackIcon slot="icon" />
        </MarketButton>
        {user.isMultiUnit && <small slot="wayfinding">{unit.name}</small>}
        <h2>{t('EditVoicemailPage.title')}</h2>
        <p className="EditVoicemailPage__desc">
          {user.isMultiUnit
            ? t('EditVoicemailPage.description_multi', {
                phoneNumber:
                  unit.subscription?.dedicatedNumber?.displayDedicatedNumber,
              })
            : t('EditVoicemailPage.description')}
        </p>
      </MarketHeader>
      {/** Loading state is passed here for completeness but should be handled already by LoadingIndicator above */}
      <MessengerModalFullContent status={status.value} isLoading={isLoading}>
        <div className="EditVoicemailPage__audio-section-container">
          <div className="EditVoicemailPage__player-title-container">
            <h3>{t('EditVoicemailPage.voicemail_player_title')}</h3>
            <MarketLink
              onClick={() => {
                modal.openModal('RECORD_NEW_GREETING');
                event.track('Click Record New Voicemail Greeting Button', {
                  unit_token: unitSetting.unitToken,
                });
              }}
            >
              {t('EditVoicemailPage.record_new_greeting')}
            </MarketLink>
          </div>
          <VoicemailPlayer
            unitSetting={unitSetting}
            onClickDeleteButton={() => {
              modal.openModal('DELETE_VOICEMAIL');
            }}
          />
        </div>
        <h3>{t('EditVoicemailPage.options_title')}</h3>
        <p>{t('EditVoicemailPage.options_description')}</p>
        <ControlledMarketRowWithToggle
          interactive
          selected={isEnabled}
          onMarketRowSelected={() => setVoicemailEnabled(true)}
          onMarketRowDeselected={() => setVoicemailEnabled(false)}
          disabled={isToggleDisabled || undefined}
          className={classNames({
            'EditVoicemailPage__toggle-option-disabled': isToggleDisabled,
          })}
        >
          <label slot="label">
            {t('EditVoicemailPage.enable_voicemail_title')}
          </label>
          <p slot="subtext">
            {t('EditVoicemailPage.enable_voicemail_description')}
          </p>
          <MarketToggle slot="control"></MarketToggle>
        </ControlledMarketRowWithToggle>
        <ControlledMarketRowWithToggle
          interactive
          selected={isTextEnabled}
          onMarketRowSelected={() => setTextEnabled(true)}
          onMarketRowDeselected={() => setTextEnabled(false)}
          disabled={isToggleDisabled || undefined}
          className={classNames({
            'EditVoicemailPage__toggle-option-disabled': isToggleDisabled,
          })}
        >
          <label slot="label">{t('EditVoicemailPage.enable_text_title')}</label>
          <p
            slot="subtext"
            className="EditVoicemailPage__enable_text_description"
          >
            <span>{t('EditVoicemailPage.enable_text_description')}</span>
            {textMessage}
          </p>
          <MarketToggle slot="control"></MarketToggle>
        </ControlledMarketRowWithToggle>
        <Link
          className="EditVoicemailPage__edit-button"
          onClick={() => {
            modal.openModal('EDIT_VOICEMAIL_TEXT_MESSAGE');
          }}
          disabled={!isTextEnabled}
        >
          {t('common.edit')}
        </Link>
        {modal.currentModal === 'EDIT_VOICEMAIL_TEXT_MESSAGE' && (
          <EditRequestedTextMessageModal unitSetting={unitSetting} />
        )}
        {modal.currentModal === 'RECORD_NEW_GREETING' && (
          <RecordNewVoicemailGreetingModal unitSetting={unitSetting} />
        )}
        {modal.currentModal === 'DELETE_VOICEMAIL' && (
          <DeleteVoicemailConfirmationDialog unitSetting={unitSetting} />
        )}
      </MessengerModalFullContent>
    </>
  );
});

export default EditVoicemailPage;
