/**
 * This file contain most of the types and enums required for Messenger.
 *
 * Declare all types and enums in this files UNLESS they are:
 * - Prop types for components: should be in component files
 *
 * Other declarations that should NOT be in this file:
 * - Declaration for global Window interface: in index.tsx
 * - 3rd party libraries with no d.ts files: in external.d.ts
 *
 * Please insert new types/enums into the appopriate headers, such as
 * // ///////////////////////////////////////////////
 * // Search                                       //
 * // ///////////////////////////////////////////////
 * or create a new one, and comment clearly the usage of it.
 */
import {
  Utterance,
  Medium,
  ConsentStatus,
  IUtterance,
  IExternalAttachment,
  MessengerSettings,
  UnitDedicatedNumber,
  GetUnitsInformationResponse,
  Suggestion,
  IVoicemailGreeting,
  Transcript,
  SearchUtterancesResponse,
  Attachment,
  IAttachment,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import { ReactElement } from 'react';
import { FeatureRelayFlagValue } from '@square/feature-relay-web-sdk';

export type Environment = 'staging' | 'production';

// ///////////////////////////////////////////////
// Search                                       //
// ///////////////////////////////////////////////
/**
 * The type of query on the new message page
 */
export type SearchQueryType = 'EMAIL' | 'PHONE' | 'NAME';

/**
 * The type of utterance search results expected from the SearchUtterances endpoint.
 */
export type UtteranceSearchResult = {
  utterance: IUtterance;
  transcriptId: number;
  isRead: boolean;
  displayName?: Transcript.IDisplayName;
  highlightSegments?: SearchUtterancesResponse.SearchResult.IHighlightSegment[];
};

// ///////////////////////////////////////////////
// Conversation                                 //
// ///////////////////////////////////////////////
/**
 * Types of conversations
 * ORPHAN := no customers linked
 * ORDINARY := exactly 1 customer linked
 * SYNTHETIC := >1 customer. Marks a shared transcript to help
 * with the "merge customer" flow later
 *
 * Only ORDINARY conversations have multiple transcripts.
 */
export type ConversationType = 'ORDINARY' | 'ORPHAN' | 'SYNTHETIC';

/**
 * All of the information needed to render and sort a conversations list
 * item, essentially an utterance plus the path to get to it.
 * The exact mechanism we use to compare these is subject to change as we
 * get feedback from merchants, e.g. we might start off sorting simply by
 * utterance recency, but then get more sophistocated. Regardless of the method,
 * this data structure should be sufficient for sorting and rendering.
 */
export type UtterancePreview = {
  /** Shouldn't be null */
  customerTokens: string[] | null;
  /** Phone number or email. Note: may not be formatted nicely */
  contactId: string | null;
  /** Used to format contactId, eventually */
  medium: Medium | null;
  /** The actual utterance backend data structure */
  utterance: IUtterance;
  /**
   * This is true iff the conversation should appear as
   * unread (blue dot). Computed from Conversation#read.
   */
  isUnread: boolean;
  /** Photos of a local utterance */
  localPhotos?: Photo[];
  /** Files of a local utterance */
  localFiles?: IAttachment[];
};

/**
 * Contains information required to send a message. Either
 * transcriptId or unitToken needs to be present.
 */
export type MessageDestination = {
  /**
   * The type of medium.
   */
  medium: Medium;

  /**
   * The consent status at the point where this message is sent.
   */
  consentStatus?: ConsentStatus;

  /**
   * The transcript id associated to this medium. If not present,
   * it usually means that there has not been conversation between
   * the customer and merchant over this medium. Used to send
   * messages.
   */
  transcriptId?: number;

  /**
   * Unit token associated to the consent status of this medium.
   */
  sellerKey?: string;
};

// ///////////////////////////////////////////////
// Transcript View Item                       //
// ///////////////////////////////////////////////

/**
 * The type of contextual event. They are essentially information that
 * appears in a transcript that represents activities done between
 * the buyer and seller.
 *
 * APPOINTMENT: infomation of an appointment, such as the date, service, staff,
 * and status. This can appear as a GENERAL_EVENT_CARD or GENERAL_EVENT_BANNER.
 *
 * FEEDBACK: information of a customer feedback, such as positive/negative sentiment,
 * comment, and payment information. This is rendered as a CUSTOMER_EVENT_CARD.
 *
 * RECEIPT: information of a reply to receipt, such as the sale value and comment.
 * This is rendered as a CUSTOMER_EVENT_CARD.
 *
 * MARKETING: information of a reply to marketing campaign, such as marketing name and
 * the comment. This is rendered as a CUSTOMER_EVENT_CARD.
 *
 * COUPON: information of a merchant-sent coupon, such as coupon code and expiration.
 * This is rendered as a MERCHANT_EVENT_CARD.
 *
 * FORM_SUBMISSION: information from a buyer form submission on a Square Online site.
 * This is rendered as a CUSTOMER_EVENT_CARD.
 */
export type ContextualEventType =
  | 'APPOINTMENT'
  | 'FEEDBACK'
  | 'RECEIPT'
  | 'MARKETING'
  | 'COUPON'
  | 'ORDER'
  | 'INVOICE'
  | 'ESTIMATE'
  | 'CHECKOUT_LINK'
  | 'FORM_SUBMISSION'
  | 'MESSAGES_PLUGIN_SUBMISSION'
  | 'VOICEMAIL'
  | 'SQUARE_GO_REVIEW'
  | 'INBOUND_CALL';

/**
 * The component type that renders a contextual event. In contrast with a normal
 * utterance, contextual events have much richer content and layout than a plaintext.
 * Most events are interleaved between utterances.
 *
 * CUSTOMER_EVENT_CARD: use this component when we have a customer-initiated activity
 * that we want to show. Events may have utterance associated with it, but we want to
 * render even more information than just the utterance text. For example, a feedback
 * event contains a text component like an utterance, but also has the sentiment, facet,
 * and sale value. These additional information are rendered into the title, subtitle, and
 * description of a CustomerEventCard.
 *
 * MERCHANT_EVENT_CARD: use this component when we have a merchant-initiated activity to
 * render. Similar to CUSTOMER_EVENT_CARD, there may be associated utterance where we want to
 * show the utterance metadata information visually. As such, the props of a MerchantEventCard
 * can be used to show this. For example, a coupon is an utterance with a coupon code metadata.
 * Instead of just rendering the utterance text, we want to render an icon with the code.
 *
 * GENERAL_EVENT_CARD: use this component when we want to render an activity that is not strictly
 * initiated by either the customer or merchant. The events rendered by this does not have any
 * associated utterance. For example, a past completed event uses this component to show its
 * details.
 *
 * GENERAL_EVENT_BANNER: similar to GENERAL_EVENT_CARD, but this does not render inline with other
 * cards. Instead, the banner will be sticky on top of the current page it is on, and does not move
 * with scrolling.
 */
export type ContextualEventComponent =
  | 'CUSTOMER_EVENT_CARD'
  | 'MERCHANT_EVENT_CARD'
  | 'GENERAL_EVENT_CARD'
  | 'GENERAL_EVENT_BANNER';

/**
 * An item that is rendered on the TranscriptViewPage. It can represent
 * many different content, such as utterance, contextual events, and
 * timestamp. This acts as the most parent type of all things that can be
 * rendered on that page.
 */
export type TranscriptViewItem = {
  timestampMillis: number;
  unitToken?: string;
  showCustomerImage?: boolean;
  dataType: 'UTTERANCE' | 'TIMESTAMP' | ContextualEventType;
  componentType: 'UTTERANCE_CARD' | 'TIMESTAMP' | ContextualEventComponent;
  data:
    | LocalUtterance
    | MediumTimestamp
    | Appointment
    | Feedback
    | Receipt
    | Marketing
    | Coupon
    | CheckoutLink
    | Order
    | Invoice
    | Estimate
    | EcomFormEntry
    | MessagesPluginEntry
    | Voicemail
    | SquareGoReview
    | InboundCall;
  /**
   * Some contextual events have utterance associated to it. When this happens,
   * we take the attached utterance as the source of truth. This is a LocalUtterance
   * type because some merchant initiated view item, like coupons, can fail to send,
   * and we need the MessageDestination in LocalUtterance to retry the send.
   */
  attachedUtterance?: LocalUtterance;
};

// UTTERANCE //

/**
 * A local utterance. This wraps an utterance, and an optional message destination in case the
 * utterance is not yet associated with any transcript.
 */
export type LocalUtterance = {
  /**
   * The utterance instance to send.
   */
  utterance: IUtterance;
  messageDestination?: MessageDestination;
  photos?: Photo[];
  files?: IAttachment[];
  externalAttachments?: IExternalAttachment[];
};

/**
 * This is a schema for the strings we use as utterance client-side
 * IDs. It gives us enough information to identify an utterance
 * and also to look up utterances in IndexedDB.
 *
 * Implementation details: When we set a client side ID on a
 * new utterance, we create this object and JSON.stringify() it.
 * To compare two client IDs, we compare the stringify-d
 * version of this data structure. When reading from IndexedDB,
 * we JSON.parse(clientIdString) to determine the right
 * key for this utterance.
 */
export type LocalUtteranceClientId = {
  /**
   * The part of this struct that uniquely identifies an
   * utterance in a particular IndexedDB bucket
   */
  uuid: string;
  /**
   * Used as the key in IndexedDB to find this utterance.
   */
  transcriptId: string;
};

// TIMESTAMP //

/**
 * Data of a timestamp to be shown along utterances and contextual events. This should not
 * exist on its own, but should be a part of a TranscriptViewItem where the timestamp and
 * unitToken are key information of a timestamp. A medium here is defined as a messaging channel,
 * such as SMS or EMAIL, and also a Square product, such as APPOINTMENT and INVOICE (in the future).
 */
export type MediumTimestamp = {
  medium?: Medium | 'APPOINTMENT';
};

// PHOTO //

/**
 * The data required for rendering photo.
 */
export type Photo = {
  // The url to put in src of <img>, in the format of {fastly_link}?token={token}
  // For example, https://fastly.link/asd123?token=qwe345
  url: string;
  // id of attachment that this photo is associated to
  attachmentId?: number;
  // true if this photo is a local file and does not apply image optimization params
  // to the url
  isLocal?: boolean;
  // Hash of the attachment. Used to associate uploaded StagedAttachments with Attachments returned
  // from the server.
  hash?: string;
};

/**
 * Contains the url to access the file, and the File object to access the content.
 */
export type LocalFile = {
  url: string;
  file: File;
  type: Attachment.AttachmentType;
};

// APPOINTMENT //

/**
 * The status of an appointment, returned from graphql. Should correspond to
 * https://prototype.sqprod.co/#/docs/squareup.appointments.merchant.ReservationStatusCode
 */
export type AppointmentStatusCode =
  | 'UNKNOWN'
  | 'PENDING' // waiting for merchant to confirm
  | 'DECLINED' // merchant decline the request
  | 'ACCEPTED' // merchant accepted request
  | 'CANCELLED_BY_BUYER' // cancelled by customer
  | 'CANCELLED_BY_SELLER' // cancelled by merchant
  | 'NO_SHOW' // appointment past and customer did not show up
  | 'TEMPORARY';

/**
 * Represents appointment data that is returned from graphQL after it is being parsed.
 */
// TODO(klim): remove ReservationStatusCodeV1 type from status_code once
// graphlql migration is done
export type GraphQLAppointment = {
  dateStart?: number; // in microseconds
  statusCode?: AppointmentStatusCode | ReservationStatusCodeV1;
  reservationId?: string;
  unitToken?: string;
  schedule?: {
    rrule?: string;
  };
  staffNames?: string[];
  itemNames?: string[];
};

/**
 * Contains information of an appointment. It almost never exist by itself,
 * but a property of a TranscriptViewItem.
 */
export type Appointment = {
  dateStart?: number; // in milliseconds
  statusCode?: AppointmentStatusCode;
  reservationId?: string;
  isRecurring?: boolean;
  staffNames?: string[];
  itemNames?: string[];
};

/**
 * Represents the data for a past appointment.
 */
export type PastAppointment = {
  reservationId: string;
  timestampMillis: number;
  isRecurring: boolean;
  staffName: string;
  itemNames: string[];
};

// DIALOGUE (FEEDBACK, MARKETING, RECEIPT) //

/**
 * Customer's reaction of a feedback. Unknown refers to feedback without
 * a specific sentiment. Should correspond to
 * https://prototype.sqprod.co/#/docs/squareup.dialogue.api.Sentiment
 */
export type FeedbackSentiment = 'POSITIVE' | 'NEGATIVE' | 'UNKNOWN';

/**
 * Specific qualities that the customer made a feedback on. Should correspond
 * to
 * https://prototype.sqprod.co/#/docs/squareup.dialogue.api.Facet
 */
export type FeedbackFacet =
  | 'INVALID'
  | 'OTHER'
  | 'PRODUCT_QUALITY'
  | 'WAIT_TIME'
  | 'CUSTOMER_SERVICE'
  | 'PRODUCT_SELECTION'
  | 'QUALITY'
  | 'THOROUGHNESS'
  | 'ENVIRONMENT'
  | 'TIMELINESS'
  | 'ROUTE_TAKEN'
  | 'VEHICLE'
  | 'TRAVEL_TIME'
  | 'DRIVER'
  | 'OFFERINGS'
  | 'PROFESSIONALISM'
  | 'SELECTION'
  | 'AMBIANCE'
  | 'ORGANIZATION'
  | 'LOCATION'
  | 'SERVICE'
  | 'UNSPECIFIED'
  | 'PERFORMANCE_LENGTH'
  | 'EVENT_LENGTH'
  | 'MOVIE_LENGTH'
  | 'UNKNOWN_CHARGE'
  | 'UNDELIVERED';

/**
 * Our custom Payment type because graphQL returns currencyCode in string
 * instead of enum from the standard Square Money proto.
 */
export type Payment = {
  currencyCode?: string;
  amount?: number;
};

/**
 * Contains information of a feedback. It almost never exist by itself,
 * but a property of a TranscriptViewItem.
 */
export type Feedback = {
  conversationToken?: string;
  createdAt?: number;
  comment?: string;
  sentiment?: FeedbackSentiment;
  facets?: FeedbackFacet[];
  paymentToken?: string;
  payment?: Payment;
};

/**
 * Contains information of a reply to receipt. It almost never exist by itself,
 * but a property of a TranscriptViewItem.
 */
export type Receipt = {
  conversationToken?: string;
  createdAt?: number;
  comment?: string;
  paymentToken?: string;
  payment?: Payment;
};

/**
 * Contains information of a reply to marketing campaign. It almost never exist by itself,
 * but a property of a TranscriptViewItem.
 */
export type Marketing = {
  conversationToken?: string;
  createdAt?: number;
  comment?: string;
  url?: string;
  name?: string;
};

// COUPON //

/**
 * Custom type of Money from
 * https://prototype.sqprod.co/#/docs/squareup.connect.v2.common.Money
 * because GraphQL needs currency to be in string.
 */
export type Money = {
  amount: number;
  currency?: string;
};

/**
 * Data that is returned from GraphQL from a create coupon request. Contain
 * all the information needed to send the coupon via SendMessage. This type
 * is similar to Utterance.Metadata.Coupon except for amount.currency be
 * string in this case. The currency needs to be converted to an enum of
 * https://prototype.sqprod.co/#/docs/squareup.connect.v2.common.Currency
 * before it can be used as a coupon metadata for utterance.
 */
export type GraphQLCouponResponse = {
  id?: string;
  code?: string;
  name?: string;
  expirationMillis?: number;
  amount?: Money;
  percentage?: number;
};

/**
 * The metadata of a send coupon modal.
 * If type is 'AMOUNT', value means the small denominator of the currency.
 * e.g. 1000 = USD $10
 *
 * If type is 'PERCENTAGE', value means the percentage itself.
 * e.g. 50 = 50%
 */
export type CouponMetadata = {
  type: 'AMOUNT' | 'PERCENTAGE';
  value: number;
};

// CHECKOUT LINK //

/**
 * Supported types of checkout links that can be created with Messages.
 */
export type GraphQLCheckoutLinkType =
  | 'ONE_TIME_LINK'
  | 'ITEM_LINK'
  | 'DONATION_LINK'
  | 'PAYMENT_LINK';

/**
 * All the data needed to render a checkout link in Messages
 */
export type GraphQLCheckoutLinkResponse = {
  type: GraphQLCheckoutLinkType;
  id: string;
  url: string;
  // `amount` won't be present if the amount is chosen by the customer
  amount?: Money;
  imageUrl?: string;
  title?: string;
};

/**
 * Contact Information for a Create Customer Suggestion.
 */
export type ContactInfo = Suggestion.SuggestionMetadata.ICustomerInfo & {
  firstName?: string;
  lastName?: string;
};

// MISC //

/**
 * The MDS data returned from GraphQL. Used for current user/employee.
 */
export type GraphQLRosterResponse = {
  // Fields from the Merchant proto
  // https://prototype.sqprod.co/#/docs/squareup.roster.mds.Merchant
  merchant: {
    token: string;
    mainUnitToken: string;
    countryCode: string;
    currencyCode: string;
    businessName: string;
  };
  // Fields from the Employment proto
  // https://prototype.sqprod.co/#/docs/squareup.roster.mds.Employment
  employee: {
    token: string;
    // Matches is_account_owner in EmployeeRole
    // https://prototype.sqprod.co/#/docs/squareup.roster.mds.EmployeeRole
    isAccountOwner: boolean;
    // Whether this employee has the
    // ( https://prototype.sqprod.co/#/docs/squareup.employees.EmployeePermissionFlags#MANAGE_SUBSCRIPTIONS )
    // permission and has access to all units.
    canManageSubscriptions: boolean;
    // Whether this employee has the
    // ( https://prototype.sqprod.co/#/docs/squareup.employees.EmployeePermissionFlags#MANAGE_ONLINE_CHECKOUT )
    // permission and has access to all units.
    canManageOnlineCheckout: boolean;
  };
};

/**
 * An alias for utterance metadata Square Go review type. Used to render the
 * Square Go reviews as a TranscriptViewItem.
 */
export type SquareGoReview = Utterance.Metadata.ISquareGoReview;

/**
 * An alias for utterance metadata coupon type. Used to render the
 * coupon as a TranscriptViewItem, as well as to send a coupon
 * via sendMessage.
 */
export type Coupon = Utterance.Metadata.ICoupon;

/**
 * An alias for utterance metadata order type. Used to render the
 * order as a TranscriptViewItem.
 */
export type Order = Utterance.Metadata.IOrder;

/**
 * An alias for utterance metadata invoice type. Used to render the
 * order as a TranscriptViewItem.
 */
export type Invoice = Utterance.Metadata.IInvoice;

/**
 * An alias for utterance metadata estimate type. Used to render the
 * order as a TranscriptViewItem.
 */
export type Estimate = Utterance.Metadata.IEstimate;

/*
 * An alias for utterance metadata checkout link type. Used to render the
 * checkout link as a TranscriptViewItem, as well as to send a checkout link
 * via sendMessage.
 */
export type CheckoutLink = Utterance.Metadata.ICheckoutLink;

/**
 * Aliases for utterance metadata ecom form entry type and its children. Used to render the form
 * entry as a TranscriptViewItem.
 */
export type EcomFormEntry = Utterance.Metadata.IEcomFormEntry;
export type EcomFormQuestionAnswer =
  Utterance.Metadata.EcomFormEntry.IEcomFormQuestionAnswer;

/**
 * Aliases for utterance metadata messages plugin entry type. Used to render messages plugin entry in TranscriptViewItem.
 */
export type MessagesPluginEntry = Utterance.Metadata.IMessagesPluginEntry;

export type Voicemail = Utterance.Metadata.IVoicemail;

export type InboundCall = Utterance.Metadata.IInboundCall;

// ///////////////////////////////////////////////
// Notification                                 //
// ///////////////////////////////////////////////

/**
 * The shape of our Pusher notifications, as they appear from the backend.
 * We transform this into camel case to make a NotificationPayload, which
 * is used everywhere else.
 */
export type RawNotificationPayload = {
  transcript_id: number;
  unit_tokens: string[];
  updated_utterance_id?: number;
  utterance_id?: number;
};

/**
 * The notification payload we get from the backend, when we get a push notification.
 */
export type NotificationPayload = {
  /** The ID of the transcript that was updated */
  transcriptId: number;
  /** The unit tokens associated with this transcript */
  unitTokens: string[];
  /** The ID of a single utterance that has been updated */
  updatedUtteranceId?: number;
  /** The ID of the oldest utterance to update (and all utterances after it) */
  utteranceId?: number;
};

// ///////////////////////////////////////////////
// Navigation                                   //
// ///////////////////////////////////////////////

/**
 * Controls what Messenger should be able to do.
 * 'CLOSED' is basically nothing (the blade is closed).
 * 'OPEN' is the default mode to indicate that Messages is open.
 * 'SINGLE_CONVERSATION' means the person is only meant to look at
 * one customers' chat. This happens if we're in the context of some
 * peron's appointment elsewhere in Dashboard.
 * 'FULL_MESSENGER' is an open blade with full navigation of all the
 * conversations available.
 */
export type MessengerMode =
  | 'CLOSED'
  | 'OPEN'
  | 'SINGLE_CONVERSATION'
  | 'FULL_MESSENGER';

export type MessengerApplication = 'BLADE' | 'FULL_PAGE';

export const MESSENGER_SHEET_PAGE_NAMES = [
  'SETTINGS',
  'MESSAGES_PLUS_PRICING',
  'BUSINESS_NUMBERS',
  'UNITS_TO_VERIFY',
  'UNIT_VERIFICATION_FORM',
  'UNIT_VERIFICATION_SUCCESS',
  'EDIT_VOICEMAIL',
  'MESSAGES_PLUS_START_VERIFICATION',
  'SQ_ONLINE_SETTINGS',
] as const;
export type MessengerSheetPageName = typeof MESSENGER_SHEET_PAGE_NAMES[number];

/**
 * The pages currently implemented in Messenger.
 *
 * 'SETTINGS' page should only be exclusively used in the settings
 * sheet flow.
 */
export type MessengerPageName =
  | 'TRANSCRIPT_VIEW'
  | 'TRANSCRIPTS_LIST'
  | 'NEW_MESSAGE'
  | 'STATUS'
  | 'CUSTOMER_DETAIL'
  | 'CUSTOMER_MERGE_VIEW'
  | 'CUSTOMER_ADD'
  | 'SEARCH'
  | 'DEFAULT'
  | 'PAST_APPOINTMENTS'
  | 'CONVERSATION_HISTORY'
  | 'NO_SELECTED_TRANSCRIPT'
  | MessengerSheetPageName;

/**
 * The transition direction of MessengerContent.
 */
export type Transition = 'LEFT' | 'RIGHT' | 'NONE';

/**
 * The different modals that can be opened in Messenger.
 */
export type ModalType =
  | 'NONE'
  | 'COUPON'
  | 'NEW_CUSTOMER'
  | 'REQUEST_CONSENT'
  | 'CONFIRM_CONSENT'
  | 'SEND_LIMIT'
  | 'CHECKOUT_LINK'
  | 'PHOTOS_GALLERY'
  | 'DO_NOT_DISTURB'
  | 'CONFIRM_BLOCK'
  | 'MESSAGE_BLOCKED'
  | 'UNVERIFIED_IDENTITY'
  | 'CHANGE_MEDIUM'
  | 'CHANGE_LOCATION'
  | 'REVIEW_SETUP'
  | 'UNSAVED'
  | 'SELECT_CONTACT_METHOD'
  | 'CREATE_CUSTOMER_SUGGESTION'
  | 'PAYMENT_ALTERNATIVES'
  | 'MESSAGES_PLUS_SUBSCRIPTION'
  | 'EDIT_VOICEMAIL_TEXT_MESSAGE'
  | 'RECORD_NEW_GREETING'
  | 'DELETE_VOICEMAIL'
  | 'FILE_LIMIT_REACHED'
  | 'FILE_EMAIL_ONLY'
  | 'FILE_FIRST_TIME'
  | 'UNEXPECTED_ERROR'
  | 'FILE_PHOTO_MUTUALLY_EXCLUSIVE'
  | 'FILE_UNSUPPORTED_FORMAT'
  | 'FILE_VIRUS_DETECTED'
  | 'DEDICATED_NUMBER_FEATURE_ONLY_DIALOG'
  | 'SQUARE_ONE_UPSELL_MODAL';

/**
 * Sections of the sheet page. Only ones that we want to automatically scroll
 * to are listed here. See useSheetSectionScrollTo hook for usage.
 */
export type MessengerSheetSectionName = 'MESSAGES_PLUGIN';

// ///////////////////////////////////////////////
// i18n                                         //
// ///////////////////////////////////////////////

/**
 * All of the locales that we support.
 */
export const SUPPORTED_LOCALE = ['en-US', 'es-US', 'fr-CA', 'en-CA'] as const;
export type SupportedLocale = typeof SUPPORTED_LOCALE[number];

/**
 * All of the countries that Square has a presence in
 */
export type SupportedCountry = 'AU' | 'CA' | 'GB' | 'US' | 'JP';

/**
 * Country with its translation key for a display name
 */
export type CountryCodeAndDisplay = {
  code: SupportedCountry;
  translationKey: string;
};

/**
 * State/province with its translation key for a display name
 */
export type StateCodeAndDisplay = {
  code: string;
  translationKey: string;
};

// ///////////////////////////////////////////////
// Checkout Link                                //
// ///////////////////////////////////////////////

/**
 * Maximum and minimum allowable checkout link amounts, in the smallest
 * currency value for the specified currency type. For USD, this is cents.
 */
export type SettingsInfoResponse = {
  settings_info: {
    currency_limits: {
      min_amount: Money;
      max_amount: Money;
    };
  };
};

/**
 * Maximum and minimum allowable checkout link amounts, in the smallest
 * currency value for the specified currency type. For USD, this is cents.
 */
export type CheckoutLinkLimits = {
  minAmount: number;
  maxAmount: number;
};

// ///////////////////////////////////////////////
// Feature flags                                //
// ///////////////////////////////////////////////
/**
 * These are the feature flag values supported by Launch Darkly
 * and the Square Feature Service.
 */
export type FeatureFlagValue = FeatureRelayFlagValue;

/**
 * Flags specific to a medium (SMS, email). Used mostly for attachments related
 * features.
 */
export type MediumFlags = {
  maxPhotosCount: number;
  maxPhotoSizeBytes: number;
  attachmentMaxChunkSizeBytes: number;
  maxOutboundLengthChars: number;
  maxTotalFilesSizeBytes: number;
};

// ///////////////////////////////////////////////
// Current User                                 //
// ///////////////////////////////////////////////
/**
 * Describes metadata about the current user. Loaded from
 * roster via a Messages GraphQL API call.
 */
export type CurrentUser = {
  currentEmployee: CurrentEmployee;
  currencyCode: string;
  timezone: string;
  merchantToken: string;
  mainUnitToken: string;
  countryCode: SupportedCountry;
  businessName: string;
};

/**
 * Object describing the metadata of the current employee.
 */
export type CurrentEmployee = {
  employeeToken: string;
  isOwner: boolean;
  canManageSubscriptions: boolean;
  canManageOnlineCheckout: boolean;
};

// ///////////////////////////////////////////////
// OneTrust                                     //
// ///////////////////////////////////////////////
/**
 * A subset of the SqOneTrust data structure that's attached to the window in Dashboard
 * as window.SqOneTrust.
 *
 * Doc with definitions of cookie types:
 * https://docs.google.com/document/d/1Jl-bH9g8lrDFtHDzWymhzwSMAgkeQDQyA5alVP5xEng/edit#heading=h.49ggydf7ilvt
 */
export type MessagesSqOneTrust = {
  // Will only be true if a person has blocked all cookies
  strictlyNecessaryCookiesBlocked: boolean;
  // Will be true if a person has blocked all cookies or specifically blocked
  // functionality cookies (e.g. cookies to indicate whether educational content
  // has already been displayed)
  functionalityCookiesBlocked: boolean;
  // Will be true if a person has blocked all cookies or specifically blocked
  // analytics cookies (e.g. CDP cookies)
  performanceAndAnalyticsCookiesBlocked: boolean;
  // Will be true if a person has blocked all cookies or specifically blocked
  // retargeting or advertising technology cookies (i.e. Google Analytics)
  retargetingOrAdvertisingCookiesBlocked: boolean;
  // Used only for making requests. Keys are 'C000[1-4]'. A value of "1" means
  // that cookie type is allowed.
  groupConsentHash: GroupConsentHash;
};

/**
 * Used only for making requests. Keys are 'C000[1-4]'. A value of "1" means
 * that cookie type is allowed.
 * strictlyNecessary: 'C0001'
 * performanceAndAnalytics: 'C0002'
 * functionality: 'C0003'
 * retargetingOrAdvertising: 'C0004'
 */
export type GroupConsentHash = Record<string, '0' | '1'>;

/**
 * A request header that lists what type of cookies the user has allowed.
 * Messages doesn't use server-side cookies, so it's not obvious to me (eblaine@) what
 * effect this actually has.
 */
export type AllowCookiesHeaderName = 'X-Allow-Cookies';

/**
 * A request header that is present if GDPR is enforced and server-side cookies should
 * be blocked based on user consent.
 * Messages doesn't use server-side cookies, so it's not obvious to me (eblaine@) what
 * effect this actually has.
 */
export type BlockCookiesHeaderName = 'X-Block-Cookies';

/**
 * A data structure to describe the two OneTrust headers x-allow-cookies and x-block-cookies.
 */
export type OneTrustHeaders = Record<
  AllowCookiesHeaderName | BlockCookiesHeaderName,
  string
>;

// ///////////////////////////////////////////////
// Tooltips                                     //
// ///////////////////////////////////////////////

/**
 * Names of tooltips we show. Used in TooltipStore.
 */
export const TOOLTIP_NAMES = [
  'FULL_PAGE_INBOX',
  'PLUGIN_FEEDBACK',
  'VIEW_NUMBER',
  'SUBSCRIPTION_EXPIRING_BANNER',
  'FREE_TRIAL_EXPIRING_BANNER',
  'UNITS_EXPIRING_BANNER',
  'SUBSCRIPTION_IN_GRACE_BANNER',
  'SUBSCRIPTION_DELINQUENT_BANNER',
  'MERGE_CUSTOMERS',
  'EDIT_REQUESTED_TEXT_MESSAGE_TIP',
  'MESSAGES_PLUS_SUCCESS_BANNER',
  'VERIFICATION_FAILED_DIALOG',
  'VERIFICATION_SUCCESS_DIALOG',
  'MESSAGES_PLUGIN_LAUNCH_BANNER',
  'FILE',
] as const;
export type TooltipName = typeof TOOLTIP_NAMES[number];

// ///////////////////////////////////////////////
// Settings                                     //
// ///////////////////////////////////////////////

export type Setting = MessengerSettings.ISetting;

export type VoicemailSetting = {
  isEnabled: boolean;
  defaultVoicemailGreeting: IVoicemailGreeting;
  customVoicemailGreeting?: IVoicemailGreeting;
  customVoicemailId?: string;
  isTextEnabled: boolean;
  textMessage: string;
  appliesToCurrentEmployee: boolean;
};

export type UnitSetting = {
  unitToken: string;
  placeId: string;
  reviewMessage: string;
  url: string;
  address?: string;
  voicemail: VoicemailSetting;
};

export type SquareOnlineSetting = MessengerSettings.ISquareOnlineWebsiteSetting;

// ///////////////////////////////////////////////
// Unit/Location                                //
// ///////////////////////////////////////////////

/**
 * Details of a Square unit/location.
 */
export type Unit = {
  token: string;
  /**
   * Nickname for the location. Used for internal, seller-facing purposes only,
   * i.e. in the location selector when composing a message, or displaying
   * which locations have M+ numbers in the settings page.
   *
   * If a customer-facing name is needed, use `businessName` instead.
   */
  name: string;
  address: string;
  isActive: boolean;
  /**
   * The business name for the location. Used for external, customer-facing purposes.
   * i.e. in the default email subject ("[Business Name] sent you a message")
   */
  businessName: string;
  subscription?: {
    isSubscribed: boolean;
    dedicatedNumber?: UnitDedicatedNumber;
    verificationInfoToReview?: GetUnitsInformationResponse.UnitInformationForReview;
    isPendingCancellation?: boolean;
  };
};

/**
 * Properties of a single subunit obtained from subunits request.
 */
export type SubunitEntity = {
  nickname: string;
  token: string;
  unit_active: boolean;
  merchant_profile: {
    street1?: string;
    street2?: string;
    city?: string;
    state?: string;
    postal_code?: string;
    country_code?: string;
    name?: string;
  };
};

/**
 * The shape of the response that comes back from the Subunits API.
 */
export type SubunitsResponse = {
  entities: SubunitEntity[];
  // The follow properties only exist when the endpoint fails
  success?: boolean;
  message?: string; // the error message
};

// ///////////////////////////////////////////////
// Subscription                                 //
// ///////////////////////////////////////////////

export type UnitsInformationForReview = Record<
  string,
  GetUnitsInformationResponse.UnitInformationForReview
>;

// ///////////////////////////////////////////////
// Other APIs                                   //
// ///////////////////////////////////////////////

export type MethodDescriptorProto = {
  parent: {
    name: string;
    fullName: string;
  };
  name: string;
};

/**
 * The shape of the multipass credentials response. The real response has more fields, but
 * csrfToken is the only one we use.
 */
export type MultipassCredentialsResponse = {
  csrfToken: string;
};

/**
 * The shape of the multipass status response. The real response has more fields,
 * but many of them are misleading. Also, `expired` is an addition
 * for implementation convenience; it is also not part of the /mp/status response.
 * See https://prototype.sqprod.co/#/docs/squareup.multipass.http.StatusResponse
 * for all fields.
 */
export type MultipassStatusResponse = {
  current_idle_minutes?: number;
  expired?: boolean;
};

/**
 * Details of a google business for reviews.
 */
export type GoogleMapsPlaceDetails = {
  name: string;
  address: string;
  placeId: string;
};

// ///////////////////////////////////////////////
// Status/Error Handling                        //
// ///////////////////////////////////////////////

/**
 * The type of status. Changes the styling of the component.
 */
export type StatusType = 'ERROR' | 'WARNING' | 'SUCCESS';

/**
 * The component to use to display the status.
 */
export type StatusDisplay = 'SCREEN' | 'BANNER' | 'TOAST';

/**
 * The top-level component that the status should be displayed in.
 */
export type StatusScope = 'BLADE' | 'MODAL' | 'SHEET' | 'WINDOW';

/**
 * A function to interact with the status. For example,
 * retrying when failed to load something.
 *
 * If only action is provided:
 * StatusScreen - an SqButton labeled 'Retry' is rendered which calls
 * action() when clicked.
 * StatusBanner - an 'X' is rendered which calls
 * action() when clicked.
 *
 * If action abd button are provided:
 * StatusScreen - Same as above, button is not used here.
 * StatusBanner - The button component replaces the 'X',
 * which calls action() when clicked.
 */
export type StatusAction = {
  action: () => Promise<void> | null;
  button?: ReactElement;
};

/**
 * Represents a status, such as an error.
 */
export type Status = {
  /**
   * The primary description of the status.
   */
  label: string;

  /**
   * The type of status.
   */
  type: StatusType;

  /**
   * The component of which this status is displayed.
   */
  display: StatusDisplay;

  /**
   * The top-level component that the status should be displayed in.
   */
  scope: StatusScope;

  /**
   * A function/button to interact with the status, such
   * as to dismiss it.
   */
  action?: StatusAction;

  /**
   * The label for the action button. Used only for "SCREEN" display.
   */
  actionLabel?: string;

  /**
   * If provided, the status will be removed after the time.
   * Only works for statusOverride.
   */
  timerMillis?: number;
};

// ///////////////////////////////////////////////
// LEGACY TYPES                                 //
// ///////////////////////////////////////////////

/**
 * Status code for reservation.
 * Copied from
 * https://git.sqcorp.co/projects/sq/repos/appointments/browse/protos/squareup/appointments/merchant/model.proto?at=606a9f66878fa67cb824db1831e91aa1987ae1e0#34
 */
export enum ReservationStatusCodeV1 {
  /** Reservations that have a different status not listed here. */
  UNKNOWN = 0,

  /*
    Reservations in the Pending state were either created or changed by the buyer.
    The seller must therefore accept or decline the reservation to move it into the
    proper status.
  */
  PENDING = 1,

  /** Reservations that were created/changed by the buyer, and then declined by the seller */
  DECLINED = 2,

  /*
    Reservations in the Accepted state were either created by the seller, or created
    by the buyer and accepted by the seller. The seller may have manually accepted
    the reservation, or automatically accepted it, depending on their settings.
  */
  ACCEPTED = 3,

  /** Reservations that were cancelled by the buyer. */
  CANCELLED_BY_BUYER = 4,

  /** Reservations that were cancelled by the seller. */
  CANCELLED_BY_SELLER = 5,

  /** Reservations that were marked as no-show by the seller. */
  NO_SHOW = 6,

  /** Temporary reservations that are used to hold time before being finalized. */
  TEMPORARY = 7,
}

/**
 * Generic type that defines the possible loading states that some async logic can be in.
 * Can be used to track the status of API calls. Stores typically use this by tracking a state
 * value for what stage in the lifecycle they are in for fetching data.
 */
export type LoadingStatus = 'SUCCESS' | 'LOADING' | 'ERROR' | 'NOT_STARTED';

/**
 * Type representing a highlight segment, which is a pair of numbers indicating the start and end indices
 * of a highlighted segment in a string. The start index is inclusive (the first number), and the end
 * index is exclusive (the second number).
 */
export type HighlightSegment = [number, number];

/**
 * Describes the data associated with a specific URL in the `window.history` object
 */
export type MessengerUrlState = {
  page: MessengerPageName;
  id?: number | string | string[];
  queryParams?: Map<string, string>;
};

/**
 * Describes a contact method used to identify a transcript.
 * Typically used to open the transcript view with a contact method.
 */
export type ContactMethod = {
  contactId?: string;
  medium: Medium;
  contactHandle?: string;
  sellerKey?: string;
};

/**
 * Describes a customer token used to identify a transcript.
 * Typically used to open the transcript view with a customer token.
 */
export type CustomerToken = {
  customerToken: string;
};

// https://docs.google.com/document/d/1ypoz-cgZSA7C89FZEyJkIMIsWwPLkFeMxpibUfUzvpE/edit#heading=h.8a6mxfg9dl4z
export type OpenTranscriptEventLoggingSource =
  | 'web_link'
  | 'email'
  | 'conversations_list'
  | 'conversations_history_list'
  | 'utterance_click_from_search'
  | 'files_sms_to_email_medium_switcher'
  | 'messages_sdk'
  | 'medium_switcher'
  | 'customers'
  | 'contact_click_from_search'
  | 'contact_click_from_contact_selector';

/**
 * Describes an ID that can be used in place of the transcript ID to identify a transcript.
 */
export type TranscriptIdAlternative = CustomerToken | ContactMethod;

export const isCustomerToken = (
  id: number | TranscriptIdAlternative,
): id is CustomerToken => (id as CustomerToken)?.customerToken !== undefined;

export const isContactMethod = (
  id: number | TranscriptIdAlternative,
): id is ContactMethod =>
  ((id as ContactMethod)?.contactId !== undefined ||
    (id as ContactMethod)?.contactHandle !== undefined) &&
  (id as ContactMethod)?.medium !== undefined;

export const isTranscriptIdAlternative = (
  id: number | TranscriptIdAlternative,
): id is TranscriptIdAlternative => isCustomerToken(id) || isContactMethod(id);

export type BasicMessengerPage = {
  name: MessengerPageName;
};

export type TranscriptMessengerPage = {
  name: 'TRANSCRIPT_VIEW';
  transcriptId: number;
};

export type CustomerDetailMessengerPage = {
  name: 'CUSTOMER_DETAIL';
  customerToken: string;
  transcriptId: number;
};

export type MergeCustomersMessengerPage = {
  name: 'CUSTOMER_MERGE_VIEW';
  transcriptId: number;
};

export type UnitsToVerifyMessengerPage = {
  name: 'UNITS_TO_VERIFY';
  unitTokens: string[];
};

export type UnitVerificationFormMessengerPage = {
  name: 'UNIT_VERIFICATION_FORM';
  unitToken: string;
};

export type EditVoicemailPage = {
  name: 'EDIT_VOICEMAIL';
  unitToken: string;
};

export type AddCustomerMessengerPage = {
  name: 'CUSTOMER_ADD';
  transcriptId: number;
};

export type PastAppointmentsPage = {
  name: 'PAST_APPOINTMENTS';
  customerToken: string;
};

export type ConversationHistoryPage = {
  name: 'CONVERSATION_HISTORY';
  customerToken: string;
  transcriptId: number;
};

export type MessengerPage =
  | BasicMessengerPage
  | TranscriptMessengerPage
  | CustomerDetailMessengerPage
  | MergeCustomersMessengerPage
  | UnitsToVerifyMessengerPage
  | UnitVerificationFormMessengerPage
  | EditVoicemailPage
  | AddCustomerMessengerPage
  | PastAppointmentsPage
  | ConversationHistoryPage;

export const isMessengerPage = (
  page: MessengerPage | MessengerPageName,
): page is MessengerPage =>
  typeof page !== 'string' && page?.name !== undefined;

export const isTranscriptMessengerPage = (
  page: MessengerPage,
): page is TranscriptMessengerPage => page.name === 'TRANSCRIPT_VIEW';

export const SAAS_SHARED_UI_APP_NAME = 'Messages';
