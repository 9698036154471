import { Component, Host, Method, Element, State, Prop, Event, EventEmitter, Listen, h } from '@stencil/core';

import { DialogElement } from '../../utils/dialog';
import { getNamespacedTagFor } from '../../utils/namespace';
import { asyncRequestAnimationFrame } from '../../utils/raf';

@Component({
  tag: 'market-context-manager',
  styleUrl: 'market-context-manager.css',
  shadow: true,
})
export class MarketContextManager {
  @Element() el: HTMLMarketContextManagerElement;

  /**
   * Whether or not the context manager is UI blocking
   */
  @Prop({ mutable: true, reflect: true }) active: boolean = false;

  @State() currentContext: HTMLMarketContextElement;
  @State() stack: Array<HTMLMarketContextElement> = [];

  mouseDownEl: HTMLElement;

  childListObserver: MutationObserver;

  /**
   * Emitted when the context manager is activated/blocking is turned on
   */
  @Event() marketContextManagerActivated: EventEmitter;

  /**
   * Emitted when the context manager is deactivated/blocking is turned off
   */
  @Event() marketContextManagerDeactivated: EventEmitter;

  initChildListObserver() {
    if (this.childListObserver) return;

    const updateChildList = () => {
      this.stack = [...this.el.children] as HTMLMarketContextElement[];
      this.currentContext = this.stack.length > 0 ? this.stack[this.stack.length - 1] : null;
    };

    updateChildList();
    this.childListObserver = new MutationObserver(updateChildList);
    this.childListObserver.observe(this.el, { childList: true });
  }

  async getCurrentContext() {
    while (!this.currentContext) {
      await asyncRequestAnimationFrame();
    }
    return this.currentContext;
  }

  @Listen('marketContextEmptied')
  contextEmptiedEventHandler({ target: emptiedContext }) {
    // Remove the emptied context from the DOM
    emptiedContext.remove();

    // Remove the emptied context from the stack
    this.stack.splice(this.stack.indexOf(emptiedContext), 1);

    // Set the currentContext to the next highest context or null if this was the
    // only context in the stack

    if (this.stack.length === 0) {
      this.deactivate();
    }
  }

  @Listen('keydown', { target: 'window' })
  windowKeydown(e: KeyboardEvent) {
    if (e.key === 'Escape' || e.key === 'Esc') {
      this.currentContext?.currentDialog.el.dismiss({ origin: this.el });
    }
  }

  /**
   * Adds the passed dialogEl to the DOM and creates a new context if necessary or according to
   * `shouldCreateNewContext` if it is passed
   */
  @Method()
  async open(dialogEl: DialogElement, shouldCreateNewContext?: boolean) {
    let createNewContext = shouldCreateNewContext;
    if (!this.currentContext || dialogEl) {
      createNewContext = true;
    }

    // If we don't currently have a context, or we're opening certain types of
    // dialogs, then we should create a new context
    if (createNewContext) {
      this.createNewContext();
    }

    await this.currentContext.open(dialogEl);
  }

  /**
   * Closes the dialog with matching ID
   */
  @Method()
  close(dialogID?: string) {
    this.currentContext.close(dialogID);
    return Promise.resolve();
  }

  /**
   * Adds a new market-context to the stack in the DOM and activates it
   */
  @Method()
  createNewContext() {
    this.activate();

    this.el.appendChild(document.createElement(getNamespacedTagFor('market-context')) as HTMLMarketContextElement);
    this.currentContext = this.el.lastElementChild as HTMLMarketContextElement;

    return Promise.resolve();
  }

  /**
   * Hides the entire context manager
   */
  @Method()
  deactivate() {
    this.active = false;
    this.marketContextManagerDeactivated.emit();

    return Promise.resolve();
  }

  /**
   * Shows the context manager
   */
  @Method()
  activate() {
    if (!this.active) {
      this.active = true;
      this.marketContextManagerActivated.emit();
    }
    return Promise.resolve();
  }

  handleMouseEvents(e) {
    // checking to make sure the click started and ended on a market-context
    // with a veil before dismissing the current dialog
    // (clicks pass through contexts w/o veil, currently only used w/ market-blade)
    if (e.type === 'mousedown') {
      this.mouseDownEl = e.target.tagName;
    } else if (e.type === 'mouseup') {
      const mouseUpEl = e.target.tagName;
      if (
        this.mouseDownEl === mouseUpEl &&
        e.target.tagName.toLowerCase() === getNamespacedTagFor('market-context') &&
        !e.target.classList.contains('no-veil')
      ) {
        this.currentContext?.currentDialog?.el.dismiss({ origin: this.el });
      }
    }
  }

  componentDidLoad() {
    this.initChildListObserver();
  }

  render() {
    return (
      <Host
        class="market-context-manager"
        onMouseDown={(e) => this.handleMouseEvents(e)}
        onMouseUp={(e) => this.handleMouseEvents(e)}
      >
        <slot></slot>
      </Host>
    );
  }

  disconnectedCallback() {
    this.childListObserver?.disconnect();
  }
}
