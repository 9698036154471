import { Component, Element, Host, Prop, State, Watch, h } from '@stencil/core';
import tokens from '@market/market-theme/js/icons.json';
import marketSVGSprite from '@market/market-theme/assets/icons/icons.svg';
import {
  getDefaultIconFidelity,
  getFidelityToken,
  isValidTokenFidelity,
  isValidSpriteFidelity,
  getMarketIconSVGSymbol,
} from '../../utils/icons';

const marketIconSVGSpriteID = '#market-icon-sprite';

interface Icon {
  asset: string;
  size: number;
  width: number;
  height: number;
  tintable: boolean;
}

@Component({
  tag: 'market-icon',
  styleUrl: 'market-icon.css',
  shadow: true,
})
export class MarketIcon {
  @Element() el: HTMLMarketIconElement;

  /**
   * A string identifier for the icon. This can be either the semantic name which maps to
   * a token or the descriptive name, which maps to a SVG id in the sprite, though using the
   * semantic name is preferred because it gives you access to additional features like fidelity.
   * You can also pass any string and it will display a symbol or group within any SVG sprite
   * on the page, whether it is the Market sprite or not.
   */
  @Prop({ reflect: true, mutable: false }) readonly name: string;

  /**
   * Optional: A number representing the fidelity of the icon to display.
   */
  @Prop({ reflect: true, mutable: false }) readonly fidelity: number;

  /**
   * JSON object of the token values for this icon. Can only be accessed if the semantic
   * name is passed.
   */
  @State() fidelityToken: Icon;

  /**
   * Whether or not the icon can change color (is monotone).
   * */
  @State() tintable: boolean = true;

  /**
   * The default fidelity as read from the tokens
   */
  @State() defaultFidelity: number;

  /**
   * The current fidelity the icon is displaying at. This will be the same as this.fidelity
   * if the number passed is a valid fidelity for the icon, otherwise it will be the default.
   * This value should never be an invalid/non-existent fidelity
   */
  @State() currentFidelity: number;

  @State() width: number;
  @State() height: number;

  /**
   * string name for the asset this icon displays
   */
  @State() asset: string;

  /**
   * The id of the symbol in the SVG sprite to display. This is likely to be the
   * descriptive name of the icon.
   */
  @State() identifier: string;

  /**
   * SVG symbol reference for this icon
   */
  @State() symbol: HTMLElement;

  cloneSymbol(symbol: HTMLElement) {
    if (symbol) {
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      svg.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns:xlink', 'http://www.w3.org/1999/xlink');
      svg.dataset.name = this.asset;
      svg.setAttribute('viewBox', symbol?.getAttribute('viewBox'));
      this.width && svg.setAttribute('width', `${this.width}`);
      this.height && svg.setAttribute('height', `${this.height}`);
      svg.innerHTML = symbol.innerHTML;

      this.el.appendChild(svg);
    }
  }

  setDimensions(width?: number, height?: number) {
    // Get the dimensions from the symbol's viewBox attribute
    const dimensions =
      this.symbol
        ?.getAttribute('viewBox')
        .split(/\s/)
        .map((d) => Number.parseInt(d, 10)) || [];

    /* Set the dimensions based on the following:
    1. Passed width/height param
    2. Width & Height as read from the tokens
    3. The numbers from the matching SVG symbol's viewBox attribute
    4. Whatever the currentFidelity is set to (assumes square dimensions)
    */
    this.width = width ?? this.fidelityToken?.width ?? dimensions[2] ?? this.currentFidelity;
    this.height = height ?? this.fidelityToken?.height ?? dimensions[3] ?? this.currentFidelity;
  }

  @Watch('width')
  widthHeightWatcher() {
    this.el.style.setProperty('--icon-width', `${this.width}px`);
  }

  @Watch('height')
  heightWatcher() {
    this.el.style.setProperty('--icon-height', `${this.height}px`);
  }

  @Watch('name')
  @Watch('fidelity')
  handleUserPropChanges() {
    // Get the full icon object as well as the object at the correct fidelity key if passed.
    const iconToken = tokens.core.icon[this.name];

    // If we have passed a semantic name
    if (iconToken) {
      // Determine the default fidelity from the tokens.
      this.defaultFidelity = getDefaultIconFidelity(iconToken);
      // Current fidelity is either the fidelity that is passed if it is valid, or the default fidelity
      this.currentFidelity = isValidTokenFidelity(this.fidelity, iconToken) ? this.fidelity : this.defaultFidelity;
      // Get the fidelity token for this icon
      this.fidelityToken = getFidelityToken(this.currentFidelity, iconToken);

      // Find the asset name for this semantic icon
      this.asset = this.fidelityToken?.asset;

      // Otherwise we've passed a descriptive name
    } else {
      // The name passed is assumed to be the asset name
      this.asset = this.name;

      // If fidelity here is null or invalid, then this will be an approximate selection of the symbol
      // But we need to surmise some default fidelity and current fidelity from something and since we
      // dont have tokens, the SVG markup is the only thing we have more or less.
      this.symbol = getMarketIconSVGSymbol(this.asset, this.fidelity);

      // Approximate a default fidelity from the matching SVG symbol in the sprite
      this.defaultFidelity = Number.parseInt(this.symbol?.dataset.fidelity, 10);

      // Current fidelity is either the fidelity that is passed if it is valid, or the default fidelity
      this.currentFidelity = isValidSpriteFidelity(this.asset, this.fidelity) ? this.fidelity : this.defaultFidelity;
    }
  }

  @Watch('currentFidelity')
  @Watch('asset')
  handleComponentPropChanges() {
    /* Even if we already have a symbol, we want to run this function again in case the symbol
    assignment on line 144 was assigned based on a non-existent or invalid fidelity */
    this.symbol = getMarketIconSVGSymbol(this.asset, this.currentFidelity);

    this.setDimensions();

    this.tintable = this.fidelityToken?.tintable ?? true;

    this.identifier = `market-icon-${this.asset}-fidelity-${this.currentFidelity}`;
  }

  @Watch('identifier')
  showObserver(newValue: string, oldValue: string) {
    if (newValue !== oldValue) {
      // Remove any existing SVG child elements so we don't duplicate them
      this.el.querySelectorAll('svg').forEach((svg) => svg.remove());

      // Appent the symbol to the component's template
      this.cloneSymbol(this.symbol);
    }
  }

  componentWillLoad() {
    // Find the market SVG sprite if it is on the page
    const documentSVGSprite = document.querySelector(marketIconSVGSpriteID) as SVGElement;

    /* If we can't find a symbol or a root level SVG sprite, that means we need to add the default one
    to the page */
    if (!documentSVGSprite) {
      /* This feels sort of icky, but using innerHTML apparently the best way to convert the string
      that gets loaded from importing marketSVGSprite into an actual DOM element */
      const template = document.createElement('div');
      template.innerHTML = marketSVGSprite;
      const sprite = template.querySelector(marketIconSVGSpriteID) as SVGElement;
      sprite.style.display = 'none';
      document.body.append(sprite);
      template.remove();
    }
    this.handleUserPropChanges();
  }

  render() {
    return (
      <Host class="market-icon" tintable={this.tintable}>
        <slot></slot>
      </Host>
    );
  }
}
