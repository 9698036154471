import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  MarketAccessory,
  MarketLink,
  MarketToggle,
} from 'src/components/Market';
import './WebsiteSettingRow.scss';
import classNames from 'classnames';
import type { JSX } from '@market/react';
import ControlledMarketRowWithToggle from 'src/components/ControlledMarketRowWithToggle/ControlledMarketRowWithToggle';

export interface WebsiteSettingRowProps extends JSX.MarketRow {
  icon: ReactElement;
  websiteLabel: string;
  description?: string;
  url?: string;
  urlLabel?: string;
  urlOnClick?: () => void;
  dataTestId?: string;
  toggleDisabled?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

/**
 * Component that renders website settings in the Settings page.
 * Used to render Online Booking Site settings as well as Square Online settings.
 * Extends all props of the MarketRow component.
 *
 * If sites need to be grouped in a separate page, use the `variant: drill` prop
 * combined with the `onClick` prop to navigate to the new page.
 *
 * @param {ReactElement} icon
 * Icon to render on the left side of the row component.
 * @param {string} websiteLabel
 * Label for the website.
 * @param {string} [description]
 * (Optional) Description for the website.
 * @param {string} [url]
 * (Optional) URL to navigate to, accompanied by a "View site" CTA.
 * @param {string} [urlLabel]
 * (Optional) Label for the url link.
 * @param {() => void} [urlOnClick]
 * (Optional) Callback to execute when the url link is clicked.
 * @param {string} [dataTestId]
 * (Optional) Data test ID for the component.
 * @param {boolean} [toggleDisabled]
 * (Optional) Disables the toggle but not the entire row component.
 * @param {boolean} [disabled]
 * (Optional) Disables the entire row component.
 * @param {() => void} [onClick]
 * (Optional) Callback to execute when the row is clicked.
 * @param {string} [className]
 * (Optional) Classname to apply to the MarketRow component.
 */
const WebsiteSettingRow = observer(
  (props: WebsiteSettingRowProps): ReactElement => {
    const {
      icon,
      websiteLabel,
      description,
      url,
      urlLabel,
      urlOnClick,
      dataTestId,
      variant = 'regular',
      toggleDisabled,
      disabled,
    } = props;

    const { t } = useTranslation();

    return (
      <ControlledMarketRowWithToggle
        interactive
        data-testid={dataTestId}
        {...props}
        className={classNames({
          'WebsiteSettingRow__toggle-option-disabled': disabled,
        })}
      >
        <MarketAccessory size="icon" slot="leading-accessory">
          {icon}
        </MarketAccessory>
        <label slot="label">{websiteLabel}</label>
        {description && (
          <p slot="subtext" className="WebsiteSettingRow__description">
            {description}
          </p>
        )}
        {url && (
          <div slot="subtext">
            <MarketLink
              href={url}
              target="_blank"
              onClick={urlOnClick}
              data-testid={
                dataTestId ? `${dataTestId}__url` : 'WebsiteSettingRow__url'
              }
            >
              {urlLabel || t('MessagesPluginSettings.surfaces.cta_label')}
            </MarketLink>
          </div>
        )}
        {variant === 'regular' && (
          <MarketToggle
            slot={toggleDisabled ? 'trailing-accessory' : 'control'}
            disabled={toggleDisabled || undefined}
            data-testid="WebsiteSettingRow__toggle"
          />
        )}
      </ControlledMarketRowWithToggle>
    );
  },
);

export default WebsiteSettingRow;
