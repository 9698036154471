import { Component, Host, h, Element, Prop, Listen, Event, EventEmitter, Method } from '@stencil/core';

import { isMarketCheckbox } from '../../market-checkbox/types';
import { MarketTableV2ControlElement, isMarketTableV2ControlElement } from './types';
import {
  TMarketTableV2Selection,
  MarketTableV2SelectionChangeEventDetail,
  MarketTableV2SortOrderChangeDetail,
  TMarketTableV2SortOrder,
  TMarketTableV2SortStrategy,
} from '../market-table-v2/types';

/**
 * @slot - Default slot for content.
 * @slot control - Intended for use with a form control element.
 * @slot leading-accessory - Intended for use with a leading accessory.
 * @slot trailing-accessory - Intended for use with a trailing accessory.
 */
@Component({
  tag: 'market-table-v2-cell',
  styleUrl: 'market-table-v2-cell.css',
  shadow: true,
})
export class MarketTableV2Cell {
  private control: MarketTableV2ControlElement;

  @Element() el: HTMLMarketTableV2CellElement;

  /**
   * Whether the cell is currently active.
   */
  @Prop({ reflect: true }) readonly active: boolean = false;

  /**
   * Sets the horizontal alignment. When not set,
   * alignment is inherited from an ancestor row or table.
   */
  @Prop({ reflect: true }) readonly align: 'left' | 'center' | 'right';

  /**
   * Displays a leading clickable caret;
   * intended to be used in conjunction with
   * `<market-table-v2-group>` to support nested rows.
   */
  @Prop({ reflect: true }) readonly caret: 'up' | 'down';

  /**
   * Translated label for the collapse action when group is currently expanded (for screen reader users)
   */
  @Prop() readonly caretAriaLabelExpanded: string = 'Group of rows is expanded: click to collapse';

  /**
   * Translated label for the expand action when group is currently collapsed (for screen reader users)
   */
  @Prop() readonly caretAriaLabelCollapsed: string = 'Group of rows is collapsed: click to expand';

  /**
   * Whether the cell is currently disabled.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * Indentation level
   */
  @Prop({ reflect: true }) readonly indent: number;

  /**
   * Whether the cell is interactive, which results in hover, focus, & pressed styles.
   */
  @Prop({ reflect: true }) readonly interactive: boolean = false;

  /**
   * Set this to `true` to force cell text onto one line.
   * May cause horizontal scrolling in the ancestor table.
   */
  @Prop({ reflect: true }) readonly nowrap: boolean = false;

  /**
   * Whether the cell is selected.
   * Relevant if the cell has a slotted control.
   */
  @Prop({ mutable: true }) selected: TMarketTableV2Selection = 'false';

  /**
   * Makes a cell "stick" to the left or right of its parent row.
   * Requires the row to be sized wider than the table to enable horizontal scrolling.
   */
  @Prop({ reflect: true }) readonly sticky: 'left' | 'right';

  /**
   * When the cell is in a table header row, this prop enables sorting by this cell's column.
   */
  @Prop({ reflect: true }) readonly sortable: boolean;

  /**
   * Translated label for the icon indicating an ascending sort (for screen reader users)
   */
  @Prop() readonly sortAriaLabelAscending: string = 'Sorted ascending: click to sort descending';

  /**
   * Translated label for the icon indicating a descending sort (for screen reader users)
   */
  @Prop() readonly sortAriaLabelDescending: string = 'Sorted descending: click to sort ascending';

  /**
   * Translated label for the icon indicating no sort applied (for screen reader users)
   */
  @Prop() readonly sortAriaLabelNone: string = 'Not sorted: click to sort ascending';

  /**
   * When `sortable` is `true`, this prop sets the `aria-sort` attribute
   * and displays an arrow in the correct sort direction.
   */
  @Prop({ mutable: true }) sortOrder: TMarketTableV2SortOrder = 'none';

  /**
   * When `sortable` is `true`, this prop specifies the sorting strategy.
   * - `'string'`: sorts rows alphabetically (case-insensitive) by the text content of the cell (default)
   * - `'number'`: sorts rows numerically using [`parseFloat()`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseFloat) to parse the cell content
   * - `'datetime'`: sorts rows chronologically using [date-fns `parse()`](https://date-fns.org/v3.3.1/docs/parse) method to parse the cell content. This strategy requires specifying a format in the cell's `sortStrategyFormat` prop; see accepted formats [here](https://date-fns.org/v3.3.1/docs/parse)
   * - `Function`: a custom callback function to compare rows, similar to the `compareFn` in [`Array.sort()`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort). The following arguments are provided to the function:
   *   - `rowA`: the first row for comparison
   *   - `rowB`: the second row for comparison
   *   - `order`: the direction of the sort, either `ascending` or `descending`
   *   - `index`: the index of the column being sorted on
   *
   *   The callback function should return a number whose sign indicates the relative order of the two elements:
   *   - negative if `rowA` is less than `rowB`
   *   - positive if `rowA` is greater than `rowB`
   *   - zero if `rowA` & `rowB` are equal
   */
  @Prop() readonly sortStrategy: TMarketTableV2SortStrategy;

  /**
   * When setting `sortStrategy` to `"datetime"`, this prop is required to specify the format.
   * See accepted formats [here](https://date-fns.org/v3.3.1/docs/parse)
   */
  @Prop() readonly sortStrategyFormat: string;

  /**
   * Sets the vertical alignment. When not set,
   * alignment is inherited from an ancestor row or table.
   */
  @Prop({ reflect: true }) readonly valign: 'bottom' | 'middle' | 'top';

  /**
   * Fired when the caret is clicked
   */
  @Event({ bubbles: true, composed: true }) marketTableV2CellCaretClicked: EventEmitter<void>;

  /**
   * Fired when clicked when sortable is `true`
   */
  @Event({ bubbles: true, composed: true })
  marketTableV2CellSortClicked: EventEmitter<MarketTableV2SortOrderChangeDetail>;

  /**
   * @internal
   * Fired when the cell selection state changes. Used internally in table components.
   */
  @Event({ bubbles: true, composed: true })
  marketInternalTableV2CellSelectionChange: EventEmitter<MarketTableV2SelectionChangeEventDetail>;

  @Listen('keydown')
  onKeydown(e: KeyboardEvent) {
    const { target, key } = e;
    const { el, disabled, interactive } = this;
    if (disabled) return;
    if (!interactive) return;
    if (target !== el) return;
    if (key === 'Enter' || key === ' ') {
      e.preventDefault();
      el.click();
    }
  }

  @Listen('marketToggleChange')
  @Listen('marketCheckboxValueChange')
  async onMarketControlSelectionChange(e: CustomEvent<{ current: boolean; previous: boolean }>) {
    const { control } = this;
    const { target, detail } = e;

    // return if the target wasn't this cell's control
    if (target !== control) return;

    const selected = detail.current ? 'true' : 'false';
    await this.setSelected(selected);
  }

  /**
   * @internal
   * Sets selection on the cell and propagates value to its slotted control
   */
  @Method()
  async setSelected(selected: TMarketTableV2Selection, { silent = false } = {}) {
    const { marketInternalTableV2CellSelectionChange, selected: prevSelected } = this;

    // return if no values have changed
    if (prevSelected === selected) return Promise.resolve();

    // fire the internal selection event
    if (!silent) {
      marketInternalTableV2CellSelectionChange.emit({
        current: selected,
        previous: prevSelected,
      });
    }

    // save the state
    this.selected = selected;
    await this.setControlSelected(selected);

    return Promise.resolve();
  }

  private async setControlSelected(selected: TMarketTableV2Selection) {
    const { control } = this;
    if (!control) return;
    await control.setSelection(selected === 'true', { silent: true });
    if (isMarketCheckbox(control)) await control.setIndeterminate(selected === 'indeterminate');
  }

  private getTabIndex() {
    const { disabled, interactive } = this;
    return interactive && !disabled ? '0' : null;
  }

  private getStyles() {
    const { indent } = this;
    if (!indent || indent < 1) return {};
    return { '--table-cell-indent-level': indent.toString() };
  }

  private getSortButtonLabel() {
    const { sortOrder, sortAriaLabelAscending, sortAriaLabelDescending, sortAriaLabelNone } = this;
    switch (sortOrder) {
      case 'ascending':
        return sortAriaLabelAscending;
      case 'descending':
        return sortAriaLabelDescending;
      default:
        return sortAriaLabelNone;
    }
  }

  private onCaretClick(e: MouseEvent) {
    e.stopPropagation();
    this.marketTableV2CellCaretClicked.emit();
  }

  private onSortClick() {
    const { sortOrder, marketTableV2CellSortClicked } = this;
    const previous = sortOrder || 'none';
    const current = previous === 'ascending' ? 'descending' : 'ascending';
    const { defaultPrevented } = marketTableV2CellSortClicked.emit({
      current,
      previous,
    });
    if (!defaultPrevented) this.sortOrder = current;
  }

  private async syncControlState() {
    const { el, selected } = this;
    const control = el.querySelector('[slot="control"]');
    if (isMarketTableV2ControlElement(control)) {
      this.control = control;
      if (selected) await this.setControlSelected(selected);
    }
  }

  async connectedCallback() {
    await this.syncControlState();
  }

  private renderCaretButton() {
    return (
      // Note: We would ideally also have aria-expanded and aria-controls attributes; however, this is not currently
      // possible (as of Mar 2024) due to the lack of support for referencing elements across shadow DOMs. Browser
      // support work is ongoing in this area, but it is insufficient at this time. We should revisit in the future.
      <button
        class="caret-button"
        aria-label={this.caret === 'down' ? this.caretAriaLabelCollapsed : this.caretAriaLabelExpanded}
        onClick={(e: MouseEvent) => this.onCaretClick(e)}
      >
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.70715 11.7071C8.31663 12.0976 7.68346 12.0976 7.29294 11.7071L1.29294 5.70711L2.70715 4.29289L8.00005 9.58579L13.2929 4.29289L14.7072 5.70711L8.70715 11.7071Z"
          />
        </svg>
      </button>
    );
  }

  private renderSortAscendingSvg() {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.52861 2.86177C7.78895 2.60142 8.21107 2.60142 8.47141 2.86177L13.1381 7.52843L12.1953 8.47124L8.66668 4.94265L8.66668 12.6665H7.33334V4.94265L3.80475 8.47124L2.86194 7.52843L7.52861 2.86177Z"
        />
      </svg>
    );
  }

  private renderSortDecendingSvg() {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.47129 13.1382C8.21094 13.3986 7.78883 13.3986 7.52848 13.1382L2.86182 8.47157L3.80463 7.52876L7.33322 11.0574L7.33322 3.3335L8.66655 3.3335L8.66655 11.0574L12.1952 7.52876L13.138 8.47157L8.47129 13.1382Z"
        />
      </svg>
    );
  }

  private renderSortNoneSvg() {
    return (
      <svg width="16" height="17" viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.8633 14.31L8.19664 11.6434L9.1433 10.7034L10.67 12.23L10.67 3.17002L12.0033 3.17002L12.0033 12.23L13.53 10.7034L14.47 11.6434L11.8033 14.31C11.5433 14.57 11.1233 14.57 10.8633 14.31ZM2.46997 6.30338L1.52997 5.36338L4.19664 2.69671C4.45664 2.43671 4.87664 2.43671 5.13664 2.69671L7.8033 5.36338L6.8633 6.30338L5.33664 4.77671L5.33664 13.8367L4.0033 13.8367L4.0033 4.77671L2.46997 6.30338Z"
        />
      </svg>
    );
  }

  private renderSortSvg() {
    switch (this.sortOrder) {
      case 'ascending':
        return this.renderSortAscendingSvg();
      case 'descending':
        return this.renderSortDecendingSvg();
      default:
        return this.renderSortNoneSvg();
    }
  }

  render() {
    const { el, caret, sortable, sortOrder } = this;
    return (
      <Host
        role={el.role ?? 'cell'}
        tabindex={this.getTabIndex()}
        style={this.getStyles()}
        class="market-table-v2-cell"
        sort-order={sortOrder !== 'none' ? sortOrder : null}
        aria-sort={sortOrder !== 'none' ? sortOrder : null}
      >
        <div class="content-outer">
          {caret && this.renderCaretButton()}
          <slot name="control" onSlotchange={() => this.syncControlState()}></slot>
          <div class="content-inner">
            <slot name="leading-accessory"></slot>
            <div class="default-slot">
              {!sortable && <slot></slot>}
              {sortable && (
                <button class="sort-button" aria-describedby="sort-button-label" onClick={() => this.onSortClick()}>
                  <slot></slot>
                  {this.renderSortSvg()}
                  <span id="sort-button-label" hidden>
                    {this.getSortButtonLabel()}
                  </span>
                </button>
              )}
            </div>
            <slot name="trailing-accessory"></slot>
          </div>
        </div>
      </Host>
    );
  }
}
