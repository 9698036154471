import { Component, Host, h, Element, EventEmitter, Prop, Fragment, Event, State, Watch } from '@stencil/core';

import { TMarketProgressTrackerStepClickEventDetail } from './events';
import { getIcon } from './icons';
import { classNames } from '../../../../utils/classnames';
import { getNamespacedTagFor } from '../../../../utils/namespace';

/**
 * @slot label - Primary text for the step
 * @slot subtext - Secondary text for the step
 * @part icon - The `<market-accessory>` that contains the custom icon indicator
 * @part content - The container for the text content
 * @part button - The clickable part when interactive and on horizontal orientation
 */
@Component({
  tag: 'market-progress-tracker-step',
  styleUrl: './styles/market-progress-tracker-step.css',
  shadow: true,
})
export class MarketProgressTrackerStep {
  @Element() el: HTMLMarketProgressTrackerStepElement;

  /**
   * Whether the step is active
   *
   * @default false
   */
  @Prop({ reflect: true }) readonly active: boolean = false;

  /**
   * @internal
   *
   * Whether the step is in compact mode
   *
   * Only functional when `orientation` is set to `"horizontal"`
   *
   * @default false
   */
  @Prop({ reflect: true }) readonly compact: boolean = false;

  /**
   * @internal
   *
   * Type of connector displayed between steps
   *
   * @default undefined
   */
  @Prop({ reflect: true }) readonly connector: 'active' | 'hidden' | 'inactive';

  /**
   * Whether the step is completed
   *
   * @default false
   */
  @Prop({ reflect: true }) readonly completed: boolean = false;

  /**
   * @internal
   *
   * Type of icon used to indicate the step’s progress
   *
   * This is disregarded when a custom icon is slotted via the `icon` slot.
   *
   * @default 'circle'
   */
  @Prop() readonly indicator: 'circle' | 'check' = 'circle';

  /**
   * @internal
   *
   * Whether the step is interactive
   *
   * Only functional when `orientation` is set to `"horizontal"`
   *
   * @default false
   */
  @Prop({ reflect: true }) readonly interactive: boolean = false;

  /**
   * Unique name of the step
   *
   * When this step is interactive and clicked, it is included in the detail of the emitted event.
   *
   * @default undefined
   */
  @Prop() readonly name: string;

  /**
   * @internal
   *
   * Affects the step’s appearance
   *
   * @default 'vertical'
   */
  @Prop({ reflect: true }) readonly orientation: 'vertical' | 'horizontal' = 'vertical';

  /**
   * @internal
   *
   * Step’s size
   *
   * @default 'medium'
   */
  @Prop({ reflect: true }) readonly size: 'small' | 'medium' | 'large' = 'medium';

  /**
   * Emitted when this step is clicked
   */
  @Event({ bubbles: true, composed: true })
  marketProgressTrackerStepClick: EventEmitter<TMarketProgressTrackerStepClickEventDetail>;

  /**
   * Default icon element if an `icon` slot isn’t provided
   */
  @State() defaultIcon: SVGElement;

  /**
   * Updates the default icon based on the following attributes
   */
  @Watch('active')
  @Watch('completed')
  @Watch('indicator')
  @Watch('orientation')
  updateDefaultIcon() {
    const { active, completed, el, indicator, orientation } = this;

    const slottedIcon = [...el.children].some((e) => e.slot === 'icon');
    if (slottedIcon) {
      this.defaultIcon = null;
    } else {
      this.defaultIcon = getIcon({ active, completed, indicator, orientation });
    }
  }

  handleClick() {
    const { el, interactive, marketProgressTrackerStepClick, name } = this;
    if (interactive) {
      marketProgressTrackerStepClick.emit({
        index: el.dataset.stepIndex !== undefined ? Number.parseInt(el.dataset.stepIndex, 10) : null,
        name: name ?? null,
      });
    }
  }

  connectedCallback() {
    this.updateDefaultIcon();
  }

  render() {
    const { defaultIcon, handleClick, interactive, orientation, updateDefaultIcon } = this;
    const MarketAccessoryTagName = getNamespacedTagFor('market-accessory');
    const HorizontalWrapperTagName = interactive ? 'button' : 'div';

    const renderContent = () => {
      return (
        <Fragment>
          <div class={classNames('icon', { 'custom-icon': Boolean(!defaultIcon) })} part="icon">
            <slot name="icon" onSlotchange={updateDefaultIcon.bind(this)}>
              <MarketAccessoryTagName>{defaultIcon}</MarketAccessoryTagName>
            </slot>
          </div>
          <div class="content" part="content">
            <slot name="label"></slot>
            <slot name="subtext"></slot>
            <slot></slot>
          </div>
        </Fragment>
      );
    };

    return (
      <Host class="market-progress-tracker-step" role={orientation === 'horizontal' ? 'presentation' : 'listitem'}>
        {orientation === 'horizontal' ? (
          <HorizontalWrapperTagName
            class={interactive ? 'button' : 'label'}
            onClick={interactive ? handleClick.bind(this) : null}
            part={interactive ? 'button' : null}
            role={interactive ? 'tab' : 'listitem'}
          >
            {renderContent()}
          </HorizontalWrapperTagName>
        ) : (
          renderContent()
        )}
      </Host>
    );
  }
}
