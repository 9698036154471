/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import '../api/sync/sync';
import '../common/time';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "agenda": {
        "nested": {
          "AppointmentStatus": {
            "values": {
              "TEMPORARY": 0,
              "PENDING": 1,
              "CANCELLED_BY_BUYER": 4,
              "CANCELLED_BY_SELLER": 5,
              "DECLINED": 2,
              "ACCEPTED": 3,
              "NO_SHOW": 6
            }
          },
          "ValidateAposDataRequest": {
            "fields": {
              "appointmentsTokens": {
                "type": "string",
                "id": 1,
                "rule": "repeated"
              },
              "personalEventsTokens": {
                "type": "string",
                "id": 2,
                "rule": "repeated"
              },
              "syncFilters": {
                "type": "squareup.api.sync.AgendaSyncFilter",
                "id": 3,
                "rule": "repeated"
              },
              "customerIds": {
                "type": "string",
                "id": 4,
                "rule": "repeated"
              },
              "syncToken": {
                "type": "string",
                "id": 5,
                "rule": "optional"
              },
              "dbCreationTime": {
                "type": "squareup.common.time.DateTime",
                "id": 6,
                "rule": "optional"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.agenda?.nested?.AppointmentStatus && !$root.nested?.squareup?.nested?.agenda?.nested?.ValidateAposDataRequest) {
	$root.addJSON(jsonData);
}

/**
 * Appointments have many potential 'states' that they transition to.
 * State transitions can occur either as a side effect of other actions, or as a direct PUT, however there are
 * restrictions.
 * In simplest terms, buyers must PUT their appointment in state TEMPORARY when first creating one, then PUT
 * that same appointment in PENDING when they are ready to commit to the appointment.  They may also DECLINE
 * an appointment that is PENDING, but not when it is ACCEPTED.
 * Sellers, on the other hand, may only PUT either ACCEPTED in response to a PENDING, or DECLINE in response to
 * either a PENDING or ACCEPTED.
 * Writing the same status you read is always safe.
 *
 * SOURCE AppointmentStatus @ squareup/agenda/agendasync.proto at 82:1
 */
export const AppointmentStatus = $root.lookupEnum('squareup.agenda.AppointmentStatus').values;
/**
 * These protos are used by an endpoint in appointments, but android doesn't currently have any
 * appointments protos, so it's easier to add them here.
 *
 * SOURCE ValidateAposDataRequest @ squareup/agenda/agendasync.proto at 1346:1
 */
export const ValidateAposDataRequest = $root.lookupType('squareup.agenda.ValidateAposDataRequest');
fixType(ValidateAposDataRequest);
Builder.createAndAttachToType(ValidateAposDataRequest);
