/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../items/items';
import '../../common/time';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "api": {
        "nested": {
          "sync": {
            "nested": {
              "ObjectType": {
                "fields": {
                  "type": {
                    "type": "squareup.api.items.Type",
                    "id": 101,
                    "rule": "optional"
                  }
                }
              },
              "ObjectId": {
                "fields": {
                  "id": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "type": {
                    "type": "squareup.api.sync.ObjectType",
                    "id": 2,
                    "rule": "optional"
                  },
                  "v3Token": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "merchantToken": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "customerToken": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "refType": {
                    "type": "squareup.api.items.Type",
                    "id": 101,
                    "rule": "optional"
                  }
                }
              },
              "ObjectWrapper": {
                "fields": {
                  "id": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "objectId": {
                    "type": "squareup.api.sync.ObjectId",
                    "id": 2,
                    "rule": "optional"
                  },
                  "timestamp": {
                    "type": "int64",
                    "id": 3,
                    "rule": "optional"
                  },
                  "deleted": {
                    "type": "bool",
                    "id": 4,
                    "rule": "optional",
                    "options": {
                      "default": false
                    }
                  },
                  "objectType": {
                    "type": "squareup.api.items.Type",
                    "id": 101,
                    "rule": "optional"
                  },
                  "item": {
                    "type": "squareup.api.items.Item",
                    "id": 1001,
                    "rule": "optional"
                  },
                  "itemImage": {
                    "type": "squareup.api.items.ItemImage",
                    "id": 1002,
                    "rule": "optional"
                  },
                  "menuCategory": {
                    "type": "squareup.api.items.MenuCategory",
                    "id": 1005,
                    "rule": "optional"
                  },
                  "itemVariation": {
                    "type": "squareup.api.items.ItemVariation",
                    "id": 1007,
                    "rule": "optional"
                  },
                  "fee": {
                    "type": "squareup.api.items.Fee",
                    "id": 1008,
                    "rule": "optional"
                  },
                  "discount": {
                    "type": "squareup.api.items.Discount",
                    "id": 1010,
                    "rule": "optional"
                  },
                  "itemModifierList": {
                    "type": "squareup.api.items.ItemModifierList",
                    "id": 1012,
                    "rule": "optional"
                  },
                  "itemModifierOption": {
                    "type": "squareup.api.items.ItemModifierOption",
                    "id": 1014,
                    "rule": "optional"
                  },
                  "diningOption": {
                    "type": "squareup.api.items.DiningOption",
                    "id": 1020,
                    "rule": "optional"
                  },
                  "ticketGroup": {
                    "type": "squareup.api.items.TicketGroup",
                    "id": 1023,
                    "rule": "optional"
                  },
                  "ticketTemplate": {
                    "type": "squareup.api.items.TicketTemplate",
                    "id": 1024,
                    "rule": "optional"
                  },
                  "menu": {
                    "type": "squareup.api.items.Menu",
                    "id": 1026,
                    "rule": "optional"
                  },
                  "surcharge": {
                    "type": "squareup.api.items.Surcharge",
                    "id": 1032,
                    "rule": "optional"
                  }
                }
              },
              "ReferenceDescriptor": {
                "fields": {
                  "type": {
                    "type": "squareup.api.sync.ObjectType",
                    "id": 1,
                    "rule": "repeated"
                  },
                  "onDelete": {
                    "type": "squareup.api.sync.ReferenceDescriptor.OnDeleteType",
                    "id": 7,
                    "rule": "optional",
                    "options": {
                      "default": "BLOCK"
                    }
                  },
                  "external": {
                    "type": "bool",
                    "id": 8,
                    "rule": "optional",
                    "options": {
                      "default": false
                    }
                  }
                },
                "nested": {
                  "OnDeleteType": {
                    "values": {
                      "CASCADE": 1,
                      "SET_NULL": 2,
                      "BLOCK": 3,
                      "EXTERNAL": 4
                    }
                  }
                }
              },
              "AgendaSyncFilter": {
                "fields": {
                  "dateStart": {
                    "type": "squareup.common.time.DateTime",
                    "id": 1,
                    "rule": "optional"
                  },
                  "dateEnd": {
                    "type": "squareup.common.time.DateTime",
                    "id": 2,
                    "rule": "optional"
                  },
                  "customerId": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.api?.nested?.sync?.nested?.ObjectType && !$root.nested?.squareup?.nested?.api?.nested?.sync?.nested?.ObjectId && !$root.nested?.squareup?.nested?.api?.nested?.sync?.nested?.ObjectWrapper && !$root.nested?.squareup?.nested?.api?.nested?.sync?.nested?.ReferenceDescriptor && !$root.nested?.squareup?.nested?.api?.nested?.sync?.nested?.ReferenceDescriptor?.nested?.OnDeleteType && !$root.nested?.squareup?.nested?.api?.nested?.sync?.nested?.AgendaSyncFilter) {
	$root.addJSON(jsonData);
}

/**
 * Describes an object.
 *
 * SOURCE ObjectType @ squareup/api/sync/sync.proto at 314:1
 */
export const ObjectType = $root.lookupType('squareup.api.sync.ObjectType');
fixType(ObjectType);
Builder.createAndAttachToType(ObjectType);
/**
 * An object is identified by the tuple of its string id and type
 *
 * SOURCE ObjectId @ squareup/api/sync/sync.proto at 338:1
 */
export const ObjectId = $root.lookupType('squareup.api.sync.ObjectId');
fixType(ObjectId);
Builder.createAndAttachToType(ObjectId);
/**
 * SOURCE ObjectWrapper @ squareup/api/sync/sync.proto at 360:1
 */
export const ObjectWrapper = $root.lookupType('squareup.api.sync.ObjectWrapper');
fixType(ObjectWrapper);
Builder.createAndAttachToType(ObjectWrapper);
/**
 * SOURCE ReferenceDescriptor @ squareup/api/sync/sync.proto at 403:1
 */
export const ReferenceDescriptor = $root.lookupType('squareup.api.sync.ReferenceDescriptor');
fixType(ReferenceDescriptor);
Builder.createAndAttachToType(ReferenceDescriptor);
/**
 * SOURCE OnDeleteType @ squareup/api/sync/sync.proto at 406:5
 */
ReferenceDescriptor.OnDeleteType = $root.lookupEnum('squareup.api.sync.ReferenceDescriptor.OnDeleteType').values;
/**
 * End Put Objects
 *
 * SOURCE AgendaSyncFilter @ squareup/api/sync/sync.proto at 489:1
 */
export const AgendaSyncFilter = $root.lookupType('squareup.api.sync.AgendaSyncFilter');
fixType(AgendaSyncFilter);
Builder.createAndAttachToType(AgendaSyncFilter);
