/* TODO: add design tokens for component */

@import "../../styles/mixins/component-base.css";
@import "../../styles/mixins/typography.css";

.label-container {
  border-width: var(--file-upload-border-width, 1px);
  border-style: var(--file-upload-normal-state-border-style, dashed);
  border-color: var(--file-upload-normal-state-border-color, var(--core-fill-30-color));
  border-radius: var(--file-upload-border-radius, 6px);
  transition: background-color border-color 0.2s;

  & > label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:
      var(--file-upload-dropzone-vertical-padding, var(--core-metrics-spacing-400))
      var(--file-upload-dropzone-horizontal-padding, var(--core-metrics-spacing-400));
    text-align: center;
    cursor: pointer;

    .market-icon {
      color: var(--file-upload-label-fill-color, var(--core-text-10-color));
    }
  }

  /* Overriding market-row margin since it overflows the container natively */
  & > .market-row {
    margin: 0;
  }
}

button,
.market-button,
::slotted(button),
::slotted(.market-button),
::slotted(.market-link) {
  @extend %semibold-30;

  padding: 0;

  /* removes the default button styling */
  border: none;
  background-color: inherit;
  color: var(--file-upload-button-label-color, var(--button-normal-variant-tertiary-rank-normal-state-label-color));
  font-family: inherit;
  text-align: right;
  cursor: pointer;
}

.has-file-selected {
  /* stylelint-disable-next-line declaration-no-important */
  border-style: var(--file-upload-file-selected-state-border-style, solid) !important;
  border-color: var(--file-upload-file-selected-state-border-color, var(--core-fill-30-color));
}

@media (hover: hover) {
  :host(:hover) .label-container {
    border-style: var(--file-upload-hover-state-border-style, dashed);
    border-color: var(--file-upload-hover-state-border-color, var(--core-emphasis-fill-color));
    background-color: var(--file-upload-hover-state-background-color, var(--core-emphasis-40-color));
  }
}

:host(:active) {
  .label-container,
  .is-dragging-over {
    border-style: var(--file-upload-active-state-border-style, dashed);
    border-color: var(--file-upload-active-state-border-color, var(--core-emphasis-fill-color));
    background-color: var(--file-upload-active-state-background-color, var(--core-emphasis-30-color));
  }
}

:host([disabled]) {
  .label-container {
    border-style: var(--file-upload-disabled-state-border-style, dashed);
    border-color: var(--file-upload-disabled-state-border-color, var(--field-disabled-state-border-color));
    background-color: var(--file-upload-disabled-state-background-color, var(--field-disabled-state-background-color));
    color: var(--file-upload-disabled-state-label-color, var(--field-disabled-state-empty-phase-label-color));
  }

  button,
  ::slotted(button),
  ::slotted(.market-button),
  ::slotted(.market-icon),
  ::slotted(.market-link),
  label .market-icon,
  label ::slotted(.market-icon) {
    opacity: 30%;
  }
}

/* FILE ROWS */

.market-list {
  margin-top: var(--file-upload-dropzone-vertical-margin, var(--core-metrics-spacing-100));
}

/* leading accessory */
.market-accessory[slot="leading-accessory"] {
  border-radius: var(--file-upload-border-radius, 6px);
  background-color: var(--file-upload-leading-accessory-background-color, var(--core-fill-40-color));
}

/* trailing accessory */
.market-accessory[slot="trailing-accessory"] {
  cursor: pointer;

  .market-icon {
    color: var(--file-upload-delete-fill-color, var(--core-fill-20-color));
  }
}

/*  error/success/loading rows */
[data-status]:not([data-status=""]) .market-accessory[slot="leading-accessory"] {
  background-color: unset;
}

[data-status="error"] [slot="subtext"],
[data-status="error"] .market-accessory[slot="leading-accessory"] {
  color: var(--file-upload-leading-accessory-error-color, var(--core-critical-fill-color));
}

:host([disabled]) [data-status="error"] [slot="subtext"] {
  opacity: 30%;
}

[data-status="success"] .market-accessory[slot="leading-accessory"] {
  color: var(--file-upload-leading-accessory-success-color, var(--core-success-fill-color));
}

[data-status="loading"] .market-accessory[slot="leading-accessory"] {
  animation: market-file-upload-loading-rotation 1s infinite linear;
}

@keyframes market-file-upload-loading-rotation {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* DEPRECATED FEATURES / SLOTS */

:host([invalid]) {
  .label-container {
    border-color: var(--field-normal-state-invalid-validity-border-color);
  }
}

::slotted([slot="bottom-accessory"]) {
  color: var(--core-text-20-color);
}
