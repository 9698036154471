import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import {
  KEY_INVOICES_PLUGIN_ENABLED,
  KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED,
} from 'src/stores/FeatureFlagStore';
import WebsiteSettingRow from 'src/components/WebsiteSettingRow/WebsiteSettingRow';
import AppointmentIcon from 'src/svgs/AppointmentIcon';
import GlobeIcon from 'src/svgs/GlobeIcon';
import DollarSignOnPaperIcon from 'src/svgs/DollarSignOnPaperIcon';
import ProjectIcon from 'src/svgs/ProjectIcon';
import ContractIcon from 'src/svgs/ContractIcon';
import {
  getBookingChannelsUrl,
  getBuyerBookingFlowUrl,
  getSquareOnlineUrl,
} from 'src/utils/url';
import './SurfacesList.scss';

const SurfacesList = observer(() => {
  const { user, featureFlag, settings, event, navigation } =
    useMessengerControllerContext();
  const { t } = useTranslation();

  const {
    pluginEnabledOnAppts,
    userPluginEnabledOnAppts,
    setUserPluginEnabledOnAppts,
    sqOnlineSettings,
    setUserPluginEnabledOnSqOnlineSite,
    pluginEnabledOnInvoices,
    userPluginEnabledOnInvoices,
    setUserPluginEnabledOnInvoices,
    pluginEnabledOnEstimates,
    userPluginEnabledOnEstimates,
    setUserPluginEnabledOnEstimates,
    pluginEnabledOnProjects,
    userPluginEnabledOnProjects,
    setUserPluginEnabledOnProjects,
    pluginEnabledOnContracts,
    userPluginEnabledOnContracts,
    setUserPluginEnabledOnContracts,
    hasSqOnlineSettings,
    canViewSqOnlineSettings,
    canEnableOnlineBookingSite,
    canEnablePluginOnAppts,
    userPluginEnabledOnSqOnlineSite,
    isOnlineBookingSiteSurfaceShown,
    isInvoiceSurfaceShown,
    isEstimateSurfaceShown,
    isProjectSurfaceShown,
    isContractSurfaceShown,
  } = settings;

  const hasSingleSqOnlineSite =
    hasSqOnlineSettings && sqOnlineSettings.length === 1;

  const logClickTextUsToggle = (enabled: boolean, surface: string): void => {
    event.track('Click Text Us Toggle', {
      enabled,
      setting_source: 'messages_settings',
      surface,
    });
  };

  const onUserPluginEnabledOnApptsChanged = (enabled: boolean): void => {
    setUserPluginEnabledOnAppts(enabled);
    logClickTextUsToggle(enabled, 'APPOINTMENTS_BOOKING_SITE');
  };

  const onUserPluginEnabledOnSqOnlineSiteChanged = (enabled: boolean): void => {
    setUserPluginEnabledOnSqOnlineSite(sqOnlineSettings[0], enabled);
    logClickTextUsToggle(enabled, 'SQUARE_ONLINE_SITE');
  };

  const onUserPluginEnabledOnInvoicesChanged = (enabled: boolean): void => {
    setUserPluginEnabledOnInvoices(enabled);
    logClickTextUsToggle(enabled, 'INVOICE');
  };

  const onUserPluginEnabledOnEstimatesChanged = (enabled: boolean): void => {
    setUserPluginEnabledOnEstimates(enabled);
    logClickTextUsToggle(enabled, 'ESTIMATE');
  };

  const onUserPluginEnabledOnProjectsChanged = (enabled: boolean): void => {
    setUserPluginEnabledOnProjects(enabled);
    logClickTextUsToggle(enabled, 'PROJECT');
  };

  const onUserPluginEnabledOnContractsChanged = (enabled: boolean): void => {
    setUserPluginEnabledOnContracts(enabled);
    logClickTextUsToggle(enabled, 'CONTRACT');
  };

  const apptsRow = (
    <WebsiteSettingRow
      selected={userPluginEnabledOnAppts ?? pluginEnabledOnAppts.boolValue}
      onMarketRowSelected={() => onUserPluginEnabledOnApptsChanged(true)}
      onMarketRowDeselected={() => onUserPluginEnabledOnApptsChanged(false)}
      dataTestId="MessagesPluginSettings__appts-row"
      icon={<AppointmentIcon />}
      websiteLabel={t('MessagesPluginSettings.surfaces.appts_title')}
      description={
        featureFlag.get(KEY_INVOICES_PLUGIN_ENABLED)
          ? undefined
          : t('MessagesPluginSettings.surfaces.appts_description')
      }
      url={getBuyerBookingFlowUrl(user.merchantToken)}
    />
  );

  const sqOnlineRow = (
    <WebsiteSettingRow
      selected={
        hasSingleSqOnlineSite &&
        (userPluginEnabledOnSqOnlineSite?.pluginEnabled?.boolValue ??
          sqOnlineSettings[0].pluginEnabled?.boolValue)
      }
      onMarketRowSelected={
        hasSingleSqOnlineSite
          ? () => onUserPluginEnabledOnSqOnlineSiteChanged(true)
          : undefined
      }
      onMarketRowDeselected={
        hasSingleSqOnlineSite
          ? () => onUserPluginEnabledOnSqOnlineSiteChanged(false)
          : undefined
      }
      // The 'drill' variant doesn't work with onMarketRowSelected/onMarketRowDeselected, and onClick should be used instead
      onClick={
        !hasSingleSqOnlineSite
          ? () => {
              event.track('Click Into Text Us Multiple Websites');
              navigation.sheet.navigateTo('SQ_ONLINE_SETTINGS');
            }
          : undefined
      }
      icon={<GlobeIcon />}
      websiteLabel={
        hasSingleSqOnlineSite
          ? t('MessagesPluginSettings.surfaces.sq_online_title_single')
          : t('MessagesPluginSettings.surfaces.sq_online_title_multiple')
      }
      description={
        !featureFlag.get(KEY_INVOICES_PLUGIN_ENABLED) || !hasSingleSqOnlineSite
          ? t('MessagesPluginSettings.surfaces.sq_online_description')
          : undefined
      }
      url={
        hasSingleSqOnlineSite && sqOnlineSettings[0].url
          ? getSquareOnlineUrl(sqOnlineSettings[0].url)
          : undefined
      }
      variant={hasSingleSqOnlineSite ? 'regular' : 'drill'}
      dataTestId="MessagesPluginSettings__sq-online-row"
    />
  );

  if (featureFlag.get(KEY_INVOICES_PLUGIN_ENABLED)) {
    return (
      <>
        <h5 className="SurfacesList__title">
          {t('MessagesPluginSettings.surfaces.title')}
        </h5>
        {isOnlineBookingSiteSurfaceShown && apptsRow}
        {canViewSqOnlineSettings && sqOnlineRow}
        {isInvoiceSurfaceShown && (
          <WebsiteSettingRow
            dataTestId="SurfacesList__invoices"
            selected={
              userPluginEnabledOnInvoices ?? pluginEnabledOnInvoices.boolValue
            }
            onMarketRowSelected={() =>
              onUserPluginEnabledOnInvoicesChanged(true)
            }
            onMarketRowDeselected={() =>
              onUserPluginEnabledOnInvoicesChanged(false)
            }
            icon={<DollarSignOnPaperIcon />}
            websiteLabel={t('MessagesPluginSettings.surfaces.invoice.title')}
          />
        )}
        {isEstimateSurfaceShown && (
          <WebsiteSettingRow
            dataTestId="SurfacesList__estimates"
            selected={
              userPluginEnabledOnEstimates ?? pluginEnabledOnEstimates.boolValue
            }
            onMarketRowSelected={() =>
              onUserPluginEnabledOnEstimatesChanged(true)
            }
            onMarketRowDeselected={() =>
              onUserPluginEnabledOnEstimatesChanged(false)
            }
            icon={<DollarSignOnPaperIcon />}
            websiteLabel={t('MessagesPluginSettings.surfaces.estimate.title')}
          />
        )}
        {isProjectSurfaceShown && (
          <WebsiteSettingRow
            dataTestId="SurfacesList__projects"
            selected={
              userPluginEnabledOnProjects ?? pluginEnabledOnProjects.boolValue
            }
            onMarketRowSelected={() =>
              onUserPluginEnabledOnProjectsChanged(true)
            }
            onMarketRowDeselected={() =>
              onUserPluginEnabledOnProjectsChanged(false)
            }
            icon={<ProjectIcon />}
            websiteLabel={t('MessagesPluginSettings.surfaces.project.title')}
          />
        )}
        {isContractSurfaceShown && (
          <WebsiteSettingRow
            dataTestId="SurfacesList__contracts"
            selected={
              userPluginEnabledOnContracts ?? pluginEnabledOnContracts.boolValue
            }
            onMarketRowSelected={() =>
              onUserPluginEnabledOnContractsChanged(true)
            }
            onMarketRowDeselected={() =>
              onUserPluginEnabledOnContractsChanged(false)
            }
            icon={<ContractIcon />}
            websiteLabel={t('MessagesPluginSettings.surfaces.contract.title')}
          />
        )}
      </>
    );
  }

  const onClickEnableOnlineBooking = (): void => {
    event.track('Click Enable Online Booking from Text Us');
  };

  return (
    <>
      <h5 className="SurfacesList__title">
        {t('MessagesPluginSettings.surfaces.title')}
      </h5>
      {featureFlag.get(KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED) &&
        canEnableOnlineBookingSite && (
          <WebsiteSettingRow
            icon={<AppointmentIcon />}
            websiteLabel={t('MessagesPluginSettings.surfaces.appts_title')}
            description={t(
              'MessagesPluginSettings.enable_online_booking_description',
            )}
            url={getBookingChannelsUrl}
            urlLabel={t(
              'MessagesPluginSettings.enable_online_booking_button_label',
            )}
            urlOnClick={onClickEnableOnlineBooking}
            toggleDisabled
            dataTestId="MessagesPluginSettings__enable-online-booking-row"
          />
        )}
      {canEnablePluginOnAppts && apptsRow}
      {canViewSqOnlineSettings && sqOnlineRow}
    </>
  );
});

export default SurfacesList;
