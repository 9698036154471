@import "../../styles/mixins/component-base.css";
@import "../../styles/animations.css";

:host {
  /* TODO: add design tokens */
  --input-search-accessory-height: 48px;
  --input-search-accessory-width: 48px;
  --input-search-animation-enter-transition-duration: var(--core-animation-enter-transition-moderate-speed-duration);
  --input-search-animation-enter-transition-easing: var(--core-animation-enter-transition-easing);
  --input-search-animation-exit-transition-duration: var(--core-animation-exit-transition-fast-speed-duration);
  --input-search-animation-exit-transition-easing: var(--core-animation-exit-transition-easing);
  --input-search-background-color: var(--core-surface-5-color);
  --input-search-border-radius: 6px;
  --input-search-border-size: 1px;
  --input-search-clear-button-fill: var(--core-text-20-color);
  --input-search-clear-button-hover-state-fill: var(--core-fill-10-color);
  --input-search-compact-state-hover-state-background-color: var(--core-fill-40-color);
  --input-search-compact-state-hover-state-border-color: var(--core-fill-30-color);
  --input-search-compact-state-max-width: 40px;
  --input-search-disabled-state-border-color: var(--core-fill-40-color);
  --input-search-disabled-state-text-color: var(--core-text-20-color);
  --input-search-focused-state-border-color: var(--core-fill-10-color);
  --input-search-height: 48px;
  --input-search-hover-state-border-color: var(--core-fill-20-color);
  --input-search-icon-color: var(--core-text-10-color);
  --input-search-normal-state-border-color: var(--core-fill-30-color);
  --input-search-secondary-button-disabled-state-color: var(--core-text-30-color);
  --input-search-small-size-accessory-height: 40px;
  --input-search-small-size-accessory-width: 40px;
  --input-search-small-size-compact-state-max-width: 40px;
  --input-search-small-size-height: 40px;
  --input-search-small-size-text-size: 14px;
  --input-search-text-color: var(--core-text-10-color);
  --input-search-text-font-family: var(--square-sans-text);
  --input-search-text-leading: 24px;
  --input-search-text-placeholder-color: var(--core-text-30-color);
  --input-search-text-size: 16px;
  --input-search-text-weight: var(--core-type-regular-weight);

  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  border: var(--input-search-border-size) solid var(--input-search-normal-state-border-color);
  border-radius: var(--input-search-border-radius);
  background-color: var(--input-search-background-color);
  color: var(--input-search-text-color);
  font-weight: var(--input-search-text-weight);
  font-family: var(--input-search-text-font-family);
  line-height: var(--input-search-text-leading);
  cursor: text;
  transition:
    flex
    var(--input-search-animation-enter-transition-duration)
    var(--input-search-animation-enter-transition-easing);
}

/**
 * Disables unintended transitions on page load
 * https://css-tricks.com/transitions-only-after-page-load/
 */
:host(.preload) {
  transition: none !important; /* stylelint-disable-line declaration-no-important */
}

:host(:hover) {
  border-color: var(--input-search-hover-state-border-color);
}

:host([focused]) {
  border-color: var(--input-search-focused-state-border-color);
}

:host([disabled]) {
  border-color: var(--input-search-disabled-state-border-color);
  color: var(--input-search-disabled-state-text-color);
  cursor: not-allowed;

  svg {
    fill: var(--input-search-secondary-button-disabled-state-color);
  }
}

:host([variant="medium" i]), /* @deprecated (v4.5.0) */
:host([size="medium" i]) {
  height: var(--input-search-height);
  font-size: var(--input-search-text-size);

  .leading-accessory,
  .leading-accessory .market-accessory,
  ::slotted([slot="trailing-accessory"]),
  .clear-button {
    width: calc(var(--input-search-accessory-width) - var(--input-search-border-size) * 2);
    height: calc(var(--input-search-accessory-height) - var(--input-search-border-size) * 2);
  }
}

:host([variant="medium" i][compact]:not([focused])), /* @deprecated (v4.5.0) */
:host([size="medium" i][compact]:not([focused])) {
  flex-basis: var(--input-search-compact-state-max-width);
}

:host([variant="small" i]), /* @deprecated (v4.5.0) */
:host([size="small" i]) {
  height: var(--input-search-small-size-height);
  font-size: var(--input-search-small-size-text-size);

  .leading-accessory,
  .leading-accessory .market-accessory,
  ::slotted([slot="trailing-accessory"]),
  .clear-button {
    width: calc(var(--input-search-small-size-accessory-width) - var(--input-search-border-size) * 2);
    height: calc(var(--input-search-small-size-accessory-height) - var(--input-search-border-size) * 2);
  }
}

:host([variant="small" i][compact]:not([focused])), /* @deprecated (v4.5.0) */
:host([size="small" i][compact]:not([focused])) {
  flex-basis: var(--input-search-small-size-compact-state-max-width);
}

/* Full compact mode */
:host([compact]:not([focused])) {
  flex: 0;
  cursor: pointer;
  transition:
    flex
    var(--input-search-animation-exit-transition-duration)
    var(--input-search-animation-exit-transition-easing);
  animation-name: market-input-search-compact-exit;
  animation-duration: var(--input-search-animation-exit-transition-duration);

  .leading-accessory {
    margin-right: 0;
    pointer-events: none;
  }

  ::slotted([slot="trailing-accessory"]),
  .clear-button {
    display: none;
  }
}

:host([compact]:not([focused]):hover) {
  border-color: var(--input-search-compact-state-hover-state-border-color);
  background-color: var(--input-search-compact-state-hover-state-background-color);
}

:host([compact][focused]) {
  animation-name: market-input-search-compact-enter;
  animation-duration: var(--input-search-animation-enter-transition-duration);
}

input::placeholder,
::slotted(input::placeholder),
:host([value=""]) input,
:host([value=""]) ::slotted(input) {
  color: var(--input-search-text-placeholder-color);
}

.leading-accessory,
::slotted([slot="trailing-accessory"]),
.clear-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 0;
  background: transparent;
}

.leading-accessory.is-back-icon,
.clear-button {
  cursor: pointer;
}

.clear-button {
  svg {
    fill: var(--input-search-clear-button-fill);
  }

  &:hover svg {
    fill: var(--input-search-clear-button-hover-state-fill);
  }

  &.hidden {
    display: none;
  }
}

svg {
  fill: var(--input-search-icon-color);
}

.input-container {
  flex: 1;
}

input,
::slotted(input) {
  width: 100%;
  height: var(--input-search-text-leading);
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
}

input:focus,
::slotted(input:focus) {
  outline: none;
}

input[disabled],
::slotted(input[disabled]) {
  color: var(--input-search-disabled-state-text-color);
}

:host(:not([value=""])) ::slotted([slot="trailing-accessory"]) {
  display: none;
}

/**
 * Overwrite autofocus background. Setting background-color does not currently work.
 * !important is necessary
 * https://stackoverflow.com/a/14205976
 */
input:-webkit-autofill,
::slotted(input:-webkit-autofill) {
  /* stylelint-disable declaration-no-important */
  box-shadow: 0 0 0 var(--input-search-text-leading) var(--input-search-background-color) inset !important;
  -webkit-text-fill-color: var(--input-search-text-color) !important;
  /* stylelint-enable declaration-no-important */
  caret-color: var(--input-search-text-color);
}

/**
 * These animations are part of a hack that allows us to detect browser autofill:
 * https://stackoverflow.com/a/41530164
 */
:host input:-webkit-autofill,
:host ::slotted(input:-webkit-autofill) {
  animation-name: market-input-autofill-start;
}

:host input:not(:-webkit-autofill),
:host ::slotted(input:not(:-webkit-autofill)) {
  animation-name: market-input-autofill-cancel;
}
