@import "../../styles/mixins/component-base.css";
@import "../../styles/mixins/link.css";

/* below styles implement the "medium" size text link */

:host {
  --transition-duration: 0.2s;

  color: var(--text-link-normal-variant-color);
  font-weight: var(--text-link-medium-size-text-weight);
  font-size: inherit;
  line-height: inherit;
  letter-spacing: var(--text-link-medium-size-text-tracking);
  cursor: pointer;

  a,
  a:link,
  a:visited,
  a:hover,
  a:active,
  button {
    color: inherit;
    font-family: inherit;
    text-decoration: none;
    transition: color var(--transition-duration);
  }

  a,
  a:link,
  a:visited,
  button {
    opacity: var(--text-link-normal-state-opacity);
  }

  a:hover,
  button:hover,
  a:focus,
  button:focus {
    color: var(--core-blue-20-color); /* TODO make component specific token */
    text-decoration: underline;
  }

  a:active,
  button:active {
    color: var(--core-blue-10-color); /* TODO make component specific token */
  }

  &([highlight="underline"]) a,
  &([highlight="underline"]) button, {
    display: inline-block; /* prevent underline from spilling into blank space */
    text-decoration: underline;
  }

  &([highlight="underline"]) a:hover,
  &([highlight="underline"]) a:focus,
  &([highlight="underline"]) button:hover,
  &([highlight="underline"]) button:focus, {
    color: var(--core-blue-20-color); /* TODO make component specific token */
    text-decoration: none;
  }

  &([aria-disabled]) a,
  &([aria-disabled]) button {
    opacity: var(--text-link-disabled-state-opacity);
  }

  &([destructive]) {
    color: var(--text-link-destructive-variant-color);
  }

  button {
    padding: 0;
    border: none;
    background: none;
    font: inherit;
    text-align: inherit;
    cursor: pointer;
  }

  a,
  button {
    @extend %link-focus-ring;
  }
}
