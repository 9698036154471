import { Component, Element, Event, EventEmitter, Host, h, Listen, Method, Prop } from '@stencil/core';

import { TMarketActionCardDeselectedEventDetail, TMarketActionCardSelectedEventDetail } from './events';
import { getRowInActionCard } from './utils';

/**
 * @slot - Optimized for use w/ slotted `<market-row>`s but can take any content. All slotted `market-row`s will automatically be set to interactive mode.
 */
@Component({
  tag: 'market-action-card',
  styleUrl: 'market-action-card.css',
  shadow: true,
})
export class MarketActionCard {
  rowEl: HTMLMarketRowElement;

  @Element() el: HTMLMarketActionCardElement;

  /**
   * Whether the action card is selected or not.
   */
  @Prop({ mutable: true, reflect: true }) selected: boolean = false;

  /**
   * Visually and functionally disables the action card.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * A string specifying a value for the action card.
   */
  @Prop({ reflect: true }) readonly value: string;

  /**
   * When set to `true`, card will not persist selected state on click.
   */
  @Prop() readonly transient: boolean = false;

  /**
   * Fired whenever the action card is selected.
   */
  @Event({ bubbles: true, composed: true }) marketCardSelected: EventEmitter<TMarketActionCardSelectedEventDetail>;

  /**
   * Fired whenever the action card is deselected.
   */
  @Event({ bubbles: true, composed: true }) marketCardDeselected: EventEmitter<TMarketActionCardDeselectedEventDetail>;

  /**
   * When rows are slotted into cards, we want to catch their selection events
   * and emit our own, so that the containing `market-list` only gets one set
   * of selection events.
   */
  @Listen('marketRowSelected')
  handleRowSelection(e: CustomEvent) {
    this.select();
    // Prevent `marketRowSelected` from bubbling up to containing lists, since we expect
    // them to listen to our card selection events instead.
    e.stopPropagation();
  }

  /**
   * When rows are slotted into cards, we want to catch their selection events
   * and emit our own, so that the containing `market-list` only gets one set
   * of selection events.
   */
  @Listen('marketRowDeselected')
  handleRowDeselection(e: CustomEvent) {
    this.deselect();
    // Prevent `marketRowSelected` from bubbling up to containing lists, since we expect
    // them to listen to our card selection events instead.
    e.stopPropagation();
  }

  /**
   * Set `selected` to `true` and emit `marketCardSelected`. Generally speaking,
   * it is preferable to avoid using this method from outside this component
   * and allow `market-action-card` to manage its own selection state based on user
   * interaction. It should only be used for parent components that need to
   * manage a group of rows, such as `market-list`.
   */
  @Method()
  async select() {
    this.selected = true;
    await this.rowEl?.silentlySelect();
    const { defaultPrevented } = this.marketCardSelected.emit({ value: this.value });
    if (defaultPrevented) {
      this.selected = false;
      await this.rowEl?.silentlyDeselect();
    }
  }

  /**
   * Set `selected` to `false` and emit `marketCardDeselected`. Generally speaking,
   * it is preferable to avoid using this method from outside this component
   * and allow `market-action-card` to manage its own selection state based on user
   * interaction. It should only be used for parent components that need to
   * manage a group of rows, such as `market-list`.
   */
  @Method()
  async deselect() {
    this.selected = false;
    await this.rowEl?.silentlyDeselect();
    const { defaultPrevented } = this.marketCardDeselected.emit({ value: this.value });
    if (defaultPrevented) {
      this.selected = true;
      await this.rowEl?.silentlySelect();
    }
  }

  /**
   * Used for setting the selection state to true without emitting events.
   * Useful for scenarios where another component (ex. `<market-list>`) needs
   * to sync state with slotted `<market-action-card>`s.
   */
  @Method()
  async silentlySelect() {
    this.selected = true;
    await this.rowEl?.silentlySelect();
    return Promise.resolve();
  }

  /**
   * Set `selected` to `false`. Generally speaking,
   * it is preferable to avoid using this method from outside this component
   * and allow `market-action-card` to manage its own selection state based on user
   * interaction. It should only be used for parent components that need to
   * manage a group of rows, such as `market-list`.
   */
  @Method()
  async silentlyDeselect() {
    this.selected = false;
    await this.rowEl?.silentlyDeselect();
    return Promise.resolve();
  }

  isContentEditable(el) {
    // check whether element (Market or HTML) accepts text input
    const inputTagnames = ['input', 'textarea'];
    return inputTagnames.some((str) => el.tagName.includes(str)) || el.isContentEditable;
  }

  handleClick(e) {
    // clicks to text inputs should not select action card
    if (this.isContentEditable(e.target)) {
      return;
    }

    // Rows handle selected state when slotted. The only way you can click directly on
    // the card is by clicking the border, and we want to just ignore that edge case.
    if (this.disabled || this.transient || this.rowEl) {
      return;
    }

    if (!this.selected) {
      this.select();
    } else {
      this.deselect();
    }
  }

  handleKeydown(e: KeyboardEvent) {
    // user should be able to type normally in text inputs
    if (this.isContentEditable(e.target)) {
      return;
    }

    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault(); // prevents scroll down when Space is pressed
      if (this.rowEl) {
        this.rowEl.click();
      } else {
        this.el.click();
      }
    }
  }

  syncRowAttributes() {
    if (!this.rowEl) {
      return;
    }
    this.rowEl.interactive = true;
    this.rowEl.selected = this.selected;
    this.rowEl.removeAttribute('tabIndex');
  }

  handleSlotChangeDefault() {
    this.rowEl = getRowInActionCard(this.el);
    this.el.classList.toggle('has-slotted-row', Boolean(this.rowEl));
    this.syncRowAttributes();
  }

  componentDidRender() {
    // slotted rows inside action cards should not be able to receive focus because
    // they are controlled by interaction w/ the action card
    if (this.rowEl) {
      this.rowEl.removeAttribute('tabIndex');
    }
  }

  render() {
    return (
      <Host
        aria-selected={this.selected}
        class="market-action-card"
        onClick={this.handleClick.bind(this)}
        onKeydown={this.handleKeydown.bind(this)}
        role="option"
        tabindex={this.disabled ? null : '0'}
      >
        <slot onSlotchange={() => this.handleSlotChangeDefault()}></slot>
      </Host>
    );
  }
}
