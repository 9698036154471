import { Component, Host, Element, Prop, State, Event, EventEmitter, Watch, Method, h } from '@stencil/core';

/**
 * @slot - Default slot for all content
 */
@Component({
  tag: 'market-table-column',
  styleUrl: 'market-table-column.css',
  shadow: true,
})
export class MarketTableColumn {
  @Element() el: HTMLMarketTableColumnElement;

  // ----------- Consumer-defined props -------------

  /**
   * **REQUIRED:** A unique key for this column, used to map all related cells together
   */
  @Prop({ mutable: false, reflect: true }) readonly name: string;

  /**
   * Will set the `align` property on all related cells to this value
   */
  @Prop({ mutable: false, reflect: false }) readonly align: false | 'left' | 'right' = false;

  /**
   * Makes this column stick to an edge of the table
   */
  @Prop({ mutable: true, reflect: true }) stickTo: 'left' | 'right';

  /**
   * Whether or not this column is sortable
   */
  @Prop({ mutable: false, reflect: true }) readonly sortable: boolean = false;

  /**
   * What order the column is sorting in; ascending points up and descending points down
   * If the column is sortable and this prop is not present, the sort icon points up but is grayed out.
   */
  @Prop({ reflect: true }) readonly sortOrder: 'ascending' | 'descending';

  /**
   * Hides the column and all related cells.
   */
  @Prop({ mutable: false, reflect: true }) readonly hidden: boolean = false;

  // --------------- Internal props -----------------

  /**
   * **INTERNAL [do not use directly]**
   * The order of this market-table-column in the DOM
   */
  @Prop({ mutable: false, reflect: false }) readonly index: number = 0;

  /**
   * **INTERNAL [do not use directly]**
   * Set by the parent table element, based on it's grid-template-columns CSS value
   */
  @Prop({ mutable: false, reflect: false }) readonly width: string;

  /**
   * The original slot this column was placed in
   */
  @State() originalSlot: string;

  /**
   * Emitted when this column is stuck to a table edge.
   * Can happen when the stick-to property is updated, .stick() method is called
   * or when slotted content in the parent table changes
   */
  @Event() marketTableColumnStick: EventEmitter<{
    position: 'left' | 'right';
    index: number;
  }>;

  /**
   * Emitted when a table column heading is clicked to sort
   */
  @Event() marketTableColumnUnstick: EventEmitter<{
    position: 'left' | 'right';
    index: number;
  }>;

  /**
   * Emitted when a table column heading is clicked to sort
   */
  @Event() marketTableColumnSort: EventEmitter<{
    column: string;
    previousSortOrder: 'ascending' | 'descending';
  }>;

  /**
   * Emitted when a table column's hidden property changes. Used by market-table
   * to toggle visibility of related table cells.
   */
  @Event() marketTableColumnVisibilityChange: EventEmitter<{
    columnName: string;
    hidden: boolean;
  }>;

  @Watch('hidden')
  emitVisibilityChangeEvent(newValue: boolean, oldValue: boolean) {
    if (newValue !== oldValue) {
      this.marketTableColumnVisibilityChange.emit({
        columnName: this.name,
        hidden: newValue,
      });
    }
  }

  @Watch('stickTo')
  async emitStickyEvents(newValue: 'left' | 'right', oldValue: 'left' | 'right') {
    if (newValue && newValue !== oldValue) {
      await this.stick(newValue);
    } else {
      this.unstick(newValue);
    }
  }

  /**
   * Fixes this column to the provided table edge (position)
   */
  @Method()
  stick(position?: 'left' | 'right') {
    /* If our position is different than out current stickTo value,
    then we are probably calling stick() directly from javascript, rather
    than having it be triggered from the stickTo watcher, so we want to
    update that value, which will call this function again, the values will match
    and then the event will be emitted */
    if (position !== this.stickTo) {
      this.stickTo = position;

      /* Otherwise this function has been triggered from the stickTo watcher
    by someone updating the bound property so we want to emit the event */
    } else {
      this.marketTableColumnStick.emit({
        position,
        index: this.index,
      });
    }

    return Promise.resolve();
  }

  /**
   * Un-fixes this column from any table edge
   */
  @Method()
  unstick(position?: 'left' | 'right') {
    this.marketTableColumnUnstick.emit({
      position,
      index: this.index,
    });
    return Promise.resolve();
  }

  /**
   * **INTERNAL [do not use directly]**
   * Moves this column into a slot inside the market-table-area
   * which is fixed to the provided edge (position), allowing
   * for fixed columns
   */
  @Method()
  _stickSelf(position: 'left' | 'right') {
    if (position) {
      this.el.slot = `sticky-${position}`;
    }
    this.stickTo = position;
    return Promise.resolve();
  }

  /**
   * **INTERNAL [do not use directly]**
   * Moves this column back into it's original slot from a slot
   * within a fixed market-table-area
   */
  @Method()
  _unstickSelf() {
    if (!this.originalSlot) {
      this.el.removeAttribute('slot');
    } else {
      this.el.slot = this.originalSlot;
    }
    this.el.removeAttribute('stick-to');
    return Promise.resolve();
  }

  clickHandler() {
    if (this.sortable) {
      this.marketTableColumnSort.emit({
        column: this.name,
        previousSortOrder: this.sortOrder,
      });
    }
  }

  componentWillLoad() {
    this.originalSlot = this.el.getAttribute('slot');

    if (!this.name) {
      console.warn('Please set a name on <market-table-column>. Tables may not work correctly without this.'); // eslint-disable-line no-console
    }
  }

  componentDidLoad() {
    // stickTo watcher does not fire on first component load
    if (this.stickTo) {
      this.stick(this.stickTo);
    }
  }

  render() {
    return (
      <Host
        class="market-table-column"
        align={this.align === 'right' && this.align}
        role="columnheader"
        aria-sort={this.sortOrder}
        onClick={() => this.clickHandler()}
      >
        <slot name="leading-accessory"></slot>
        {this.sortable && (
          // A11Y: This button allows screen readers to access and click on the column header.
          <button>
            <slot></slot>
            <svg
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="sorting-caret"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.70709 14.7071C8.31657 15.0976 7.6834 15.0976 7.29288 14.7071L0.292879 7.7071L1.70709 6.29289L6.99999 11.5858L6.99999 -7.612e-07L8.99999 -5.86354e-07L8.99999 11.5858L14.2929 6.29289L15.7071 7.70711L8.70709 14.7071Z"
              />
            </svg>
          </button>
        )}

        {!this.sortable && <slot></slot>}
        <slot name="trailing-accessory"></slot>
      </Host>
    );
  }
}
