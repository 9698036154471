import React, {
  ReactElement,
  useRef,
  useEffect,
  useState,
  RefObject,
  createRef,
  useCallback,
} from 'react';
import { observer } from 'mobx-react';
import { Utterance } from 'src/gen/squareup/messenger/v3/messenger_service';
import {
  TranscriptViewItem as TranscriptViewItemType,
  LocalUtterance,
} from 'src/MessengerTypes';
import {
  marketComponentsOnReady,
  scrollElementTo,
} from 'src/utils/renderUtils';
import TranscriptViewItem from 'src/pages/TranscriptViewPage/components/TranscriptViewItem/TranscriptViewItem';
import { PHOTOS_INTERSECTION_OBSERVER_ROOT_ID } from 'src/utils/photoUtils';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { areItemsEqual } from 'src/utils/viewItemUtils';
import Suggestions from 'src/pages/TranscriptViewPage/components/Suggestions/Suggestions';
import { MESSAGES_MARKET_COMPONENT_PREFIX } from 'src/components/Market';
import GeneralEventBanner from 'src/pages/TranscriptViewPage/components/GeneralEventBanner/GeneralEventBanner';
import AppointmentEventBanner from 'src/pages/TranscriptViewPage/components/AppointmentEventBanner/AppointmentEventBanner';
import CustomerDetailEventBanner from 'src/pages/TranscriptViewPage/components/CustomerDetailEventBanner/CustomerDetailEventBanner';
import AddCustomerBanner from 'src/pages/TranscriptViewPage/components/AddCustomerBanner/AddCustomerBanner';
import JumpToBottomButton from './components/JumpToBottomButton/JumpToBottomButton';
import LoadMoreIndicator from 'src/pages/TranscriptViewPage/components/TranscriptViewItemsList/components/LoadMoreIndicator/LoadMoreIndicator';
import { mark, measure, PerformanceEvent } from 'src/utils/performanceTracking';
import './TranscriptViewItemsList.scss';

/**
 * When the current scroll position to the edge of the content is less than this amount in pixels,
 * getPrevPage() or getNextPage() will be triggered to load more utterances.
 */
const SCROLL_TO_LOAD_MORE = 100;

/**
 * When the user has scrolled at least this many pixels up the chat, we show a button
 * to allow user to jump to the bottom. This has been set to allow for multi-line
 * messages or larger integrations (i.e. coupons) that may take up more space.
 */
const BUFFER_SHOW_JUMP_BUTTON = 300;

/**
 * Pixel buffer to reduce the precision at which we determine the scrollbar to be
 * at the bottom of the transcript.
 */
const BUFFER_SCROLL_AT_BOTTOM = 200;

/**
 * The primary Market component that defines the height of this component.
 */
const MAIN_MARKET_COMPONENT_TAG = `${MESSAGES_MARKET_COMPONENT_PREFIX}-market-content-card`;

export type TranscriptViewItemsListProps = {
  photoInputRef: RefObject<HTMLInputElement>;
};

/**
 * This component renders a set of utterances and events of a transcript from
 * a list of view items. It also renders the medium headers that
 * separate sets of utterances by medium and time. Contextual events
 * will be rendered with the utterances based on a timestamp, usually when
 * they occur.
 *
 * @example <TranscriptViewItemsList />
 * @param {RefObject} photoInputRef
 * A ref to the photo input used to upload photos.
 */
const TranscriptViewItemsList = observer(
  ({ photoInputRef }: TranscriptViewItemsListProps): ReactElement => {
    const { transcriptView, navigation } = useMessengerControllerContext();
    const { transcript, message, setMessage, requestReview, isInputDisabled } =
      transcriptView;

    // Tracks whether the scroll is far up enough to show the jump to bottom button.
    const [showJumpButton, setShowJumpButton] = useState(false);

    // Reference to the list container component
    const listRef = useRef<HTMLDivElement>(null);
    // References to all the individual view items, for seeking purposes
    const itemRefs = useRef<Record<number, RefObject<HTMLDivElement>>>({});
    // Tracks the previous last item, i.e. most recent item, to determine if there
    // are new messages when updating, so that we can control the scrolling behavior
    const prevLastItem = useRef<TranscriptViewItemType | undefined>(undefined);
    // Tracks the height of the list, for resizing scrolling behavior
    const listHeight = useRef(0);
    // Tracks if the browser page is active.
    const isPageActive = useRef(true);

    /**
     * Computes whether the scroll is at the bottom of the list.
     */
    const getIsScrollAtBottom = async (): Promise<boolean> => {
      if (listRef.current) {
        const listElement = await marketComponentsOnReady(
          listRef.current,
          MAIN_MARKET_COMPONENT_TAG,
        );
        const spaceFromBottom =
          listElement.scrollHeight -
          listElement.scrollTop -
          listElement.offsetHeight;

        // Check if we are at the bottom of page
        return spaceFromBottom < BUFFER_SCROLL_AT_BOTTOM;
      }
      return false;
    };

    /**
     * Wait for Market components to hydrate and scroll to the bottom of the list.
     *
     * @param {boolean} [smooth]
     * Set to true to scroll with animation.
     */
    const scrollListToBottom = async (smooth?: boolean): Promise<void> => {
      if (listRef.current) {
        const listElement = await marketComponentsOnReady(
          listRef.current,
          MAIN_MARKET_COMPONENT_TAG,
        );

        scrollElementTo(listElement, listElement.scrollHeight, smooth);
        setShowJumpButton(false);
      }
    };

    /**
     * Gets more items by calling loadPrevPage() and handles the scrolling
     * after the promise returns. This can be called either when scrolling to the top,
     * or via retry button when it previously failed (e.g offline)
     */
    const getPrevItems = useCallback(async (): Promise<void> => {
      if (transcript.loadPrevStatus !== 'LOADING' && listRef.current) {
        const oldHeight = listRef.current.scrollHeight;
        if (transcript.hasPrevPage) {
          await transcript.loadPrevPage();

          /**
           * There was a scrolling bug where if the scroll is at the topmost edge,
           * it will be snapped to it, causing loadPrevPage() to be called infinitely
           * until all utterances in the transcript are loaded.
           *
           * To fix this, we manually set the scroll position to the last point before
           * loadPrevPage() is called, if the scroll is at the topmost edge.
           */
          if (
            listRef.current &&
            listRef.current.scrollTop === 0 &&
            transcript.loadPrevStatus !== 'ERROR'
          ) {
            const listElement = await marketComponentsOnReady(
              listRef.current,
              MAIN_MARKET_COMPONENT_TAG,
            );
            scrollElementTo(listElement, listElement.scrollHeight - oldHeight);
          }
        }
      }
    }, [transcript]);

    /**
     * Gets the next set of view items by making a paginated call to loadNextPage().
     */
    const getNextItems = useCallback(async (): Promise<void> => {
      await transcript.loadNextPage();
    }, [transcript]);

    /**
     * Performance measurement.
     */
    useEffect(() => {
      mark(PerformanceEvent.TRANSCRIPT_VIEW_ITEMS_LIST_SHOWN);
      measure(
        PerformanceEvent.FULL_PAGE_APP_LOADED,
        PerformanceEvent.TRANSCRIPT_VIEW_ITEMS_LIST_SHOWN,
      );
    }, []);

    /**
     * Handle scrolling when we change transcripts without seeking to an utterance.
     * While we read seekUtteranceId in this effect, we don't want to include it in the dependency
     * list because we only want to re-trigger this effect when the whole transcript changes.
     */
    useEffect(() => {
      if (!transcript.seekUtteranceId) {
        scrollListToBottom();
      }
    }, [transcript]);

    /**
     * Set up scrolling behavior for the list to check if we should try to load more utterances.
     */
    useEffect(() => {
      /**
       * This is called on scroll events for the list. It's responsible
       * for checking if we should try to load more utterances.
       */
      const onListScroll = async (): Promise<void> => {
        if (listRef.current) {
          const listElement = await marketComponentsOnReady(
            listRef.current,
            MAIN_MARKET_COMPONENT_TAG,
          );

          const spaceFromBottom =
            listElement.scrollHeight -
            listElement.scrollTop -
            listElement.offsetHeight;
          const shouldShowJumpButton =
            spaceFromBottom > BUFFER_SHOW_JUMP_BUTTON;

          // Only show jump button if user has scrolled past a buffer
          setShowJumpButton(shouldShowJumpButton);

          // Load more when we have less than SCROLL_TO_LOAD_MORE more to scroll
          // If the list has error (e.g. offline), it should show a retry button instead of triggering
          // this scrolling logic.
          if (
            listElement.scrollTop < SCROLL_TO_LOAD_MORE &&
            transcript.loadPrevStatus !== 'ERROR'
          ) {
            getPrevItems();
          }

          if (
            spaceFromBottom < SCROLL_TO_LOAD_MORE &&
            transcript.hasNextPage &&
            transcript.loadNextStatus !== 'LOADING' &&
            transcript.loadNextStatus !== 'ERROR'
          ) {
            getNextItems();
          }

          // Marks the transcript as read if we are not showing the jump button,
          // which includes the initial render of a transcript
          if (isPageActive.current && !shouldShowJumpButton) {
            transcriptView.markTranscriptAsReadIfUnread();
          }
        }
      };

      const listRefCurrent = listRef.current;
      listRefCurrent?.addEventListener('scroll', onListScroll);

      // Trigger on list scroll so that we can get more items in the case where the
      // vertical height of the view is very long.
      // requestAnimationFrame is needed here because there is a bug on iOS browsers
      // where the DOM is not updated with the view items yet on initial render, causing
      // the listElement's scrollTop to be incorrect.
      requestAnimationFrame(() => {
        onListScroll();
      });

      return () => {
        listRefCurrent?.removeEventListener('scroll', onListScroll);
      };
    }, [getNextItems, getPrevItems, transcript, transcriptView]);

    /**
     * If page is inactive, i.e. if user is on a different tab, we do not mark the open transcript as read.
     * When the user navigates back to this page/tab, we then mark it as read.
     */
    useEffect(() => {
      const onVisibilityChange = (): void => {
        if (document.visibilityState === 'hidden') {
          isPageActive.current = false;
        } else {
          isPageActive.current = true;
          transcriptView.markTranscriptAsReadIfUnread();
        }
      };

      document.addEventListener('visibilitychange', onVisibilityChange);

      return () => {
        document.removeEventListener('visibilitychange', onVisibilityChange);
      };
    }, [transcriptView]);

    /**
     * Manage scroll behavior when the window is being resized.
     */
    useEffect(() => {
      const resizeObserver = new ResizeObserver(
        async (entries): Promise<void> => {
          const isScrollAtBottom = await getIsScrollAtBottom();
          entries.forEach((entry) => {
            const newHeight = entry.target.clientHeight;
            if (
              newHeight !== listHeight.current &&
              listRef.current &&
              isScrollAtBottom
            ) {
              // If the scroll position of the list is close to the bottom, force the list to
              // scroll to the bottom when a list resize happens so that the latest utterance is always visible.
              scrollListToBottom();
            }
            listHeight.current = newHeight;
          });
        },
      );

      if (listRef.current) {
        // When the main list is being resized from these actions (non-exhausive):
        // 1. On screen keyboard appear on mobile web
        // 2. Input bar increases in number of lines as user types
        // 3. Input bar shows banner when no contact method or consent
        // We scroll the list down so that the bottom contents are always shown.
        resizeObserver.observe(listRef.current);
      }

      return () => {
        resizeObserver.disconnect();
      };
    }, []);

    /**
     * Handle behavior when there are new view items.
     */
    useEffect(() => {
      if (listRef.current) {
        // Handle scrolling when new items appear
        if (transcript.viewItems.length > 0) {
          // Get the most recent item
          const lastItem =
            transcript.viewItems[transcript.viewItems.length - 1];
          const lastItemSendStatus =
            (lastItem?.data as LocalUtterance)?.utterance?.sendStatus ||
            lastItem?.attachedUtterance?.utterance?.sendStatus;
          if (!areItemsEqual(lastItem, prevLastItem.current)) {
            // If the most recent item is a new item, manage the scrolling here.

            requestAnimationFrame(() => {
              getIsScrollAtBottom().then((isAtBottom) => {
                if (isAtBottom) {
                  scrollListToBottom(true);
                } else if (
                  lastItemSendStatus === Utterance.SendStatus.PENDING ||
                  lastItemSendStatus === Utterance.SendStatus.UPLOADING
                ) {
                  // If we are not at the bottom, and the user just send a new message,
                  // scroll to the bottom. This is done by checking if the latest is a
                  // local utterance with a PENDING status.

                  scrollListToBottom();
                }
              });
            });

            prevLastItem.current = lastItem;

            // Because there is a new item, we want to mark it as read if the transcript is still marked as unread.
            // Only mark as read if user is active on the Square Messages page and the jump button is not displayed
            // (i.e. we are at the bottom of the page to view the latest utterance)
            if (isPageActive.current && !showJumpButton) {
              transcriptView.markTranscriptAsReadIfUnread();
            }
          }
        }
      }
    }, [showJumpButton, transcript.viewItems, transcriptView]);

    /**
     * Handle scrolling when seek utterance changes.
     */
    useEffect(() => {
      /**
       * Scrolls the list to the utterance that was seeked to when opening the transcript.
       */
      const scrollListToSeekedUtterance = async (): Promise<void> => {
        if (!transcript.seekUtteranceId) {
          return;
        }

        if (listRef.current) {
          await marketComponentsOnReady(
            listRef.current,
            MAIN_MARKET_COMPONENT_TAG,
          );
        }

        itemRefs.current[
          transcript.seekUtteranceId as number
        ]?.current?.scrollIntoView?.({
          block: 'center',
        });
      };

      // If the selected transcript changed, always scroll to the bottom or the seeked utterance
      if (transcript.seekUtteranceId) {
        scrollListToSeekedUtterance();
      }
    }, [transcript.seekUtteranceId]);

    /**
     * Handle scrolling when new suggestions appear.
     */
    useEffect(() => {
      const scrollToSuggestionsIfAtBottom = async (): Promise<void> => {
        const isScrollAtBottom = await getIsScrollAtBottom();
        if (isScrollAtBottom) {
          scrollListToBottom(true);
        }
      };

      if (transcript.suggestions.length > 0) {
        scrollToSuggestionsIfAtBottom();
      }
    }, [transcript.suggestions]);

    let isScrollable = false;
    if (listRef.current) {
      const { scrollHeight, clientHeight } = listRef.current;
      isScrollable = scrollHeight > clientHeight;
    }
    const showDivider = isScrollable && !transcript.futureContextualEvent;
    const showCustomerDetailsBanner =
      !navigation.secondary.isOpen &&
      transcript.customerDetailsStatus === 'SUCCESS' &&
      transcript.customerTokens.length > 0;

    return (
      <div
        className={`TranscriptViewItemsList${
          showDivider ? ' TranscriptViewItemsList__divider' : ''
        }`}
        ref={listRef}
        data-testid="TranscriptViewItemsList"
        id={PHOTOS_INTERSECTION_OBSERVER_ROOT_ID}
      >
        {(showCustomerDetailsBanner || transcript.futureContextualEvent) && (
          <GeneralEventBanner>
            {transcript.futureContextualEvent && (
              <AppointmentEventBanner item={transcript.futureContextualEvent} />
            )}
            {showCustomerDetailsBanner && <CustomerDetailEventBanner />}
          </GeneralEventBanner>
        )}
        {transcript.customerTokens.length === 0 &&
          !navigation.secondary.isOpen && <AddCustomerBanner />}
        <div className="TranscriptViewItemsList__content">
          <LoadMoreIndicator
            loadMore={getPrevItems}
            hasMorePages={transcript.hasPrevPage}
            hasError={transcript.loadPrevStatus === 'ERROR'}
          />
          {transcript.viewItems.map((viewItem, index) => {
            const utteranceId =
              viewItem.dataType === 'UTTERANCE'
                ? (viewItem.data as LocalUtterance)?.utterance?.id
                : viewItem.attachedUtterance?.utterance?.id;
            let itemRef;
            if (utteranceId) {
              itemRef = createRef<HTMLDivElement>();
              itemRefs.current[utteranceId] = itemRef;
            }
            return (
              <TranscriptViewItem
                key={`TranscriptViewItem_${viewItem.dataType}_${viewItem.componentType}_${utteranceId}_${viewItem.timestampMillis}_${transcript.id}`}
                item={viewItem}
                isLastItem={index === transcript.viewItems.length - 1}
                itemRef={itemRef}
              />
            );
          })}
          <LoadMoreIndicator
            loadMore={getNextItems}
            hasMorePages={transcript.hasNextPage}
            hasError={transcript.loadNextStatus === 'ERROR'}
          />
          {!isInputDisabled &&
            transcript.suggestions.length > 0 &&
            !transcript.hasNextPage && (
              <Suggestions
                suggestions={transcript.suggestions}
                messageInput={message}
                setMessageInput={setMessage}
                requestReview={requestReview}
                photoInputRef={photoInputRef}
              />
            )}
        </div>
        <JumpToBottomButton
          hasUnreadMessages={!transcript.isRead}
          onClick={() => {
            if (transcript.hasNextPage) {
              transcript.clearUtterances();
              transcript.load();
              return;
            }
            scrollListToBottom();
          }}
          show={showJumpButton}
        />
      </div>
    );
  },
);

export default TranscriptViewItemsList;
