import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import './DedicatedNumberFeatureOnlyDialog.scss';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { MarketButton } from 'src/components/Market';

/**
 * Dialog to inform the user that the feature is only available with a dedicated number.
 * This is used when a SquareOne-subscribed user tries to send a coupon but doesn't yet
 * have a dedicated number.
 */
const DedicatedNumberFeatureOnlyDialog = observer((): ReactElement => {
  const { t } = useTranslation();
  const { modal, event } = useMessengerControllerContext();

  return (
    <MessengerModalDialog
      title={t('DedicatedNumberFeatureOnlyDialog.coupon_title')}
      primaryButton={
        <MarketButton
          rank="primary"
          onClick={() => {
            event.track('Click Premium Feature Available Post Verified Modal', {
              premium_feature_name: 'coupon',
            });
            modal.closeModal();
          }}
        >
          {t('DedicatedNumberFeatureOnlyDialog.dismiss_button')}
        </MarketButton>
      }
    >
      <p className="DedicatedNumberFeatureOnlyDialog__description paragraph-30">
        {t('DedicatedNumberFeatureOnlyDialog.coupon_description')}
      </p>
    </MessengerModalDialog>
  );
});

export default DedicatedNumberFeatureOnlyDialog;
