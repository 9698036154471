import React, { ReactElement, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import MessengerController from './MessengerController';
import MessengerControllerContext from './context/MessengerControllerContext';
import App from './App';
import { SaaSProvider } from '@squareup/saas-shared-ui';
import { Currency } from './gen/squareup/connect/v2/common/money';
import { getReactRoot } from './utils/shadowDomUtils';
import {
  TrackingName,
  TrackingProperties,
} from 'src/stores/EventTrackingStore';
import { SAAS_SHARED_UI_APP_NAME } from 'src/MessengerTypes';

type AppContainerProps = {
  messenger: MessengerController;
};

/**
 * The React component root for the Messages Blade.
 *
 * @param {MessengerController} messenger
 */
const AppContainer = observer(
  ({ messenger }: AppContainerProps): ReactElement => {
    const saasPortalContainerRef = useRef(document.createElement('div'));

    // Adds portal container for Saas modals, which would otherwise render
    // outside the Messages shadow root, which would cause issues with styling
    useEffect(() => {
      const saasPortalContainer = saasPortalContainerRef.current;

      saasPortalContainer.className = 'SaasPortalBlade';
      const rootElement = getReactRoot() ?? document.body;
      rootElement.appendChild(saasPortalContainer);
      return () => {
        rootElement.removeChild(saasPortalContainer);
      };
    }, []);

    // TODO (teresalin): Remove merchantToken when saas makes it optional
    // `merchantToken` is currently needed for saas-shared-ui to read FFs ahead of the 4/1/2025 launch.
    // Before 4/1/2025, `merchantToken` will be made optional, so we can remove it then.
    return (
      <MessengerControllerContext.Provider value={messenger}>
        <SaaSProvider
          appName={SAAS_SHARED_UI_APP_NAME}
          merchantToken={messenger.user.merchantToken}
          currencyCode={
            (messenger.user.currencyCode as keyof typeof Currency) || 'USD'
          }
          countryCode={messenger.user.countryCode}
          locale={messenger.user.locale}
          name={messenger.user.businessName}
          customModalPortalContainer={saasPortalContainerRef.current}
          trackEvent={(eventName, eventProps, options): void => {
            messenger.event.track(
              eventName as TrackingName, // Override allowable name here since these events will be defined by saas library
              eventProps as TrackingProperties,
              options,
            );
          }}
        >
          <App />
        </SaaSProvider>
      </MessengerControllerContext.Provider>
    );
  },
);

export default AppContainer;
