// note that there is currently no spec test for this component. for some
// reason, jest chokes when running spec tests on components that use
// js imports (ex. @market/market-theme/style-dictionary).

// it appears to be a known issue, but i couldn't get the proposed configuration
// changes to work as expected: https://github.com/ionic-team/stencil/issues/2129

import type { Placement, PositioningStrategy } from '@popperjs/core';
import { Component, Element, Event, EventEmitter, Host, Listen, Prop, h, Method } from '@stencil/core';

import { getNamespacedTagFor } from '../../utils/namespace';

/**
 * @slot trigger - The text or icon used for the tooltip trigger. Interacting with the
 * slotted content will serve as the trigger that opens the popover. Defaults to
 * an ℹ️ icon.
 * @slot content - Content slotted here will appear in `market-popover`, which
 * becomes visible when the slotted trigger content is interacted with.
 * @part dropdown - the market-dropdown element.
 * @part trigger - the trigger element.
 * @part popover - the popover element.
 */
@Component({
  tag: 'market-tooltip',
  styleUrl: 'market-tooltip.css',
  shadow: true,
})
export class MarketTooltip {
  @Element() el: HTMLMarketTooltipElement;

  /**
   * Defines what types of interaction the tooltip should have
   * (see `market-dropdown` docs for more granular explanation)
   */
  @Prop() readonly interaction: 'click' | 'hover' | 'persistent' = 'hover';

  /**
   * Functionally and visually disables the tooltip trigger.
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * Configuration option for Popper.js (used to position the tooltip overlay).
   * Describes the preferred placement of the popper.
   * https://popper.js.org/docs/v2/constructors//#placement
   */
  @Prop() readonly popoverPlacement: Placement = 'bottom';

  /**
   * Configuration option for Popper.js (used to position `<market-popover>`).
   * Describes the positioning strategy to use. By default, it is absolute. If
   * your reference element is in a fixed container, use the fixed strategy.
   * https://popper.js.org/docs/v2/constructors//#strategy
   */
  @Prop() readonly popoverStrategy: PositioningStrategy = 'absolute';

  /**
   * Configuration option for Popper.js (used to position `<market-popover>`).
   * Displaces the popover along the reference element.
   * https://popper.js.org/docs/v2/modifiers/offset/#skidding-1
   */
  @Prop() readonly popoverSkidding: number;

  /**
   * Configuration option for Popper.js (used to position `<market-popover>`).
   * Displaces the popper away from, or toward, the reference element in the
   * direction of its placement.
   * https://popper.js.org/docs/v2/modifiers/offset/#distance-1
   */
  @Prop() readonly popoverDistance: number = 0;

  /**
   * Whether or not the tooltip is open. Setting it to true means it will be open
   * by default
   */
  // eslint-disable-next-line @stencil-community/props-must-be-readonly
  @Prop({ reflect: true }) expanded: boolean = false;

  /**
   * Fired whenever the tooltip is opened.
   */
  @Event({ bubbles: true, composed: true }) marketTooltipOpened: EventEmitter;

  /**
   * Fired whenever the tooltip is closed.
   */
  @Event({ bubbles: true, composed: true }) marketTooltipClosed: EventEmitter;

  @Listen('marketDropdownOpened')
  dropdownOpenedEventHandler(e: CustomEvent<void>) {
    if (e.target !== this.el) return;
    this.expanded = true;
    this.marketTooltipOpened.emit();
  }

  @Listen('marketDropdownClosed')
  dropdownClosedEventHandler(e: CustomEvent<void>) {
    if (e.target !== this.el) return;
    this.expanded = false;
    this.marketTooltipClosed.emit();
  }

  innerDropdown: HTMLMarketDropdownElement;

  /**
   * Opens the tooltip
   */
  @Method()
  openTooltip(): Promise<void> {
    return this.innerDropdown.openDropdown();
  }

  /**
   * Closes the tooltip
   */
  @Method()
  closeTooltip(): Promise<void> {
    return this.innerDropdown.closeDropdown();
  }

  styleLinks() {
    // since it isn't possible to style slotted child elements, we need to add
    // inline styles to slotted links in order to style them correctly
    // https://github.com/WICG/webcomponents/issues/594
    // https://github.com/WICG/webcomponents/issues/331

    const slottedLinks = [...this.el.children]
      .find((child) => child.slot === 'content')
      ?.querySelectorAll<HTMLAnchorElement | HTMLMarketLinkElement>(`a, ${getNamespacedTagFor('market-link')}`);

    slottedLinks.forEach((link) => {
      link.style.color = 'var(--tooltip-link-color)';
      link.style.fontSize = 'var(--tooltip-link-size)';
      link.style.fontWeight = 'var(--tooltip-link-weight)';
      link.style.lineHeight = 'var(--tooltip-link-leading)';
      link.style.letterSpacing = 'var(--tooltip-link-tracking)';
      link.style.textTransform = 'var(--tooltip-link-case)';
      link.style.textDecoration = 'none';
    });
  }

  componentWillRender() {
    this.styleLinks();
  }

  render() {
    const MarketDropdownTagName = getNamespacedTagFor('market-dropdown');
    return (
      <Host aria-expanded={this.expanded} class="market-tooltip">
        <MarketDropdownTagName
          interaction={this.interaction}
          popover-placement={this.popoverPlacement}
          popover-strategy={this.popoverStrategy}
          popover-distance={this.popoverDistance}
          popover-skidding={this.popoverSkidding}
          disabled={this.disabled}
          aria-expanded={this.expanded}
          part="dropdown"
          ref={(el) => (this.innerDropdown = el)}
        >
          {/* this gets slotted into market-dropdown's trigger slot */}
          <div slot="trigger" part="trigger" class="trigger-tap-target">
            {/* this is tooltip's trigger slot (expects an icon or text) */}
            <slot name="trigger">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9 0C4.04 0 0 4.04 0 9C0 13.96 4.04 18 9 18C13.96 18 18 13.96 18 9C18 4.04 13.96 0 9 0ZM9 16C5.14 16 2 12.86 2 9C2 5.14 5.14 2 9 2C12.86 2 16 5.14 16 9C16 12.86 12.86 16 9 16ZM8 7.5V14H10V7.5H8ZM10.25 5.25C10.25 5.94036 9.69036 6.5 9 6.5C8.30964 6.5 7.75 5.94036 7.75 5.25C7.75 4.55964 8.30964 4 9 4C9.69036 4 10.25 4.55964 10.25 5.25Z"
                />
              </svg>
            </slot>
          </div>
          <aside slot="popover" part="popover">
            <slot name="content" onSlotchange={() => this.styleLinks()}></slot>
          </aside>
        </MarketDropdownTagName>
      </Host>
    );
  }
}
