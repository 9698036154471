import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import MessengerBanner from 'src/components/MessengerBanner/MessengerBanner';
import { getDashboardPricingAndSubscriptionsUrl } from 'src/utils/url';
import { useLongDateFromMicroseconds } from 'src/utils/timeUtils';
import 'src/components/MessagesPlusExpiringBanner/MessagesPlusExpiringBanner.scss';

export type SubscriptionExpiringBannerProps = {
  expiryDate: number;
  onDismiss?: () => void;
};

/**
 * Banner that is displayed when the merchant is subscribed to M+ but their
 * entire subscription will not renew, or a subset of units will not renew.
 *
 * @example
 * // Basic usage:
 * const date = 1643655894000000; // Jan 31, 2022 in microseconds since Epoch
 * <SubscriptionExpiringBanner expiryDate={date} />
 * @example
 * // With optional:
 * const date = 1643655894000000; // Jan 31, 2022 in microseconds since Epoch
 * <SubscriptionExpiringBanner
 *   expiryDate={date}
 *   onDismiss={() => {
 *     tooltip.dismiss('SUBSCRIPTION_EXPIRING_BANNER');
 *   }}
 * />
 * @param {number} expiryDate
 * Expiration date, in microseconds since epoch time.
 * @param {Function} onDismiss
 * (Optional) Callback to execute when banner is dismissed, i.e. to set the banner
 * visibility state.
 */
const SubscriptionExpiringBanner = observer(
  ({
    expiryDate,
    onDismiss,
  }: SubscriptionExpiringBannerProps): ReactElement => {
    const { t } = useTranslation();
    const endDate = useLongDateFromMicroseconds(expiryDate);

    return (
      <MessengerBanner
        variant="info"
        dismissable
        className="SubscriptionExpiringBanner"
        onMarketBannerDismissed={onDismiss}
        data-testid="SubscriptionExpiringBanner"
      >
        <Trans
          i18nKey="SubscriptionExpiringBanner.subscription.text"
          components={{
            1: <span className="MessagesPlusExpiringBanner__expiry-date" />,
          }}
          values={{ date: endDate }}
        />
        <a
          href={getDashboardPricingAndSubscriptionsUrl}
          target="_self"
          slot="action"
        >
          {t('SubscriptionExpiringBanner.button_text')}
        </a>
      </MessengerBanner>
    );
  },
);

export default SubscriptionExpiringBanner;
