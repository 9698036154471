import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import CustomerEventCard from 'src/pages/TranscriptViewPage/components/CustomerEventCard/CustomerEventCard';
import SoundIcon from 'src/svgs/SoundIcon';
import { Voicemail } from 'src/MessengerTypes';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { BaseContextualEventCardProps } from 'src/types/BaseContextualEventCardProps';
import SoundPlayer from 'src/components/SoundPlayer/SoundPlayer';
import './VoicemailEventCard.scss';

/**
 * Card used in the list of utterances to represent a voicemail.
 */
const VoicemailEventCard = observer(
  ({ item, cardRef }: BaseContextualEventCardProps) => {
    const { timestampMillis, data, attachedUtterance, showCustomerImage } =
      item;
    const { user, transcriptView, event } = useMessengerControllerContext();
    const { transcript } = transcriptView;
    const { t, i18n } = useTranslation();
    const voicemailId = attachedUtterance?.utterance.attachments?.[0].id;
    const voicemail = transcript.voicemails.get(voicemailId || -1);

    if (!voicemailId || !voicemail) {
      return null;
    }

    const trackingMetadata = {
      merchant_token: user.merchantToken,
      unit_token: transcript.sellerKey,
      transcript_id: transcript.id,
      utterance_id: attachedUtterance?.utterance.id,
    };
    const onPause = (): void => {
      event.track('Click Voicemail Action', {
        ...trackingMetadata,
        action_type_name: 'pause',
      });
    };
    const onPlay = (): void => {
      event.track('Click Voicemail Action', {
        ...trackingMetadata,
        action_type_name: 'play',
      });
    };
    const onScrub = (): void => {
      event.track('Click Voicemail Action', {
        ...trackingMetadata,
        action_type_name: 'scrub',
      });
    };

    return (
      <CustomerEventCard
        className="VoicemailEventCard__card"
        timestampMillis={timestampMillis}
        icon={<SoundIcon />}
        title={t('VoicemailEventCard.title')}
        subtitle={new Date(timestampMillis).toLocaleString(i18n.language, {
          weekday: 'short',
          hour: 'numeric',
          minute: 'numeric',
          timeZone: user.timezone,
        })}
        showCustomerImage={showCustomerImage}
        body={
          <>
            <p className="VoicemailEventCard__transcription">
              {(data as Voicemail).transcription || (
                <span className="VoicemailEventCard__transcription-unavailable">
                  {t('VoicemailEventCard.transcription_unavailable')}
                </span>
              )}
            </p>
            <SoundPlayer
              sound={voicemail}
              soundLoadErrorText={t('VoicemailEventCard.load_error')}
              onPlay={onPlay}
              onPause={onPause}
              onScrub={onScrub}
              className={classNames({
                'VoicemailEventCard__sound-player':
                  voicemail.status !== 'ERROR',
              })}
            />
          </>
        }
        cardRef={cardRef}
        id={attachedUtterance?.utterance.id}
      />
    );
  },
);

export default VoicemailEventCard;
