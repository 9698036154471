/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';
import './rst/extensions';
import './pos/extensions';
import './card/balance/extensions';
import './appts/extensions';
import './fulfillment/extensions';
import './ecom/extensions';
import './notifications/extensions';
import './workflows/extensions';
import '../checkoutfe/extensions/extensions';
import './splits/extensions';
import './thirdparty/extensions';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "omg": {
        "nested": {
          "OrderExtensions": {
            "fields": {
              "rstOrderExtension": {
                "type": "squareup.omg.rst.RstOrderExtension",
                "id": 1,
                "rule": "optional"
              },
              "posOrderExtension": {
                "type": "squareup.omg.pos.PosOrderExtension",
                "id": 3,
                "rule": "optional"
              },
              "rewardMappingOrderExtension": {
                "type": "squareup.omg.card.balance.RewardMappingOrderExtension",
                "id": 4,
                "rule": "optional"
              },
              "apptsOrderExtension": {
                "type": "squareup.omg.appts.ApptsOrderExtension",
                "id": 5,
                "rule": "optional"
              },
              "commitmentOwnerExtension": {
                "type": "squareup.omg.fulfillment.CommitmentOwnerExtension",
                "id": 6,
                "rule": "optional"
              },
              "ecomOrderExtension": {
                "type": "squareup.omg.ecom.EcomOrderExtension",
                "id": 7,
                "rule": "optional"
              },
              "notificationsExtension": {
                "type": "squareup.omg.notifications.NotificationsExtension",
                "id": 8,
                "rule": "optional"
              },
              "commandExtension": {
                "type": "squareup.omg.workflows.WorkflowsCommandExtension",
                "id": 9,
                "rule": "optional"
              }
            }
          },
          "OrderLineItemExtensions": {
            "fields": {
              "checkoutfe": {
                "type": "squareup.checkoutfe.extensions.LineItemModelExtension",
                "id": 1,
                "rule": "optional"
              },
              "rstLineItemExtension": {
                "type": "squareup.omg.rst.RstLineItemExtension",
                "id": 2,
                "rule": "optional"
              },
              "posLineItemExtension": {
                "type": "squareup.omg.pos.PosLineItemExtension",
                "id": 3,
                "rule": "optional"
              },
              "apptsLineItemExtension": {
                "type": "squareup.omg.appts.ApptsLineItemExtension",
                "id": 4,
                "rule": "optional"
              },
              "shouldCommitLineItemExtension": {
                "type": "squareup.omg.fulfillment.ShouldCommitLineItemExtension",
                "id": 5,
                "rule": "optional"
              },
              "ecomLineItemExtension": {
                "type": "squareup.omg.ecom.EcomLineItemExtension",
                "id": 6,
                "rule": "optional"
              },
              "splitLineItemExtension": {
                "type": "squareup.omg.splits.SplitLineItemExtension",
                "id": 7,
                "rule": "optional"
              }
            }
          },
          "OrderDiscountExtensions": {
            "fields": {}
          },
          "OrderTaxExtensions": {
            "fields": {
              "posTaxExtension": {
                "type": "squareup.omg.pos.PosTaxExtension",
                "id": 3,
                "rule": "optional"
              },
              "thirdPartyTaxExtension": {
                "type": "squareup.omg.thirdparty.ThirdPartyTaxExtension",
                "id": 4,
                "rule": "optional"
              }
            }
          },
          "OrderServiceChargeExtensions": {
            "fields": {
              "rstServiceChargeExtension": {
                "type": "squareup.omg.rst.RstServiceChargeExtension",
                "id": 1,
                "rule": "optional"
              },
              "ecomServiceChargeExtension": {
                "type": "squareup.omg.ecom.EcomServiceChargeExtension",
                "id": 2,
                "rule": "optional"
              }
            }
          },
          "OrderModifierExtensions": {
            "fields": {
              "rstModifierExtension": {
                "type": "squareup.omg.rst.RstModifierExtension",
                "id": 1,
                "rule": "optional"
              },
              "posModifierExtension": {
                "type": "squareup.omg.pos.PosModifierExtension",
                "id": 2,
                "rule": "optional"
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.omg?.nested?.OrderExtensions && !$root.nested?.squareup?.nested?.omg?.nested?.OrderLineItemExtensions && !$root.nested?.squareup?.nested?.omg?.nested?.OrderDiscountExtensions && !$root.nested?.squareup?.nested?.omg?.nested?.OrderTaxExtensions && !$root.nested?.squareup?.nested?.omg?.nested?.OrderServiceChargeExtensions && !$root.nested?.squareup?.nested?.omg?.nested?.OrderModifierExtensions) {
	$root.addJSON(jsonData);
}

/**
 * --
 * @desc Square-exclusive first-party private proto extension areas corresponding to various
 * components of an order.
 *
 * Fields within these messages must be defined outside of the Orders project and will never
 * be exposed to third-parties.
 * --
 *
 * SOURCE OrderExtensions @ squareup/omg/order_extensions.proto at 34:1
 */
export const OrderExtensions = $root.lookupType('squareup.omg.OrderExtensions');
fixType(OrderExtensions);
Builder.createAndAttachToType(OrderExtensions);
/**
 * SOURCE OrderLineItemExtensions @ squareup/omg/order_extensions.proto at 64:1
 */
export const OrderLineItemExtensions = $root.lookupType('squareup.omg.OrderLineItemExtensions');
fixType(OrderLineItemExtensions);
Builder.createAndAttachToType(OrderLineItemExtensions);
/**
 * SOURCE OrderDiscountExtensions @ squareup/omg/order_extensions.proto at 84:1
 */
export const OrderDiscountExtensions = $root.lookupType('squareup.omg.OrderDiscountExtensions');
fixType(OrderDiscountExtensions);
Builder.createAndAttachToType(OrderDiscountExtensions);
/**
 * SOURCE OrderTaxExtensions @ squareup/omg/order_extensions.proto at 89:1
 */
export const OrderTaxExtensions = $root.lookupType('squareup.omg.OrderTaxExtensions');
fixType(OrderTaxExtensions);
Builder.createAndAttachToType(OrderTaxExtensions);
/**
 * SOURCE OrderServiceChargeExtensions @ squareup/omg/order_extensions.proto at 98:1
 */
export const OrderServiceChargeExtensions = $root.lookupType('squareup.omg.OrderServiceChargeExtensions');
fixType(OrderServiceChargeExtensions);
Builder.createAndAttachToType(OrderServiceChargeExtensions);
/**
 * SOURCE OrderModifierExtensions @ squareup/omg/order_extensions.proto at 107:1
 */
export const OrderModifierExtensions = $root.lookupType('squareup.omg.OrderModifierExtensions');
fixType(OrderModifierExtensions);
Builder.createAndAttachToType(OrderModifierExtensions);
