import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { MarketBanner } from 'src/components/Market';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { M_PLUS_SUBSCRIPTION_FAQ_URL } from 'src/utils/url';
import useReferralPageName from 'src/hooks/useReferralPageName';

/**
 * Banner that is displayed when a merchant attempts to subscribe to M+ (v2),
 * but fails TFN verification and does not successfully subscribe due to being
 * deemed by carrier networks as a prohibited (SHAFT-C) merchant.
 */
const IneligibleBanner = observer((): ReactElement => {
  const { event, subscription } = useMessengerControllerContext();
  const { t } = useTranslation();
  const referralPageName = useReferralPageName();

  return (
    <MarketBanner
      variant="info"
      className="IneligibleBanner"
      data-testid="IneligibleBanner"
    >
      <span slot="title">
        {subscription.isEligibleForSquareOne
          ? t('IneligibleBanner.title_sqone')
          : t('IneligibleBanner.title')}
      </span>
      {subscription.isEligibleForSquareOne
        ? t('IneligibleBanner.description_sqone')
        : t('IneligibleBanner.description')}
      <a
        slot="action"
        href={M_PLUS_SUBSCRIPTION_FAQ_URL}
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          event.track('Click CBD Learn More', {
            referral_page_name: referralPageName,
          });
        }}
      >
        {t('common.learn_more')}
      </a>
    </MarketBanner>
  );
});

export default IneligibleBanner;
