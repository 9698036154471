import React, { ReactElement, RefObject, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import RequestPaymentIcon from 'src/svgs/RequestPaymentIcon';
import {
  amountToCurrencyString,
  currencyCodeToKey,
} from 'src/utils/moneyUtils';
import NewCustomerIcon from 'src/svgs/NewCustomerIcon';
import SuggestionRow from './components/SuggestionRow/SuggestionRow';
import GoogleIcon from 'src/svgs/GoogleIcon';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { Suggestion } from 'src/gen/squareup/messenger/v3/messenger_service';
import { getSuggestionTrackingMetadata } from 'src/utils/suggestionUtils';
import CouponSuggestion from './components/CouponSuggestion/CouponSuggestion';
import InvoiceSuggestion from './components/InvoiceSuggestion/InvoiceSuggestion';
import PhotoSuggestion from './components/PhotoSuggestion/PhotoSuggestion';
import { KEY_REQUEST_GOOGLE_REVIEW } from 'src/stores/FeatureFlagStore';
import AssistantIcon from 'src/svgs/AssistantIcon';
import AppointmentIcon from 'src/svgs/AppointmentIcon';
import { getCreateAppointmentUrl } from 'src/utils/url';
import './Suggestions.scss';

const M_PLUS_SUGGESTIONS = new Set([Suggestion.SuggestionType.SEND_COUPON]);

export type SuggestionsProps = {
  suggestions: Suggestion[];
  messageInput: string;
  setMessageInput: (text: string) => void;
  requestReview: () => void;
  photoInputRef: RefObject<HTMLInputElement>;
};

/**
 * A section that appears at the end of TranscriptViewItemsList that suggest
 * actions the user can take. This can either be a text reply, or actions like
 * sending a coupon, checkout link, etc. Smart replies will be hidden when the
 * input bar is not empty.
 *
 * New suggestions that apply text in the input bar should be included in the
 * list of filtered suggestions below.
 *
 * @example
 * Basic usage:
 * <Suggestions
 *   suggestions={[...]}
 *   messageInput={message}
 *   setMessageInput={setMessage}
 *   requestReview={() => {...}}
 * />
 * @param {Suggestion[]} suggestions
 * The suggestions to render.
 * @param {string} messageInput
 * The text in the input bar, used to hide smart reply suggestions if the user
 * is already typing something.
 * @param {Function} setMessageInput
 * Function to set the text in the input bar, for smart replies.
 * @param {Function} requestReview
 * Function to call to request a review from the customer.
 * @param {RefObject} photoInputRef
 * A ref to the photo input used to upload photos.
 */
const Suggestions = observer(
  ({
    suggestions,
    messageInput,
    setMessageInput,
    requestReview,
    photoInputRef,
  }: SuggestionsProps): ReactElement => {
    const { event, transcriptView, modal, user, featureFlag } =
      useMessengerControllerContext();
    const { t } = useTranslation();
    const { transcript, isNotSubscribedToMPlus } = transcriptView;
    const { id: transcriptId, customerToken, sellerKey } = transcript;

    useEffect(() => {
      suggestions.forEach((suggestion) => {
        event.track('View Smart Action', {
          suggestion_id: suggestion.suggestionId,
          action_type_name:
            Suggestion.SuggestionType[suggestion.suggestionType].toLowerCase(),
          needs_upgrade:
            M_PLUS_SUGGESTIONS.has(suggestion.suggestionType) &&
            isNotSubscribedToMPlus,
          transcript_id: transcriptId,
        });
      });
    }, [suggestions, isNotSubscribedToMPlus, transcriptId, event]);

    const trackClickSuggestion = (suggestion: Suggestion): void => {
      event.track('Click Smart Action', {
        ...getSuggestionTrackingMetadata(suggestion),
        needs_upgrade:
          M_PLUS_SUGGESTIONS.has(suggestion.suggestionType) &&
          isNotSubscribedToMPlus,
        transcript_id: transcriptId,
      });
    };

    // Do not show smart replies if there is already text in the input bar
    let filteredSuggestions = suggestions;
    if (messageInput !== '') {
      filteredSuggestions = suggestions.filter(
        (suggestion) =>
          suggestion.suggestionType !==
            Suggestion.SuggestionType.SUGGEST_REPLIES &&
          suggestion.suggestionType !==
            Suggestion.SuggestionType.REQUEST_REVIEW &&
          suggestion.suggestionType !==
            Suggestion.SuggestionType.SEND_BOOKING_LINK,
      );
    }

    if (filteredSuggestions.length > 0) {
      const content: (ReactElement | null)[] = filteredSuggestions
        .map((suggestion) => {
          switch (suggestion.suggestionType) {
            case Suggestion.SuggestionType.SUGGEST_REPLIES: {
              const text = suggestion.suggestionMetadata?.reply?.text ?? '';

              if (!text) {
                return null;
              }

              return (
                <SuggestionRow
                  key={suggestion.suggestionId}
                  onClick={() => {
                    trackClickSuggestion(suggestion);
                    setMessageInput(text);
                  }}
                  text={text}
                />
              );
            }
            case Suggestion.SuggestionType.REQUEST_PAYMENT: {
              const amount = suggestion.suggestionMetadata?.payment?.amount;

              let paymentText;
              if (amount) {
                paymentText = t('SmartSuggestions.request_amount', {
                  amount: amountToCurrencyString(
                    amount.amount,
                    currencyCodeToKey(amount.currency),
                    user.locale,
                  ),
                });
              } else {
                paymentText = t('SmartSuggestions.request');
              }

              return (
                <SuggestionRow
                  key={suggestion.suggestionId}
                  onClick={() => {
                    trackClickSuggestion(suggestion);
                    modal.openCheckoutLinkModal(amount?.amount);
                  }}
                  text={paymentText}
                  icon={<RequestPaymentIcon />}
                />
              );
            }
            case Suggestion.SuggestionType.CREATE_CUSTOMER:
              return (
                <SuggestionRow
                  key={suggestion.suggestionId}
                  onClick={() => {
                    trackClickSuggestion(suggestion);
                    modal.openCreateCustomerSuggestionModal(
                      suggestion.suggestionMetadata?.customerInfo,
                    );
                  }}
                  text={t('SmartSuggestions.create_customer')}
                  icon={<NewCustomerIcon />}
                />
              );
            case Suggestion.SuggestionType.REQUEST_REVIEW: {
              if (!featureFlag.get(KEY_REQUEST_GOOGLE_REVIEW)) {
                return null;
              }

              return (
                <SuggestionRow
                  key={suggestion.suggestionId}
                  onClick={() => {
                    trackClickSuggestion(suggestion);
                    requestReview();
                  }}
                  text={t('SmartSuggestions.request_review')}
                  icon={<GoogleIcon />}
                />
              );
            }
            case Suggestion.SuggestionType.CREATE_APPOINTMENT:
              return (
                <SuggestionRow
                  key={suggestion.suggestionId}
                  onClick={() => {
                    trackClickSuggestion(suggestion);
                    window.open(
                      getCreateAppointmentUrl(
                        customerToken,
                        sellerKey,
                        transcriptId,
                      ),
                      '_blank',
                    );
                  }}
                  text={t('SmartSuggestions.create_appointment')}
                  icon={<AppointmentIcon />}
                />
              );
            case Suggestion.SuggestionType.SEND_BOOKING_LINK: {
              const text =
                suggestion.suggestionMetadata?.bookingLink?.bookingUrl;

              if (!text) {
                return null;
              }

              return (
                <SuggestionRow
                  key={suggestion.suggestionId}
                  onClick={() => {
                    trackClickSuggestion(suggestion);
                    setMessageInput(text);
                  }}
                  text={t('SmartSuggestions.booking_link')}
                  icon={<AppointmentIcon />}
                />
              );
            }
            case Suggestion.SuggestionType.SEND_COUPON:
              return (
                <CouponSuggestion
                  key={suggestion.suggestionId}
                  suggestion={suggestion}
                  trackClickSuggestion={() => trackClickSuggestion(suggestion)}
                />
              );
            case Suggestion.SuggestionType.SEND_INVOICE:
              return (
                <InvoiceSuggestion
                  key={suggestion.suggestionId}
                  trackClickSuggestion={() => trackClickSuggestion(suggestion)}
                />
              );
            case Suggestion.SuggestionType.SEND_PHOTO:
              return (
                <PhotoSuggestion
                  key={suggestion.suggestionId}
                  photoInputRef={photoInputRef}
                  trackClickSuggestion={() => trackClickSuggestion(suggestion)}
                />
              );
            default:
              return null;
          }
        })
        .filter((element) => element !== null);

      if (content.length > 0) {
        return (
          <div className="Suggestions" data-testid="Suggestions">
            <div className="Suggestions__title">
              <AssistantIcon />
              {t('SmartSuggestions.suggested')}
            </div>
            <div className="Suggestions__body">{content}</div>
          </div>
        );
      }
    }

    return <></>;
  },
);

export default Suggestions;
