import { Component, Element, Event, EventEmitter, h, Host, Listen, Prop } from '@stencil/core';

import { getNamespacedTagFor } from '../../utils/namespace';
import { TMarketInternalPaginationNavigationEventDetail, TMarketPaginationPageSizeChangeEventDetail } from './events';

/**
 * @slot page-size-label - localized string to label the page size dropdown (defaults to English "Results per page")
 * @slot page-size-feedback - localized string to label the current page size (defaults to value of {this.pageSize})
 * @slot nav-label - localized string to label the page dropdown (defaults to English "Page")
 * @slot nav-feedback - localized string to label the current page of total pages
 *      (defaults to English "{this.currentPage} of {this.totalPages}").
 *      The #s for current page/total pages need to be passed in as part of the string.
 */

@Component({
  tag: 'market-pagination',
  styleUrl: 'market-pagination.css',
  shadow: true,
})
export class MarketPagination {
  @Element() el: HTMLMarketPaginationElement;

  /**
   * The current page being viewed.
   */
  @Prop() readonly currentPage!: number;

  /**
   * Functionally and visually disables all navigation buttons
   */
  @Prop({ reflect: true }) readonly disabled: boolean = false;

  /**
   * The total number of pages.
   */
  @Prop() readonly totalPages!: number;

  /**
   * Allow the user to navigate to the next page
   */
  @Prop() readonly hasNextPage!: boolean;

  /**
   * Allow the user to navigate to the previous page
   */
  @Prop() readonly hasPreviousPage!: boolean;

  /**
   * The number of results displayed per page.
   */
  @Prop() readonly pageSize: string;

  /**
   * Options for page sizes (comma separated list of numbers). If omitted, will not display the page size subcomponent.
   */
  @Prop() readonly pageSizeOptions: string;

  /**
   * Fired whenever the page size is changed.
   */
  @Event({ bubbles: true, composed: true })
  marketPaginationPageSizeChange: EventEmitter<TMarketPaginationPageSizeChangeEventDetail>;

  /**
   * Fired whenever the menu selection is changed.
   */
  @Event({ bubbles: true, composed: true })
  marketPaginationNavigation: EventEmitter<TMarketInternalPaginationNavigationEventDetail>;

  /**
   * Rebroadcast up events from market-pagination-page-size.
   */
  @Listen('marketInternalPaginationPageSizeChange')
  changePageSize(e: CustomEvent<TMarketPaginationPageSizeChangeEventDetail>) {
    e.stopPropagation();
    const { detail } = e;
    this.marketPaginationPageSizeChange.emit(detail);
  }

  /**
   * Rebroadcast up events from market-pagination-nav.
   */
  @Listen('marketInternalPaginationNavigation')
  navigatePage(e: CustomEvent<TMarketInternalPaginationNavigationEventDetail>) {
    e.stopPropagation();
    const { detail } = e;
    this.marketPaginationNavigation.emit(detail);
  }

  render() {
    const MarketPaginationPageSizeTagName = getNamespacedTagFor('market-pagination-page-size');
    const MarketPaginationNavTagName = getNamespacedTagFor('market-pagination-nav');

    return (
      <Host class="market-pagination">
        {this.pageSizeOptions && (
          <MarketPaginationPageSizeTagName
            value={this.pageSize}
            pageSizeOptions={this.pageSizeOptions}
            disabled={this.disabled}
          >
            <slot name="page-size-label" slot="page-size-label">
              Results per page
            </slot>
            <slot name="page-size-feedback" slot="page-size-feedback">
              {this.pageSize}
            </slot>
          </MarketPaginationPageSizeTagName>
        )}

        <MarketPaginationNavTagName
          currentPage={this.currentPage}
          disabled={this.disabled}
          totalPages={this.totalPages}
          pageSize={this.pageSize}
          hasNextPage={this.hasNextPage}
          hasPreviousPage={this.hasPreviousPage}
        >
          <slot name="nav-label" slot="nav-label">
            Page
          </slot>
          <slot name="nav-feedback" slot="nav-feedback">
            {this.currentPage} of {this.totalPages}
          </slot>
        </MarketPaginationNavTagName>
      </Host>
    );
  }
}
