import { parse } from 'date-fns';
import {
  HTMLMarketTableV2ChildElement,
  TMarketTableV2SortOrder,
  TMarketTableV2SortStrategy,
  TMarketTableV2SortStrategyCallback,
} from './types';
import { isMarketTableV2Group } from '../market-table-v2-group/types';

export const sortItems = ({
  items,
  order,
  column,
  strategy,
  format,
}: {
  items: Array<HTMLMarketTableV2ChildElement>;
  order: TMarketTableV2SortOrder;
  column: number;
  strategy: TMarketTableV2SortStrategy;
  format: string;
}): Array<HTMLMarketTableV2ChildElement> => {
  const sortedItems = [...items];
  sortedItems.sort((childA: HTMLMarketTableV2ChildElement, childB: HTMLMarketTableV2ChildElement) => {
    const rowA = isMarketTableV2Group(childA)
      ? (childA.querySelector('[slot="parent"]') as HTMLMarketTableV2RowElement)
      : childA;
    const rowB = isMarketTableV2Group(childB)
      ? (childB.querySelector('[slot="parent"]') as HTMLMarketTableV2RowElement)
      : childB;
    const params = { rowA, rowB, order, column };
    if (typeof strategy === 'function') {
      return strategy(params);
    } else if (strategy === 'number') {
      return sortByNumber(params);
    } else if (strategy === 'datetime' && format) {
      return sortByDateTime({ ...params, format });
    } else {
      return sortByString(params);
    }
  });
  return sortedItems;
};

const sortByNumber: TMarketTableV2SortStrategyCallback = ({
  rowA,
  rowB,
  order,
  column,
}: {
  rowA: HTMLMarketTableV2RowElement;
  rowB: HTMLMarketTableV2RowElement;
  order: TMarketTableV2SortOrder;
  column: number;
}): number => {
  const numberA = Number.parseFloat(rowA?.children[column]?.textContent?.trim());
  const numberB = Number.parseFloat(rowB?.children[column]?.textContent?.trim());

  if (order === 'ascending') {
    return numberA - numberB;
  } else {
    return numberB - numberA;
  }
};

const sortByString: TMarketTableV2SortStrategyCallback = ({
  rowA,
  rowB,
  order,
  column,
}: {
  rowA: HTMLMarketTableV2RowElement;
  rowB: HTMLMarketTableV2RowElement;
  order: TMarketTableV2SortOrder;
  column: number;
}): number => {
  const contentA = rowA?.children[column]?.textContent?.trim()?.toUpperCase();
  const contentB = rowB?.children[column]?.textContent?.trim()?.toUpperCase();

  if (order === 'ascending') {
    if (contentA < contentB) return -1;
    if (contentA > contentB) return 1;
    return 0;
  } else {
    if (contentA < contentB) return 1;
    if (contentA > contentB) return -1;
    return 0;
  }
};

const sortByDateTime: TMarketTableV2SortStrategyCallback = ({
  rowA,
  rowB,
  order,
  column,
  format,
}: {
  rowA: HTMLMarketTableV2RowElement;
  rowB: HTMLMarketTableV2RowElement;
  order: TMarketTableV2SortOrder;
  column: number;
  format: string;
}): number => {
  const contentA = rowA?.children[column]?.textContent?.trim();
  const contentB = rowB?.children[column]?.textContent?.trim();
  const dateA = parse(contentA, format, new Date());
  const dateB = parse(contentB, format, new Date());

  if (order === 'ascending') {
    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;
    return 0;
  } else {
    if (dateA < dateB) return 1;
    if (dateA > dateB) return -1;
    return 0;
  }
};
