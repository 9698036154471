import { Component, Host, h, Prop, Element, State, Listen, Event, EventEmitter } from '@stencil/core';
import {
  endOfWeek,
  isAfter,
  isBefore,
  isSameDay,
  lastDayOfMonth,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
  startOfYesterday,
  startOfYear,
  lastDayOfYear,
  subYears,
} from 'date-fns';
import { v4 as uuid } from 'uuid';

import { MENU_SLOT_NAMES } from './enums/menu';
import { TMarketDateRangeChangedEventDetail, TMarketDatePickerMenuSelectionChangedEventDetail } from './events';

import { getNamespacedTagFor } from '../../utils/namespace';

/**
 * Object class to hold necessary date information when building out each market-date-picker-date for the calendar
 */
class Day {
  date: string = '';
  month: number = null;
  year: number = null;

  // For market-date-picker-date props
  selected: boolean = false;
  selection: 'none' | 'single' | 'range-first' | 'range-middle' | 'range-last' = 'none';
  today: boolean = false;
  disabled: boolean = false;
}

@Component({
  tag: 'market-date-picker',
  styleUrl: 'market-date-picker.css',
  shadow: true,
})
export class MarketDatePicker {
  @Element() el: HTMLMarketDatePickerElement;

  /**
   * Whether the date picker allows selection of a single date or a date range
   */
  @Prop() readonly selectionType: 'single' | 'range' = 'single';

  /**
   * User selected single date or start date for a range saved as an ISO formatted string.
   * Use DateTime string format as seen here: https://tc39.es/ecma262/#sec-date-time-string-format
   * Example: `YYYY-MM-DDT08:00`.
   */
  @Prop({ reflect: true, mutable: true }) selectedStartDate: string;

  /**
   * User selected end date for a range saved as an ISO formatted string.
   * Use DateTime string format as seen here: https://tc39.es/ecma262/#sec-date-time-string-format
   * Example: `YYYY-MM-DDT08:00`.
   */
  @Prop({ reflect: true, mutable: true }) selectedEndDate: string;

  /**
   * Preset menu option to populate date picker range.
   * Presetting `custom` should be used with `selectedStartDate` and `selectedEndDate` props.
   * Otherwise if both `selectedStartDate/selectedEndDate` and `presetMenuOption` are given, `presetMenuOption` takes precedence.
   */
  @Prop() readonly presetMenuOption: MENU_SLOT_NAMES;

  /**
   * Whether or not the side market-date-picker-menu is shown.
   * To pass in translation supported text, use slots available for each option.
   */
  @Prop() readonly displayMenu: boolean = false;

  /**
   * Position of menu on a mobile screen, if market-date-picker-menu is shown.
   */
  @Prop({ reflect: true }) readonly mobileMenuPosition: 'top' | 'bottom' = 'top';

  /**
   * A list of market-date-picker-menu items that will be excluded from appearing on the menu list.
   * i.e. `this-year,last-year` or `today,this-week,last-week,custom`
   * The menu names are lowercase and hyphenated strings, found here:
   * https://github.com/squareup/market/blob/main/web/web-components/src/components/market-date-picker/enums/menu.tsx
   *
   * This works in conjunction with timeframe,
   * i.e. "timeframe=past", excludes dates in the future in addition to the ones here.
   * This is written as items separated by ','.
   */
  @Prop() readonly excludeMenuItems: string = '';

  /**
   * String for setting timeframe type to select which market-date-picker-menu items to show.
   * Past means only past dates available, future is only current and future dates, and present is the default for all shown.
   */
  @Prop() readonly timeframe: 'past' | 'present' | 'future' = 'present';

  /**
   * A function that takes a datestring and returns a boolean determining if it should be disabled.
   * This does not override disabled dates based on the timeframe prop.
   * See https://ionicframework.com/docs/api/datetime#advanced-date-constraints for examples of passing in a function as a Stencil component prop.
   */
  @Prop() readonly isDateDisabled: (day: Date) => boolean;

  /**
   * Date picker locale. Defaults to browser locale. If that cannot be determined, defaults to 'en-US'.
   */
  @Prop() readonly locale: string = navigator.language || 'en-US';

  /**
   * The ISO formatted string that determines the displayed month on the calendar.
   * Use DateTime string format as seen here: https://tc39.es/ecma262/#sec-date-time-string-format
   * Example: `YYYY-MM-DDT08:00`.
   * Note: Omitting the time portion defaults to UTC, so this may display as a day behind on your calendar!
   * If you add hours it will default to your timezone, like the above example.
   * Invalid date strings default to today's date.
   */
  @Prop({ mutable: true }) displayedDate: string;

  /**
   * This enables the input field for the date picker.
   */
  @Prop({ reflect: true, mutable: true }) withInputs: '' | 'date' | 'date-and-time' = '';

  /**
   * Whether the selected dates are invalid.
   * It should not be possible to click on invalid dates, so this occurs through date inputs.
   */
  @Prop({ reflect: true, mutable: true }) invalid: boolean = false;

  /**
   * Displays a clickable caret in the month/year header.
   * If it is up, we show the month/year selection view instead of dates.
   */
  @Prop({ reflect: true, mutable: true }) yearViewActive: boolean = false;

  /**
   * Fired whenever the selected date range is changed.
   */
  @Event({ bubbles: true, composed: true }) marketDateRangeChanged: EventEmitter<TMarketDateRangeChangedEventDetail>;

  /**
   * @deprecated
   * **DEPRECATED (v4.5.0)** Use `marketDatePickerMenuSelectionChanged` instead.
   *
   * Fired whenever the menu selection is changed. Indicates which menu option is currently selected.
   * Possible values are found here:
   * https://github.com/squareup/market/blob/main/web/web-components/src/components/market-date-picker/enums/menu.tsx
   */
  @Event({ bubbles: true, composed: true })
  marketMenuSelectionChanged: EventEmitter<TMarketDatePickerMenuSelectionChangedEventDetail>;

  /**
   * Current displayed month and year for the calendar view. This can be updated with month or locale changes.
   */
  @State() displayedMonth: string;

  /**
   * Current displayed weekday header for the calendar view. This can be updated with locale changes.
   */
  @State() displayedWeekdays: Array<string> = [];

  /**
   * Current displayed years for the calendar year view. This can be updated with the left and right chevron buttons.
   */
  @State() yearViewYearsList: Array<string> = [];

  /**
   * Currently hovered date. Only used for date range calendar styling to highlight potential ranges.
   */
  @State() hoveredDate: Date;

  /**
   * Array holding our Day objects to build out calendar view.
   */
  private days: Array<Day>;

  /**
   * Current displayed months for the calendar year view. This can be updated with locale changes.
   */
  private yearViewMonthList: Array<string> = [];

  /**
   * Unique id for date picker label for a11y
   */
  private datePickerLabel: string;

  /**
   * Build out the weekday headers based on locale for the calendar view.
   */
  buildWeekdays() {
    this.displayedWeekdays = [];

    // Grab start of the week
    const weekday = startOfWeek(new Date(), { weekStartsOn: this.getLocaleFirstDayOfWeek() });
    let weekdayText;

    for (let d: number = 0; d < 7; d++) {
      weekdayText = weekday.toLocaleDateString(this.locale, { weekday: 'short' });

      // Shorten English based weekday headers by one
      if (this.locale.startsWith('en-')) {
        weekdayText = weekdayText.slice(0, -1);
      }
      this.displayedWeekdays.push(weekdayText);
      weekday.setDate(weekday.getDate() + 1);
    }
  }

  getLocaleFirstDayOfWeek() {
    const intl = new Intl.Locale(this.locale) as any;

    // Chrome uses weekInfo as a property, Safari uses getWeekInfo() as a fn on the locale to get week info.
    // This is not yet supported in Firefox, so default to Sunday if no first day info is found.
    const firstDay = intl?.weekInfo?.firstDay ?? intl?.getWeekInfo?.()?.firstDay ?? 0;

    // Translates the "Sunday" case, which weekInfo sets to 7
    return (firstDay % 7) as 0 | 1 | 2 | 3 | 4 | 5 | 6;
  }

  /**
   * Compares current hovered date to selected dates to determine correct styling for selection.
   */
  addHoveredDateRangeStyling(currentDate: Date, datePickerDay: Day) {
    if (!this.hoveredDate) {
      return;
    }

    const currentStartDate = new Date(this.selectedStartDate);
    // Set the day to be in the middle of a range when there is a start date and no end date,
    // and the day in question is between the start date and hovered date.
    if (
      this.selectedStartDate &&
      !this.selectedEndDate &&
      isBefore(currentDate, this.hoveredDate) &&
      isAfter(currentDate, currentStartDate)
    ) {
      datePickerDay.selection = 'range-middle';
    }

    if (!isSameDay(this.hoveredDate, currentDate)) {
      return;
    }

    // If there's no start date, or a date range is selected, or the hovered date is before or the same as the start date,
    // the hovered button should be the start of a new range.
    if (
      !this.selectedStartDate ||
      (this.selectedStartDate && this.selectedEndDate) ||
      !isAfter(this.hoveredDate, currentStartDate)
    ) {
      datePickerDay.selection = 'range-first';
    } else {
      datePickerDay.selection = 'range-last';
    }
  }

  /**
   * Helper function to add selection metadata to the date object being processed in buildCalendar.
   */
  addDateSelectionAttributes(day, calendarDate, currentStartDate, currentEndDate) {
    // Checks for start date comparison (for both single/range types)
    if (currentStartDate && isSameDay(calendarDate, currentStartDate)) {
      day.selected = true;
      day.selection = this.selectionType === 'single' ? 'single' : 'range-first';
    }

    // For range types only, check for dates within a selected range, or ends in a range
    if (this.selectionType === 'range') {
      this.addHoveredDateRangeStyling(calendarDate, day);

      if (
        currentStartDate &&
        currentEndDate &&
        isAfter(calendarDate, currentStartDate) &&
        isBefore(calendarDate, currentEndDate)
      ) {
        day.selected = true;
        day.selection = 'range-middle';
      }

      if (currentEndDate && isSameDay(calendarDate, currentEndDate)) {
        day.selected = true;
        day.selection = 'range-last';
      }
    }
  }

  /**
   * The main chunk of this component is building out the calendar view.
   * Ensures correct styling and formatting is placed on each date component.
   * Adapted from: https://ionicframework.com/blog/building-with-stencil-calendar-component/
   */
  buildCalendar() {
    const today: Date = new Date();
    const currentDisplayedDate = new Date(this.displayedDate);

    // Calendar used in iteration
    const calendar: Date = new Date(currentDisplayedDate.getFullYear(), currentDisplayedDate.getMonth(), 1);

    // First day of month may not be first day of week
    // Roll back until first day of week

    // First day can either start at Sunday or Monday, so if it is Monday, subtract a day at the start of the calendar
    const firstDayOffset = this.getLocaleFirstDayOfWeek();

    // Handles edge case where a locale with Monday as the first day, starts on Sunday
    // This case builds the calendar with 6 blank squares in front of the first day (as expected)
    if (calendar.getDay() === 0 && firstDayOffset === 1) {
      calendar.setDate(calendar.getDate() - 6);
    } else {
      calendar.setDate(calendar.getDate() - (calendar.getDay() - firstDayOffset));
    }

    // Clear days to be rendered
    this.days = [];

    // Create date objects from selected dates if they exist
    const currentStartDate = this.selectedStartDate && new Date(this.selectedStartDate);
    const currentEndDate = this.selectedEndDate && new Date(this.selectedEndDate);

    for (let d: number = 0; d < 42; d++) {
      // Day to be rendered
      // Seed with current date in iteration
      const day: Day = new Day();
      day.year = calendar.getFullYear();
      day.month = calendar.getMonth();

      // Populate day in month
      // Undefined date properties are not rendered
      if (
        calendar.getFullYear() === currentDisplayedDate.getFullYear() &&
        calendar.getMonth() === currentDisplayedDate.getMonth()
      ) {
        day.date = calendar.getDate().toString();
      }

      // Check for today
      if (isSameDay(calendar, today)) {
        day.today = true;
      }

      // Check for selection types
      this.addDateSelectionAttributes(day, calendar, currentStartDate, currentEndDate);

      // Check for disabled dates
      if (day.date && this.isDateDisabled) {
        day.disabled = this.isDateDisabled(calendar);
      }

      if (this.timeframe === 'future' && isBefore(calendar, today) && !isSameDay(calendar, today)) {
        day.disabled = true;
      }

      if (this.timeframe === 'past' && isAfter(calendar, today) && !isSameDay(calendar, today)) {
        day.disabled = true;
      }

      // Add to days to be rendered
      this.days.push(day);

      // Move to next date
      calendar.setDate(calendar.getDate() + 1);

      // Do not render the last week
      // Depending on calendar layout
      // Some months require five weeks
      // Others six weeks (see May 2021)
      if (calendar.getDay() === 0 + firstDayOffset && calendar.getMonth() !== currentDisplayedDate.getMonth()) {
        break;
      }
    }
  }

  /**
   * Helper function to clear date selections.
   */
  clearDateSelections() {
    this.selectedStartDate = null;
    this.selectedEndDate = null;
  }

  /**
   * Used for the navigation arrows in the market-date-picker header, moves the calendar forward/backward by increment.
   * Currently only used for +1/-1 month.
   */
  navigateMonth(increment) {
    const currentDisplayedDate = new Date(this.displayedDate);
    this.displayedDate = new Date(
      currentDisplayedDate.getFullYear(),
      currentDisplayedDate.getMonth() + increment,
      1,
    ).toISOString();
  }

  /**
   *  When ever an event changes the selected date, this updates the calendar view.
   *  The event receives a date string in the yyyy-mm-dd or yyy-mm-ddThh:mm format.
   *  this then checks what parts of the date have changed and only sends the changed parts to the
   * _selectDate function that receives (day, month, year)
   */
  @Listen('marketDatePickerInputDateSet')
  dateInputDateSet({ detail: { date, input } }) {
    const [newDate, time] = date.split('T');
    const [newYear, newMonth, newDay] = newDate.split('-').map(Number);
    this.displayedDate = new Date(newYear, newMonth - 1, 1).toString();

    // fallback to undefined, to prevent overwriting if time was initialized but withTime is disabled.
    const [hour, minute] = this.withInputs === 'date-and-time' ? time.split(':').map(Number) : [];
    this._selectDate({
      day: newDay,
      month: newMonth - 1,
      year: newYear,
      hour,
      minute,
      input,
    });
  }

  /**
   * Whenever a market-date-picker-date is selected, this updates the selected date props, which then updates the calendar view.
   */
  @Listen('marketDatePickerDateSelected')
  selectDate({ detail: { date: dateElement } }) {
    if (dateElement.type !== 'day') {
      this.updateDisplayedHeader(dateElement.type, dateElement.day);
    } else {
      this._selectDate({ day: dateElement.day });
    }
  }

  /**
   * Whenever a market-date-picker-date is moused over or hovered, this updates the hovered date to update the calendar view.
   */
  @Listen('marketDatePickerDateMousedOver')
  hoverDate({ detail: { date: dateElement } }) {
    const currentDisplayedDate = new Date(this.displayedDate);
    this.hoveredDate = new Date(currentDisplayedDate.getFullYear(), currentDisplayedDate.getMonth(), dateElement.day);
  }

  /**
   * Whenever a market-date-picker-date is moused out, clear any hovering styling in the calendar view.
   */
  @Listen('marketDatePickerDateMousedOut')
  mousedOutDate() {
    this.hoveredDate = null;
  }

  /**
   * Whenever a market-row within market-date-picker-menu is selected, this ensures we move the current selected dates
   * to whichever option the user has selected.
   */
  @Listen('marketDatePickerMenuSelectionChanged')
  selectMenuRow(e: CustomEvent<TMarketDatePickerMenuSelectionChangedEventDetail>) {
    const menuOption = e.detail.menuSelection;
    if (!menuOption) {
      return;
    }
    this.marketMenuSelectionChanged.emit({
      menuSelection: menuOption,
    });

    const prevStartDate = this.selectedStartDate;
    const prevEndDate = this.selectedEndDate;
    this._setMenuRowOption(menuOption);

    if (menuOption !== MENU_SLOT_NAMES.CUSTOM) {
      this.marketDateRangeChanged.emit({
        prevStartDate,
        prevEndDate,
        startDate: this.selectedStartDate,
        endDate: this.selectedEndDate,
        menuSelection: menuOption,
      });
    }
  }

  /**
   * Updates invalid state of date picker based on date input selections
   */
  @Listen('marketDatePickerInputDateInvalidStateChanged')
  setInvalidState({ detail: { invalid } }) {
    this.invalid = invalid;
  }

  private _setMenuRowOption(menuOption: `${MENU_SLOT_NAMES}`) {
    const today = new Date();

    switch (menuOption) {
      case MENU_SLOT_NAMES.TODAY:
        this.clearDateSelections();
        this.selectedStartDate = today.toISOString();
        break;

      case MENU_SLOT_NAMES.YESTERDAY:
        this.clearDateSelections();
        this.selectedStartDate = startOfYesterday().toISOString();
        break;

      case MENU_SLOT_NAMES.THIS_WEEK:
        this.selectedStartDate = startOfWeek(today).toISOString();
        if (this.selectionType === 'range') {
          this.selectedEndDate = endOfWeek(today).toISOString();
        }
        break;

      case MENU_SLOT_NAMES.LAST_WEEK: {
        const lastWeekDate = subWeeks(today, 1);

        this.selectedStartDate = startOfWeek(lastWeekDate).toISOString();
        if (this.selectionType === 'range') {
          this.selectedEndDate = endOfWeek(lastWeekDate).toISOString();
        }
        break;
      }

      case MENU_SLOT_NAMES.THIS_MONTH:
        this.selectedStartDate = startOfMonth(today).toISOString();
        if (this.selectionType === 'range') {
          this.selectedEndDate = lastDayOfMonth(today).toISOString();
        }
        break;

      case MENU_SLOT_NAMES.LAST_MONTH: {
        const lastMonthDate = subMonths(today, 1);

        this.selectedStartDate = startOfMonth(lastMonthDate).toISOString();
        if (this.selectionType === 'range') {
          this.selectedEndDate = lastDayOfMonth(lastMonthDate).toISOString();
        }
        break;
      }

      case MENU_SLOT_NAMES.THIS_YEAR:
        this.selectedStartDate = startOfYear(today).toISOString();
        if (this.selectionType === 'range') {
          this.selectedEndDate = lastDayOfYear(today).toISOString();
        }
        break;

      case MENU_SLOT_NAMES.LAST_YEAR: {
        const lastYearDate = subYears(today, 1);

        this.selectedStartDate = startOfYear(lastYearDate).toISOString();
        if (this.selectionType === 'range') {
          this.selectedEndDate = lastDayOfYear(lastYearDate).toISOString();
        }
        break;
      }

      default:
        break;
    }

    // Update displayed date to selected date
    if (menuOption !== MENU_SLOT_NAMES.CUSTOM) {
      this.displayedDate = this.selectedStartDate;
    }
  }

  _selectDate(options: {
    day?: number;
    month?: number;
    year?: number;
    hour?: number;
    minute?: number;
    input?: string;
  }) {
    const prevStartDate = this.selectedStartDate;
    const prevEndDate = this.selectedEndDate;

    const currentDisplayedDate = new Date(this.displayedDate);

    // Handles case where month is January, represented by 0
    const checkedMonth = options.month ?? currentDisplayedDate.getMonth();

    const day = new Date(
      options.year || currentDisplayedDate.getFullYear(),
      checkedMonth,
      options.day || currentDisplayedDate.getDate(),
      options.hour || currentDisplayedDate.getHours(),
      options.minute || currentDisplayedDate.getMinutes(),
    ).toISOString();

    if (this.displayMenu && !isSameDay(new Date(this.selectedStartDate), new Date(day))) {
      // Select custom menu row if it exists when calendar is updated
      const menu = this.el.shadowRoot.querySelector<HTMLMarketDatePickerMenuElement>(
        getNamespacedTagFor('market-date-picker-menu'),
      );
      menu._selectCustomRow();
    }

    if (this.selectionType === 'single') {
      this.clearDateSelections();
      this.selectedStartDate = day;
    } else {
      // Date range logic
      if (this.selectedStartDate && !this.selectedEndDate && day >= this.selectedStartDate) {
        // if new day is equal or after selected start date, and end date isn't set, update end date.
        this.selectedEndDate = day;
      } else {
        if (options.input) {
          if (options.input === 'start') this.selectedStartDate = day;
          if (options.input === 'end') this.selectedEndDate = day;
        } else {
          this.clearDateSelections();
          this.selectedStartDate = day;
        }
      }
    }

    this.marketDateRangeChanged.emit({
      prevStartDate,
      prevEndDate,
      startDate: this.selectedStartDate,
      endDate: this.selectedEndDate,
      menuSelection: MENU_SLOT_NAMES.CUSTOM,
    });
  }

  // MONTH & YEAR VIEW FUNCTIONS
  toggleCaret() {
    this.yearViewActive = !this.yearViewActive;
  }

  validateDisplayedDate(date: string) {
    // Handles invalid dates passed in to displayed date
    if (new Date(date).toString() === 'Invalid Date') {
      this.displayedDate = new Date().toISOString();
    }
  }

  buildInitialYearView() {
    this.validateDisplayedDate(this.displayedDate);

    const currentDisplayedDate = new Date(this.displayedDate);
    const currentYear = currentDisplayedDate.getFullYear();
    this.yearViewYearsList = [(currentYear - 1).toString(), currentYear.toString(), (currentYear + 1).toString()];

    for (let i = 0; i < 12; i++) {
      const monthName = new Date(2024, i).toLocaleDateString(this.locale, { month: 'short' });
      this.yearViewMonthList.push(monthName);
    }
  }

  updateDisplayedYearList(increment: number) {
    this.yearViewYearsList = this.yearViewYearsList.map((year) => {
      return (Number(year) + increment).toString();
    });
  }

  updateDisplayedHeader(type: string, value: string) {
    let updatedDate;
    if (type === 'year') {
      updatedDate = { year: value };
    }

    if (type === 'month') {
      const monthDate = new Date(`${value} 1`);
      const monthValue = monthDate.toLocaleDateString(this.locale, { month: 'numeric' });

      updatedDate = { month: Number(monthValue) - 1 };

      // Close the year view if month is selected
      this.toggleCaret();
    }

    const currentDisplayedDate = new Date(this.displayedDate);

    // Handles case where month is January, represented by 0
    const checkedMonth = updatedDate?.month ?? currentDisplayedDate.getMonth();
    const day = new Date(updatedDate.year || currentDisplayedDate.getFullYear(), checkedMonth, 1).toISOString();

    this.displayedDate = day;
  }

  componentWillLoad() {
    if (this.presetMenuOption) {
      this._setMenuRowOption(this.presetMenuOption);
    }

    this.buildInitialYearView();
    this.datePickerLabel = `date-picker-label-${uuid()}`;
  }

  /**
   * Core function called whenever props or states are changed to update the calendar view.
   */
  componentWillRender() {
    this.validateDisplayedDate(this.displayedDate);
    this.displayedMonth = new Date(this.displayedDate).toLocaleString(this.locale, { month: 'short', year: 'numeric' });
    this.buildWeekdays();
    this.buildCalendar();
  }

  render() {
    const MarketDatePickerMenuTagName = getNamespacedTagFor('market-date-picker-menu');
    const MarketDatePickerDateTagName = getNamespacedTagFor('market-date-picker-date');
    const MarketDatePickerInputDateTagName = getNamespacedTagFor('market-date-picker-input-date');
    const MarketButtonTagName = getNamespacedTagFor('market-button');
    const MarketIconTagName = getNamespacedTagFor('market-icon');
    const MarketDividerTagName = getNamespacedTagFor('market-divider');

    // TODO: remove when design tokens are added (UI-6454)
    const DATE_PICKER_BUTTON_PREVIOUS_YEAR_ICON_ASSET = 'chevron-left';
    const DATE_PICKER_BUTTON_NEXT_YEAR_ICON_ASSET = 'chevron-right';
    const DATE_PICKER_BUTTON_PREVIOUS_MONTH_ICON_ASSET = 'arrow-left';
    const DATE_PICKER_BUTTON_NEXT_MONTH_ICON_ASSET = 'arrow-right';

    return (
      <Host class="market-date-picker">
        {/* DATE PICKER MENU */}
        {this.displayMenu && (
          <list-view>
            <MarketDatePickerMenuTagName
              timeframe={this.timeframe}
              excludeMenuItems={this.excludeMenuItems}
              presetMenuOption={this.presetMenuOption}
            >
              <slot name={MENU_SLOT_NAMES.TODAY} slot={MENU_SLOT_NAMES.TODAY}>
                Today
              </slot>
              <slot name={MENU_SLOT_NAMES.YESTERDAY} slot={MENU_SLOT_NAMES.YESTERDAY}>
                Yesterday
              </slot>
              <slot name={MENU_SLOT_NAMES.THIS_WEEK} slot={MENU_SLOT_NAMES.THIS_WEEK}>
                This week
              </slot>
              <slot name={MENU_SLOT_NAMES.LAST_WEEK} slot={MENU_SLOT_NAMES.LAST_WEEK}>
                Last week
              </slot>
              <slot name={MENU_SLOT_NAMES.THIS_MONTH} slot={MENU_SLOT_NAMES.THIS_MONTH}>
                This month
              </slot>
              <slot name={MENU_SLOT_NAMES.LAST_MONTH} slot={MENU_SLOT_NAMES.LAST_MONTH}>
                Last month
              </slot>
              <slot name={MENU_SLOT_NAMES.THIS_YEAR} slot={MENU_SLOT_NAMES.THIS_YEAR}>
                This year
              </slot>
              <slot name={MENU_SLOT_NAMES.LAST_YEAR} slot={MENU_SLOT_NAMES.LAST_YEAR}>
                Last year
              </slot>
              <slot name={MENU_SLOT_NAMES.CUSTOM} slot={MENU_SLOT_NAMES.CUSTOM}>
                Custom
              </slot>
            </MarketDatePickerMenuTagName>
          </list-view>
        )}

        {/* DATE PICKER CALENDAR VIEW */}
        <calendar>
          {/* DATE PICKER HEADER */}
          <header>
            <nav>
              {!this.yearViewActive && (
                <MarketButtonTagName
                  class="left-nav"
                  size="small"
                  aria-label="Previous month"
                  onClick={() => this.navigateMonth(-1)}
                >
                  <MarketIconTagName
                    slot="icon"
                    name={DATE_PICKER_BUTTON_PREVIOUS_MONTH_ICON_ASSET}
                  ></MarketIconTagName>
                </MarketButtonTagName>
              )}
              <MarketButtonTagName
                class="year-view-button"
                aria-label={'Toggle year view'}
                onClick={() => this.toggleCaret()}
                caret={this.yearViewActive ? 'up' : 'down'}
                rank="tertiary"
              >
                <h2 id={this.datePickerLabel}>{this.displayedMonth}</h2>
              </MarketButtonTagName>
              {!this.yearViewActive && (
                <MarketButtonTagName size="small" aria-label="Next month" onClick={() => this.navigateMonth(1)}>
                  <MarketIconTagName slot="icon" name={DATE_PICKER_BUTTON_NEXT_MONTH_ICON_ASSET}></MarketIconTagName>
                </MarketButtonTagName>
              )}
            </nav>
          </header>

          {/* DATE PICKER GROUP VIEW */}
          {!this.yearViewActive && (
            <month-view role="grid" aria-labelledby={this.datePickerLabel}>
              {
                // Set the weekdays header for the date picker calendar
                this.displayedWeekdays.map((text) => (
                  <MarketDatePickerDateTagName
                    class={'weekday-header'}
                    disabled={true}
                    day={text}
                  ></MarketDatePickerDateTagName>
                ))
              }
              {
                // Set the available dates
                this.days.map((day: Day) => (
                  <MarketDatePickerDateTagName
                    disabled={day.disabled}
                    selection={day.selection}
                    today={day.today}
                    selected={day.selected}
                    day={day.date || null}
                    type="day"
                  ></MarketDatePickerDateTagName>
                ))
              }
            </month-view>
          )}
          {this.yearViewActive && (
            <year-view aria-labelledby={this.datePickerLabel}>
              {/* add chevrons for year updates */}
              <year-section>
                <MarketButtonTagName
                  size="small"
                  rank="tertiary"
                  aria-label="Previous year"
                  onClick={() => this.updateDisplayedYearList(-1)}
                >
                  <MarketIconTagName slot="icon" name={DATE_PICKER_BUTTON_PREVIOUS_YEAR_ICON_ASSET}></MarketIconTagName>
                </MarketButtonTagName>
                {this.yearViewYearsList.map((year) => (
                  <MarketDatePickerDateTagName day={year} type="year"></MarketDatePickerDateTagName>
                ))}
                <MarketButtonTagName
                  size="small"
                  rank="tertiary"
                  aria-label="Next year"
                  onClick={() => this.updateDisplayedYearList(1)}
                >
                  <MarketIconTagName slot="icon" name={DATE_PICKER_BUTTON_NEXT_YEAR_ICON_ASSET}></MarketIconTagName>
                </MarketButtonTagName>
              </year-section>
              <MarketDividerTagName size="thin"></MarketDividerTagName>
              <month-section role="grid">
                {this.yearViewMonthList.map((monthName: string) => (
                  <MarketDatePickerDateTagName day={monthName} type="month"></MarketDatePickerDateTagName>
                ))}
              </month-section>
            </year-view>
          )}
          {this.withInputs && (
            <MarketDatePickerInputDateTagName
              class="date-input-top-margin"
              range={this.selectionType === 'range'}
              isDateDisabled={this.isDateDisabled}
              selectedStartDate={this.selectedStartDate}
              selectedEndDate={this.selectedEndDate}
              withTime={this.withInputs === 'date-and-time'}
              timeframe={this.timeframe}
            >
              <slot name="start-date" slot="start-date">
                {this.selectionType === 'range' ? 'Start ' : ''}Date
              </slot>
              <slot name="end-date" slot="end-date">
                End Date
              </slot>
              <slot name="start-time" slot="start-time">
                {this.selectionType === 'range' ? 'Start ' : ''}Time
              </slot>
              <slot name="end-time" slot="end-time">
                End Time
              </slot>
              <slot name="range-error" slot="range-error">
                Enter a valid date range
              </slot>
            </MarketDatePickerInputDateTagName>
          )}
        </calendar>
      </Host>
    );
  }
}
