import { isInboxSubdomain } from 'src/utils/url';
import Logger from 'src/Logger';

export enum PerformanceEvent {
  FULL_PAGE_APP_LOADED = 'full-page-app-loaded',
  TRANSCRIPT_VIEW_ITEMS_LIST_SHOWN = 'transcript-view-items-list-shown',
  SETUP_FULL_PAGE_APP_START = 'setup-full-page-app-start',
  SETUP_FULL_PAGE_APP_USER_INFO_LOADED = 'setup-full-page-app-user-info-loaded',
  SETUP_FULL_PAGE_APP_FF_LOADED = 'setup-full-page-app-ff-loaded',
  SETUP_FULL_PAGE_APP_SUBS_LOADED = 'setup-full-page-app-subs-loaded',
  SETUP_FULL_PAGE_APP_SAAS_LOADED = 'setup-full-page-app-saas-loaded',
  TRANSCRIPT_DETAILS_PRE_FETCH = 'transcript-details-pre-fetch',
  TRANSCRIPT_HISTORY_PRE_FETCH = 'transcript-history-pre-fetch',
  PAST_APPOINTMENTS_PRE_FETCH = 'past-appointments-pre-fetch',
  CURRENT_USER_DATA_FETCH = 'current-user-data-fetch',
}

type PerformanceEventWithStart = `${PerformanceEvent}-start`;
type PerformanceEventWithEnd = `${PerformanceEvent}-end`;

const getMarkName = (
  event: PerformanceEvent | PerformanceEventWithStart | PerformanceEventWithEnd,
): string => {
  return `messages-${event}`;
};

const _mark = (
  event: PerformanceEvent | PerformanceEventWithStart | PerformanceEventWithEnd,
): void => {
  const eventName = getMarkName(event);
  performance.mark(eventName); // Logs in browser performance tools
  window.DD_RUM?.onReady(() => {
    window.DD_RUM?.addAction(eventName, {
      timestamp: Date.now(),
      performance_now: performance.now(),
      event_type: 'performance.mark',
    }); // Adds event in DataDog that can be queried and used to build custom dashboards
    window.DD_RUM?.addTiming(eventName); // Adds timing info in DataDog for use in performance waterfall
  });
};

/**
 * Marks a point in time in the performance timeline for a given event.
 *
 * @param {string} event
 * The name of the event.
 */
export const mark = (
  event: PerformanceEvent | PerformanceEventWithStart | PerformanceEventWithEnd,
): void => {
  if (!isInboxSubdomain()) {
    return;
  }

  try {
    _mark(event);
  } catch (error) {
    Logger.error('Error logging performance mark.', undefined, { error });
  }
};

const _measure = (
  start: PerformanceEvent | PerformanceEventWithStart,
  end: PerformanceEvent | PerformanceEventWithEnd,
): void => {
  const startMarkName = getMarkName(start);
  const endMarkName = getMarkName(end);
  const name = `${startMarkName}_to_${endMarkName}`;

  performance.measure(name, startMarkName, endMarkName); // Logs in browser performance tools

  const measure = performance.getEntriesByName(name)[0];

  window.DD_RUM?.onReady(() => {
    window.DD_RUM?.addAction('performance_measure', {
      name,
      duration: measure.duration,
      start_time: measure.startTime,
      event_type: 'performance.measure',
    }); // Adds event in DataDog that can be queried and used to build custom dashboards
  });
};

/**
 * Measures the time between two points in the performance timeline.
 *
 * @param {string} start
 * The name of the start event.
 * @param {string} end
 * The name of the end event.
 */
export const measure = (
  start: PerformanceEvent | PerformanceEventWithStart,
  end: PerformanceEvent | PerformanceEventWithEnd,
): void => {
  if (!isInboxSubdomain()) {
    return;
  }

  try {
    _measure(start, end);
  } catch (error) {
    Logger.error('Error logging performance measure.', undefined, { error });
  }
};

/**
 * Marks a start point for a given event name in the performance timeline.
 * Intended to be used in conjunction with `markEnd` to specify when this event ends.
 *
 * @param {PerformanceEvent} event
 * The name of the event.
 */
export const markStart = (event: PerformanceEvent): void => {
  mark(`${event}-start`);
};

/**
 * Marks an end point for a given event name in the performance timeline.
 * Intended to be used in conjunction with `markStart` to specify when this event starts.
 *
 * @param {PerformanceEvent} event
 * The name of the event.
 */
export const markEnd = (event: PerformanceEvent): void => {
  mark(`${event}-end`);
  measure(`${event}-start`, `${event}-end`);
};
