@property --icon-width {
  syntax: "<length>";
  inherits: false;
  initial-value: 24px;
}

@property --icon-height {
  syntax: "<length>";
  inherits: false;
  initial-value: 24px;
}

:host {
  display: inline-block;
  width: var(--icon-width);
  height: var(--icon-height);
  color: inherit;
}

:host([tintable]) {
  ::slotted(svg) {
    fill: currentcolor;

    path,
    circle,
    rect,
    polygon,
    ellipse,
    line,
    polyline {
      fill: inherit;
    }
  }
}

::slotted(svg) {
  display: block;
  width: 100%;
  height: 100%;
}
