import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { getVerifyIdentityUrl } from 'src/utils/url';
import { MarketButton } from 'src/components/Market';
import MessengerModalDialog from 'src/components/MessengerModalDialog/MessengerModalDialog';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import 'src/components/UnverifiedModal/UnverifiedModal.scss';

/**
 * A modal to let the merchant know that they will need to verify
 * their identity before they start using Messages.
 *
 * @example
 * Basic usage:
 * <UnverifiedModal />
 * @author eblaine
 */
const UnverifiedModal = observer((): ReactElement => {
  const { modal, event, subscription } = useMessengerControllerContext();
  const { closeModal, unverifiedModalVariant: variant } = modal;
  const { t } = useTranslation();

  const title =
    variant === 'SUBSCRIPTION_BLOCKED'
      ? t('UnverifiedModal.messagesPlusPricingTitle')
      : t('UnverifiedModal.title');

  let description;
  if (
    variant === 'SUBSCRIPTION_BLOCKED' &&
    subscription.isEligibleForSquareOneFeature
  ) {
    description = t('UnverifiedModal.messagesPlusPricingDescriptionSqOne');
  } else if (variant === 'SUBSCRIPTION_BLOCKED') {
    description = t('UnverifiedModal.messagesPlusPricingDescription');
  } else {
    description = t('UnverifiedModal.description');
  }

  const primaryButton = (
    <MarketButton
      rank="primary"
      onClick={() => {
        event.track('Click IDV Modal Verify Identity', {
          reason: variant,
        });
        window.open(getVerifyIdentityUrl, '_blank');
        closeModal();
      }}
    >
      {t('UnverifiedModal.verifyIdentity')}
    </MarketButton>
  );

  const secondaryButton = (
    <MarketButton
      rank="secondary"
      onClick={() => {
        closeModal();
        event.track('Click IDV Modal Dismiss', {
          reason: variant,
        });
      }}
    >
      {t('UnverifiedModal.dismiss')}
    </MarketButton>
  );

  return (
    <MessengerModalDialog
      title={title}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      close={closeModal}
    >
      <p className="UnverifiedModal__description">{description}</p>
    </MessengerModalDialog>
  );
});

export default UnverifiedModal;
