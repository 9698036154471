@import "../../../../../styles/mixins/component-base.css";
@import "../../../../../styles/mixins/typography.css";
@import "./market-progress-tracker-step.button.css";
@import "./market-progress-tracker-step.connector.css";
@import "./market-progress-tracker-step.content.css";
@import "./market-progress-tracker-step.horizontal.css";
@import "./market-progress-tracker-step.icon.css";
@import "./market-progress-tracker-step.vertical.css";

:host {
  display: inline-grid;
}
