import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { MessagesPluginEntry } from 'src/MessengerTypes';
import CustomerEventCard from 'src/pages/TranscriptViewPage/components/CustomerEventCard/CustomerEventCard';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { BaseContextualEventCardProps } from 'src/types/BaseContextualEventCardProps';
import MessagesPluginLaunchBanner from 'src/components/MessagesPluginLaunchBanner/MessagesPluginLaunchBanner';
import useIsMobile from 'src/hooks/useIsMobile';
import PhoneMessageIcon from 'src/svgs/PhoneMessageIcon';
import { Utterance } from 'src/gen/squareup/messenger/v3/messenger_service';
import './MessagesPluginEntryEventCard.scss';

/**
 * Renders an event card for a Messages Plugin Entry.
 */
const MessagesPluginEntryEventCard = observer(
  ({ item, cardRef }: BaseContextualEventCardProps) => {
    const { timestampMillis, data, attachedUtterance, showCustomerImage } =
      item;
    const { message, url, urlLabel, urlLabelEnum, ftux } =
      data as MessagesPluginEntry;
    const { tooltip, transcriptView, navigation, event } =
      useMessengerControllerContext();
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    const utteranceId = attachedUtterance?.utterance.id;

    const source =
      urlLabelEnum ??
      Utterance.Metadata.MessagesPluginEntry.UrlLabel.URL_LABEL_UNRECOGNIZED;

    return (
      <CustomerEventCard
        timestampMillis={timestampMillis}
        icon={<PhoneMessageIcon />}
        title={t('ContextualEvent.messagesPlugin.title')}
        subtitle={
          url ? (
            <a
              className="MessagesPluginEntryEventCard__booking-site-url"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                event.track('Click View Context Link', {
                  unit_token: transcriptView.transcript.sellerKey,
                  utterance_id: utteranceId,
                  context_source: source,
                  url,
                });
              }}
            >
              {urlLabel ||
                t('ContextualEvent.messagesPlugin.default_booking_link_label')}
            </a>
          ) : undefined
        }
        showCustomerImage={showCustomerImage}
        body={<div className="paragraph-30">{message}</div>}
        cardRef={cardRef}
        id={utteranceId}
        className="MessagesPluginEntryEventCard"
      >
        {tooltip.isVisible('MESSAGES_PLUGIN_LAUNCH_BANNER') &&
          !isMobile &&
          navigation.application === 'FULL_PAGE' &&
          utteranceId &&
          transcriptView.mostRecentMessagesPluginEntryUtterance?.id ===
            utteranceId && (
            <MessagesPluginLaunchBanner source={source} ftux={ftux} />
          )}
      </CustomerEventCard>
    );
  },
);

export default MessagesPluginEntryEventCard;
